import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/reducers/auth';
import CurrentTime from '../atoms/CurrentTime';
import useUser from 'hooks/auth/useUser';
import ArrowDown from '../atoms/CustomIcons/ArrowDown';
import AedProfilePopup from '../molecules/AedProfilePopup';
import Person from '@mui/icons-material/Person';
import ProfilePopupBody, { ProfilePopupProvider } from '../molecules/ProfilePopupBody';

function Header({ userType }) {
  const { isLoggedIn, user } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const onClick = () => {
    setShowPopup(false);
    dispatch(logout());
    navigate('/');
  };

  return (
    <div className="flex justify-between fort font-extralight tracking-[2px]">
      <div className="flex space-x-4 mx-8 my-4"></div>
      <div
        className="flex items-center relative gap[8px] space-x-4 pr-8 py-5 w-58"
        style={{ cursor: 'default' }}
      >
        <div className="flex flex-row gap-[16px] items-center mr-[16px]">
          <Typography variant="p" className="text-black border-black-100 text-[12px]">
            <CurrentTime />
          </Typography>
        </div>
        {isLoggedIn && (
          <div
            className="flex items-center gap-[8px] cursor-pointer"
            onClick={() => setShowPopup(!showPopup)}
          >
            <div className="uppercase text-[12px]">
              {user?.name || `${user?.firstName} ${user?.lastName}`}
            </div>
            <div
              className={`transition-all ease-in-out duration-300 ${
                showPopup ? 'rotate-180' : 'rotate-0'
              }`}
            >
              <ArrowDown size={12} />
            </div>
            <Person
              style={{
                color: 'white',
                fontSize: '32px',
                cursor: 'pointer',
                border: '3px solid white',
                borderRadius: '100%',
              }}
            />
          </div>
        )}
      </div>
      <ProfilePopupProvider>
        {showPopup && (
          <AedProfilePopup onClose={() => setShowPopup(false)}>
            <ProfilePopupBody onLogout={onClick} />
          </AedProfilePopup>
        )}
      </ProfilePopupProvider>
    </div>
  );
}

export default Header;
