import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
// import Paper from '@mui/material/Paper';

export default function NavigationPopper({ children, isPopover, setIsPopover, elem }) {
  return (
    <Box
      sx={{
        width: 500,
        zIndex: '5000',
        backgroundColor: '#232431',
        color: '#8e9193',
      }}
    >
      <Popper
        sx={{
          maxHeight: '50vh',
          maxWidth: '280px',
          // overflowY: 'auto',
          zIndex: '5000',
          backgroundColor: '#232431',
          // left: '10px !important',
          left: '24px !important',
          // borderRadius: '8px',
          padding: '32px',
          overflowX: 'hidden',
        }}
        className="list-scroll-w"
        open={isPopover}
        anchorEl={elem}
        placement={'right-start'}
        transition
        onMouseEnter={() => setIsPopover(true)}
        onMouseLeave={() => setIsPopover(false)}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <div>{children}</div>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
