import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} UploadFile
 * @prop {string} fileName
 * @prop {string} fileExt
 * @prop {File | null} file
 * @prop {number} timestamp
 */

/**
 * @typedef {Object} ProjectSingleViewState
 * @prop {UploadFile[]} uploadFiles
 * @prop {UploadFile} projectPresentation
 * @prop {UploadFile} projectProgressSnapshot
 */

/**
 * @type {Object<string, ProjectSingleViewState>}
 */
const initialState = {
  collections: {},
};

const projectSingleViewStateSlice = createSlice({
  name: 'projectSingleViewState',
  initialState,
  reducers: {
    setUploadFiles: (state, action) => {
      // state.uploadFiles = action.payload;
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections[action.payload.id].uploadFiles =
        state.collections[action.payload.id].uploadFiles || [];
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          uploadFiles: action.payload.data,
        },
      };
      return state;
    },

    appendUploadFiles: (state, action) => {
      // state.uploadFiles = [...state.uploadFiles, ...action.payload];
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections[action.payload.id].uploadFiles =
        state.collections[action.payload.id].uploadFiles || [];
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          uploadFiles: [
            ...state.collections[action.payload.id].uploadFiles,
            ...action.payload.data,
          ],
        },
      };
      return state;
    },

    setProjectPresentation: (state, action) => {
      // state.projectPresentation = action.payload;
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          projectPresentation: action.payload.data,
        },
      };
      return state;
    },

    setProjectProgressSnapshot: (state, action) => {
      // state.projectProgressSnapshot = action.payload;
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          projectProgressSnapshot: action.payload.data,
        },
      };
      return state;
    },
  },
});

export const {
  setUploadFiles,
  appendUploadFiles,
  setProjectPresentation,
  setProjectProgressSnapshot,
} = projectSingleViewStateSlice.actions;

export default projectSingleViewStateSlice.reducer;
