import ArrowRightIcon from '../atoms/CustomIcons/ArrowRight';

//
/**
 * @type {import('react').CSSProperties}
 */
const styles = {};

/**
 *
 * @param {import('react').HTMLAttributes & {text: string, icon: Component }} param0
 * @returns
 */
export default function OvalIconButtonWhite({ text, icon, style, className, ...props }) {
  return (
    <button
      className={
        'font-normal flex flex-row items-center text-[12px] tracking-[2px] ' +
        'border-white border-[1px] bg-white/0 h-[36px] gap-[8px] pl-[16px] pr-[12px] ' +
        'bg-white/0 h-[36px] gap-[8px] pl-[16px] pr-[12px] py-[4px] rounded-[80px] ' +
        'box-border hover:bg-white/30' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      style={{ ...styles, ...style }}
      {...props}
    >
      <div>{text || 'Button Text'}</div>
      {!!icon && <div>{icon || <ArrowRightIcon size={12} />}</div>}
    </button>
  );
}
