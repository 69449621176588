import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfessionalDevelopmentRecordList from './ProfessionalDevelopmentRecordList';
import ProfessionalDevelopmentRecord from './ProfessionalDevelopmentRecord';

export default function SelfWorthRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <Routes>
      <Route
        path="professional-development-record"
        element={<ProfessionalDevelopmentRecordList />}
      />
      <Route path="professional-development-record/*" element={<ProfessionalDevelopmentRecord />} />
    </Routes>
  );
}
