const key = 'ZhdcWcopY';

/**
 * @typedef {Object} RememberParams
 * @prop {string} email
 * @prop {string} password
 */

/**
 * @param {RememberParams} param0
 */
export const setRemember = (params) => {
  const val = key + window.btoa(JSON.stringify(params));
  return localStorage.setItem('remember', val);
};

/**
 * @returns {RememberParams}
 */
export const getRemember = () => {
  const val = localStorage.getItem('remember');
  if (typeof val === 'string') {
    const regex = new RegExp(`^${key}`);
    const b64 = val.replace(regex, '');
    if (val === '') return null;
    return JSON.parse(window.atob(b64));
  }

  return null;
};

export const getRememberCheckStatus = () => {
  const val = localStorage.getItem('rememberChecked');
  if (val !== null && val !== '') {
    return JSON.parse(val);
  }
  return false;
};

/**
 * @param {boolean} status
 */
export const setRememberCheckStatus = (status) => {
  localStorage.setItem('rememberChecked', JSON.stringify(status));
};

export const removeRemember = () => {
  localStorage.removeItem('remember');
};
