import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import StrokeText from 'components/UI/atoms/StrokeText';
import useReviewYourProfileDevelopmentState from 'hooks/student/useReviewYourProfileDevelopmentState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import useDirectories from 'hooks/student/useDirectories';
import useUser from 'hooks/auth/useUser';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function ReviewYourProfileDevelopment() {
  const { userType } = useUser();
  const [searchParams] = useSearchParams();
  const { histories } = useFileUploadPanel();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    slot1,
    slot2,
    slot3,
    slot1Input,
    slot2Input,
    slot3Input,
    removeSlot1,
    removeSlot2,
    removeSlot3,
    targetSlot,
    handleLinkUpload,
  } = useReviewYourProfileDevelopmentState();
  const { list: folderList, getByPath } = useDirectories();
  const { list: assignmentDocuments } = useAssignmentDocuments();

  const currentFolder =
    folderList.find((item) => item.id === Number(searchParams.get('folderId'))) || {};

  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'REVIEW YOUR PROFILE DEVELOPMENT',
      path: '/engagement/review-your-profile-development?folderId=' + currentFolder.id,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
      case 'slot2':
        targetSlot.current = 2;
        break;
      case 'slot3':
        targetSlot.current = 3;
        break;
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
      case 'slot2':
        slot2Input.click();
        break;
      case 'slot3':
        slot3Input.click();
        break;
    }
  };
  return (
    <div>
      <MainHeadingText>REVIEW YOUR PROFILE DEVELOPMENT</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => <>C.V. DRAFT 3</>}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={removeSlot1}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => <>C.V. DRAFT 2</>}
            activeFile={slot2}
            histories={histories}
            onUpload={() => uploadModal('slot2')}
            onRemoveHistory={removeSlot2}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => <>C.V. DRAFT 1</>}
            activeFile={slot3}
            histories={histories}
            onUpload={() => uploadModal('slot3')}
            onRemoveHistory={removeSlot3}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      <DashboardContentText>
        Have you effectively evidenced the experience and knowledge which you will need in order to
        make a success of life after 6th Form?{' '}
      </DashboardContentText>
      <DashboardContentText>
        This area is for you to review your profile development and will give you a clear picture of
        what you need to do in order to be able to compete well for opportunities and might wish to
        take up in the future.
      </DashboardContentText>
      <DashboardContentText>
        These could include jobs whilst at university, apprenticeships or even a first job after 6th
        Form. apprenticeships or even a first job after 6th Form.
      </DashboardContentText>
      <div style={{ height: 36 }} />
      {userType === 'student' ? (
        <>
          <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
          {assignmentDocuments.length === 0 ? (
            <div className="py-[56px]">
              <StrokeText>
                No Assignment <br />
                Documents
              </StrokeText>
            </div>
          ) : (
            <DocumentCardContainer>
              {assignmentDocuments.map((item, index) => (
                <DocumentCard data={item} key={index} onClick={item.onClick} />
              ))}
            </DocumentCardContainer>
          )}
          <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
            <BottomLandscapeCard
              title="VISIT THE EVIDENCE RECORD"
              buttonTitle="START"
              onClick={() => {
                navigate('/student/purpose/evidence-record');
              }}
            />
          </div>
        </>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default ReviewYourProfileDevelopment;
