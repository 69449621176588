// import OvalIconButton from 'components/UI/molecules/OvalIconButton';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import EvidenceRecordCard from 'components/UI/molecules/EvidenceRecordCard';
import CompetenceScale from 'components/UI/molecules/CompetenceScale';
import { Route, Routes, useLocation } from 'react-router-dom';
import SkillView from './SkillView';
import useEvidenceSkillsState from 'hooks/student/useEvidenceSkillsState';
import { useEffect } from 'react';
import useEvidenceCompetencyState from 'hooks/student/useEvidenceCompetencyState';

export default function EvidenceRecordView() {
  const loc = useLocation();
  const { getList, list: evidenceSkillList } = useEvidenceSkillsState();
  const { getList: getCompetencyList, list: evidenceCompetencyList } = useEvidenceCompetencyState();

  useEffect(() => {
    getList();
    getCompetencyList();
  }, []);

  return (
    <>
      <div className="flex flex-row items-center mt-[16px] mb-[16px] p-[16px] gap-[16px] e-f-collapse">
        {/* <div className='flex flex-row items-center gap-[16px]'>
          <OvalIconButton
            title='SORT'
            textLight={true}
            style={{
              border: 'none',
              minWidth: 0,
            }}
            labelProps={{
              paddingLeft: 0,
            }}
            icon={
              <ChevronRightIcon
                style={{
                  fontSize: '44px',
                  fontWeight: 100,
                }}
              />
            }
          />
          <OvalIconButton
            title='GRADE'
            textLight={true}
            style={{
              minWidth: 0,
            }}
            icon={
              <KeyboardArrowUpIcon
                style={{
                  fontSize: '44px',
                  fontWeight: 100,
                }}
              />
            }
          />
          <OvalIconButton
            title='INCOMPLETE'
            textLight={true}
            style={{
              minWidth: 0,
            }}
          />
          <OvalIconButton
            title='COMPLETE'
            textLight={true}
            style={{
              minWidth: 0,
            }}
          />
        </div> */}
        {/* <div className='flex-1'></div> */}
        <Routes>
          <Route path="skill/*" element={<CompetenceScale />} />
        </Routes>
      </div>
      <div style={{ height: 40 }} />
      <DashboardContentHeading className={'px-[16px]'}>
        START BY SELECTING A SKILL
      </DashboardContentHeading>

      <Routes>
        <Route
          path=""
          element={
            <div className="flex flex-row flex-wrap gap-[12px] mt-[32px] px-[16px]">
              {evidenceSkillList.map(
                ({ id, title, subtitle, code, description, createdAt, updatedAt, ...props }) => {
                  return (
                    <EvidenceRecordCard
                      title={`${code}: ${title}`}
                      content={subtitle}
                      info={description}
                      {...props}
                      key={id}
                      to={`skill/${encodeURIComponent(id)}${loc.search}`}
                    />
                  );
                }
              )}
            </div>
          }
        />

        <Route path=":mode/:id" element={<SkillView />} />
      </Routes>

      <div style={{ height: 72 }} />
      <DashboardContentHeading className={'px-[16px]'}>
        START BY SELECTING A COMPETENCY
      </DashboardContentHeading>

      <div className="flex flex-row flex-wrap gap-[12px] mt-[32px] px-[16px] items-stretch">
        {evidenceCompetencyList.map(
          ({ id, title, description, code, createdAt, updatedAt, ...props }) => {
            return (
              <EvidenceRecordCard
                title={code}
                content={title}
                info={description}
                {...props}
                key={id}
                to={`competency/${encodeURIComponent(id)}${loc.search}`}
              />
            );
          }
        )}
      </div>
    </>
  );
}
