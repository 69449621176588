import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import SubHeadingText from 'components/UI/atoms/SubHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import HighlightCard from 'components/UI/molecules/HighlightCard';
import EvidenceHighlightsItem from 'components/UI/molecules/EvidenceHighlightsItem';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import selfWorthBgImage from 'assets/images/svg/self-worth-bg.svg';
import engagementBgImage from 'assets/images/svg/engagement-bg.svg';
import purposeBgImage from 'assets/images/svg/purpose-bg.svg';
import DirectoriesLoader from 'components/UI/molecules/student/DirectoriesLoader';
import useDirectories from 'hooks/student/useDirectories';
import useStudentAssessment from 'hooks/trainer/useStudentAssessment';
import stringPadding from 'utils/stringPadding';
import useUser from 'hooks/auth/useUser';

function StudentHome() {
  const navigate = useNavigate();
  const { list: dirList, getByPath } = useDirectories();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user } = useSelector(({ auth }) => auth);
  const { user: _user } = useUser();
  const { totalCount: gradedCount } = useStudentAssessment(_user.userId, true);
  const { totalCount: pendingCount } = useStudentAssessment(_user.userId, false);
  const currentUser = user?.value ? JSON?.parse(user?.value) : user;
  const data = [
    // {
    //   title: (
    //     <>
    //       new <br /> assignments
    //     </>
    //   ),
    //   equivalent: 'new assignments',
    //   description:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod
    // tincidunt ut laoreet dolore magna aliquam erat volutpat.',
    //   number: '01',
    //   showButton: false,
    // },
    {
      title: (
        <>
          ASSESSMENTS <br /> pending review
        </>
      ),
      equivalent: 'assignments pending review',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      number: stringPadding(pendingCount, 2),
      onClick: () => {
        navigate(
          '/student/purpose/evidence-record/overview?folderId=' + getByPath('evidence-record')?.id
        );
      },
    },
    {
      title: (
        <>
          GRADED <br /> ASSESSMENTS
        </>
      ),
      equivalent: 'completed assignments',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      number: stringPadding(gradedCount, 2),
      onClick: () => {
        navigate(
          '/student/purpose/evidence-record/overview?folderId=' + getByPath('evidence-record')?.id
        );
      },
    },

    /* {
      title: (
        <>
          past due <br /> assignments
        </>
      ),
      equivalent: 'past due assignments',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod
         tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      number: '00',
      onClick: () => {
        navigate(
          '/student/purpose/evidence-record/overview?folderId=' + getByPath('evidence-record')?.id
        );
      },
    }, */
  ];
  const buttons = [
    {
      title: 'Self worth',
      onClick: () => {
        const _path = dirList.find((item) => item.path === 'self-worth' && item.parentId === null);
        navigate('self-worth?folderId=' + _path.id);
      },
      bgComponent: (
        <div className="bg-gradient-to-r from-[#34467e] to-[#2d3f71] absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
          <img
            src={selfWorthBgImage}
            alt="bg-image"
            className="absolute bottom-0 right-[-26px] opacity-20"
          />
        </div>
      ),
      boxBorderWidth: 0,
      textColor: 'white',
      titleTextStyle: {
        fontSize: 28,
      },
    },
    {
      title: 'engagement',
      onClick: () => {
        const _path = dirList.find((item) => item.path === 'engagement' && item.parentId === null);
        navigate('engagement?folderId=' + _path.id);
      },
      bgComponent: (
        <div className="bg-gradient-to-r from-[#af3635] to-[#8f3132] absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
          <img
            src={engagementBgImage}
            alt="bg-image"
            className="absolute bottom-0 right-[-56px] opacity-50"
          />
        </div>
      ),
      textColor: 'white',
      boxBorderWidth: 0,
      titleTextStyle: {
        fontSize: 28,
      },
    },
    {
      title: 'purpose',
      onClick: () => {
        const _path = dirList.find((item) => item.path === 'purpose' && item.parentId === null);
        navigate('purpose?folderId=' + _path.id);
      },
      bgComponent: (
        <div className="bg-gradient-to-r from-[#14a1db] to-[#166a94] absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
          <img
            src={purposeBgImage}
            alt="bg-image"
            className="absolute bottom-0 right-[-0px] opacity-60"
          />
        </div>
      ),
      textColor: 'white',
      boxBorderWidth: 0,
      titleTextStyle: {
        fontSize: 28,
      },
    },
  ];
  return (
    <DirectoriesLoader>
      <div className="">
        <MainHeadingText>
          <div className="font-extralight uppercase">Welcome</div>
          <div style={{ lineHeight: '85%', margin: '-5px auto' }}>
            {currentUser?.name || `Student`}
          </div>
        </MainHeadingText>
        <div className="my-[32px]">
          <SubHeadingText>
            {currentUser?.academyName || 'Livingstone Academy Bournemouth'}
          </SubHeadingText>
        </div>
        <MainHeadingSeparator />
        <DashboardContentHeading>CURRENT NOTIFICATIONS</DashboardContentHeading>
        <HighlightCard>
          {data.map((item, index) => (
            <EvidenceHighlightsItem
              title={item.title}
              equivalent={item.equivalent}
              description={item.description}
              text={item.number}
              onClick={item.onClick}
              key={index}
              showButton={item.showButton}
            />
          ))}
        </HighlightCard>
        <DashboardContentText>
          The unique Aspirations Employability Diploma provides you with opportunities to develop
          your work readiness and with your full investment in the programme sets you ahead of other
          students. The programme helps you to develop the range of skills and capabilities required
          for success in today and tomorrow’s global world.
        </DashboardContentText>
        <LandscapeCardListContainer>
          {buttons.map((item, index) => {
            const { title, onClick, ...otherProps } = item;
            return (
              <div onClick={() => onClick()} key={index}>
                <LandscapeCard
                  title={title}
                  buttonTitle="START"
                  buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
                  {...otherProps}
                />
              </div>
            );
          })}
        </LandscapeCardListContainer>
      </div>
    </DirectoriesLoader>
  );
}

export default StudentHome;
