import React, { useState, useEffect } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
// import PlusBox from 'components/UI/atoms/PlusBox';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import StrokeText from 'components/UI/atoms/StrokeText';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
// import PlusBoxHolder from 'components/UI/atoms/PlusBoxHolder';
// import PlusBoxHeading from 'components/UI/atoms/PlusBoxHeading';
import useProfessionalDevelopmentRecordState from 'hooks/student/useProfessionalDevelopmentRecordState';
// import UploadDocumentCard from 'components/UI/molecules/UploadDocumentCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDirectories from 'hooks/student/useDirectories';
// File history
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import useUser from 'hooks/auth/useUser';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function ProfessionalDevelopmentRecord() {
  const { userType } = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { list: folderList, getByPath } = useDirectories();
  const { histories } = useFileUploadPanel();
  const currentFolder =
    folderList.find((item) => item.id === Number(searchParams.get('folderId'))) || {};
  const {
    slot1,
    slot2,
    slot1Input,
    slot2Input,
    removeSlot1,
    removeSlot2,
    targetSlot,
    handleLinkUpload,
  } = useProfessionalDevelopmentRecordState();

  const { list: assignmentDocuments } = useAssignmentDocuments();

  const breadcrumbsItems = [
    {
      text: 'SELF WORTH',
      path: '/self-worth?folderId=' + getByPath('self-worth')?.id,
    },
    {
      text: 'PROFESSIONAL DEVELOPMENT RECORD',
      path:
        '/self-worth/professional-development-record?folderId=' +
        getByPath('professional-development-record')?.id,
    },
  ];

  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
      case 'slot2':
        targetSlot.current = 2;
        break;
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
      case 'slot2':
        slot2Input.click();
        break;
    }
  };
  return (
    <div>
      <MainHeadingText>{currentFolder.name}</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => (
              <>
                YOUR <br /> 1:1
              </>
            )}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={removeSlot1}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => (
              <>
                YOUR <br /> SNAPSHOT
              </>
            )}
            activeFile={slot2}
            histories={histories}
            onUpload={() => uploadModal('slot2')}
            onRemoveHistory={removeSlot2}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      <DashboardContentText>{/* TODO need copy */}</DashboardContentText>
      <div style={{ height: 36 }} />

      <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
      {assignmentDocuments.length === 0 ? (
        <div className="py-[56px]">
          <StrokeText>
            No Assignment <br />
            Documents
          </StrokeText>
        </div>
      ) : (
        <DocumentCardContainer>
          {assignmentDocuments.map((item, index) => (
            <DocumentCard data={item} key={index} onClick={item.onClick} />
          ))}
        </DocumentCardContainer>
      )}
      {userType === 'student' ? (
        <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
          {/* TODO make evidence record component? */}
          <BottomLandscapeCard
            title="VISIT THE EVIDENCE RECORD"
            buttonTitle="START"
            onClick={() => {
              navigate('/student/purpose/evidence-record');
            }}
          />
        </div>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default ProfessionalDevelopmentRecord;
