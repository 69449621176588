import React from 'react';
import reactDOM from 'react-dom';
// import Button from '../atoms/Button';

function SessionTimeoutModal({ showSessionWarningModal, setShowSessionWarningModal }) {
  return reactDOM.createPortal(
    <>
      {showSessionWarningModal && (
        <div className="z-[2]">
          <div className="fort font-extralight h-full z-10 w-full fixed bg-black/70 flex items-center justify-center top-0 left-0 p-[16px]">
            <div className="modal-bg relative border-2 border-[#e6e6e6] rounded-[16px] px-[16px] pb-[16px] pt-[16px] w-full max-w-[540px]">
              <h1 className="text-white uppercase text-[18px] fort font-normal leading-[28px]">
                Session Logout Soon!
              </h1>
              <div className="mt-[30px]">
                <div className="text-white text-[18px] text-white text-[18px] text-center">
                  Due to security purpose, you will be logout shortly, please save your changes...
                </div>
                <div className="flex justify-center mt-[30px]">
                  <button
                    className="flex flex-row items-center justify-center fort text-[16px] rounded-full 
                                border-[2px] border-white text-white min-h-[40px] px-[16px] w-full max-w-[320px]
                                hover:bg-white/30"
                    onClick={() => {
                      setShowSessionWarningModal(false);
                    }}
                  >
                    Continue
                  </button>
                  {/* <Button
                    onClick={() => {
                      setShowSessionWarningModal(false);
                    }}
                    btnTitle='Continue'
                    bgColor='#FEB2B2'
                    color='#1A202C'
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>,
    document.body
  );
}
export default SessionTimeoutModal;
