import useUser from 'hooks/auth/useUser';
import useEvidenceRecord from 'hooks/trainer/EvidenceRecord/useEvidenceRecord';
import { useState } from 'react';
import EvidenceGradingModalBase from '../EvidenceGradingModalBase';
// import GradeModalFileItem from 'components/UI/molecules/GradeModalFileItem';
// import OvalIconButtonWhite from 'components/UI/molecules/OvalIconButtonWhite';
// import RadioScoreButton from 'components/UI/molecules/Trainer/RadioScoreButton';
// import useEvidenceRecordGrades from 'hooks/trainer/EvidenceRecord/useEvidenceRecordGrades';
// import ReactDOM from 'react-dom';
// import logger from 'utils/logger';
// import CloseIcon from '@mui/icons-material/Close';

export default function EvidenceRecordGradingModal({ open = false, onSubmit, onClose, ...props }) {
  const { fsId, pcId, skillList, competencyList, student, evidenceGrades, selectedUploadsOnly } =
    useEvidenceRecord();
  const { user } = useUser();

  const currentGradeData = evidenceGrades.find((item) => {
    return (
      item.fsId === fsId &&
      item.pcId === pcId &&
      item.teacherId === user.userId &&
      item.studentId === student.userId
    );
  });

  const [skillScore, setSkillScore] = useState(currentGradeData?.skillScore || 0);
  const [competencyScore, setCompetencyScore] = useState(currentGradeData?.competencyScore || 0);
  const [comments, setComments] = useState(currentGradeData?.comments || '');
  const onScoreChange = (e) => {
    if (e.target.name === 'skillScore') {
      setSkillScore(Number(e.target.value));
    } else {
      setCompetencyScore(Number(e.target.value));
    }
  };

  const currentFS = skillList.find((item) => item.id === fsId);
  const currentPC = competencyList.find((item) => item.id === pcId);

  const closeModal = () => {
    typeof onClose === 'function' && onClose();
  };

  /**
   * @param {import('../EvidenceGradingModalBase').EvidenceGradingModalResponse} res
   */
  const onSubmitGrade = (res) => {
    typeof onSubmit === 'function' &&
      onSubmit({
        fsId,
        pcId,
        skillScore,
        competencyScore,
        teacherId: user.userId,
        studentId: student.userId,
        comments,
      });
    closeModal();
  };

  /**
   *
   * @param {InputEvent} e
   */
  const onCommentChange = (e) => {
    e.preventDefault();
    setComments(e.target.value);
  };

  return (
    <EvidenceGradingModalBase
      open={open}
      skillScore={skillScore}
      competencyScore={competencyScore}
      fsCode={currentFS.code}
      fsTitle={currentFS.title}
      fsSubtitle={currentFS.subtitle}
      fsDescription={currentFS.description}
      pcCode={currentPC.code}
      pcTitle={currentPC.title}
      pcSubtitle={currentPC.subtitle}
      pcDescription={currentPC.description}
      onScoreChange={onScoreChange}
      comments={comments}
      onCommentChange={onCommentChange}
      onSubmit={onSubmitGrade}
      onClose={onClose}
      uploads={selectedUploadsOnly}
      tabIndex={0}
    />
  );
}
