import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setName,
  setType,
  openAccordion,
  toggleStudent,
  toggleMaterial,
  addBatchStudents,
  removeSelectedStudents,
  removeSelectedMaterials,
  addBatchMaterials,
  openModify,
} from 'redux/reducers/trainer/manageGroupInstance';
import http from 'services/http';

// Know that all features relating materials under a group is depreciated
// TODO: Remove toggle materials, etc.
export default function useGroupInstance(group = null) {
  const {
    modifying,
    modified,
    groupName,
    groupType,
    selectedStudents,
    selectedMaterials,
    studentAccordion,
    materialAccordion,
  } = useSelector(({ manageGroupInstance }) => manageGroupInstance);
  const [alert, setAlert] = useState(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    studentPanel: false,
    materialPanel: false,
    resourcePanel: false,
  });

  const [listLoading, setListLoading] = useState({
    studentList: false,
    materialList: false,
  });

  const [groupTypes, setTypes] = useState([]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 3000);
    }
  }, [alert]);

  const setGroupType = (type) => dispatch(setType(type));
  const setGroupName = (name) => dispatch(setName(name));
  const togStudent = (student) => dispatch(toggleStudent(student));
  const togMaterial = (material) => dispatch(toggleMaterial(material));
  const toggleAccordion = (accordion, value = true) =>
    dispatch(openAccordion({ accordion, value }));

  const loadStudents = async () => {
    if (!group || !group?.id) {
      setAlert('No selected group!');
      return;
    }
    setLoading({ ...loading, studentPanel: true });
    dispatch(addBatchStudents({ students: [], clear: true }));
    const { data } = await http.post('/api/assigned-users/search', {
      groupId: group?.id,
    });
    const { list: students } = data;
    setLoading({ ...loading, studentPanel: false });
    dispatch(addBatchStudents({ students, clear: true }));
  };

  const loadMaterials = async () => {
    if (!group || !group?.id) {
      setAlert('No selected group!');
      return;
    }
    setLoading({ ...loading, materialPanel: true });
    dispatch(addBatchMaterials({ materials: [], clear: true }));
    const { data } = await http.post('/api/groups-materials/search', {
      groupId: group?.id,
    });
    const { list: materials } = data;
    setLoading({ ...loading, materialPanel: false });
    dispatch(addBatchMaterials({ materials, clear: true }));
  };

  useEffect(() => {
    const loadTypes = async () => {
      try {
        const { data } = await http.get('/api/group-type/search');
        const { list } = data;
        setTypes(list);
      } catch (e) {}
    };
    loadTypes();
  }, []);

  useEffect(() => {
    if (group && group?.id !== null) {
      if (!group.isCreated) {
        loadStudents();
        loadMaterials();
      }
      setGroupName(group?.groupName || group?.name || '');
      const type = groupTypes.find((i) => i.id === group?.groupTypeId);
      setGroupType(type?.id);
    }
  }, [group]);

  const batchAddStudent = async (students, group = null) => {
    // if (!group || !group?.id) {
    //   setAlert('No selected group!');
    //   return;
    // }
    // setLoading({ ...loading, studentPanel: true });
    // await http.post('/api/assigned-users', {
    //   groupId: group?.id,
    //   userId: students.map((i) => i.id),
    // });
    // setLoading({ ...loading, studentPanel: false });
    dispatch(addBatchStudents({ students }));
  };

  const batchAddMaterial = async (materials, group = null) => {
    if (!group || !group?.id) {
      setAlert('No selected group!');
      return;
    }
    setLoading({ ...loading, materialPanel: true });
    await http.post('/api/groups-materials', {
      groupId: group?.id,
      materialId: materials.map((i) => i.id),
    });
    setLoading({ ...loading, materialPanel: false });
    dispatch(addBatchMaterials({ materials }));
  };

  const batchAddMaterialGroups = async (materials, groups = [], dirId) => {
    if (!groups.length) {
      setAlert('No group Selected!');
      return false;
    }
    if (!materials.length) {
      setAlert('No material group!');
      return false;
    }
    try {
      setLoading({ ...loading, resourcePanel: true });
      const resp = await http.post('/api/groups-materials', {
        groupId: groups.map((grp) => grp.id),
        materialId: materials.map((i) => i.id),
        dirId,
      });
      setLoading({ ...loading, resourcePanel: false });
      return resp;
    } catch (err) {
      setLoading({ ...loading, resourcePanel: false });
      throw err;
    }
  };

  const batchAddMaterialGroupsDirectories = async (materials, students = [], dirId) => {
    if (!students.length) {
      setAlert('No student Selected!');
      return false;
    }
    if (!materials.length) {
      setAlert('No material selected!');
      return false;
    }
    if (!dirId) {
      setAlert('No directory selected!');
      return false;
    }
    try {
      setLoading({ ...loading, resourcePanel: true });
      // TODO: Change endpoint
      const resp = await http.post('/api/users-materials', {
        userId: students.map((std) => std.userId),
        materialId: materials.map((i) => i.id),
        dirId,
      });
      setLoading({ ...loading, resourcePanel: false });
      return resp;
    } catch (err) {
      setLoading({ ...loading, resourcePanel: false });
      throw err;
    }
  };

  const deleteStudents = async () => {
    const removePivots = selectedStudents.filter((i) => i.active).map((i) => i.uuid);
    setListLoading({ ...listLoading, studentList: true });
    try {
      await http.delete('/api/assigned-users', [...removePivots]);
      dispatch(removeSelectedStudents());
    } catch (e) {}
    setListLoading({ ...listLoading, studentList: false });
  };

  const deleteMaterials = async () => {
    const removePivots = selectedMaterials.filter((i) => i.active).map((i) => i.uuid);
    setListLoading({ ...listLoading, materialList: true });
    try {
      await http.delete('/api/groups-materials', [...removePivots]);
      dispatch(removeSelectedMaterials());
    } catch (e) {}
    setListLoading({ ...listLoading, materialList: false });
  };

  const openForm = (open = true) => dispatch(openModify(open));

  return {
    alert,
    loading,
    modified,
    modifying,

    openForm,
    // Group type list
    groupTypes,
    // Group modifier
    setGroupName,
    setGroupType,
    toggleAccordion,
    // List modifiers
    togStudent,
    togMaterial,
    batchAddStudent,
    batchAddMaterial,
    // List feedback loaders

    loadStudents,
    loadMaterials,

    deleteStudents,
    deleteMaterials,
    // data
    groupName,
    groupType,
    studentAccordion,
    materialAccordion,
    selectedStudents,
    selectedMaterials,
    batchAddMaterialGroups,
    batchAddMaterialGroupsDirectories,
  };
}
