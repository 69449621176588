import ChevronRightIcon from '@mui/icons-material/ChevronRight';

/**
 * @param {import("react").HTMLAttributes} param0
 * @returns
 */
export default function EvidenceHighlightsItem({
  title,
  text,
  equivalent,
  description,
  onClick,
  showButton = true,
  ...props
}) {
  return (
    <div
      className="eh-item text-black min-w-[180px] border-r-[1px] border-black/20 mr-[32px] pr-[32px] fort"
      {...props}
    >
      <div className="relative eh-item-title text-[12px] tracking-[3px]">
        {title}
        {/*    
        TODO: Return this tooltip if there's a dynamic data of each notif.
        */}
      </div>
      <div className="sub-heading-white-stroke2 text-[56px] mt-[16px]">{text}</div>
      {showButton && (
        <div
          className="border-[#777] border-[1px] text-[#777] w-[24px] h-[24px] flex items-center justify-center bg-white rounded-[50%] mt-[12px] cursor-pointer"
          onClick={onClick}
        >
          <ChevronRightIcon
            style={{
              fontSize: '20px',
              fontWeight: 100,
            }}
          />
        </div>
      )}
    </div>
  );
}
