import { useState } from 'react';
import stringPadding from 'utils/stringPadding';
import ArrowRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import EvidenceRecordGradingModal from 'components/UI/organisms/trainer/PendingAssessment/EvidenceRecordGradingModal';
import useEvidenceToReview from 'hooks/trainer/EvidenceRecord/useEvidenceToReview';

export default function EvidenceToReviewItem({ data }) {
  const [openGradingModal, setOpenGradingModal] = useState(false);
  const _date = new Date(data?.updatedAt);
  const { setTargetEvidence, submitEvidenceGrade } = useEvidenceToReview();

  const onClick = () => {
    const fsId = data?.fsId;
    const pcId = data?.pcId;
    setTargetEvidence({
      fsId,
      pcId,
    });
    setOpenGradingModal(true);
  };

  const closeGradingModal = () => {
    setOpenGradingModal(false);
  };

  const onGradeSubmit = (data) => {
    submitEvidenceGrade(data);
  };

  return (
    <>
      <div
        className="select-none flex flex-row items-center gap-[40px] fort font-extralight text-[13px] min-h-[40px] pl-[16px] pr-[32px] cursor-pointer hover:bg-[#eee]"
        onClick={onClick}
      >
        <div className="flex-1 uppercase flex flex-row items-center gap-[16px]">
          <div
            className="w-[20px] h-[20px] border-[1px] rounded-full flex items-center justify-center text-[#777] text-[8px]"
            style={{ borderColor: 'currentColor' }}
          >
            <ArrowRightIcon />
          </div>
          <div>{data?.userFullName || data?.studentName || 'REVIEW EVIDENCE FS01 PC01'}</div>
        </div>
        <div className="text-[12px] min-w-[68px] flex flex-row items-center gap-[4px]">
          <div className="min-w-[32px]"> {`FS${stringPadding(data?.fsId, 2)}`}</div>
          <div className="min-w-[32px]"> {`PC${stringPadding(data?.pcId, 2)}`}</div>
        </div>
        <div className="text-[12px]">{`${_date.getMonth() + 1}/${_date.getFullYear()}`}</div>
        <div className="text-[12px]">
          {_date.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: '2-digit' })}
        </div>
      </div>
      {openGradingModal && (
        <EvidenceRecordGradingModal
          defaultValue={data}
          open={openGradingModal}
          onClose={closeGradingModal}
          onSubmit={onGradeSubmit}
        />
      )}
    </>
  );
}
