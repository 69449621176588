import { useEffect, useState } from 'react';
import http from 'services/http';

export default function useStudentAssessment(studentId, isGrade = false) {
  const [list, setList] = useState([]);

  useEffect(() => {
    getList();

    window.addEventListener('grade.submitted', getList);

    return () => {
      window.removeEventListener('grade.submitted', getList);
    };
  }, []);

  const getList = async () => {
    try {
      const result = await http.post('/api/pending-assessment/files/student', {
        graded: isGrade,
        ...(studentId && { studentId }),
      });
      setList(result.data);
    } catch (err) {
      alert(err?.response?.data || err.message);
    }
  };

  return {
    list,
    totalCount: list.length,
    getList,
  };
}
