import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {import('../../../utils/createFileInput').UploadFile} UploadFile
 */

/**
 * @typedef {Object} PlatinumAwardSlice
 * @prop {UploadFile} projectPresentation
 * @prop {UploadFile} projectProgress2Snapshot
 * @prop {UploadFile} projectProgress1Snapshot
 * @prop {UploadFile} projectUpload
 */

/**
 * @type {PlatinumAwardSlice}
 */
const initialState = {
  projectPresentation: null,
  projectProgress2Snapshot: null,
  projectProgress1Snapshot: null,
  projectUpload: null,
};

const platinumAwardSlice = createSlice({
  name: 'platinumAwardState',
  initialState,
  reducers: {
    setProjectPresentation: (state, action) => {
      state.projectPresentation = action.payload;
      // return state;
    },
    setProjectProgress2Snapshot: (state, action) => {
      state.projectProgress2Snapshot = action.payload;
      // return state;
    },
    setProjectProgress1Snapshot: (state, action) => {
      state.projectProgress1Snapshot = action.payload;
      // return state;
    },
    setProjectUpload: (state, action) => {
      state.projectUpload = action.payload;
      // return state;
    },
  },
});

export const {
  setProjectPresentation,
  setProjectProgress2Snapshot,
  setProjectProgress1Snapshot,
  setProjectUpload,
} = platinumAwardSlice.actions;

export default platinumAwardSlice.reducer;
