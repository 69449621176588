import { CircularProgress } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const list = [];
for (let i = 1; i <= 30; i += 1) {
  list.push({
    value: i,
    title: `Item ${i}`,
    subtitle: `item${i}@gmail.com`,
  });
}

/**
 *  @typedef {{ title: string, value: string | number, subtitle?: string }} DropdownItem
 * @param {{
 *  onChange: (selected: DropdownItem) => void,
 *  defaultValue: DropdownItem,
 *  options: Array<DropdownItem>,
 *  onSearchChange: (keyword: string) => void
 *  onLoadMoreClicked: (e: Event) => void,
 *  loading: boolean,
 *  loadmoreVisible: boolean,
 *  variant: 'normal' | 'outline',
 *  style: import('react').CSSProperties
 * }} param0
 * @returns
 */
export default function DropdownSearch({
  onChange,
  defaultValue = { title: null, value: null },
  options = list,
  onSearchChange,
  onLoadMoreClicked,
  loading = true,
  loadmoreVisible = true,
  variant = 'normal',
  style,
}) {
  const [selected, setSelected] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [optionList, setOptionList] = useState([]);
  const [isLoading, setLoading] = useState(loading);
  const [coordinates, setCoordinates] = useState({
    top: null,
    left: null,
    position: 'bottom',
    maxWidth: null,
  });
  const [showLoadmore, setShowLoadmore] = useState(true);
  //
  /**
   * @type {{ current: HTMLDivElement }}
   */
  const parentDOMRef = useRef(null);

  useEffect(() => {
    setOptionList(options);
  }, [options]);

  useEffect(() => {
    setShowLoadmore(loadmoreVisible);
  }, [loadmoreVisible]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (typeof onSearchChange === 'function') onSearchChange(keyword);
  }, [keyword]);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
      const clientRec = parentDOMRef.current.getBoundingClientRect();
      let _top = clientRec.bottom + 8;
      let _pos = 'bottom';
      if (_top + 256 > window.innerHeight) {
        _top = clientRec.top - (256 + 8);
        _pos = 'top';
      }
      setCoordinates({
        top: `${_top}px`,
        left: `${clientRec.left}px`,
        position: _pos,
        maxWidth: clientRec.width,
      });
    } else {
      document.body.style.overflowY = 'auto';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [open]);

  useEffect(() => {
    if (typeof onChange === 'function') onChange(selected);
  }, [selected]);

  const onClickOpen = () => {
    if (!open) setOpen(true);
  };

  const onClickSelectItem = (e) => {
    setSelected(e);
    setOpen(false);
  };

  return (
    <>
      <div
        tabIndex={0}
        className={
          variant === 'outline'
            ? `relative font-extralight flex flex-row items-center h-[44px] px-[16px] w-full 
                rounded-[8px] border-[2px] border-white overflow-hidden bg-black/30 focus:bg-black/50`
            : `relative fort font-extralight h-[40px] bg-white shadow rounded-[8px] 
        flex flex-row items-center px-[12px] py-[8px] cursor-pointer`
        }
        ref={parentDOMRef}
        onClick={onClickOpen}
        style={style}
      >
        <div className="uppercase">{selected?.title}</div>
        <div
          className="text-[24px] absolute right-[6px] pointer-events-none"
          style={{ transition: 'all 0.2s', transform: open ? 'rotateZ(180deg)' : null }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 48 48">
            <path d="M24 29 13.8 18.8l.8-.8 9.4 9.4 9.4-9.4.8.8Z" stroke="currentColor" />
          </svg>
        </div>
      </div>
      {open && (
        <PopUp>
          <div className="fixed top-0 left-0 bottom-0 right-0 z-[1000]">
            <div
              className="fixed top-0 left-0 bottom-0 right-0"
              onClick={() => setOpen(false)}
            ></div>
            <div
              className="absolute w-full max-w-[400px] min-w-[300px] bg-white min-h-[100px]  rounded-[8px] drop-shadow-2xl p-[8px] top-expand"
              style={{
                top: coordinates.top,
                left: coordinates.left,
                maxWidth: coordinates.maxWidth,
                ...(coordinates.position === 'top' && { transformOrigin: 'bottom center' }),
              }}
            >
              <div className="fort font-extralight relative list-scroll overflow-auto h-[240px] overscroll-contain pr-[8px]">
                <div className="sticky top-0 h-[56px] border-b-[1px] border-[#eee] flex flex-row items-center bg-white">
                  <div className="flex flex-row items-center relative h-[40px] w-full border-[2px] border-[#eee] rounded-[8px] overflow-hidden">
                    <input
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value || '')}
                      placeholder="Search..."
                      type="text"
                      className="fort font-extralight box-border h-[38px] w-full outline-none 
                                pl-[12px] pr-[40px] py-[0px] flex flex-row items-center focus:bg-[#f5f5f5]"
                    />
                    {keyword !== '' && (
                      <button
                        onClick={() => setKeyword('')}
                        className="text-[16px] absolute right-[8px] bg-[#eee] text-[#777] 
                                w-[24px] h-[24px] rounded-full flex items-center justify-center 
                                hover:bg-[#ccc]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          width="1em"
                          viewBox="0 0 48 48"
                        >
                          <path
                            d="m12.7 36.1-.8-.8L23.2 24 11.9 12.7l.8-.8L24 23.2l11.3-11.3.8.8L24.8 24l11.3 11.3-.8.8L24 24.8Z"
                            stroke="currentColor"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                {Array.isArray(optionList) && optionList.length > 0 ? (
                  optionList.map((item, index) => (
                    <div
                      onClick={() => onClickSelectItem(item)}
                      key={index}
                      data-value={item.value}
                      className="min-h-[40px] flex flex-row items-center border-b-[1px] 
                                border-[#eee] cursor-pointer px-[8px] hover:bg-[rgba(0,0,0,0.05)]"
                    >
                      <div className="py-[8px]">
                        <div className="uppercase">{item.title}</div>
                        {item.subtitle && (
                          <div className="text-[12px] text-[#777] mt-[-4px]">{item.subtitle}</div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="fort font-normal p-[16px] text-center">No Results</div>
                )}
                {!isLoading && optionList.length > 0 && showLoadmore && (
                  <div
                    onClick={onLoadMoreClicked}
                    className="min-h-[40px] flex flex-col items-center justify-center pt-[16px] pb-[8px] 
                                border-b-[1px] border-[#eee] cursor-pointer hover:bg-[rgba(0,0,0,0.05)]
                                text-[#777]"
                  >
                    <div className="font-normal">LOAD MORE</div>
                    <div className="text-[24px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        width="1em"
                        viewBox="0 0 48 48"
                      >
                        <path
                          d="M24 29 13.8 18.8l.8-.8 9.4 9.4 9.4-9.4.8.8Z"
                          stroke="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                )}

                {isLoading && (
                  <div className="flex items-center justify-center p-[16px]">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
}

const PopUp = ({ children }) => {
  return ReactDOM.createPortal(<>{children}</>, document.body);
};
