import useMaterialQuery from 'hooks/trainer/useMaterialQuery';
import { useState } from 'react';
import ModalShareDocuments from './ModalShareDocuments';
import SearchMaterialExtended from './SearchMaterialExtended';

export const AssignResources = () => {
  const [showModal, setShowModal] = useState(false);

  const {
    materials,
    selected: selectedMaterials,
    checkMaterial,
    unCheckMaterials,
    onSearch,
    loadingMaterials,
    optionState,
  } = useMaterialQuery();

  return (
    <div>
      <SearchMaterialExtended
        assignTitle={'ASSIGN'}
        loading={false}
        addToGroup={() => {
          setShowModal(true);
        }}
        materials={materials}
        optionState={optionState}
        checkMaterial={checkMaterial}
        selected={selectedMaterials}
        onSearch={onSearch}
        loadingMaterials={loadingMaterials}
      />
      <ModalShareDocuments
        showModal={showModal}
        selectedMaterials={selectedMaterials}
        checkMaterial={checkMaterial}
        unCheckMaterials={unCheckMaterials}
        loadingMaterials={loadingMaterials}
        setShowModal={setShowModal}
      />
    </div>
  );
};
