import { useState, useRef, useMemo, useCallback, useEffect, useDeferredValue } from 'react';
import http from 'services/http';

export default function useStudentQuery(selectedUuids = {}) {
  const boxContainer = useRef();
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [optionState, setOptions] = useState({});
  const [showStudent, setStudent] = useState(null);
  const [activeStudents, setActiveStudent] = useState([]);
  const defFirstName = useDeferredValue(firstName);
  const defLastName = useDeferredValue(lastName);

  const isActiveStudent = (student) => activeStudents.includes(student.id);

  const checkStudent = (student) => {
    const active = isActiveStudent(student);
    setActiveStudent(
      active ? activeStudents.filter((s) => s !== student.id) : [student.id, ...activeStudents]
    );
  };

  const unCheckStudents = () => setActiveStudent([]);

  const loadStudents = async (page = 1) => {
    try {
      setLoading(true);
      const query = {
        userTypeId: '3',
      };
      const keys = Object.keys(selectedUuids);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        // get names if there's string
        if (selectedUuids[key].length > 0) {
          query[key] = selectedUuids[key];
        }
      }
      if (defFirstName) query.firstName = defFirstName;
      if (defLastName) query.lastName = defLastName;

      const { data } = await http.post('/api/assigned-users/search', {
        ...query,
        distinct: true,
        page,
        limit: 50,
      });
      const { list, ...options } = data;
      const newList = optionState?.page < page ? [...students, ...list] : [...list];
      setStudents(newList);
      setOptions(options);
      setLoading(false);
    } catch (e) {}
  };

  const loadNextPageStudents = useCallback(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && optionState.page < optionState.pageCount && !loading) {
        loadStudents(optionState.page + 1);
      }
    },
    [optionState]
  );

  useEffect(() => {
    loadStudents();
  }, [selectedUuids, defFirstName, defLastName]);

  useEffect(() => {
    const observer = new IntersectionObserver(loadNextPageStudents, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (boxContainer.current) observer.observe(boxContainer.current);
    return () => {
      if (boxContainer.current) observer.unobserve(boxContainer.current);
    };
  }, [boxContainer, optionState]);

  const selectStudent = () => {
    const [student] = students.filter((i) => activeStudents.includes(i.id));
    setStudent(student?.id !== showStudent?.id ? student : null);
  };

  const unSelectStudent = () => setStudent(null);

  const onSearch = useCallback(loadStudents, []);

  const selected = useMemo(
    () => students.filter((i) => activeStudents.includes(i.id)),
    [students, activeStudents]
  );

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    optionState,
    showStudent,
    loading,
    students,
    isActiveStudent,
    selected,
    setStudents,
    checkStudent,
    unCheckStudents,
    onSearch,
    selectStudent,
    unSelectStudent,
    boxContainer,
  };
}
