function PlusBox({ onClick }) {
  return (
    <div
      className="w-[136px] min-h-[180px] cursor-pointer font-100 select-none plus-text text-[40px] flex items-center justify-center border-[0.5px] border-black/80  over:bg-white/5 active:bg-white/10 rounded-[12px]"
      onClick={onClick}
    >
      +
    </div>
  );
}

export default PlusBox;
