import { useEffect, useState } from 'react';
import logger from 'utils/logger';
import useEvidenceCompetencyState from './useEvidenceCompetencyState';
import useEvidenceSkillsState from './useEvidenceSkillsState';

export const OverviewStatuses = {
  GOOD_STANDING: 3,
  NEEDS_ATTENTION: 2,
  EVIDENCE_IN_REVIEW: 1,
  NO_CURRENT_EVIDENCE: 0,
};

export default function useEvidenceRecordViewState() {
  const { list: skillList, getList: getSkillList } = useEvidenceSkillsState();
  const { list: competencyList, getList: getCompetencyList } = useEvidenceCompetencyState();
  const [tableData, setTableData] = useState({
    th: [],
    thv: [],
    data: [[]],
  });

  useEffect(() => {
    (async () => {
      if (skillList.length === 0) {
        getSkillList();
        return;
      }

      if (competencyList.length === 0) {
        getCompetencyList();
        return;
      }

      const th = skillList.map((item) => {
        return `${item.code}:`;
      });

      const thv = competencyList.map((item) => {
        return `${item.code}:`;
      });

      const data = [];
      for (let i = 0; i < thv.length; i += 1) {
        const row = [];
        for (let j = 0; j < th.length; j += 1) {
          row.push({
            value: '0/0',
            status: OverviewStatuses.NO_CURRENT_EVIDENCE,
          });
        }
        data.push(row);
      }

      logger(th, thv);

      setTableData((prevState) => ({
        ...prevState,
        th: ['', ...th],
        thv,
        data,
      }));
    })();
  }, [skillList, competencyList]);

  return { tableData, setTableData };
}
