export const sampleData = [
  {
    title: 'Evidence Record',
    slug: 'evidence-record',
    id: '1',
    data: [
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, urna eu consectetur consectetur, ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod nunc.',
  },
  {
    title: 'TRAINING MATERIALS',
    slug: 'training-materials',
    id: '2',
    data: [
      {
        title: 'know your profile',
        data: [
          'CV Development',
          'Your Values',
          'Describe Yourself',
          'Employer Values',
          'My Natural Talents',
          'Skills Map',
        ],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'develop your story',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'create experience',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'preview your story',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'engage professionally',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'platinum award',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, urna eu consectetur consectetur, ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod nunc.',
  },
  {
    title: 'pending assignments',
    slug: 'pending-assignments',
    id: '3',
    data: [
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, urna eu consectetur consectetur, ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod nunc.',
  },
  {
    title: 'assigned groups',
    slug: 'groups',
    id: '4',
    data: [
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, urna eu consectetur consectetur, ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod nunc.',
  },
  {
    title: 'student search',
    slug: 'student-search',
    id: '5',
    data: [
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, urna eu consectetur consectetur, ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod nunc.',
  },
  {
    title: 'resources academy',
    slug: 'resources-academy',
    id: '6',
    data: [
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
      {
        title: 'lorem ipsum',
        data: ['lorem ipsum', 'lorem ipsum', 'Test Data'],
        description: 'lorem ipsum ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod',
      },
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, urna eu consectetur consectetur, ipsum nunc aliquet ligula, vel tincidunt nisi nunc euismod nunc.',
  },
];

export const sampleLinks = [
  {
    fileName: 'name-1.txt',
    fileExt: '.txt',
    description: '',
    topicId: 1,
    fileTypeId: 1,
    academyId: 1,
    yearId: 1,
    filePath: '/file/name-1.txt',
  },
  {
    fileName: 'name-1.txt',
    fileExt: '.txt',
    description: '',
    topicId: 1,
    fileTypeId: 1,
    academyId: 1,
    yearId: 1,
    filePath: '/file/name-1.txt',
  },
  {
    fileName: 'name-1.txt',
    fileExt: '.txt',
    description: '',
    topicId: 1,
    fileTypeId: 1,
    academyId: 1,
    yearId: 1,
    filePath: '/file/name-1.txt',
  },
  {
    fileName: 'name-1.txt',
    fileExt: '.txt',
    description: '',
    topicId: 1,
    fileTypeId: 1,
    academyId: 1,
    yearId: 1,
    filePath: '/file/name-1.txt',
  },
  {
    fileName: 'name-1.txt',
    fileExt: '.txt',
    description: '',
    topicId: 1,
    fileTypeId: 1,
    academyId: 1,
    yearId: 1,
    filePath: '/file/name-1.txt',
  },
  {
    fileName: 'name-1.txt',
    fileExt: '.txt',
    description: '',
    topicId: 1,
    fileTypeId: 1,
    academyId: 1,
    yearId: 1,
    filePath: '/file/name-1.txt',
  },
];
export const studentFilter = [
  {
    id: 23,
    title: 'Year',
    subtags: [
      { id: 24, title: 'Year 1' },
      { id: 25, title: 'Year 2' },
      { id: 26, title: 'Year 3' },
      { id: 27, title: 'Year 4' },
    ],
  },
];

export const tagFilters = [
  {
    id: 1,
    title: 'Topic',
    subtags: [
      {
        id: 2,
        title: 'topic',
      },
      {
        id: 3,
        title: 'topic',
      },
      {
        id: 4,
        title: 'topic',
      },
      {
        id: 5,
        title: 'topic',
      },
      {
        id: 6,
        title: 'topic',
      },
      {
        id: 7,
        title: 'topic',
      },
      {
        id: 8,
        title: 'topic',
      },
      {
        id: 9,
        title: 'topic',
      },
      {
        id: 10,
        title: 'topic',
      },
      {
        id: 11,
        title: 'topic',
      },
      {
        id: 12,
        title: 'topic',
      },
      {
        id: 13,
        title: 'topic',
      },
    ],
  },
  {
    id: 14,
    title: 'File Type',
    subtags: [
      {
        id: 15,
        title: 'pdf',
      },
      {
        id: 16,
        title: 'txt',
      },
      {
        id: 17,
        title: 'mp4',
      },
    ],
  },
  {
    id: 18,
    title: 'Subject',
    subtags: [
      { id: 19, title: 'Subject 11' },
      { id: 20, title: 'Subject 20' },
      { id: 21, title: 'Subject 21' },
      { id: 22, title: 'Subject 22' },
    ],
  },
  {
    id: 23,
    title: 'Year',
    subtags: [
      { id: 24, title: 'Year 1' },
      { id: 25, title: 'Year 2' },
      { id: 26, title: 'Year 3' },
      { id: 27, title: 'Year 4' },
    ],
  },
  {
    id: 28,
    title: 'Sort',
    subtags: [
      { id: 29, title: 'Academy: Live stone academy bournemouth' },
      { id: 30, title: 'Year: Select year' },
      { id: 31, title: 'Class: Select class' },
    ],
  },
];

export const sampleStudents = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 2,
    firstName: 'Aya',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 3,
    firstName: 'Waz',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 4,
    firstName: 'Max',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 5,
    firstName: 'John 2',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 6,
    firstName: 'Aya 2',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 7,
    firstName: 'Waz 2',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 8,
    firstName: 'Max2',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 9,
    firstName: 'John 4',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 10,
    firstName: 'Aya 4',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 11,
    firstName: 'Waz4',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 12,
    firstName: 'Max 4',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 13,
    firstName: 'John3',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 14,
    firstName: 'Aya 3',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 15,
    firstName: 'Waz3',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 16,
    firstName: 'Max3',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 17,
    firstName: 'John5',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 18,
    firstName: 'Aya5',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 19,
    firstName: 'Waz5',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 20,
    firstName: 'Max5',
    lastName: 'Doe',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 21,
    firstName: 'John',
    lastName: 'Doe1',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 22,
    firstName: 'Aya',
    lastName: 'Doe1',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 23,
    firstName: 'Waz',
    lastName: 'Doe1',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
  {
    id: 24,
    firstName: 'Max',
    lastName: 'Doe1',
    year: 'Year 1',
    groups: [],
    academy: {
      id: 1,
      name: 'Livingstone Academy Bournemouth',
    },
  },
];

export const sampleAssignments = [
  {
    id: 0,
    title: 'Attitudes/skills balanced scoreboard',
  },
  {
    id: 1,
    title: 'livingstone academy bournemouth class one',
  },
  {
    id: 2,
    title: 'livingstone academy bournemouth class two',
  },
  {
    id: 1,
    title: 'livingstone academy bournemouth class three',
  },
  {
    id: 1,
    title: 'livingstone academy bournemouth class four',
  },
  {
    id: 1,
    title: 'livingstone academy bournemouth class five',
  },
];
