import { Add, Close, Check } from '@mui/icons-material';

export default function InputLinkBox({
  item,
  onAdd = null,
  editable = false,
  selected = false,
  onSelect = null,
  onChange = null,
  onEdit = null,
  onDelete = null,
  preview = false,
  preCheck = false,
  className = '',
  setFocus = null,
}) {
  const handleSelect = () => (onSelect ? onSelect(!selected ? item : null) : null);
  const handleOnDelete = () => onDelete(item);
  return (
    <div className="mb-2 w-full">
      <div className="w-full flex fort items-center p-1">
        <div
          className={`shadow-md mr-2 px-2 py-1 bg-white rounded-lg link-input grow ${
            selected ? 'text-[#00A7E1] cursor-pointer' : ''
          } ${className}`}
          onClick={handleSelect}
        >
          {!editable ? (
            <div className={`w-full min-h-10 my-2 text-sm`} title={item?.filePath}>
              {selected || preview ? item?.filePath : item?.fileName}
            </div>
          ) : (
            <input
              className="w-full text-sm h-10 focus:outline-none autofill:font-bold text-[#00A7E1] bg-transparent"
              value={item?.fileName || ''}
              onChange={onChange}
              onFocus={setFocus}
              autoFocus
            />
          )}
        </div>
        <div className="box-btns flex align-center">
          {onAdd && (
            <span
              className={`p-1 flex items-center border-2 rounded-full border-white duration-[0.2] ${
                preCheck ? 'opacity-100 cursor-pointer' : 'opacity-30'
              }`}
              onClick={onAdd}
            >
              <Add
                style={{
                  color: 'white',
                  fontSize: '18px',
                }}
              />
            </span>
          )}
          {preview && onDelete && (
            <span
              className="p-1 flex items-center border-2 rounded-full cursor-pointer border-white duration-[0.2]"
              onClick={handleOnDelete}
            >
              <Close
                style={{
                  color: 'white',
                  fontSize: '18px',
                }}
              />
            </span>
          )}
          {editable && onEdit && (
            <span
              className="p-1 flex items-center border-2 rounded-full cursor-pointer border-white duration-[0.2]"
              onClick={onEdit}
            >
              <Check
                style={{
                  color: 'white',
                  fontSize: '18px',
                }}
              />
            </span>
          )}
        </div>
      </div>
      {editable && selected && (
        <p className="text-white text-xs fort px-2" style={{ textShadow: '1px 1px 5px #000' }}>
          {item?.filePath}
        </p>
      )}
    </div>
  );
}
