import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  file: null,
};

const uploadedFile = createSlice({
  name: 'uploadedFile',
  initialState,
  reducers: {
    newFile: (state, { payload = null }) => {
      state.file = payload;
    },
  },
});

export const { newFile } = uploadedFile.actions;

export default uploadedFile.reducer;
