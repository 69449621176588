import { useEffect, useState } from 'react';
import ConfirmModal from './ConfirmModal';

export default function FileSizeErrorModal() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    //
    /**
     * @param {CustomEvent} e
     */
    const onFileError = (e) => {
      setTitle(e.detail.title);
      setMessage(e.detail.message);
      setOpen(true);
    };

    window.addEventListener('fileSize.error', onFileError);
    return () => window.removeEventListener('fileSize.error', onFileError);
  }, []);

  return (
    <>
      {open && (
        <ConfirmModal
          title={title}
          message={message}
          onClose={() => setOpen(false)}
          buttons={[{ text: 'CLOSE', onClick: () => setOpen(false) }]}
        />
      )}
    </>
  );
}
