export default function PendingAssignmentIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 20.648 20.648"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1579"
            data-name="Rectangle 1579"
            width="20.648"
            height="20.648"
            transform="translate(0 0)"
            fill="none"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
        </clipPath>
      </defs>
      <g id="Group_2172" data-name="Group 2172" transform="translate(0 0)">
        <g
          id="Group_2172-2"
          data-name="Group 2172"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <circle
            id="Ellipse_779"
            data-name="Ellipse 779"
            cx="10.068"
            cy="10.068"
            r="10.068"
            transform="translate(0.257 0.256)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1024"
            data-name="Path 1024"
            d="M22.509,18.591a4.763,4.763,0,1,1-4.85-4.674A4.762,4.762,0,0,1,22.509,18.591Z"
            transform="translate(-7.372 -7.901)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <circle
            id="Ellipse_780"
            data-name="Ellipse 780"
            cx="0.421"
            cy="0.421"
            r="0.421"
            transform="translate(9.946 10.365)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1025"
            data-name="Path 1025"
            d="M24.4,20.26h3.154a3.574,3.574,0,0,0-3.574-3.574"
            transform="translate(-13.618 -9.474)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_398"
            data-name="Line 398"
            x2="0.631"
            transform="translate(9.315 10.786)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_399"
            data-name="Line 399"
            y2="0.907"
            transform="translate(10.828 5.108)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_400"
            data-name="Line 400"
            y1="0.907"
            transform="translate(9.921 5.108)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1026"
            data-name="Path 1026"
            d="M31.7,16.2l.641-.641-.641-.641-.641.641"
            transform="translate(-17.632 -8.47)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1027"
            data-name="Path 1027"
            d="M28.025,31.736a.21.21,0,1,1-.287-.077.21.21,0,0,1,.287.077"
            transform="translate(-15.69 -17.96)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1028"
            data-name="Path 1028"
            d="M19.755,17.413a.21.21,0,1,1-.287-.077.21.21,0,0,1,.287.077"
            transform="translate(-10.994 -9.827)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1029"
            data-name="Path 1029"
            d="M30.975,28.632a.21.21,0,1,1-.287.077.21.21,0,0,1,.287-.077"
            transform="translate(-17.408 -16.241)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1030"
            data-name="Path 1030"
            d="M16.652,20.362a.21.21,0,1,1-.287.077.21.21,0,0,1,.287-.077"
            transform="translate(-9.275 -11.545)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1031"
            data-name="Path 1031"
            d="M15.439,24.469a.21.21,0,1,1-.21.21.21.21,0,0,1,.21-.21"
            transform="translate(-8.647 -13.893)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1032"
            data-name="Path 1032"
            d="M16.442,28.632a.21.21,0,1,1-.077.287.21.21,0,0,1,.077-.287"
            transform="translate(-9.276 -16.241)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1033"
            data-name="Path 1033"
            d="M23.5,32.949a.21.21,0,1,1,.21.21.21.21,0,0,1-.21-.21"
            transform="translate(-13.342 -18.589)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <path
            id="Path_1034"
            data-name="Path 1034"
            d="M19.392,31.736a.21.21,0,1,1,.077.287.21.21,0,0,1-.077-.287"
            transform="translate(-10.994 -17.96)"
            fill="#fff"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
          <line
            id="Line_401"
            data-name="Line 401"
            x2="1.814"
            transform="translate(9.468 5.108)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1035"
            data-name="Path 1035"
            d="M14.641,16.2,14,15.56l.641-.641.641.641"
            transform="translate(-7.949 -8.47)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
