// import OvalIconButton from 'components/UI/molecules/OvalIconButton';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CompetenceScale from 'components/UI/molecules/CompetenceScale';
// import { tableData } from '../dummyData';
import InfoIcon from '@mui/icons-material/Info';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import useEvidenceRecordViewState from 'hooks/student/useEvidenceRecordViewState';
import EvidenceOverviewTableData from 'components/UI/molecules/student/EvidenceOverviewTableData';

export default function EvidenceOverviewView() {
  const { tableData } = useEvidenceRecordViewState();
  return (
    <>
      <div className="flex flex-row items-center mt-[16px] mb-[16px] p-[16px] gap-[16px] e-f-collapse">
        {/* <div className='flex flex-row items-center gap-[16px]'>
          <OvalIconButton
            title='SORT'
            textLight={true}
            style={{
              border: 'none',
              minWidth: 0,
            }}
            icon={
              <ChevronRightIcon
                style={{
                  fontSize: '44px',
                  fontWeight: 100,
                }}
              />
            }
          />
          <OvalIconButton
            title='GRADE'
            textLight={true}
            style={{
              minWidth: 0,
            }}
            icon={
              <KeyboardArrowUpIcon
                style={{
                  fontSize: '44px',
                  fontWeight: 100,
                }}
              />
            }
          />
          <OvalIconButton
            title='INCOMPLETE'
            textLight={true}
            style={{
              minWidth: 0,
            }}
          />
          <OvalIconButton
            title='COMPLETE'
            textLight={true}
            style={{
              minWidth: 0,
            }}
          />
        </div>
        <div className='flex-1'></div> */}
        <CompetenceScale />
      </div>
      <div style={{ height: 72 }} />
      <DashboardContentHeading>
        YOUR EVIDENCE OVERVIEW <KeyboardArrowDownIcon />
      </DashboardContentHeading>
      <table className="evidence-overview-table">
        <thead>
          <tr>
            {tableData.th.map((item, index) => (
              <th key={index} className="fort text-[#333]">
                {index > 0 && (
                  <InfoIcon
                    style={{
                      fontSize: '16px',
                      fontWeight: 100,
                      color: '#aaa',
                    }}
                  />
                )}
                <br />
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.thv.map((item, index) => {
            const tdData = tableData.data[index] || [];
            return (
              <tr key={index}>
                <th className="fort text-[#333]">
                  <InfoIcon
                    style={{
                      fontSize: '16px',
                      fontWeight: 100,
                      color: '#aaa',
                    }}
                  />
                  <br />
                  {item}
                </th>
                {tdData.map((itm, indx) => {
                  return (
                    <EvidenceOverviewTableData
                      key={indx}
                      status={itm.status}
                      value={itm.value}
                      fsId={indx + 1}
                      pcId={index + 1}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div className='flex flex-row items-center justify-center mt-[72px]'>
        <OvalIconButton
          title='LOAD MORE'
          icon={
            <ChevronRightIcon
              style={{
                fontSize: '44px',
                fontWeight: 100,
              }}
            />
          }
        />
      </div> */}
    </>
  );
}
