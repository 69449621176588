export default function ResourceAcademyIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 20.948 20.948"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2395"
            data-name="Rectangle 2395"
            width="20.948"
            height="20.948"
            fill="none"
            stroke="#989aa4"
            strokeWidth="0.5"
          />
        </clipPath>
      </defs>
      <g id="Group_2240" data-name="Group 2240" transform="translate(-29.899 -295.912)">
        <g id="Group_2239" data-name="Group 2239" transform="translate(29.899 295.912)">
          <g
            id="Group_2239-2"
            data-name="Group 2239"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <circle
              id="Ellipse_783"
              data-name="Ellipse 783"
              cx="10.214"
              cy="10.214"
              r="10.214"
              transform="translate(0.26 0.26)"
              fill="none"
              stroke="#989aa4"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              id="Path_1057"
              data-name="Path 1057"
              d="M25.843,22.459l1.43-2H18.455L17.6,22.6"
              transform="translate(-9.885 -11.487)"
              fill="none"
              stroke="#989aa4"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              id="Path_1058"
              data-name="Path 1058"
              d="M17.043,14.061v-.572l-.858-.858h-4a.572.572,0,0,0-.572.572v9.151a.572.572,0,0,0,.572.572h7.926a1,1,0,0,0,.685-.36l3.972-4.215v-.286H20.189l-1.144.858H15.377l-2.223,2.86h-.006V14.061Z"
              transform="translate(-6.519 -7.092)"
              fill="none"
              stroke="#989aa4"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              id="Path_1059"
              data-name="Path 1059"
              d="M24,15.892h5.433l-.858,2"
              transform="translate(-13.477 -8.924)"
              fill="none"
              stroke="#989aa4"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
