import React, { useEffect, useRef, useState } from 'react';
import reactDOM from 'react-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'redux/reducers/auth';
// import Button from '../atoms/Button';

const IDLE_DELAY = 1000 * 60 * 5; // 5 minutes
const PROMPT_DURATION = 1000 * 60;
// const IDLE_DELAY = 1000 * 5; // 5 sec
// const PROMPT_DURATION = 1000 * 10; // 10 sec
function SessionIdle() {
  const [isIdle, setIdle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const onPrompt = () => {
    setIdle(true);
  };
  const onIdle = () => {
    dispatch(logout());
    navigate('/');
  };
  const onContinue = () => {
    setIdle(false);
    start();
    setTimer(Math.floor((PROMPT_DURATION + IDLE_DELAY) / 1000));
  };

  const onAction = (event) => {
    // On any activity, reset the timer and if prompt is closed
    if (!isPrompted()) {
      setTimer(Math.floor((PROMPT_DURATION + IDLE_DELAY) / 1000));
    }
  };

  const { getRemainingTime, start, isPrompted } = useIdleTimer({
    onPrompt,
    onIdle,
    onAction,
    timeout: IDLE_DELAY, // 5 minutes
    promptTimeout: PROMPT_DURATION,
    debounce: 500,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
  });

  const [timer, setTimer] = useState(Math.floor(getRemainingTime() / 1000));

  useEffect(() => {
    if (timerRef.current) clearInterval(timerRef.current);
    if (timer > 0) {
      timerRef.current = setInterval(() => {
        const _t = Math.floor(getRemainingTime() / 1000);
        setTimer(_t);
      }, 1000);
    }

    return () => clearInterval(timerRef.current);
  }, []);

  return reactDOM.createPortal(
    <>
      {isIdle && (
        <div className="z-[2]">
          <div className="fort font-extralight h-full z-10 w-full fixed bg-black/70 flex items-center justify-center top-0 left-0 p-[16px]">
            <div className="modal-bg relative border-2 border-[#e6e6e6] rounded-[16px] px-[16px] pb-[16px] pt-[16px] w-full max-w-[540px] ">
              <h1 className="text-white uppercase text-[18px] fort font-normal leading-[28px]">
                Are you still here?
              </h1>
              <div className="mt-[30px]">
                <div className="text-white text-[18px] text-center">
                  You will be logout in <span className="fort font-normal">{timer}</span> seconds...
                </div>
                <div className="flex justify-center mt-[30px]">
                  <button
                    className="flex flex-row items-center justify-center fort text-[16px] rounded-full 
                                border-[2px] border-white text-white min-h-[40px] px-[16px] w-full max-w-[320px]
                                hover:bg-white/30"
                    onClick={onContinue}
                  >
                    Continue Session
                  </button>
                  {/* <Button
                    onClick={onContinue}
                    btnTitle='Continue Session'
                    bgColor='transparent'
                    color='white'
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>,
    document.body
  );
}
export default SessionIdle;
