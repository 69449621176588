import { useEffect, useState } from 'react';
import http from 'services/http';

export default function useAcademies() {
  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      const result = await http.post('/api/academies/search');
      setList(result.data.list);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return { list, getList };
}
