import * as React from 'react';
import FilterAutoComplete from '../molecules/FilterAutoComplete';
import TagPicker from '../molecules/TagPicker';

export default function TagAutocompleteFilter(props) {
  return props?.mainTag?.title === 'File Type' || props?.mainTag?.title === 'Year' ? (
    <TagPicker {...props} />
  ) : (
    <FilterAutoComplete {...props} />
  );
}
