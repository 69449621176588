import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import http from 'services/http';
import ConfirmModal from '../ConfirmModal';
import { useState, useRef } from 'react';
import UserEditModal from './UserEditModal';
import logger from 'utils/logger';
import useUser from 'hooks/auth/useUser';
// import Tooltip from '@mui/material/Tooltip';

const userTypes = {
  1: 'ADMIN',
  2: 'TRAINER',
  3: 'STUDENT',
};

/**
 * @param {{
 *  data: import("./UserEditModal").User
 * }} params0
 */
export default function UserListItem({ data }) {
  const [open, setOpen] = useState(false);
  const [openPromoteWarning, setOpenPromoteWarning] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const editQueueData = useRef(null);
  const { user } = useUser();

  const isSuperAdmin = data?.userId === 1 && user && user.userId === 1;

  const onDelete = async () => {
    if (isSuperAdmin) return;
    if (disabledActions()) return;
    setOpen(true);
  };

  const onEdit = async () => {
    if (isSuperAdmin) return;
    if (disabledActions()) return;
    setOpenEdit(true);
  };

  /**
   * @type {import("./UserEditModal").UserFormSubmitHandler}
   */
  const editSubmitHandler = async (formResponse) => {
    if (data.userTypeId > 1 && formResponse.userTypeId === 1) {
      setOpenEdit(false);
      showWarning(formResponse);
      return;
    }
    // todo submit
    await doSubmitEdit(formResponse);
  };

  const showWarning = (formResponse) => {
    editQueueData.current = formResponse;
    setOpenPromoteWarning(true);
  };

  const doSubmitEdit = async (formResponse) => {
    try {
      setOpenEdit(false);
      await http.put('/api/users/' + encodeURIComponent(data.uuid), formResponse);
      logger('editSubmitHandler: ', formResponse);
      window.dispatchEvent(new Event('assigned-roles.list.reload'));
      window.dispatchEvent(
        new CustomEvent('notify', {
          detail: {
            title: null,
            message: 'User Successfully Updated',
            status: 'success',
          },
        })
      );
    } catch (err) {
      window.dispatchEvent(
        new CustomEvent('notify', {
          detail: {
            title: null,
            message: err.message,
            status: 'error',
          },
        })
      );
    }
  };

  const buttons = [
    {
      text: 'YES',
      onClick: async () => {
        setOpen(false);
        if (data.userTypeId === 1) return;
        try {
          await http.delete('/api/users/' + (data?.userUUID || data?.uuid));
          window.dispatchEvent(new Event('assigned-roles.list.reload'));
          window.dispatchEvent(
            new CustomEvent('notify', {
              detail: {
                title: null,
                message: 'Deleted Successfully',
                status: 'success',
              },
            })
          );
        } catch (err) {
          window.dispatchEvent(
            new CustomEvent('notify', {
              detail: {
                title: null,
                message: err?.response?.data || err.message,
                status: 'error',
              },
            })
          );
        }
      },
    },
    {
      text: 'CANCEL',
      onClick: () => {
        setOpen(false);
      },
    },
  ];

  const buttonsForWarning = [
    {
      text: 'Promote To Admin',
      onClick: () => {
        setOpenPromoteWarning(false);
        if (editQueueData.current) {
          doSubmitEdit(editQueueData.current);
        }
      },
    },
    {
      text: 'Cancel',
      onClick: () => setOpenPromoteWarning(false),
    },
  ];

  const disabledActions = () => {
    if (isSuperAdmin) return true;
    if (user && user.userId === 1) return false;
    if (data?.userTypeId === 1) return true;
    return false;
  };

  return (
    <>
      <div className="fort font-extralight text-[12px] uppercase border-[#eee] border-[1px] rounded-[4px] hover:bg-[rgba(0,0,0,0.05)]">
        <div className="flex flex-row items-center">
          <div className="flex-1 p-[8px] border-r-[1px] border-[#eee] overflow-x-hidden">
            {data?.firstName}
          </div>
          <div className="flex-1 p-[8px] border-r-[1px] border-[#eee] overflow-x-hidden">
            {data?.lastName}
          </div>
          <div className="flex-[1.5] p-[8px] border-r-[1px] border-[#eee] overflow-x-hidden">
            {data?.email}
          </div>
          <div className="flex-1 p-[8px] border-r-[1px] border-[#eee] overflow-x-hidden max-w-[120px]">
            {userTypes[data?.userTypeId] || 'N/A'}
          </div>
          <div className="flex-1 max-w-[86px] border-r-[1px] border-[#eee] p-[8px] overflow-x-hidden">
            {data?.yearName || 'N/A'}
          </div>
          <div className="flex-1 max-w-[86px] p-[8px] overflow-x-hidden flex flex-row items-center gap-[16px] justify-end">
            <button
              onClick={onEdit}
              className="text-sky-500"
              style={{
                opacity: disabledActions() ? 0.3 : 1,
                cursor: disabledActions() ? 'not-allowed' : null,
              }}
              disabled={disabledActions()}
            >
              <EditIcon style={{ fontSize: 24 }} />
            </button>
            <button
              className="text-red-500"
              onClick={onDelete}
              style={{
                opacity: disabledActions() ? 0.3 : 1,
                cursor: disabledActions() ? 'not-allowed' : null,
              }}
              disabled={disabledActions()}
            >
              <DeleteIcon style={{ fontSize: 24 }} />
            </button>
          </div>
        </div>
      </div>

      {open && (
        <ConfirmModal
          title="DELETING USER"
          message={
            <span>
              Are you sure you want to delete{' '}
              <strong>
                {data?.firstName} {data.lastName}
              </strong>
              ?
            </span>
          }
          buttons={buttons}
          onClose={() => setOpen(false)}
        />
      )}

      {openPromoteWarning && (
        <ConfirmModal
          title="WARNING"
          message={
            <span>
              Once a user is promoted to <strong>admin</strong>, that permission can only be removed
              by the development team.
            </span>
          }
          buttons={buttonsForWarning}
          onClose={() => setOpenPromoteWarning(false)}
        />
      )}

      {openEdit && (
        <UserEditModal
          title="Edit User"
          data={JSON.parse(JSON.stringify(data))}
          onClose={() => setOpenEdit(false)}
          onSubmit={editSubmitHandler}
        />
      )}
    </>
  );
}
