export default function TrashSMIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 11}
      height={size || 11}
      viewBox="0 0 11.828 11.483"
    >
      <g id="Group_68" data-name="Group 68" transform="translate(-5663.179 -3576.53)">
        <line
          id="Line_13"
          data-name="Line 13"
          x1="4.282"
          y2="4.282"
          transform="translate(5666.952 3580.835)"
          fill="none"
          stroke="#3d3d3f"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <line
          id="Line_14"
          data-name="Line 14"
          x2="4.282"
          y2="4.282"
          transform="translate(5666.952 3580.835)"
          fill="none"
          stroke="#3d3d3f"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <line
          id="Line_15"
          data-name="Line 15"
          x2="11.828"
          transform="translate(5663.179 3578.188)"
          fill="none"
          stroke="#3d3d3f"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M5673.921,3578.188l-.927,9.575h-7.8l-.927-9.575"
          fill="none"
          stroke="#3d3d3f"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M5667.4,3578.188v-1.408h3.379v1.408"
          fill="none"
          stroke="#3d3d3f"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
}
