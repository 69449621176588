import { useRef, useEffect, useState, createContext, useContext } from 'react';
import http from 'services/http';
import createFileInput from 'utils/createFileInput';
// import useSkillList from './useSkillList';
import { useSearchParams } from 'react-router-dom';
import UploadStatus from 'utils/uploadStatus';
import logger from 'utils/logger';
import { FILE_UPLOAD_MAX_SIZE } from 'config/misc';

const target = {
  fsId: null,
  pcId: null,
};

export const SkillViewContext = createContext();
export const useSkillViewContext = () => useContext(SkillViewContext);

export const SkillViewProvider = ({ children }) => {
  const [slot1, setSlot1] = useState([]);
  // const [searchParams] = useSearchParams();

  // const { list: skillsList, getList } = useSkillList();
  const [selectedItem, setSelectedItem] = useState({
    fsId: null,
    pcId: null,
  });

  const getCurrentUploads = () => {
    http
      .post('/api/evidence-record-upload/current-user/search', { order: 'desc', orderBy: 'id' })
      .then((result) => {
        //
        /**
         * @type {UploadFile[]}
         */
        const list = result.data.list;
        const uploadedItems = [];
        for (const item of list) {
          uploadedItems.push({
            file: { ...item, timestamp: new Date(item.createdAt) },
            status: UploadStatus.SUCCESS,
          });
        }
        setSlot1([...uploadedItems]);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    logger('slot1 data: ', slot1);
  }, [slot1]);

  useEffect(() => {
    getCurrentUploads();
  }, []);

  return (
    <SkillViewContext.Provider
      value={{
        slot1,
        setSlot1,
        getCurrentUploads,
        // skillsList,
        // getList,
        selectedItem,
        setSelectedItem,
      }}
    >
      {children}
    </SkillViewContext.Provider>
  );
};

export default function useEvidenceSkillUpload() {
  const [searchParams] = useSearchParams();
  const getFolderId = () => {
    return Number(searchParams.get('folderId'));
  };
  //
  /**
   * @typedef {import('../../utils/createFileInput').UploadFile &
   *  { uuid: string, createAt: string }} UploadFile
   * @typedef {{ file: UploadFile, status: number }} InitialState
   * @typedef {React.Dispatch<React.SetStateAction<Array<InitialState>>>} setState
   * @type {[
   *  UploadFile[],
   *  setState,
   * ]}
   */
  const { slot1, setSlot1, getCurrentUploads, selectedItem } = useSkillViewContext();

  /**
   *
   * @param {{
   *  items: UploadFile[],
   *  setState: React.Dispatch<React.SetStateAction<Array<InitialState>>>,
   *  slotId: number
   * }} param0
   */
  const inputOnChange = ({ items, setState, slotId }) => {
    const overLimitFile = items.find((item) => item?.file?.size > FILE_UPLOAD_MAX_SIZE);
    if (overLimitFile) {
      alert(
        `The file you're attaching ${overLimitFile?.file?.name} is bigger than the server allows, Try putting the file in a shared location and sending a link instead`
      );
      return false;
    }
    const frmData = new FormData();
    const _newItems = [];
    for (const item of items) {
      item.file && frmData.append('files', item.file);
      item.fileName && frmData.append('fileName', item.fileName);
      item.fileExt && frmData.append('fileExt', item.fileExt);
      logger(target);
      frmData.append('fsId', target.fsId);
      frmData.append('pcId', target.pcId);
      getFolderId() && frmData.append('dirId', getFolderId());
      frmData.append('isExternal', false);
      // frmData.append('fileURL', null);
      frmData.append('slotId', slotId);
      _newItems.push({
        file: {
          ...item,
          fsId: target.fsId,
          pcId: target.pcId,
          uuid: item.id,
        },
        status: UploadStatus.UPLOADING,
      });
      // uploading item
    }
    window.dispatchEvent(new Event('form-submitted'));
    setState((prevState) => [..._newItems, ...prevState]);

    http
      .post('/api/evidence-record-upload', frmData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        getCurrentUploads();
        window.dispatchEvent(new Event('evidence.form-submitted'));
      })
      .catch((err) => {
        alert(err.message);
        setState((prevState) => ({
          ...prevState,
          status: UploadStatus.ERROR,
        }));
      });

    return true;
  };

  const handleLinkUpload = (data) => {
    const frmData = {
      links: data?.map((item) => ({
        ...item,
      })),
      dirId: getFolderId(),
      fsId: target.fsId,
      pcId: target.pcId,
    };
    http
      .post('/api/evidence-record-upload/links', frmData)
      .then(() => {
        getCurrentUploads();
        window.dispatchEvent(new Event('evidence.form-submitted'));
      })
      .catch((err) => {
        alert(err.message);
        setSlot1((prevState) => ({
          ...prevState,
          status: UploadStatus.ERROR,
        }));
      });
  };

  // removing items
  const removeItem = async (uuid, setState) => {
    const _slot1 = slot1;
    const targetIndex = slot1.findIndex((item) => {
      return item.file.uuid === uuid;
    });
    try {
      _slot1[targetIndex].status = UploadStatus.REMOVING;
      setState(() => [..._slot1]);

      const result = await http.delete(`/api/evidence-record-upload/${uuid}`);

      const filterItems = slot1.filter((item) => {
        return item.file.uuid !== uuid;
      });

      setState([...filterItems]);
      window.dispatchEvent(new CustomEvent('upload.delete', { detail: result.data }));
    } catch (err) {
      _slot1[targetIndex].status = UploadStatus.ERROR;
      setState([..._slot1]);
      window.dispatchEvent(new CustomEvent('app-error', { detail: err.message || err }));
    }
  };

  const slot1Input = useRef(
    createFileInput(
      ({ items }) => {
        inputOnChange({ items, setState: setSlot1, slotId: 1 });
      },
      { multiple: true }
    )
  );

  const removeSlot1 = (uuid) => {
    removeItem(uuid, setSlot1);
  };

  return {
    slot1,
    slot1Input: slot1Input.current,
    removeSlot1,
    getCurrentUploads,
    target,
    selectedItem,
    handleLinkUpload,
  };
}
