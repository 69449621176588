import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from 'components/UI/organisms/DashboardLayout';
import StudentHome from '.';
import SelfWorth from './SelfWorth';
import Engagement from './Engagement';
import Purpose from './Purpose';
import EngagementRoutes from './EngagementSubRoutes/EngagementRoutes';
import Profile from './Profile';
import PurposeRoutes from './PurposeSubRoutes/PurposeRoutes';
import SelfWorthRoutes from './SelfWorthSubRoutes/SelfWorthRoutes';
import { EvidenceRecordGradesProvider } from 'hooks/student/useEvidenceRecordGrades';

export default function StudentsRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <EvidenceRecordGradesProvider>
      <DashboardLayout>
        <div className="">
          <Routes>
            <Route
              path="/"
              element={
                <div className="w-full">
                  <StudentHome />
                </div>
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/self-worth" element={<SelfWorth />} />
            <Route path="/self-worth/*" element={<SelfWorthRoutes />} />
            <Route path="/engagement" element={<Engagement />} />
            <Route path="/engagement/*" element={<EngagementRoutes />} />
            <Route path="/purpose" element={<Purpose />} />
            <Route path="/purpose/*" element={<PurposeRoutes />} />
            <Route
              path="/events"
              element={<center className="pl-24 text-[45px] text-white">Events</center>}
            />
            <Route
              path="/messages"
              element={<center className="pl-24 text-[45px] text-white">Messages</center>}
            />
            <Route
              path="/account"
              element={<center className="pl-24 text-[45px] text-white">Account</center>}
            />
            <Route
              path="/groups"
              element={<center className="pl-24 text-[45px] text-white">Groups</center>}
            />
            <Route
              path="/settings"
              element={<center className="pl-24 text-[45px] text-white">Settings</center>}
            />
          </Routes>
        </div>
      </DashboardLayout>
    </EvidenceRecordGradesProvider>
  );
}
