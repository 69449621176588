export default function GroupIcon({ size }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 0.5,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 26.214 26.212"
    >
      <g id="Group_3122" data-name="Group 3122" transform="translate(-11514.085 6679.931)">
        <circle
          id="Ellipse_1170"
          data-name="Ellipse 1170"
          cx="3.127"
          cy="3.127"
          r="3.127"
          transform="translate(11518.909 -6671.186)"
          {...cssProps}
        />
        <path
          id="Path_1365"
          data-name="Path 1365"
          d="M54.51,454.875a6.564,6.564,0,1,1,13.128,0v3.939"
          transform="translate(11460.962 -7113.243)"
          {...cssProps}
        />
        <circle
          id="Ellipse_1171"
          data-name="Ellipse 1171"
          cx="3.127"
          cy="3.127"
          r="3.127"
          transform="translate(11529.221 -6673.217)"
          {...cssProps}
        />
        <path
          id="Path_1366"
          data-name="Path 1366"
          d="M63.015,450.251a6.565,6.565,0,0,1,12.371,3.063"
          transform="translate(11463.525 -7113.713)"
          {...cssProps}
        />
        <path
          id="Path_1368"
          data-name="Path 1368"
          d="M79.349,449.833a12.857,12.857,0,1,1-12.856-12.856A12.856,12.856,0,0,1,79.349,449.833Z"
          transform="translate(11460.699 -7116.658)"
          {...cssProps}
        />
      </g>
    </svg>
  );
}
