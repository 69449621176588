import React, { useEffect } from 'react';
import Sidebar from 'components/UI/organisms/Sidebar2';
import { Box } from '@mui/material';
import Header from 'components/UI/organisms/Header';
import { useNavigate } from 'react-router-dom';
import DashboardFooter from '../atoms/DashboardFooter';
import useUser from 'hooks/auth/useUser';
import useAllowedRoutes from 'hooks/auth/useAllowedRoutes';
import useSidebar from 'hooks/useSidebar';
import useAuthChecker from 'hooks/auth/useAuthChecker';
import AuthFailedModal from './AuthFailedModal';
// Trainer sidebar icons
import EvidenceRecordIcon from '../atoms/CustomIcons/EvidenceRecordIcon';
import PendingAssignmentIcon from '../atoms/CustomIcons/PendingAssignmentIcon';
import AssignedGroupIcon from '../atoms/CustomIcons/AssignedGroupIcon';
import StudentSearchIcon from '../atoms/CustomIcons/StudentSearchIcon';
import ResourceAcademyIcon from '../atoms/CustomIcons/ResourceAcademyIcon';
import SessionIdle from './SessionIdle';
import useAssignmentDirectory from 'hooks/student/useAssignmentDirectory';

function DashboardLayout({ children }) {
  const { isOpen } = useSidebar();
  const { treeList: dirList, getList } = useAssignmentDirectory(true);
  const { userType, isLoggedIn } = useUser();
  const navigate = useNavigate();
  const { error, check } = useAuthChecker();

  const { isAllow } = useAllowedRoutes();
  useEffect(() => {
    check();
    if (!isAllow) {
      navigate(`/${userType}`);
    }
    if (!isLoggedIn) {
      navigate('/login');
    }
    getList(true, {}, false);
  }, []);

  const trainerList = [
    {
      icon: <EvidenceRecordIcon size={28} />,
      text: 'Evidence Record',
      path: '/trainer/evidence',
    },
    {
      name: 'Training Materials',
      path: '/trainer/materials',
      children: dirList,
    },
    {
      icon: <PendingAssignmentIcon size={28} />,
      text: 'Pending Assignments',
      path: '/trainer/assessments',
    },
    {
      icon: <AssignedGroupIcon size={28} />,
      text: 'Assigned Groups',
      path: '/trainer/groups',
    },
    {
      icon: <StudentSearchIcon size={28} />,
      text: 'Student Search',
      path: '/trainer/search',
    },
    {
      icon: <ResourceAcademyIcon size={28} />,
      text: 'Resource Academy',
      path: '/trainer/resources',
    },
  ];
  const adminList = [
    {
      icon: <EvidenceRecordIcon size={28} />,
      text: 'Assigned Roles',
      path: '/admin/roles',
    },
    {
      icon: <EvidenceRecordIcon size={28} />,
      text: 'Evidence Record',
      path: '/admin/evidence',
    },
    {
      name: 'Training Materials',
      path: '/admin/materials',
      children: dirList,
    },
    {
      icon: <AssignedGroupIcon size={28} />,
      text: 'Assigned Groups',
      path: '/admin/groups',
    },
    {
      icon: <StudentSearchIcon size={28} />,
      text: 'Student Search',
      path: '/admin/search',
    },
    {
      icon: <ResourceAcademyIcon size={28} />,
      text: 'Resource Academy',
      path: '/admin/resources',
    },
  ];

  const getRoutes = () => {
    let routes = [];
    if (userType) {
      switch (userType) {
        case 'trainer':
          routes = trainerList;
          break;
        case 'student':
          routes = dirList;
          break;
        case 'admin':
          routes = adminList;
          break;

        default:
          break;
      }
    }
    return routes;
  };
  return (
    <Box className="flex h-full w-full">
      {userType !== 'student' && <SessionIdle />}
      <div className="bg-gradient-green"></div>
      <div className="bg-logo"></div>
      <Sidebar routesList={getRoutes()} userType={userType} />
      <Box
        className={
          'z-[1] transition-all duration-200 w-full flex flex-col min-h-[100vh]' +
          (isOpen ? ' pl-[280px]' : ' pl-[86px]')
        }
      >
        <Header userType={userType} />
        <div className="px-[64px] flex-1">{children}</div>
        <DashboardFooter />
      </Box>
      {error.isError && <AuthFailedModal message={error.errorMessage} title={error.errorTitle} />}
    </Box>
  );
}

export default DashboardLayout;
