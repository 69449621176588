import { useState, useRef } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleOutlineIconButton from '../atoms/CircleOutlineIconButton';

function DocumentCardContainerSmall({ children, className, mode = 'black', ...props }) {
  const [isOverflowing] = useState(false);
  const scrollDistance = 180;
  const overflowContainer = useRef();

  const handleLeftButtonClick = () => {
    //
    /**
     * @type {HTMLDivElement}
     */
    const con = overflowContainer.current;
    con.scrollTo({
      left: con.scrollLeft - scrollDistance,
      behavior: 'smooth',
    });
  };
  const handleRightButtonClick = () => {
    //
    /**
     * @type {HTMLDivElement}
     */
    const con = overflowContainer.current;
    con.scrollTo({
      left: con.scrollLeft + scrollDistance,
      behavior: 'smooth',
    });
  };

  const _isOverflowing = (Array.isArray(children) && children.length > 1) || isOverflowing;

  return (
    <div
      className={
        'flex items-center flex-row gap-[4px] max-w-[100%] overflow-x-hidden' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
    >
      {_isOverflowing && (
        <div>
          <CircleOutlineIconButton
            onClick={handleLeftButtonClick}
            icon={<ArrowBackIosNewIcon style={{ fontSize: 12 }} />}
            className={'pr-[2px] h-[28px] w-[28px]'}
            mode={mode}
            style={{
              height: 28,
              width: 28,
            }}
          />
        </div>
      )}
      <div
        className="relative flex items-center min-h-[156px] min-w-[172px] gap-[20px] max-w-[100%] h-[100%] overflow-y-hidden overflow-x-auto scrollbar-hidden"
        ref={overflowContainer}
      >
        <div className="sticky left-0 top-0 bottom-0 h-[100%] w-[24px] min-w-[24px] mr-[-32px] z-10"></div>
        {children}
        <div className="sticky right-0 top-0 bottom-0 h-[100%] w-[24px] min-w-[24px] ml-[-32px] z-10"></div>
      </div>
      {_isOverflowing && (
        <div>
          <CircleOutlineIconButton
            onClick={handleRightButtonClick}
            icon={<ArrowForwardIosIcon style={{ fontSize: 12 }} />}
            className="pl-[2px] h-[28px] w-[28px]"
            mode={mode}
            style={{
              height: 28,
              width: 28,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DocumentCardContainerSmall;
