import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import StrokeText from 'components/UI/atoms/StrokeText';
import useEngageProfessionallyState from 'hooks/student/useEngageProfessionallyState';
import { useNavigate } from 'react-router-dom';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import useDirectories from 'hooks/student/useDirectories';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import useUser from 'hooks/auth/useUser';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function EngageProfessionally() {
  const { userType } = useUser();

  const [open, setOpen] = useState(false);
  const { histories } = useFileUploadPanel();
  const { getByPath } = useDirectories();
  const navigate = useNavigate();
  const {
    slot1,
    slot2,
    slot3,
    slot1Input,
    slot2Input,
    slot3Input,
    removeSlot1,
    removeSlot2,
    removeSlot3,
    targetSlot,
    handleLinkUpload,
  } = useEngageProfessionallyState();
  const { list: assignmentDocuments } = useAssignmentDocuments();

  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'ENGAGE PROFESSIONALLY',
      path: '/engagement/engage-professionally?folderId=' + getByPath('engage-professionally').id,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
      case 'slot2':
        targetSlot.current = 2;
        break;
      case 'slot3':
        targetSlot.current = 3;
        break;
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
      case 'slot2':
        slot2Input.click();
        break;
      case 'slot3':
        slot3Input.click();
        break;
    }
  };

  return (
    <div>
      <MainHeadingText>
        ENGAGE <br /> PROFESSIONALLY
      </MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => <>C.V. DEVELOPMENT</>}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={removeSlot1}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => <>C.V PRESENTATION</>}
            activeFile={slot2}
            histories={histories}
            onUpload={() => uploadModal('slot2')}
            onRemoveHistory={removeSlot2}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => (
              <>
                INTERVIEW <br /> PRACTICE
              </>
            )}
            activeFile={slot3}
            histories={histories}
            onUpload={() => uploadModal('slot3')}
            onRemoveHistory={removeSlot3}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      <DashboardContentText>
        Engagement means we are emotionally, intellectually, and behaviourally engaged in our
        learning and work. When we are emotionally engaged, we experience Fun & Excitement by
        becoming so involved that we lose track of time. At the end of a lesson or task, we wonder,
        “Where did that time go?” When we are intellectually engaged, we ask “Why?” or “Why not?”
        about the world around us. Curiosity & Creativity are alive in how we learn, fostering
        inquisitiveness and a desire for new discoveries. When we are behaviourally engaged, we have
        a Spirit of Adventure. We are not afraid to try new things, or to take on healthy
        challenges, whether we might succeed or fail. With Engagement, learning becomes important in
        and of itself.
      </DashboardContentText>
      <div style={{ height: 36 }} />
      {userType === 'student' ? (
        <>
          <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
          {assignmentDocuments.length === 0 ? (
            <div className="py-[56px]">
              <StrokeText>
                No Assignment <br />
                Documents
              </StrokeText>
            </div>
          ) : (
            <DocumentCardContainer>
              {assignmentDocuments.map((item, index) => (
                <DocumentCard data={item} key={index} />
              ))}
            </DocumentCardContainer>
          )}
          <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
            <BottomLandscapeCard
              title="VISIT THE EVIDENCE RECORD"
              buttonTitle="START"
              onClick={() => {
                navigate('/student/purpose/evidence-record');
              }}
            />
          </div>
        </>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default EngageProfessionally;
