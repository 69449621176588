import { useEffect, useState } from 'react';
import { Box, Modal, CircularProgress } from '@mui/material';
import ModalUploadCard from 'components/UI/molecules/ModalUploadCard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import useUploadModal from 'hooks/organisms/useUploadModal';
import URLInputs from 'components/UI/molecules/URLInputs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: '#717171d9',
  border: '2px solid #fff',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
};

export default function ModalUploader({
  open = null,
  setOpen = () => {},
  handleUpload = () => {},
  handleLinkUploadFunc,
  ...props
}) {
  const {
    page,
    alerts,
    setAlerts,
    setPage,
    loading,
    setLinks,
    selected,
    handleSelect,
    handleNextPage,
    handlePreviousPage,
    canPrevious,
    triggerUpload,
    canNext,
    canSubmit,
  } = useUploadModal(handleUpload, setOpen, handleLinkUploadFunc);
  const [preCheck, check] = useState(false);

  const handleClose = () => setOpen(null);
  const handleSelectAndNext = (type = null) => {
    handleSelect(type);
    handleNextPage();
  };

  useEffect(() => {
    if (!open) {
      handleSelect(null);
      setPage(1);
    }
  }, [open]);
  return (
    <Modal open={open || false} onClose={handleClose} sx={{ backgroundColor: '#232432bb' }}>
      <Box sx={style}>
        <div className="relative">
          <div
            className="h-[32px] w-[32px] absolute -top-6 -right-8 inset bg-white mx-2 
                        border-[#777] border-[1px] rounded-full items-center flex 
                        right-0 justify-center text-[#aaa] cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon
              style={{
                fontSize: '1em',
              }}
            />
          </div>
        </div>
        {page === 1 && (
          <>
            {/* TODO: Change dynamically the title of modal per purpose and step */}
            <p className="fort text-center text-white text-[18px] mb-4">
              Choose how to <br /> upload your evidence:
            </p>
            <Box className="flex mx-[20px] justify-around mb-2">
              <ModalUploadCard
                className={`mx-7 ${
                  selected === 'upload' ? 'border-black border-2' : 'border-white/0'
                }`}
                onClick={triggerUpload}
                title="UPLOAD FILE"
                buttonTitle="ADD FILE"
                buttonIcon={
                  <AddIcon
                    style={{
                      fontSize: '24px',
                      color: '#555',
                      marginLeft: 2,
                    }}
                  />
                }
              />
              <ModalUploadCard
                className={`mx-7 ${
                  selected === 'url' ? 'border-black border-2' : 'border-white/0'
                }`}
                onClick={() => handleSelectAndNext('url')}
                title="ADD GOOGLE DOC URL"
                buttonTitle="START"
                buttonIcon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '24px',
                      color: '#555',
                      marginLeft: 2,
                    }}
                  />
                }
              />
            </Box>
          </>
        )}
        {/* TODO: This is temporary and needs to normalize to other components */}
        {selected === 'url' && (
          <URLInputs page={page} setLinks={setLinks} setAlerts={setAlerts} check={check} />
        )}
        {/* 
          If it reached 'page 4' then 
          it is sending the links to the api 
        */}
        {loading && <CircularProgress size={72} />}
        {alerts && (
          <p className="fond text-white text-[12px] fort font-extralight tracking-[2px] uppercase p-1 px-4 bg-[#AF3635] rounded-full mb-4">
            {alerts}
          </p>
        )}
        {page >= 2 && !loading && (
          <Box className="flex justify-center">
            {canPrevious && (
              <div
                className="h-[40px] w-[40px] bg-white mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center cursor-pointer"
                onClick={handlePreviousPage}
              >
                <ChevronLeftIcon
                  style={{
                    fontSize: '24px',
                    color: '#555',
                  }}
                />
              </div>
            )}
            {(canNext || preCheck) && !canSubmit && (
              <div
                className={`h-[40px] w-[40px] bg-white mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center ${
                  !canNext ? 'opacity-30' : 'cursor-pointer'
                }`}
                onClick={preCheck || canNext ? handleNextPage : null}
              >
                <ChevronRightIcon
                  style={{
                    fontSize: '24px',
                    color: '#555',
                  }}
                />
              </div>
            )}
            {canSubmit && (
              <div
                className="cursor-pointer bg-white overflow-hidden flex items-center w-28 h-[40px] min-w-[40px] transition-all relative ease-in-out duration-[.4s] rounded-full border-[#777] border-[1px]"
                onClick={handleNextPage}
              >
                <p className="workSans-medium text-[9pt] uppercase tracking-[2px] text-md absolute left-4 opacity-100 transition-all duration-[0.3s] delay-100">
                  Submit
                </p>
                <div className="absolute h-[40px] w-[40px] rounded-full bg-transparent items-center flex right-0 justify-center pointer-events-none">
                  <ChevronRightIcon
                    style={{
                      fontSize: '24px',
                      color: '#555',
                    }}
                  />
                </div>
              </div>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
