import React from 'react';
import { ThemeProvider } from '@mui/material';
import './App.css';
import Theme from './config/Theme';
import Routing from 'router/BrowserContent';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export default function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Routing />
    </ThemeProvider>
  );
}
