import { useState, useEffect } from 'react';
import { tagFilters } from 'constants/sampleData';
import http from 'services/http';
export default function useTagQuery() {
  const [tags, setTags] = useState(tagFilters);
  const [selectedUuids, setSelected] = useState({});
  const [featured, setFeatured] = useState(false);

  const updateTag = (tag) => {
    tag.active = !tag.active || false;
    setTags(tags.map((s) => (tag.id !== s.id ? s : tag)));
  };

  const addSelected = (id, asSearch = 'topicId') => {
    const list = selectedUuids[asSearch] || [];

    const newUuids = list.indexOf(id) === -1 ? [...list, id] : list.filter((i) => i !== id);
    setSelected({
      ...selectedUuids,
      [asSearch]: newUuids,
    });
  };

  const clearSelected = (asSearch = 'topicId') => {
    setSelected({
      ...selectedUuids,
      [asSearch]: [],
    });
  };

  useEffect(() => {
    const loadTags = async () => {
      const { data } = await http.post('/api/tags');
      const modified = data.map((tag) => {
        if (['Class', 'Inside Group', 'Outside Group'].includes(tag.title)) {
          return {
            ...tag,
            subtags: tag.subtags?.map((x) => ({ ...x, name: x.groupName })),
          };
        } else return tag;
      });
      setTags(modified);
    };

    loadTags();
  }, []);

  return {
    tags,
    setTags,
    addSelected,
    // Actually just ids
    selectedUuids,
    updateTag,
    clearSelected,
    featured,
    setFeatured,
  };
}
