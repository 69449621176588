import React, { useEffect } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EvidenceScoreItem from 'components/UI/molecules/EvidenceScoreItem';
import InfoIcon from '@mui/icons-material/Info';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import StrokeText from 'components/UI/atoms/StrokeText';
import HighlightCard from 'components/UI/molecules/HighlightCard';
import EvidenceOverviewView from './EvidenceRecordParts/EvidenceOverviewView';
import ovalIconButtonStyles from 'styles/UI/molecules/ovalIconButton.module.css';
import EvidenceRecordView from './EvidenceRecordParts/EvidenceRecordView';
import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import useEvidenceRecord, {
  EvidenceRecordProvider,
} from 'hooks/trainer/EvidenceRecord/useEvidenceRecord';
// import useEvidenceToReview from 'hooks/trainer/EvidenceRecord/useEvidenceToReview';
import EvidenceToReviewItem from 'components/UI/molecules/EvidenceToReviewItem';
import stringPadding from 'utils/stringPadding';

function EvidenceRecord() {
  return (
    <EvidenceRecordProvider>
      <EvidenceRecord_ />
    </EvidenceRecordProvider>
  );
}
function EvidenceRecord_() {
  const loc = useLocation();
  // const { list } = useEvidenceToReview();
  const { overallAssessment, evidenceSkillsScore, evidenceCompetenciesScore, evidenceToReview } =
    useEvidenceRecord();
  const breadcrumbsItems = [
    {
      text: 'TRAINER DASHBOARD',
      path: '/trainer',
    },
    {
      text: 'Evidence Record',
      path: '/trainer/evidence',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainHeadingText>
        Evidence <br /> Record
      </MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentHeading className="mt-[32px] pl-[16px] pr-[16px]">
        EVIDENCE TO REVIEW
      </DashboardContentHeading>
      <div className={'inline-block bg-white rounded-[12px] shadow-md py-[16px] ml-[16px]'}>
        <div className="max-h-[160px] list-scroll overflow-y-auto">
          {evidenceToReview.length > 0 &&
            evidenceToReview.map((item) => <EvidenceToReviewItem data={item} key={item.id} />)}
          {evidenceToReview.length === 0 && (
            <div className="px-[16px]">
              <StrokeText style={{ fontSize: 24 }}>NO EVIDENCE TO REVIEW</StrokeText>
            </div>
          )}
        </div>
      </div>

      <div className="pl-[16px] pr-[16px]">
        <div style={{ height: 72 }} />
        <DashboardContentHeading>STUDENT EVIDENCE:</DashboardContentHeading>
        <StrokeText style={{ fontSize: 40 }}>
          FUTURE SKILLS (FS) / <br />
          PROFESSIONAL COMPETENCIES (PC)
        </StrokeText>
        <div className="flex flex-row min-w-[800px] mt-[56px] place-items-start e-f-collapse">
          <HighlightCard>
            <EvidenceScoreItem
              title={
                <>
                  CURRENT EVIDENCE <br />
                  GRADE
                </>
              }
              text={stringPadding(overallAssessment, 2) + '%'}
            />

            <EvidenceScoreItem
              title={
                <>
                  EVIDENCE <br />
                  SKILLS
                </>
              }
              text={stringPadding(evidenceSkillsScore, 2)}
            />

            <EvidenceScoreItem
              title={
                <>
                  EVIDENCE <br />
                  COMPETENCIES
                </>
              }
              text={stringPadding(evidenceCompetenciesScore, 2)}
            />
          </HighlightCard>
          <div className="flex-1 min-w-[16px]"></div>
          <HighlightCard className={'py-[16px] px-[24px] gap-[32px] items-center'}>
            <div className="fort text-[14px]">
              OVERALL <br />
              ASSESSMENT <InfoIcon style={{ fontSize: 16, color: '#aaa' }} />
            </div>
            <StrokeText style={{ fontSize: 46 }}>
              {stringPadding(overallAssessment, 2) + '%'}
            </StrokeText>
          </HighlightCard>
          {/* <EvidenceScoreItem
            title={
              <>
                OVERALL <br />
                ASSESSMENT
              </>
            }
            style={{
              textAlign: 'right',
              paddingRight: 0,
              marginRight: 0,
            }}
            text='00%'
          /> */}
        </div>
      </div>
      <div className="border-t-[.5px] w-full border-white/20"></div>
      <div className="flex flex-row items-center mt-[16px] mb-[16px] p-[16px] e-f-collapse">
        <div className="flex flex-row items-center gap-[16px]">
          <NavLink to={`record${loc.search}`}>
            {({ isActive }) => (
              <OvalIconButton
                className={isActive ? ovalIconButtonStyles.selected : null}
                title="EVIDENCE RECORD"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
          </NavLink>

          <NavLink to={`overview${loc.search}`}>
            {({ isActive }) => (
              <OvalIconButton
                className={isActive ? ovalIconButtonStyles.selected : null}
                title="EVIDENCE OVERVIEW"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
          </NavLink>

          <NavLink to={`upload-materials`}>
            {({ isActive }) => (
              <OvalIconButton
                className={isActive ? ovalIconButtonStyles.selected : null}
                title="UPLOAD MATERIALS"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
          </NavLink>

          <NavLink to={`/trainer`}>
            {({ isActive }) => (
              <OvalIconButton
                // className={isActive ? ovalIconButtonStyles.selected : null}
                title="BACK TO TRAINER DASHBOARD"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
          </NavLink>
        </div>
        <div className="flex-1"></div>
      </div>
      <MainHeadingSeparator />
      <Routes>
        <Route path="record/*" element={<EvidenceRecordView />} />
        <Route path="overview" element={<EvidenceOverviewView />} />
        <Route path="*" element={<Navigate to={`record${loc.search}`} replace />} />
      </Routes>
      {/* {evidenceViewIndex === 0 ? <EvidenceRecordView /> : <EvidenceOverviewView />} */}
    </div>
  );
}

export default EvidenceRecord;
