import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import SortPicker from 'components/UI/molecules/SortPicker';

import Input from 'components/UI/atoms/Input';
import BaseList from 'components/UI/atoms/BaseList';
import HeadIcon from 'components/UI/atoms/CustomIcons/HeadIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress } from '@mui/material';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import CheckItem from 'components/UI/molecules/CheckItem';
import StudentInfo from 'components/UI/molecules/student/StudentInfo';
import StudentGroupModal from 'components/UI/molecules/student/StudentGroupModal';

import TagAutocompleteFilter from './TagAutoCompleteFilter';
import useTagQuery from 'hooks/misc/useTagQuery';
import useStudentQuery from 'hooks/student/useStudentQuery';
import useUser from 'hooks/auth/useUser';

export default function SearchStudent({
  addToGroup = null,
  allowNext = true,
  onAssign = () => {},
  onNext = () => {},
  onPrev = () => {},
  loading = false,
  allowSelectStudent = false,
  // Works as a modal
  allowAddToGroup = false,
  fromModal = false,
  allowedTags,
}) {
  const navigate = useNavigate();
  const { userType } = useUser();
  const [open, setOpen] = useState(false);
  const { tags, selectedUuids, addSelected, clearSelected } = useTagQuery();
  allowedTags = allowedTags || [
    'Academy',
    // 'Topics',
    // 'File Type',
    // 'Subject',
    'Class',
    'Inside Group',
    'Outside Group',
    'Year',
  ];
  const {
    students,
    // onSearch,
    loading: studentLoading,
    selected,
    checkStudent,
    firstName,
    lastName,
    setFirstName,
    setLastName,
    showStudent,
    selectStudent,
    unSelectStudent,
    isActiveStudent,
    boxContainer,
  } = useStudentQuery(selectedUuids);

  const handleAddToGroup = () => (addToGroup ? addToGroup(selected) : null);

  // const handleSearch = () => onSearch(selectedUuids, { firstName, lastName });

  const handleCloseModal = () => {
    setOpen(false);
    // unSelectStudent();
  };

  const goToEvidence = () =>
    navigate(`/${userType || 'trainer'}/evidence/${showStudent?.userUUID}`);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 mb-4">
      <div className=" pr-6">
        <div className="mb-4 w-[90%]">
          {tags
            .filter((tag) => allowedTags.indexOf(tag.title) !== -1)
            // .sort((a, b) => allowedTags.indexOf(a.title) - allowedTags.indexOf(b.title))
            .sort((a, b) => (a.title !== 'File Type' && a.title !== 'Year' ? -1 : 0))
            .map((i, k) => (
              <TagAutocompleteFilter
                key={k}
                mainTag={i}
                tags={i.subtags}
                selected={selectedUuids[i.asSearch] || []}
                onClear={clearSelected} // For Autocomplete
                onClick={addSelected}
                fromModal={fromModal}
              />
            ))}
        </div>
        <h5 className={`fort uppercase mb-2 ${fromModal && 'text-[#fff]'}`}>Search by name</h5>
        <Input
          placeholder="first name"
          className="text-left w-[90%] mb-2 rounded-lg p-2 shadow"
          value={firstName}
          onChange={({ target }) => setFirstName(target.value)}
        />
        <Input
          placeholder="last name"
          className="text-left w-[90%] mb-4 rounded-lg p-2 shadow"
          value={lastName}
          onChange={({ target }) => setLastName(target.value)}
        />
      </div>
      <div className="relative">
        <div className="bg-white rounded-lg h-[400px] mb-4 flex flex-col lg:min-w-[650px]">
          {showStudent !== null && (
            <StudentInfo
              open={open}
              student={showStudent}
              handleClose={unSelectStudent}
              allowAddToGroup={allowAddToGroup}
              handleAddToGroup={() => setOpen(true)}
              handleShowInfo={goToEvidence}
            />
          )}
          <BaseList listClassName={'p-4 grid grid-cols-2 gap-2 grow'}>
            {students.map((i, k) => (
              <CheckItem
                key={k}
                title={i.firstName + ' ' + i.lastName}
                subtitle={i.academyName}
                active={!!isActiveStudent(i)}
                onClick={() => checkStudent(i)}
                className="h-[46px]"
                svgicon={<HeadIcon size={20} active={i.active || false} />}
              />
            ))}
            <div ref={boxContainer} title="endoflist"></div>

            {studentLoading && (
              <div className="col-span-2 flex justify-center items-center">
                <CircularProgress />
              </div>
            )}
          </BaseList>
        </div>
        <div className="flex justify-between b-[-50px]">
          <div className="flex">
            <div
              className={`${
                !fromModal ? 'border-[#777] ' : 'border-[#fff] text-[#ffffff]'
              }bg-transparent mx-2 border-[1px] cursor-pointer rounded-full items-center flex right-0 justify-center`}
              onClick={() => onPrev(selected)}
            >
              <ChevronLeftIcon
                style={{
                  fontSize: '32px',
                  color: !fromModal ? '#777' : '#fff',
                }}
              />
            </div>
            {allowNext ? (
              <div
                className={`${
                  !fromModal ? 'border-[#777] ' : 'border-[#fff] text-[#ffffff]'
                }bg-transparent mx-2 border-[1px] cursor-pointer rounded-full items-center flex right-0 justify-center`}
                onClick={() => onNext(selected)}
              >
                <ChevronRightIcon
                  style={{
                    fontSize: '32px',
                    color: !fromModal ? '#777' : '#fff',
                  }}
                />
              </div>
            ) : (
              <OvalIconButton
                onClick={() => onAssign(selected)}
                disabled={!selected.length}
                title="ASSIGN MATERIAL"
                className="self-end cursor-pointer bg-white text-sky-500 hover:bg-[#fff] disabled:hover:bg-[#fff]"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
            {addToGroup && (
              <OvalIconButton
                onClick={handleAddToGroup}
                title="ADD TO GROUP"
                className="self-end cursor-pointer"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
            {allowSelectStudent && (
              <OvalIconButton
                onClick={selectStudent}
                title="VIEW STUDENT INFO"
                className="self-end cursor-pointer"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
            {loading && <CircularProgress />}
          </div>
          {showStudent && (
            <OvalIconButton
              onClick={goToEvidence}
              title="VIEW EVIDENCE RECORD"
              className="self-end cursor-pointer"
              icon={
                <ChevronRightIcon
                  style={{
                    fontSize: '44px',
                    fontWeight: 100,
                  }}
                />
              }
            />
          )}
        </div>
      </div>
      <StudentGroupModal open={open} student={showStudent} setOpen={handleCloseModal} />
    </div>
  );
}
