import BaseList from 'components/UI/atoms/BaseList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export default function CheckList({
  children,
  count = 0,
  onAdd = null,
  onEdit = null,
  onDelete = null,
  className,
  listClassName,
  title,
  ...props
}) {
  return (
    <div className={`fort flex flex-col ${className}`}>
      <h4 className="">
        {title}: {count < 10 ? `0${count}` : count}
      </h4>
      <BaseList listClassName={`${listClassName} my-4`}>{children}</BaseList>
      <div className="flex flex-row-reverse">
        {typeof onDelete === 'function' && (
          <button
            className="flex items-center py-2 px-3 justify-between h-[18px] text-sm rounded-lg bg-white border-solid border border-black ml-2"
            onClick={onDelete}
          >
            Delete
            <CloseIcon
              style={{
                fontSize: '14px',
              }}
            />
          </button>
        )}
        {typeof onEdit === 'function' && (
          <button
            className="flex items-center py-2 px-3 justify-between h-[18px] text-sm rounded-lg bg-white border-solid border border-black ml-2"
            onClick={onEdit}
          >
            Edit
            <ChevronRightIcon
              style={{
                fontSize: '14px',
              }}
            />
          </button>
        )}
        {typeof onAdd === 'function' && (
          <button
            className="flex items-center py-2 px-3 justify-between h-[18px] text-sm rounded-lg bg-white border-solid border border-black ml-2"
            onClick={onAdd}
          >
            Add
            <AddIcon
              style={{
                fontSize: '14px',
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
}
