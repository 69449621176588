import { useReducer, useRef } from 'react';
import http from 'services/http';
import createFileInput from 'utils/createFileInput';
import logger from 'utils/logger';

const initialData = {
  studentInputLoading: false,
  trainerInputLoading: false,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

export default function useAssignedRoles() {
  const [state, dispatch] = useReducer(reducer, initialData);

  const uploadTrainerInput = useRef(
    createFileInput(async ({ file }) => {
      if (!file) return;
      // todo submit
      logger(file);
      setState({
        trainerInputLoading: true,
      });
      const frmData = new FormData();
      frmData.append('file', file);
      try {
        const result = await http.post('/api/csv-upload/teachers', frmData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setState({
          trainerInputLoading: false,
        });
        // todo for success
        logger(result);
        window.dispatchEvent(new CustomEvent('upload.success', { detail: result }));
        window.dispatchEvent(
          new CustomEvent('notify', {
            detail: {
              title: null,
              message: 'Trainers Successfully Added',
              status: 'success',
            },
          })
        );
        window.dispatchEvent(new Event('assigned-roles.list.reload'));
      } catch (err) {
        setState({
          trainerInputLoading: false,
        });
        // todo for error
        logger(err);
        window.dispatchEvent(new CustomEvent('upload.error', { detail: err }));
        window.dispatchEvent(
          new CustomEvent('notify', {
            detail: {
              title: null,
              message: err.response?.data?.message || err.message,
              status: 'error',
            },
          })
        );
      }
    })
  );

  const uploadStudentInput = useRef(
    createFileInput(async ({ file }) => {
      if (!file) return;
      // todo submit
      logger(file);
      setState({
        studentInputLoading: true,
      });
      const frmData = new FormData();
      frmData.append('file', file);
      try {
        const result = await http.post('/api/csv-upload/students', frmData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setState({
          studentInputLoading: false,
        });
        // todo for success
        logger(result);
        window.dispatchEvent(new CustomEvent('upload.success', { detail: result }));
        window.dispatchEvent(new Event('assigned-roles.list.reload'));
        window.dispatchEvent(
          new CustomEvent('notify', {
            detail: {
              title: null,
              message: 'Students Successfully Added',
              status: 'success',
            },
          })
        );
      } catch (err) {
        setState({
          studentInputLoading: false,
        });
        // todo for error
        logger(err);
        window.dispatchEvent(new CustomEvent('upload.error', { detail: err }));
        window.dispatchEvent(
          new CustomEvent('notify', {
            detail: {
              title: null,
              message: err.response?.data?.message || err.message,
              status: 'error',
            },
          })
        );
      }
    })
  );

  const setState = (newState) => {
    dispatch(newState);
  };

  return {
    ...state,
    setState,
    uploadTrainerInput: uploadTrainerInput.current,
    uploadStudentInput: uploadStudentInput.current,
  };
}
