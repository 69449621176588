import useUser from 'hooks/auth/useUser';
import useEvidenceRecordGrades from 'hooks/student/useEvidenceRecordGrades';
import useEvidenceSkillUpload, { useSkillViewContext } from 'hooks/student/useEvidenceSkillUpload';
import { useState, useEffect } from 'react';
import EvidenceRecordGradeViewModal from 'components/UI/organisms/student/EvidenceRecordGradeViewModal';

const CompetencyScale = {
  NO_CURRENT_EVIDENCE: 0,
  EVIDENCE_IN_REVIEW: 1,
  NEED_ATTENTION: 2,
  GOOD_STANDING: 3,
};

export default function EvidenceOverviewTableData({ status, value, fsId, pcId }) {
  const { user } = useUser();
  const { gradedItems } = useEvidenceRecordGrades();
  const { target } = useEvidenceSkillUpload();
  const { slot1, setSelectedItem } = useSkillViewContext();
  const [openGradeInfo, setOpenGradeInfo] = useState(false);
  const [state, setState] = useState({
    status,
    value,
  });

  useEffect(() => {
    let _scale = 0;
    const currnetStudentUploads = slot1.filter((item) => {
      return fsId === item.file.fsId && pcId === item.file.pcId;
    });

    if (currnetStudentUploads.length > 0) {
      _scale = CompetencyScale.EVIDENCE_IN_REVIEW;
      setState((prevState) => ({
        ...prevState,
        status: _scale,
      }));
    }
    const currentItem = gradedItems.find((item) => {
      // logger('to compare: ', `${fsId}=${item.fsId} | ${pcId}=${item.pcId}`);
      return fsId === item.fsId && pcId === item.pcId && item.studentId === user.userId;
    });

    if (currentItem) {
      const squareTotal = currentItem.skillScore + currentItem.competencyScore;
      if (squareTotal >= 1 && squareTotal <= 4) {
        _scale = CompetencyScale.NEED_ATTENTION;
      } else if (squareTotal >= 5) {
        _scale = CompetencyScale.GOOD_STANDING;
      }
      setState((prevState) => ({
        ...prevState,
        status: _scale,
        value: currentItem.skillScore + '/' + currentItem.competencyScore,
      }));
    }
  }, []);

  const onViewGrade = () => {
    if (state.status <= 1) return;
    target.fsId = fsId;
    target.pcId = pcId;
    setSelectedItem({
      fsId,
      pcId,
    });
    setState((prevState) => ({
      ...prevState,
      isFocused: true,
    }));
    setOpenGradeInfo(true);
  };

  const closeGradeInfoModal = () => {
    setOpenGradeInfo(false);
  };

  return (
    <>
      <td
        className={'cursor-pointer fort' + ` competence-scale-${state.status}`}
        onClick={onViewGrade}
      >
        {state.value}
      </td>
      {openGradeInfo && (
        <EvidenceRecordGradeViewModal open={openGradeInfo} onClose={closeGradeInfoModal} />
      )}
    </>
  );
}
