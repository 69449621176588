import axios from 'axios';
import { API_ENDPOINT } from 'config/api';
import LocalStorageService from 'services/localstorage';
// import store from 'redux/index'

class HttpService {
  config(config, endpoint) {
    // const { auth } = store.getState();
    const getAccessToken = LocalStorageService.get('token');
    // const data = JSON.parse(getAccessToken.value);
    const token = getAccessToken?.value || null;
    if (!endpoint.includes('http://') && !endpoint.includes('https://'))
      config.baseURL = API_ENDPOINT;

    if (!config.headers) config.headers = {};

    if (
      token &&
      !config.headers.Authorization &&
      !endpoint.includes('http://') &&
      !endpoint.includes('https://')
    )
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  }

  // Returns true if the request was successful.
  errorhandler(errorData) {
    const getAccessToken = LocalStorageService.get('user');
    JSON.parse(getAccessToken.value);
    return errorData;
  }

  get(endpoint, params, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      config.params = params;
      config = this.config(config, endpoint);
      axios
        .get(endpoint, config)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  post(endpoint, params, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      config = this.config(config, endpoint);
      axios
        .post(endpoint, params, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  put(endpoint, params, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      config = this.config(config, endpoint);
      axios
        .put(endpoint, params, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  patch(endpoint, params, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      config = this.config(config, endpoint);
      axios
        .patch(endpoint, params, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  delete(endpoint, data, config = { headers: {} }) {
    return new Promise((resolve, reject) => {
      config.data = data;
      config = this.config(config, endpoint);
      axios
        .delete(endpoint, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }
}

export default new HttpService();
