import { useEffect, useState } from 'react';
import http from 'services/http';

export default function useRoles() {
  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      const result = await http.post('/api/user-type/search', { order: 'desc', orderBy: 'id' });
      const index = result.data.list.findIndex((item) => item.id === 2);
      result.data.list[index].name = 'trainer';
      const list = result.data.list;
      list.sort((a, b) => -(a.id - b.id));
      setList(result.data.list);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return { list, getList };
}
