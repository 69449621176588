import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Toast from '../atoms/Toast';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowButton from '../atoms/ArrowButton';
import Input from '../atoms/Input';
import LinkText from '../atoms/LinkText';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { do2fa } from 'redux/reducers/auth';
import useSessionTimeout from 'hooks/admin/useSessionTimeout';

const userTypeMap = {
  teacher: 'trainer',
  admin: 'admin',
};
function OTPComponent({ token }) {
  const { setSessionTimeout } = useSessionTimeout();
  const errMessage = useSelector((state) => state.auth.errMessage);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [toast, setToast] = useState({
    status: false,
    message: '',
  });

  useEffect(() => {
    if (errMessage !== 'null') {
      setToast({
        status: true,
        message: errMessage,
      });
    }
  }, [errMessage]);

  const onOTPChange = (event) => {
    setError('');
    setOtp(event.target.value);
  };

  const backToLogin = () => {
    navigate('/login');
  };

  const schema = yup.object({
    otp: yup.string().required('OTP is required'),
    token: yup.string().required('Token is required!'),
  });

  const onSubmit = async () => {
    const values = { otp, token };
    const isValid = await schema.isValid(values);
    schema.validate(values).catch((err) => {
      setError(err.message);
    });

    if (!isValid || loading) return;

    setLoading(true);
    const response = await dispatch(do2fa({ values }));

    if (response?.payload?.response?.data?.usertype) {
      setLoading(false);
      if (response?.payload?.response?.data?.usertype === 'admin') {
        setSessionTimeout();
      }
      const route = userTypeMap[response?.payload?.response?.data?.usertype];
      navigate(`/${route}`);
    }
    setLoading(false);
  };
  return (
    <div>
      <Toast
        onClose={() =>
          setToast({
            status: false,
            message: '',
          })
        }
        toast={toast}
      />
      <div
        className="pb-5 w-200 flex flex-col items-center pt-5 px-9  shadow 
      bg-transparent border-2 border-white rounded-[16px] bg-white/20"
      >
        <AccountCircleOutlinedIcon
          style={{
            color: 'white',
          }}
        />
        <Typography variant="p" className="text-white text-[20px] font-bold pb-1 pt-3  uppercase ">
          Enter OTP Sent In Email
        </Typography>
        <div className="w-11/12 relative flex flex-col items-center">
          <div className=" w-3/4 relative ">
            <div className="my-1  ">
              <Input
                placeholder={'OTP'}
                value={otp}
                errStatus={error}
                errMessage={error}
                onChange={onOTPChange}
                type={'text'}
              />
              <p
                className={`${
                  !error ? 'opacity-0' : 'opacity-100'
                } absolute right-1 text-sm text-red-500 ease-in-out duration-200`}
              >
                {error}
              </p>
            </div>
            <div className="mt-2 flex justify-center">
              {loading ? (
                <div className="loader"></div>
              ) : (
                <ArrowButton onClick={onSubmit} filled={true} direction="right" />
              )}
            </div>
            <div className="flex justify-between mt-1">
              <LinkText onClick={backToLogin} text={'Back to Login'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPComponent;
