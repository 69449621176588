/**
 *
 * @param {import("react").HTMLAttributes} param0
 * @returns
 */
export default function StrokeText({ children, className, ...props }) {
  return (
    <div
      className={
        'text-[48px] sub-heading-white-stroke2' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {children}
    </div>
  );
}
