import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#232431',
    border: 'none',
    color: 'white',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    padding: 0,
    borderRadius: 16,
    boxShadow: '0px 4px 16px rgba(0,0,0,0.5)',
    // overflow: 'hidden',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #232431',
      backgroundColor: '#232431',
    },
  },
}));

export default function AedTooltip({ children, title, description, ...props }) {
  return typeof title === 'string' && typeof description === 'string' ? (
    <HtmlTooltip
      {...props}
      title={
        <>
          <div className="text-center uppercase workSans-bold text-[16px] py-[8px] px-[4px]">
            {title}
          </div>
          <div className="bg-white text-black p-[16px] px-[24px] rounded-br-[14px] rounded-bl-[14px]">
            {description}
          </div>
        </>
      }
      arrow={true}
    >
      {children}
    </HtmlTooltip>
  ) : (
    children
  );
}
