export default function DashboardContentText({ children, className, ...props }) {
  return (
    <div
      className={
        'mt-[40px] text-[20px] leading-[32px] text-black workSans-medium max-w-[1280px]' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {children}
    </div>
  );
}
