import InfoIcon from '@mui/icons-material/Info';
import ArrowRightIcon from '../atoms/CustomIcons/ArrowRight';
import GroupIcon from '../atoms/CustomIcons/GroupIcon';
import OvalIconButton from './OvalIconButton';
import Tooltip from 'components/UI/molecules/AedTooltip';
import { Link } from 'react-router-dom';
import useStudentInfo from 'hooks/student/useStudentInfo';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
// import useStudentQuery from 'hooks/student/useStudentQuery';
import http from 'services/http';
import useUser from 'hooks/auth/useUser';
import stringPadding from 'utils/stringPadding';
import useEvidenceRecordGrades from 'hooks/student/useEvidenceRecordGrades';

export default function ProfilePopupBody({ onLogout }) {
  const { user } = useUser();
  const currentUser = useMemo(() => user, []);
  const { groups, studentsInGroup, loadStudentsInGroup } = useProfilePopupState();
  // const { students, onSearch } = useStudentQuery();
  const [evidenceScores, setEvidenceScores] = useState({
    overallAssessment: 0,
    evidenceSkillsScore: 0,
    evidenceCompetenciesScore: 0,
  });
  const {
    overallAssessment = 0,
    evidenceSkillsScore = 0,
    evidenceCompetenciesScore = 0,
  } = useEvidenceRecordGrades() || {};

  useEffect(() => {
    if (user.userTypeId === 3) {
      setEvidenceScores({
        overallAssessment,
        evidenceSkillsScore,
        evidenceCompetenciesScore,
      });
    }
  }, []);

  const scores = [
    {
      score: stringPadding(evidenceScores.overallAssessment, 2) + '%',
      label: (
        <>
          OVERALL
          <br />
          ASSESMENT
        </>
      ),
    },
    {
      score: stringPadding(evidenceScores.evidenceSkillsScore, 2),
      label: (
        <>
          EVIDENCED
          <br />
          SKILLS
        </>
      ),
    },
    {
      score: stringPadding(evidenceScores.evidenceCompetenciesScore, 2),
      label: (
        <>
          EVIDENCED
          <br />
          COMPETENCIES
        </>
      ),
    },
  ];

  // const activities = [
  //   {
  //     title: 'Student file Five',
  //     time: '11:15',
  //     date: '03/03/22',
  //   },
  //   {
  //     title: 'https://www.google.com/search?q=popup+mui&oq=popup+mui',
  //     time: '13:22',
  //     date: '03/03/22',
  //   },
  //   {
  //     title: 'https://www.google.com/search?q=popup+mui&oq=popup+mui',
  //     time: '12:14',
  //     date: '03/03/22',
  //   },
  //   {
  //     title: 'https://www.google.com/search?q=popup+mui&oq=popup+mui',
  //     time: '11:15',
  //     date: '03/03/22',
  //   },
  //   {
  //     title: 'Student file Five',
  //     time: '11:15',
  //     date: '03/03/22',
  //   },
  // ];

  return (
    <div>
      <div>
        <div className="text-center text-sky-500 fort text-[22px] mt-[24px] tracking-[2px]">
          {currentUser?.name || `${currentUser?.firstName} ${currentUser?.lastName}`}
        </div>
        <div className="text-[12px] fort font-extralight text-center tracking-[2px] uppercase">
          {currentUser?.academyName}
        </div>
        {user.userTypeId === 3 && (
          <>
            {currentUser && currentUser.yearName && (
              <div className="text-center text-[16px] font-bold mt-[8px] workSans-bold uppercase">
                {currentUser?.yearName}
              </div>
            )}
            <div className="bg-[#232431] flex flex-row min-h-[90px] mt-[16px] justify-evenly py-[18px]">
              {scores.map((item, index) => (
                <div className="text-center" key={index}>
                  <div className="sub-heading-white-stroke text-[42px] text-center font-bold">
                    {item.score}
                  </div>
                  <div className="text-white text-center text-[10px] workSans-bold font-bold">
                    {item.label}
                  </div>
                  <InfoIcon
                    style={{
                      fontSize: '14px',
                      fontWeight: 100,
                      color: '#aaa',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        <div className="px-[32px] py-[24px]">
          {user.userTypeId === 3 && (
            <>
              <div className="text-[12px] fort font-extralight text-left tracking-[2px] px-[8px]">
                GROUPS
              </div>
              <div className="border-t-[1px] border-b-[1px] border-[#777] mt-[6px] py-[6px]">
                <div className="max-h-[180px] overflow-visible overflow-y-auto list-scroll-w px-[6px]">
                  {groups?.map((item, index) => (
                    <Tooltip
                      key={index}
                      title={'CLASS GROUP MEMBERS'}
                      description={studentsInGroup[item.groupId]?.studentsName}
                    >
                      <div
                        onMouseEnter={() => loadStudentsInGroup(item.groupId)}
                        className="flex flex-row items-center bg-[#232431] my-[6px] rounded-[6px] box-border py-[10px] cursor-pointer drop-shadow-[0px_2px_2px_rgba(0,0,0,0.5)]"
                      >
                        <div className="w-[56px] text-[#aaa] flex items-center justify-center">
                          <GroupIcon size={26} />
                        </div>
                        <div className="flex-1 text-white workSans-bold font-bold text-[12px] tracking-[1px] leading-[16px]">
                          <div>{item.groupName}</div>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </>
          )}

          {/* <div
            className='text-[12px] fort font-extralight text-left tracking-[2px] 
                        mt-[40px] px-[8px]'
          >
            ACTIVITY
          </div>
          <div className='border-t-[1px] border-b-[1px] border-[#777] mt-[6px] py-[6px]'>
            <div
              className='max-h-[100px] overflow-y-auto list-scroll-w px-[6px] 
                            flex flex-col gap-[8px] py-[8px]'
            >
              {activities.map((item, index) => (
                <div
                  key={index}
                  className='flex flex-row gap-[24px] items-center fort text-[11px] 
                            font-extralight cursor-pointer px-[4px]'
                >
                  <div
                    className='flex-1 whitespace-nowrap text-ellipsis overflow-x-hidden 
                                  py-[4px] tracking-[2px]'
                  >
                    {item.title}
                  </div>
                  <div className='text-[10px]'>{item.time}</div>
                  <div className='text-[10px]'>{item.date}</div>
                  <div
                    className='rounded-full h-[16px] w-[16px] bg-sky-500 flex 
                                items-center justify-center text-white text-center'
                  >
                    <ArrowRightIcon size={6} />
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          <div
            className={`flex flex-row justify-center gap-[16px] mt-[32px] px-[8px] overflow-x-hidden${
              user.userTypeId === 3 ? ` mb-[32px]` : ''
            }`}
          >
            {/* {user.userTypeId !== 3 && <div style={{ flex: 1 }} />} */}
            <OvalIconButton
              onClick={onLogout}
              size="small"
              title="LOGOUT"
              className="flex-1 group hover:bg-sky-500 hover:text-white max-w-[60%]"
              textClassName="group-hover:text-white"
              textLight={true}
              style={{
                minWidth: 0,
                minHeight: 26,
                flex: 1,
              }}
              labelProps={{
                fontSize: 8,
                letterSpacing: 1.3,
              }}
              icon={<ArrowRightIcon size={16} />}
            />

            {user.userTypeId === 3 && (
              <Link
                to={
                  currentUser.usertype === 'student'
                    ? '/student/purpose/evidence-record/overview'
                    : `/${currentUser.usertype}/evidence`
                }
                className="flex-1 flex"
              >
                <OvalIconButton
                  size="small"
                  title="EVIDENCE RECORD"
                  textLight={true}
                  className="flex-1 group hover:bg-sky-500 hover:text-white"
                  textClassName="group-hover:text-white"
                  style={{
                    minWidth: 0,
                    minHeight: 26,
                    flex: 1,
                  }}
                  labelProps={{
                    fontSize: 8,
                    letterSpacing: 1.3,
                  }}
                  icon={<ArrowRightIcon size={16} />}
                />
              </Link>
            )}
            {/* {user.userTypeId !== 3 && <div style={{ flex: 1 }} />} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export const ProfilePopupContext = createContext();

export const useProfilePopupState = () => useContext(ProfilePopupContext);

export const ProfilePopupProvider = ({ children }) => {
  const [studentsInGroup, setStudentsInGroup] = useState({});
  const { user } = useUser();
  const currentUser = useMemo(() => user, []);
  const { groups } = useStudentInfo(currentUser);
  const loadStudentsInGroup = async (groupId) => {
    if (studentsInGroup[groupId]) return;

    setStudentsInGroup({ ...studentsInGroup, [groupId]: { loading: true } });

    try {
      const resp = await http.post('/api/assigned-users/search', {
        groupId,
        userTypeId: 3, // 3 is student type
      });
      let studentsName = '';
      resp?.data?.list.forEach((s) => {
        studentsName += `${s.fullName}, `;
      });
      setStudentsInGroup({ ...studentsInGroup, [groupId]: { loading: false, studentsName } });
    } catch (err) {}
  };

  return (
    <ProfilePopupContext.Provider
      value={{ studentsInGroup, setStudentsInGroup, loadStudentsInGroup, groups }}
    >
      {children}
    </ProfilePopupContext.Provider>
  );
};
