export default function ArrowDown({ size, ...props }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.838,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 12.022 6.604"
      {...props}
    >
      <path
        id="Path_795"
        data-name="Path 795"
        d="M6547.368,6293.79l5.714,5.714,5.715-5.714"
        transform="translate(-6547.072 -6293.493)"
        {...cssProps}
      />
    </svg>
  );
}
