import useUser from 'hooks/auth/useUser';
import useEvidenceRecordGrades from 'hooks/student/useEvidenceRecordGrades';
import useEvidenceSkillUpload, { useSkillViewContext } from 'hooks/student/useEvidenceSkillUpload';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'utils/logger';
import PlusIcon from '../../atoms/CustomIcons/PlusIcon';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import BellIcon from 'components/UI/atoms/CustomIcons/BellIcon';
import EvidenceRecordGradeViewModal from 'components/UI/organisms/student/EvidenceRecordGradeViewModal';
import Badge from '@mui/material/Badge';
const SkillViewCardItemStatus = {
  NORMAL: 0,
  ACTIVE: 1,
  UPLOADED: 2,
  GRADED: 3,
};

const CompetencyScale = {
  NO_CURRENT_EVIDENCE: 0,
  EVIDENCE_IN_REVIEW: 1,
  NEED_ATTENTION: 2,
  GOOD_STANDING: 3,
};

const ScaleColors = ['#fff', '#394b87', '#bc2f2c', '#00a7e1'];

/**
 * @typedef {{
 *  data: {
 *    id: string,
 *    title: string,
 *    content: string
 *    score: string
 *  }
 * }} SkillViewCardItemProps
 * @param {import("react").HTMLAttributes & SkillViewCardItemProps} param0
 * @returns
 */
export default function SkillViewCardItem({ data = {}, style, ...props }) {
  const { user } = useUser();
  const buttonLabelRef = useRef(null);
  const { NORMAL, GRADED } = SkillViewCardItemStatus;
  const { mode, id } = useParams();
  const { slot1, setSelectedItem, selectedItem } = useSkillViewContext();
  const { gradedItems } = useEvidenceRecordGrades();
  const { slot1Input, target, handleLinkUpload } = useEvidenceSkillUpload();
  const [open, setOpen] = useState(false);
  const [openGradeInfo, setOpenGradeInfo] = useState(false);
  const [state, setState] = useState({
    status: NORMAL,
    isFocused: false,
    buttonLabel: 'UPLOAD EVIDENCE',
    buttonWidth: 0,
    hasUploads: false,
    isGraded: false,
    score: null,
    scale: CompetencyScale.NO_CURRENT_EVIDENCE,
  });

  const { status, isFocused, buttonLabel, buttonWidth, hasUploads, isGraded, scale, score } = state;

  const uploadModal = (type = null) => {
    setOpen(type);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
    }
  };

  useEffect(() => {
    let _scale = 0;
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    const currnetStudentUploads = slot1.filter((item) => {
      return fsId === item.file.fsId && pcId === item.file.pcId;
    });

    if (currnetStudentUploads.length > 0) {
      _scale = CompetencyScale.EVIDENCE_IN_REVIEW;
      setState((prevState) => ({
        ...prevState,
        isGraded: true,
        scale: _scale,
      }));
    }
    const currentItem = gradedItems.find((item) => {
      logger('to compare: ', `${fsId}=${item.fsId} | ${pcId}=${item.pcId}`);
      return fsId === item.fsId && pcId === item.pcId && item.studentId === user.userId;
    });
    logger('currentItem: ', currentItem);
    if (currentItem) {
      const squareTotal = currentItem.skillScore + currentItem.competencyScore;
      if (squareTotal >= 1 && squareTotal <= 4) {
        _scale = CompetencyScale.NEED_ATTENTION;
      } else if (squareTotal >= 5) {
        _scale = CompetencyScale.GOOD_STANDING;
      }

      setState((prevState) => ({
        ...prevState,
        isGraded: true,
        score: currentItem.skillScore + '/' + currentItem.competencyScore,
        scale: _scale,
      }));
    }
  }, [gradedItems, setState]);

  /**
   *
   * @param {Event} e
   */
  const onClick = (e) => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    target.fsId = fsId;
    target.pcId = pcId;
    logger(data);
    uploadModal('slot1');
    // e.stopPropagation();
    // slot1Input.click();
  };

  const containerFocus = () => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    target.fsId = fsId;
    target.pcId = pcId;
    setSelectedItem({
      fsId,
      pcId,
    });
    setState((prevState) => ({
      ...prevState,
      isFocused: true,
    }));
    // setOpenGradeInfo(true);
  };

  const onViewGrade = () => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    target.fsId = fsId;
    target.pcId = pcId;
    setSelectedItem({
      fsId,
      pcId,
    });
    setState((prevState) => ({
      ...prevState,
      isFocused: true,
    }));
    setOpenGradeInfo(true);
  };

  const closeGradeInfoModal = () => {
    setOpenGradeInfo(false);
  };

  const containerNotFocus = () => {
    // target.fsId = null;
    // target.pcId = null;
    // setSelectedItem({
    //   fsId: null,
    //   pcId: null,
    // });
    setState((prevState) => ({
      ...prevState,
      isFocused: false,
    }));
  };

  useEffect(() => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    const currentUploads = slot1.filter((item) => {
      return fsId === item.file.fsId && pcId === item.file.pcId;
    });
    if (currentUploads.length > 0) {
      // if (buttonLabel !== 'EVIDENCE UPLOADED') {
      setState((prevState) => ({
        ...prevState,
        buttonLabel: 'EVIDENCE UPLOADED',
        hasUploads: true,
        isGraded: prevState.isGraded === false ? true : prevState.isGraded,
        scale: prevState.isGraded === false ? CompetencyScale.EVIDENCE_IN_REVIEW : prevState.scale,
      }));
    } else {
      // if (buttonLabel !== 'EVIDENCE UPLOADED') {
      setState((prevState) => ({
        ...prevState,
        buttonLabel: 'UPLOAD EVIDENCE',
        hasUploads: prevState.scale > 1,
        isGraded: prevState.scale > 1,
        scale: prevState.scale > 1 ? prevState.scale : CompetencyScale.NO_CURRENT_EVIDENCE,
      }));
      // }
    }
  }, [slot1]);

  useEffect(() => {
    if (status !== GRADED) {
      const fsId = mode === 'skill' ? Number(id) : data.id;
      const pcId = mode === 'skill' ? data.id : Number(id);
      if (selectedItem.fsId === fsId && selectedItem.pcId === pcId) {
        const currentUploads = slot1.filter((item) => {
          return fsId === item.file.fsId && pcId === item.file.pcId;
        });
        setState((prevState) => ({
          ...prevState,
          buttonLabel: currentUploads.length > 0 ? 'EVIDENCE UPLOADED' : 'UPLOAD EVIDENCE',
          hasUploads: currentUploads.length > 0,
          isFocused: true,
        }));
      }
    } else {
      // todo if graded
      setState((prevState) => ({
        ...prevState,
        isFocused: false,
      }));
    }
  }, [selectedItem]);

  const setToInReview = () => {
    setState((prevState) => ({
      ...prevState,
      scale: CompetencyScale.EVIDENCE_IN_REVIEW,
      isGraded: true,
      score: 0 + '/' + 0,
    }));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      buttonWidth: isFocused ? buttonLabelRef.current.scrollWidth : 0,
    }));
  }, [buttonLabel, setState, isFocused]);

  const onMouseEnter = () => {
    setState((prevState) => ({
      ...prevState,
      isFocused: true,
    }));
  };

  const onMouseLeave = () => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    if (selectedItem.fsId !== fsId || selectedItem.pcId !== pcId) {
      if (isFocused === true) {
        setState((prevState) => ({
          ...prevState,
          isFocused: false,
        }));
      }
    }
  };

  const hoverClass =
    'hover:opacity-100 hover:shadow-[0px_0px_0px_2px] hover:shadow-sky-500 hover:text-sky-500';
  const activeClass = 'opacity-100 shadow-[0px_0px_0px_2px] shadow-sky-500 text-sky-500';

  return (
    <>
      <div
        tabIndex={0}
        className={`relative border-[1px] group rounded-[12px] w-[240px] min-h-[180px] p-[16px] flex flex-col cursor-pointer ${
          isGraded ? 'opacity-100' : hasUploads ? 'opacity-100' : 'opacity-50'
        } ${isFocused ? activeClass : hoverClass}`}
        style={{
          ...style,
          backgroundColor: ScaleColors[scale],
          borderColor: isGraded ? ScaleColors[scale] : '#ccc',
          color: isGraded ? '#FFF' : null,
        }}
        {...props}
        onFocus={containerFocus}
        onBlur={containerNotFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className="flex-1 fort text-[13px] text-black leading-[15px] pr-[16px]"
          style={{ color: isGraded ? '#FFF' : null }}
        >
          <p>
            <span>{data?.title} </span>
            <span className="font-extralight uppercase">{data?.content}</span>
          </p>
        </div>
        <div className="flex flex-row items-center">
          <div
            className={
              'transition-all duration-200 ease-in-out flex flex-row items-center flex-1 gap-[8px] text-black' +
              (isFocused ? ' opacity-0' : ' opacity-100')
            }
            style={{ color: isGraded ? '#FFF' : null }}
          >
            <div
              className={`${
                isGraded ? 'stoke-text-white' : 'sub-heading-white-stroke2'
              } text-[32px]`}
            >
              {score || '0/0'}
            </div>
            <div className="text-[12px] leading-[14px] font-bold">
              PAIRING
              <br />
              EVIDENCE
              <br />
              SCORE
            </div>
          </div>
          <div className="relative h-[28px] min-w-[28px]">
            <button
              className="rounded-full h-[28px] flex flex-row items-center justify-center border-currentColor border-[1px] absolute right-0"
              onClick={onClick}
            >
              <div
                className="transition-all duration-200 overflow-hidden block"
                style={{ width: buttonWidth }}
              >
                <div
                  className="pl-[16px] text-[12px] fort font-extralight whitespace-nowrap block"
                  ref={buttonLabelRef}
                >
                  {buttonLabel}
                </div>
              </div>
              <div className="w-[28px] h-[28px] flex flex-row items-center justify-center">
                <PlusIcon size={12} />
              </div>
            </button>
          </div>
        </div>
        {state.scale > 1 && (
          <div className="absolute top-[6px] right-[6px]">
            <button title="View Grade" onClick={onViewGrade}>
              <Badge color="white" variant="dot" overlap="circular">
                <BellIcon size={18} />
              </Badge>
            </button>
          </div>
        )}
        <ModalUploader
          open={!!open}
          setOpen={setOpen}
          handleUpload={(...args) => {
            handleUpload(...args);
            setToInReview();
          }}
          handleLinkUploadFunc={(...args) => {
            handleLinkUpload(...args);
            setToInReview();
          }}
        />
      </div>
      {openGradeInfo && (
        <EvidenceRecordGradeViewModal open={openGradeInfo} onClose={closeGradeInfoModal} />
      )}
    </>
  );
}
