import DocumentCard from './DocumentCard';
import stringPadding from 'utils/stringPadding';
import EvidenceRecordGradingModal from 'components/UI/organisms/trainer/PendingAssessment/EvidenceRecordGradingModal';
import { useState } from 'react';
import useEvidenceToReview from 'hooks/trainer/EvidenceRecord/useEvidenceToReview';

function PendingAssessmentDocumentCard({
  data,
  onClick,
  active = false,
  className,
  fileExtension,
  downloadHandler,
}) {
  const [open, setOpen] = useState(false);
  const { setTargetEvidence, submitEvidenceGrade } = useEvidenceToReview();

  const onTargetClick = () => {
    const fsId = data?.fsId;
    const pcId = data?.pcId;
    setTargetEvidence({
      fsId,
      pcId,
    });
    setOpen(true);
  };

  const closeGradingModal = () => {
    setOpen(false);
  };

  const onGradeSubmit = (data) => {
    submitEvidenceGrade(data);
  };
  return (
    <>
      <DocumentCard
        {...{
          title:
            data.count > 1
              ? `${data.userFullName} (FS${stringPadding(data.fsId, 2)}/PC${stringPadding(
                  data.pcId,
                  2
                )})`
              : data.title,
          data,
          onClick: onTargetClick,
          active,
          className,
          fileExtension,
          downloadHandler,
        }}
        showDLButton={false}
      />
      {open && (
        <EvidenceRecordGradingModal
          defaultValue={{ ...data, ...('userUUID' in data && { studentUUID: data.userUUID }) }}
          open={open}
          onClose={closeGradingModal}
          onSubmit={onGradeSubmit}
        />
      )}
    </>
  );
}

export default PendingAssessmentDocumentCard;
