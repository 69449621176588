import React from 'react';

function ModalUploadCard({
  onClick,
  title,
  buttonTitle,
  buttonIcon,
  bgComponent,
  className = '',
  titleTextStyle,
  textColor = null,
  boxBorderWidth = 3,
}) {
  const _className = typeof className === 'string' ? ` ${className}` : '';
  return (
    <div
      onClick={onClick}
      className={
        `w-[280px] min-h-[180px] first-letter ` +
        `hover:border-[#00a7e1] transition-all relative ease-in-out duration-[.2s] ` +
        'cursor-pointer shadow-md shadow-black/20 bg-white rounded-[12px] overflow-hidden box-content' +
        _className
      }
      style={{
        borderWidth: boxBorderWidth,
      }}
    >
      {bgComponent && <div className="absolute top-0 left-0 bottom-0 right-0">{bgComponent}</div>}
      <p
        className="relative uppercase text-black fort p-5 px-7 leading-7 text-[20px] tracking-[3px] pt-[24px]"
        style={{ color: textColor, ...titleTextStyle }}
      >
        {title}
      </p>
      <div className="absolute left-7 bottom-5">
        <div
          className="group w-[36px] overflow-hidden flex items-center hover:w-48 h-[36px] transition-all relative ease-in-out duration-[.4s] rounded-full bg-transparent border-[#777] border-[1px]"
          style={{ borderColor: textColor }}
        >
          <p
            className="workSans-medium text-[9pt] tracking-[2px] absolute left-4 opacity-0 group-hover:opacity-100 transition-all duration-[0.3s] delay-100 whitespace-nowrap"
            style={{ color: textColor }}
          >
            {buttonTitle}
          </p>
          <div
            style={{ color: textColor }}
            className="absolute h-[36px] w-[36px] rounded-full bg-transparent items-center flex right-0 justify-center pointer-events-none"
          >
            {buttonIcon}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalUploadCard;
