import useSearchUser from 'hooks/admin/useSearchUser';
import UserListItem from 'components/UI/organisms/admin/UserListItem';
import { CircularProgress } from '@mui/material';
import { useRef, useEffect } from 'react';
import Input from 'components/UI/atoms/Input';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import ArrowRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import useRoles from 'hooks/admin/useRoles';
import SelectComp from 'components/UI/atoms/SelectComp';
import useYears from 'hooks/admin/useYears';
import logger from 'utils/logger';
// import ConfirmModal from '../ConfirmModal';
// let reqTimer = null;
export default function UserSearch() {
  const {
    userList,
    limit,
    page,
    setState,
    userListLoading,
    getUserList,
    firstName,
    lastName,
    pageCount,
    totalCount,
    role,
    year,
  } = useSearchUser();
  const { list: roleList } = useRoles();
  const { list: yearList } = useYears();

  const onSelectRole = (e) => {
    e.preventDefault();
    setState({
      role: Number(e.target.value),
      page: 1,
    });
    logger(role);
  };

  const onSelectYear = (e) => {
    e.preventDefault();
    setState({
      year: Number(e.target.value),
      page: 1,
    });
    logger(role);
  };

  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const firstNameDef = useDeferredValue(firstName);
  // const lastNameDef = useDeferredValue(lastName);
  const scrollRef = useRef(null);
  const onInputChange = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (Number(val) < 1) val = 1;

    if (e.target.name === 'page') {
      if (Number(val) > pageCount) val = pageCount;
    }
    logger(val);
    setState({
      [e.target.name]: val,
      ...(e.target.name === 'limit' && { page: 1 }),
    });
  };

  // useEffect(() => {
  //   if (reqTimer) clearTimeout(reqTimer);
  //   reqTimer = setTimeout(() => {
  //     onSubmit({ firstNameDef, lastNameDef });
  //   }, 400);
  // }, [firstNameDef, lastNameDef]);

  const onNextClick = () => {
    const nextVal = Number(page) + 1;
    setState({
      page: nextVal,
    });
    // scrollToTop();
  };

  const onPrevClick = () => {
    let prevVal = Number(page) - 1;
    if (prevVal < 1) prevVal = 1;
    setState({
      page: prevVal,
    });
    // scrollToTop();
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onSubmit = (params = {}) => {
    const { firstNameDef, lastNameDef } = params;
    getUserList({
      firstName: firstNameDef,
      lastName: lastNameDef,
      append: false,
    });
  };

  useEffect(() => {
    window.addEventListener('userlist.scrollToTop', scrollToTop);
    return () => {
      window.removeEventListener('userlist.scrollToTop', scrollToTop);
    };
  }, []);

  return (
    <>
      <div className="mb-[56px]">
        <div className="flex flex-row gap-[16px]">
          <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
            <Input
              containerProps={{ className: 'flex-1' }}
              placeholder="first name"
              className="flex-1 text-left rounded-lg p-2 px-[16px] shadow w-[100%]"
              value={firstName}
              onChange={({ target }) => setState({ firstName: target.value, page: 1 })}
              name="firstName"
            />
            <Input
              containerProps={{ className: 'flex-1' }}
              placeholder="last name"
              className="flex-1 text-left rounded-lg p-2 px-[16px] shadow w-[100%]"
              value={lastName}
              onChange={({ target }) => setState({ lastName: target.value, page: 1 })}
              name="lastName"
            />
          </div>
          <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
            <SelectComp className="flex-1" onChange={onSelectRole} value={role}>
              <option value={-1}>All Roles</option>
              {roleList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectComp>
            {[-1, 3].indexOf(role) > -1 && (
              <SelectComp className="flex-1" onChange={onSelectYear} value={year}>
                <option value={-1}>All Years</option>
                {yearList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </SelectComp>
            )}
          </div>
        </div>
        <div>
          <OvalIconButton title="SEARCH" icon={<ArrowRightIcon size={24} />} onClick={onSubmit} />
        </div>
      </div>
      <div className="relative w-full bg-white px-[16px] py-[24px] rounded-[16px]">
        <div
          className="max-h-[640px] list-scroll overflow-x-hidden overflow-y-auto flex flex-col gap-[8px] px-[8px] overscroll-contain"
          ref={scrollRef}
        >
          <div className="sticky top-0 bg-[#333] text-white fort text-[12px] uppercase border-[#eee] border-[1px] rounded-[4px] z-[2]">
            <div className="flex flex-row items-center">
              <div className="flex-1 p-[8px] border-r-[1px] border-[#555] overflow-x-hidden">
                FIRST NAME
              </div>
              <div className="flex-1 p-[8px] border-r-[1px] border-[#555] overflow-x-hidden">
                LAST NAME
              </div>
              <div className="flex-[1.5] p-[8px] border-r-[1px] border-[#555] overflow-x-hidden">
                EMAIL
              </div>
              <div className="flex-1 p-[8px] border-r-[1px] border-[#555] overflow-x-hidden max-w-[120px]">
                ROLE
              </div>
              <div className="flex-1 max-w-[86px] p-[8px] overflow-x-hidden">YEAR</div>
              <div className="flex-1 max-w-[86px] p-[8px] overflow-x-hidden">ACTION</div>
            </div>
          </div>
          {userList.map((item) => {
            return <UserListItem data={item} key={item.userId} />;
          })}
          {userList?.length === 0 && (
            <div className="fort text-center min-h-[56px] flex flex-row items-center justify-center">
              NO RESULTS
            </div>
          )}
          <div className="fort font-extralight sticky bottom-0 min-h-[56px] border-[1px] px-[8px] rounded-[4px] bg-white flex flex-row items-center gap-[16px] text-[12px] z-[2]">
            <div>
              ITEM COUNT: <span className="font-normal">{Number(totalCount).toLocaleString()}</span>
            </div>
            <div>
              PAGE COUNT: <span className="font-normal">{Number(pageCount).toLocaleString()}</span>
            </div>
            <div className="flex-1"></div>
            <div>LIMIT</div>
            <input
              type="number"
              placeholder="Limit"
              value={limit}
              name="limit"
              onChange={onInputChange}
              className="border-[1px] w-[100px] px-[8px] py-[4px] rounded-[4px]"
              disabled={userListLoading}
            />
            <div>PAGE</div>
            <input
              type="number"
              placeholder="Page"
              value={page}
              name="page"
              onChange={onInputChange}
              className="border-[1px] w-[100px] px-[8px] py-[4px] rounded-[4px]"
              disabled={userListLoading}
            />
            <button
              onClick={onPrevClick}
              disabled={userListLoading || page - 1 < 1}
              className="text-[24px] h-[32px] w-[32px] border-[1px] border-[#aaa] rounded-full flex items-center justify-center hover:bg-black/5 disabled:opacity-25"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 48 48">
                <path d="M28 34.2 17.8 24 28 13.75l.8.8L19.4 24l9.4 9.4Z" stroke="currentColor" />
              </svg>
            </button>
            <button
              onClick={onNextClick}
              disabled={userListLoading || page + 1 > pageCount}
              className="text-[24px] h-[32px] w-[32px] border-[1px] border-[#aaa] rounded-full flex items-center justify-center hover:bg-black/5 disabled:opacity-25"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 48 48">
                <path d="m18.8 34.2-.8-.8 9.4-9.4-9.4-9.45.8-.8L29 24Z" stroke="currentColor" />
              </svg>
            </button>
          </div>
        </div>
        {userListLoading === true && (
          <div className="logo-text absolute top-0 left-0 bottom-0 right-0 flex flex-row items-center justify-center bg-white/75 rounded-[16px]">
            <CircularProgress />
          </div>
        )}
      </div>
      {/* <ConfirmModal
        title='Deleting User'
        message='Are you sure you want to delete item?'
        buttons={[
          {
            text: 'YES',
            onClick: () => alert('YES'),
          },
          {
            text: 'NO',
          },
        ]}
      /> */}
    </>
  );
}
