import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import useDirectories from 'hooks/student/useDirectories';

function CreateExperience() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { list: folderList, getByPath } = useDirectories();
  const list = folderList.filter(
    (item) => Number(item.parentId) === Number(searchParams.get('folderId'))
  );
  const buttons = list.map((item) => {
    return {
      name: item.name,
      onClick: () => {
        navigate(`${item.path}?folderId=${item.id}`);
      },
    };
  });

  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'CREATE EXPERIENCE',
      path: '/engagement/create-experience?folderId=' + getByPath('create-experience')?.id,
    },
  ];

  return (
    <div>
      <MainHeadingText>
        create <br /> experience
      </MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {/* TODO copy is incorrect */}
      <DashboardContentText>
        Click here to upload materials to “Develop Your Story”
      </DashboardContentText>
      <DashboardContentHeading>YOUR PROJECTS</DashboardContentHeading>
      <LandscapeCardListContainer>
        {buttons.map((item, index) => {
          return (
            <div key={index} onClick={item.onClick}>
              <LandscapeCard
                title={item.name || 'N/A'}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default CreateExperience;
