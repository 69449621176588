export default function PaperThinIcon({ size, active = false }) {
  const cssProps = {
    fill: 'none',
    stroke: active ? '#00A7E1' : '#232432',
    // stroke: '#58585a',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size || 32} viewBox="0 0 130 172">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Container_Text" data-name="Container+Text">
          <polygon {...cssProps} points="128.51 28.56 101.13 28.56 101.13 1.21 128.51 28.56" />
          <path
            {...cssProps}
            d="M129.2,29v130.1a12.53,12.53,0,0,1-12.53,12.53H13A12.53,12.53,0,0,1,.5,159.14V13A12.53,12.53,0,0,1,13,.5h87.62Z"
          />
        </g>
      </g>
    </svg>
  );
}
