import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import OutlineInput from 'components/UI/atoms/OutlineInput';
import { useState, useEffect } from 'react';
import OutlineSelect from 'components/UI/atoms/OutlineSelect';
import useAcademies from 'hooks/admin/useAcademies';
import useRoles from 'hooks/admin/useRoles';
import useYears from 'hooks/admin/useYears';
import DropdownSearch from 'components/UI/molecules/DropdownSearch';
import useTrainerList from 'hooks/organisms/useTrainerList';
import useUserGroup from 'hooks/admin/useUserGroup';
import { CircularProgress } from '@mui/material';
import logger from 'utils/logger';

/**
 *
 * @typedef {{
 *    userId: number,
 *    firstName: string,
 *    lastName: string,
 *    email: string,
 *    userTypeId: number,
 *    academyId: number,
 *    academyName: string,
 *    active: boolean,
 *    createdAt: string,
 *    updatedAt: string,
 *    enrolled: boolean,
 *    name: string,
 *    uuid: string,
 *    yearId: number,
 *    yearName: string,
 *    yearUUID: string,
 *  }} User
 * @typedef {(user: User & {teacherId: number}) => void} UserFormSubmitHandler
 *
 * @param {import("react").HTMLAttributes & {
 *  title: string,
 *  data: User,
 *  onClose: () => void
 *  onSubmit: UserFormSubmitHandler
 * }} param0
 * @returns
 */
export default function UserEditModal({
  data = {},
  title,
  message,
  buttons,
  onClose,
  onSubmit,
  ...props
}) {
  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setLastName] = useState(data.lastName);
  const [email, setEmail] = useState(data.email);
  const [academy, setAcademy] = useState(data.academyId || -1);
  const [role, setRole] = useState(data.userTypeId || -1);
  const [year, setYear] = useState(data.yearId || -1);
  const [selectedTrainer, setSelectedTrainer] = useState({ title: 'Select Trainer', value: -1 });

  const { list: academies } = useAcademies();
  const { list: roles } = useRoles();
  const { list: years } = useYears();
  const { userGroup, isLoading } = useUserGroup({ userId: data.userId });

  const {
    list: trainerList,
    loadMoreTrainers,
    isLoading: trainerListLoading,
    onSearchTrainer,
    pageCount,
    page,
  } = useTrainerList();

  /**
   * @param {Event} e
   */
  const onAcademyChangeHandler = (e) => {
    e.preventDefault();
    setAcademy(Number(e.target.value));
  };

  /**
   * @param {Event} e
   */
  const onRoleChangeHandler = (e) => {
    e.preventDefault();
    setRole(Number(e.target.value));
  };

  /**
   * @param {Event} e
   */
  const onYearChangeHandler = (e) => {
    e.preventDefault();
    setYear(Number(e.target.value));
  };

  const onTrainerChanged = (response) => {
    logger('response: ', response);
    setSelectedTrainer(response);
  };

  useEffect(() => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setAcademy(data.academyId);
    setRole(data.userTypeId);
    setYear(data.yearId);
  }, [data]);

  useEffect(() => {
    if (userGroup) {
      setSelectedTrainer({
        title: `${userGroup.teacherFirstName} ${userGroup.teacherLastName}`,
        value: userGroup.teacherId,
      });
    }
  }, [userGroup]);

  const onSubmitHandler = () => {
    const payload = {
      // ...data,
      ...{
        firstName,
        lastName,
        email,
        academyId: academy,
        userTypeId: role,
        ...(role === 3 &&
          selectedTrainer.value &&
          selectedTrainer.value !== -1 && { teacherId: selectedTrainer.value }),
        ...(role === 3 && year !== -1 && { yearId: year }),
      },
    };
    // todo: validation here...
    const validateColumns = ['firstName', 'lastName', 'email', 'academyId', 'userTypeId'];
    role === 3 && validateColumns.push('yearId');
    role === 3 && validateColumns.push('teacherId');

    const warningLabels = {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      academyId: 'Academy',
      userTypeId: 'Role',
      yearId: 'Year',
      teacherId: 'Trainer',
    };

    for (const key of validateColumns) {
      const currentColumn = payload[key];
      if (
        currentColumn === '' ||
        currentColumn === -1 ||
        currentColumn === null ||
        typeof currentColumn === 'undefined'
      ) {
        alert(`${warningLabels[key]} is required`);
        document.querySelector(`.user-edit-modal [name="${key}"]`).focus();
        return;
      }
    }

    // todo: submit
    typeof onSubmit === 'function' && onSubmit(payload);
  };

  return ReactDOM.createPortal(
    <div
      {...props}
      className="user-edit-modal fort font-extralight text-white uppercase fixed top-0 left-0 bottom-0 right-0 z-[100] flex flex-row p-[16px] overflow-y-auto"
    >
      <div
        className="fixed top-0 left-0 bottom-0 right-0 z-100 bg-black/75 sb2-txt-fade"
        onClick={onClose}
      ></div>
      <div
        className="relative min-h-[200px] w-full max-w-[540px] 
                      m-auto rounded-[16px] border-[2px] border-white flex 
                      flex-col sb2-txt-fade shadow modal-bg"
      >
        <div className="min-h-[56px] flex flex-row items-center gap-[16px] px-[16px]">
          <div className="flex-1 font-normal text-[18px]">{title}</div>
          <button
            className="h-[24px] w-[24px] bg-white text-[#aaa] rounded-full 
                      flex items-center justify-center"
            onClick={onClose}
          >
            <CloseIcon style={{ fontSize: '1em' }} />
          </button>
        </div>
        <div className="flex-1 items-center p-[16px] text-white text-left">
          {/* <h2>User Info</h2>
          <br /> */}
          <div className="flex flex-col gap-[16px]">
            <OutlineInput
              inputProps={{
                placeholder: 'FIRST NAME',
                value: firstName,
                onChange: (e) => setFirstName(e.target.value),
                name: 'firstName',
              }}
            />
            <OutlineInput
              inputProps={{
                placeholder: 'LAST NAME',
                value: lastName,
                name: 'lastName',
                onChange: (e) => setLastName(e.target.value),
              }}
            />
            <OutlineInput
              inputProps={{
                placeholder: 'EMAIL',
                value: email,
                name: 'email',
                onChange: (e) => setEmail(e.target.value),
              }}
            />
            <OutlineSelect
              inputProps={{
                className: 'uppercase',
                value: academy || -1,
                name: 'academyId',
                onChange: onAcademyChangeHandler,
              }}
            >
              <option value={-1}>Select Academy</option>
              {academies.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </OutlineSelect>
            <div className="flex flex-row gap-[16px]">
              <OutlineSelect
                inputProps={{
                  className: 'uppercase',
                  value: role || -1,
                  name: 'userTypeId',
                  onChange: onRoleChangeHandler,
                }}
              >
                <option value={-1}>Select Role</option>
                {roles.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </OutlineSelect>
              {role === 3 ? (
                <OutlineSelect
                  inputProps={{
                    className: 'uppercase',
                    value: year || -1,
                    name: 'yearId',
                    onChange: onYearChangeHandler,
                  }}
                >
                  <option value={-1}>Select Year</option>
                  {years.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </OutlineSelect>
              ) : (
                <div className="w-full border-[2px] border-transparent" />
              )}
            </div>

            {/* <div
              style={{
                borderTop: 'dashed 2px white',
              }}
            ></div> */}

            {role === 3 && (
              <div className="mt-[32px]">
                <div className="mb-[8px]">ASSIGN A TRAINER</div>
                <div className="relative min-h-[42px]">
                  {isLoading ? (
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                      <CircularProgress color="white" />
                    </div>
                  ) : (
                    <DropdownSearch
                      style={{
                        opacity: isLoading ? 0.3 : 1,
                      }}
                      variant="outline"
                      defaultValue={
                        userGroup
                          ? {
                              title: `${userGroup?.teacherFirstName} ${userGroup?.teacherLastName}`,
                              value: userGroup?.teacherId,
                            }
                          : selectedTrainer
                      }
                      loading={trainerListLoading}
                      onLoadMoreClicked={loadMoreTrainers}
                      onSearchChange={onSearchTrainer}
                      options={
                        trainerList?.length > 0 &&
                        trainerList?.map((item) => ({
                          title: `${item?.firstName} ${item?.lastName}`,
                          value: item.userId,
                          subtitle: item?.email,
                        }))
                      }
                      onChange={onTrainerChanged}
                      loadmoreVisible={page + 1 < pageCount}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-[16px] px-[16px] mb-[16px]">
          <button
            onClick={onSubmitHandler}
            className="uppercase flex-1 min-h-[40px] border-white border-[2px] flex flex-row items-center 
                          justify-center px-[16px] text-white rounded-[24px] min-w-[80px] fort hover:bg-white/25"
          >
            SUBMIT
          </button>
          <button
            onClick={onClose}
            className="uppercase flex-1 min-h-[40px] border-white border-[2px] flex flex-row items-center 
                          justify-center px-[16px] text-white rounded-[24px] min-w-[80px] fort hover:bg-white/25"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}
