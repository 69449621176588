import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import StrokeText from 'components/UI/atoms/StrokeText';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import useDirectories from 'hooks/student/useDirectories';
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useCreateExperience from 'hooks/student/useCreateExperience';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import useUser from 'hooks/auth/useUser';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function CreateExperienceChild() {
  const { userType } = useUser();
  const [searchParams] = useSearchParams();
  const {
    slot1,
    slot1Input,
    removeSlot1,
    slot2,
    slot2Input,
    removeSlot2,
    slot3,
    slot3Input,
    removeSlot3,
    targetSlot,
    handleLinkUpload,
  } = useCreateExperience();
  const { histories } = useFileUploadPanel();
  const { list: folderList, getByPath } = useDirectories();
  const currentFolder =
    folderList.find((item) => item.id === Number(searchParams.get('folderId'))) || {};
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { list: assignmentDocuments } = useAssignmentDocuments();

  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'CREATE EXPERIENCE',
      path: '/engagement/create-experience?folderId=' + getByPath('create-experience')?.id,
    },
    {
      text: currentFolder.name?.toUpperCase(),
      path: `/engagement/know-your-profile/${currentFolder.path}?folderId=${currentFolder.id}`,
    },
  ];

  // dummy data
  // const assignmentDocuments = [];
  // for (let i = 1; i <= 5; i += 1) {
  //   assignmentDocuments.push({
  //     title: `Assignment Document ${i}`,
  //     onClick: () => {
  //       alert(`Document ${i} Name`);
  //     },
  //   });
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
      case 'slot2':
        slot2Input.click();
        break;
      case 'slot3':
        slot3Input.click();
        break;
    }
  };

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
      case 'slot2':
        targetSlot.current = 2;
        break;
      case 'slot3':
        targetSlot.current = 3;
        break;
    }
  };
  const dispSubRouteArrDesc = (c) => {
    let desc = [];
    switch (c) {
      case 'health-and-medicine':
        desc = [
          'Spending for the NHS in England is around £130 billion per year (not including private spending and the secondary produce that is bought and sold every day).  Health and Medicine is, therefore, one of the most valuable sectors in the country.  Every single one of us is a customer or client during our lifetime. We were clients before we were born. There is no other sector that can claim this level of importance in our lives. ',
          'The sector is not merely about helping people when they get ill, and the employment is not limited to the NHS. From global crises that require strategic responses and macro-planning to producing the best possible plaster for a papercut. Every individual element of the sector is open for constant improvement. ',
          'The projects in this section will be set in multiple facets of the industry, and will range from strategy to products. Their success is all about making sure that the short-term and long-term health of everyone is paramount and the efficiency of the system is as important as the quality of the care. ',
        ];
        break;
      case 'engineering':
        desc = [
          'Since humans first put one rock on top of another, we have been obsessed with the process of creating and shaping our environment.  Engineering is the process of creating the world that surrounds us down to the smallest of objects with which we interact every day.',
          'We often think of Engineering as being buildings, but in this project you will be able to explore the processes of chemical, electrical, material and structural engineering. Each of these strands is open to you to explore, and the industry-led projects will look at design as well as processes involved.',
          'This project is not just about the what, but about the why and the how of engineering. ',
        ];
        break;
      case 'education-and-training':
        desc = [
          'Education is unique because it has a personal effect on every single person. This project is not, however, just about teaching. This is a multi-million industry including both compulsory and non-compulsory education. From early years home learning support such as toys, to workplace and lifelong learning this sector directly shapes lives.',
          'Alongside this direct influence, the industry contains a vast array of companies who appraise and analyse the successes and failures of education, trying to constantly assess how, and why, people learn. The metrics for data and the handling of it is just as important as how that data is generated in learning environments. ',
          'In this project, you will be set projects that aim to embrace the cultural and digital revolutions going on within the education spectrum. They may include methods of instruction or teaching, platforms for accessing learning, or perhaps analytics and methods of measuring and understanding successes or shortcomings in the education spectrum. ',
        ];
        break;
      case 'the-digital-world':
        desc = [
          'Every year, thousands of new tech jobs are created. A growing sector, the Digital World is always seeking new talent.',
          'This sector is growing so fast that many employers simply can’t find enough applicants with the right skills for roles. In a Tech Futures survey, 50% of tech companies in the UK reported challenges in finding future employees with skills right for their business.',
          'It is crucial to be a creative thinker, who enjoys solving problems and finding opportunities. Innovation thrives in this sector, with thousands of businesses in financial services, the creative industries, life sciences and the public sector depending on tech professionals to deliver their services it is easy to understand why this is such an area of growth.',
        ];
        break;
      case 'independent-choice':
        desc = [
          'Developing high standards is a key aspect to gain a competitive advantage and set a professional reputation in a 21st Century collaborative working world. The Choice Project task is an opportunity to choose a topic of interest relating to a potential future career destination and to create a high quality mini-dissertation and presentation explaining the topic.',
        ];
        break;
      case 'future-project':
        desc = [''];
        break;
      default:
        desc = [];
        break;
    }
    return desc;
  };

  return (
    <div>
      <MainHeadingText>PROJECT: {currentFolder.name}</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => (
              <>
                YOUR PROJECT
                <br />
                PRESENTATION
              </>
            )}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={removeSlot1}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => (
              <>
                YOUR PROJECT
                <br />
                PROGRESS SNAPSHOT
              </>
            )}
            activeFile={slot2}
            histories={histories}
            onUpload={() => uploadModal('slot2')}
            onRemoveHistory={removeSlot2}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => (
              <>
                YOUR PROJECT
                <br />
                UPLOADS
              </>
            )}
            activeFile={slot3}
            histories={histories}
            onUpload={() => uploadModal('slot3')}
            onRemoveHistory={removeSlot3}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      {dispSubRouteArrDesc(currentFolder.path).map((x) => {
        return <DashboardContentText key={x.id}>{x}</DashboardContentText>;
      })}
      <div style={{ height: 36 }} />
      {userType === 'student' ? (
        <>
          <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
          {assignmentDocuments.length === 0 ? (
            <div className="py-[56px]">
              <StrokeText>
                No Assignment <br />
                Documents
              </StrokeText>
            </div>
          ) : (
            <DocumentCardContainer>
              {assignmentDocuments.map((item, index) => (
                <DocumentCard data={item} key={index} onClick={item.onClick} />
              ))}
            </DocumentCardContainer>
          )}
          <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
            <BottomLandscapeCard
              title="VISIT THE EVIDENCE RECORD"
              buttonTitle="START"
              onClick={() => {
                navigate('/student/purpose/evidence-record');
              }}
            />
          </div>
        </>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default CreateExperienceChild;
