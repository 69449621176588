import { useState, useEffect } from 'react';
import http from 'services/http';

export default function useStudentInfo(student = {}, open = false) {
  const [groups, setGroups] = useState([]);
  const loadGroups = async () => {
    const { data } = await http.post('/api/assigned-users/search', {
      userId: student?.userId,
    });
    const { list } = data;
    const mapped = list
      .filter((i) => i.groupId !== null)
      .map((i) => {
        const groupKeys = Object.keys(i).filter((x) => x.includes('group'));
        const key = { agUUID: i.uuid, id: i.id };
        for (let k = 0; k < groupKeys.length; k += 1) {
          const keyString = groupKeys[k];
          key[keyString] = i[keyString];
        }
        return key;
      });
    setGroups(mapped);
  };

  useEffect(() => {
    if (!open) loadGroups();
  }, [student, open]);

  return {
    groups,
  };
}
