import { useSelector, useDispatch } from 'react-redux';
import { setEvidenceSkillList } from 'redux/reducers/student/evidenceSkillsState';
import http from 'services/http';

export default function useEvidenceSkillsState() {
  // skill list
  /**
   * @type {import('../../redux/reducers/student/evidenceSkillsState').EvidenceSkillList}
   */
  const evidenceSkills = useSelector((state) => state.evidenceSkills);
  const list = evidenceSkills.list;
  const dispatch = useDispatch();

  const getList = async (params) => {
    setEvidenceSkillList({ ...evidenceSkills, isLoading: true });
    const result = await http.post('/api/evidence-skills/search', params);
    dispatch(setEvidenceSkillList({ ...result.data, isLoading: false }));
    return result;
  };

  const setList = (data) => {
    dispatch(setEvidenceSkillList(data));
  };

  return {
    getList,
    list,
    setList,
    evidenceSkillsState: evidenceSkills,
    isLoading: evidenceSkills.isLoading,
  };
}
