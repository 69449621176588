import React, { useState, useEffect, useCallback } from 'react';

function CurrentTime() {
  const [date, setDate] = useState(new Date());

  const runClock = () => setDate(new Date());

  useEffect(() => {
    const timer = setInterval(runClock, 15000); // Changes every 15 secs
    return function cleanup() {
      clearInterval(timer); // Remove duplicates if mounted again.
    };
  }, []);

  const currentTime = useCallback(() => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }, [date]);

  return <>{currentTime()}</>;
}

export default CurrentTime;
