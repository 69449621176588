export default function SelectComp({ children, className, style, containerProps, ...props }) {
  return (
    <div
      className={
        'relative flex flex-row items-start cursor-pointer overflow-x-visible ' + className
      }
      {...containerProps}
    >
      <select
        className={
          'truncate flex-1 max-w-[100%] outline-none cursor-pointer pl-[12px] pr-[32px] py-[8px] rounded-[8px] fort font-extralight uppercase shadow h-[40px]'
        }
        style={{
          ...style,
          appearance: 'none',
        }}
        {...props}
      >
        {children}
      </select>
      <div className="text-[24px] absolute right-[6px] pointer-events-none flex items-center h-[40px]">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 48 48">
          <path d="M24 29 13.8 18.8l.8-.8 9.4 9.4 9.4-9.4.8.8Z" stroke="currentColor" />
        </svg>
      </div>
    </div>
  );
}
