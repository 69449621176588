import { useState } from 'react';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import ArrowRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import Input from 'components/UI/atoms/Input';
import useYears from 'hooks/admin/useYears';
import SelectComp from 'components/UI/atoms/SelectComp';
import useAcademies from 'hooks/admin/useAcademies';
import useRoles from 'hooks/admin/useRoles';
import http from 'services/http';
import DropdownSearch from 'components/UI/molecules/DropdownSearch';
import useTrainerList from 'hooks/organisms/useTrainerList';
import logger from 'utils/logger';
// import { randomBase64 } from 'utils/randomString';

export default function NewUserForm() {
  const [role, setRole] = useState(3);
  const [year, setYear] = useState(1);
  const [academy, setAcademy] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedTrainer, setSelectedTrainer] = useState({ title: 'Select Trainer', value: -1 });

  const { list: optionList } = useYears();
  const { list: academyList } = useAcademies();
  const { list: roleList } = useRoles();
  const {
    list: trainerList,
    loadMoreTrainers,
    isLoading: trainerListLoading,
    onSearchTrainer,
    pageCount,
    page,
  } = useTrainerList();

  const onSelectRole = (e) => {
    e.preventDefault();
    setRole(Number(e.target.value));
    logger(role);
  };

  const onSelectYear = (e) => {
    e.preventDefault();
    setYear(Number(e.target.value));
    logger(role);
  };

  const onSelectAcademy = (e) => {
    e.preventDefault();
    setAcademy(Number(e.target.value));
    logger(role);
  };

  const onTrainerChanged = (response) => {
    logger('response: ', response);
    setSelectedTrainer(response);
  };

  const reset = () => {
    setRole(3);
    setYear(1);
    setAcademy(1);
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const onSubmit = async () => {
    const payload = {
      firstName,
      lastName,
      email,
      userTypeId: role,
      academyId: academy,
      password: '12345678',
      ...(role === 3 && { yearId: year }),
      ...(role === 3 && { teacherId: selectedTrainer.value > 0 ? selectedTrainer.value : null }),
    };
    const keys = Object.keys(payload);
    for (const key of keys) {
      if (!payload[key] || payload[key] === '') {
        alert(key + ' is required.');
        const target = document.querySelector(`[name="${key}"]`);
        if (target) target.focus();
        return;
      }
    }

    try {
      await http.post('/api/users', payload);
      reset();
      window.dispatchEvent(new Event('assigned-roles.list.reload'));
      window.dispatchEvent(
        new CustomEvent('notify', {
          detail: {
            title: null,
            message: 'User Successfully Added',
            status: 'success',
          },
        })
      );
    } catch (err) {
      logger(err);
      alert(err?.response?.data || err.message);
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-row gap-[16px]">
          <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
            <Input
              containerProps={{ className: 'flex-1' }}
              placeholder="first name"
              className="flex-1 text-left rounded-lg p-2 px-[16px] shadow w-[100%]"
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
              name="firstName"
            />
            <Input
              containerProps={{ className: 'flex-1' }}
              placeholder="last name"
              className="flex-1 text-left rounded-lg p-2 px-[16px] shadow w-[100%]"
              value={lastName}
              onChange={({ target }) => setLastName(target.value)}
              name="lastName"
            />
          </div>
          <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
            <Input
              containerProps={{ className: 'flex-1' }}
              placeholder="email"
              className="flex-1 text-left rounded-lg p-2 px-[16px] shadow w-[100%]"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              name="email"
            />
            <div className="flex flex-row gap-[16px]">
              <SelectComp className="flex-1" onChange={onSelectRole} value={role}>
                {roleList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </SelectComp>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[16px]">
          <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
            <SelectComp className="flex-1" value={academy} onChange={onSelectAcademy}>
              {academyList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectComp>
          </div>

          {role === 3 && (
            <>
              <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
                <SelectComp className="flex-1" value={year} onChange={onSelectYear}>
                  {optionList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </SelectComp>
              </div>
            </>
          )}
        </div>
        {role === 3 && (
          <div className="flex flex-row gap-[16px]">
            <div className="flex-1 flex flex-col gap-[16px] max-w-[400px] mb-[16px] overflow-x-visible">
              <DropdownSearch
                defaultValue={selectedTrainer}
                loading={trainerListLoading}
                onLoadMoreClicked={loadMoreTrainers}
                onSearchChange={onSearchTrainer}
                options={
                  trainerList?.length > 0 &&
                  trainerList?.map((item) => ({
                    title: `${item?.firstName} ${item?.lastName}`,
                    value: item.userId,
                    subtitle: item?.email,
                  }))
                }
                onChange={onTrainerChanged}
                loadmoreVisible={page + 1 < pageCount}
              />
            </div>
          </div>
        )}
        <div>
          <OvalIconButton title="ADD" icon={<ArrowRightIcon size={24} />} onClick={onSubmit} />
        </div>
      </div>
    </>
  );
}
