import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import SubHeadingText from 'components/UI/atoms/SubHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import HighlightCard from 'components/UI/molecules/HighlightCard';
import EvidenceHighlightsItem from 'components/UI/molecules/EvidenceHighlightsItem';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import useEvidenceToReview, {
  EvidenceToReviewProvider,
} from 'hooks/trainer/EvidenceRecord/useEvidenceToReview';

import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Trainer(props) {
  return (
    <EvidenceToReviewProvider>
      <_Trainer {...props} />
    </EvidenceToReviewProvider>
  );
}

function _Trainer({ cardsData }) {
  const navigate = useNavigate();
  const { user } = useSelector(({ auth }) => auth);
  const currentUser = user?.value ? JSON?.parse(user?.value) : user;
  const { fileList } = useEvidenceToReview();
  // TODO: receive data from server

  const data = [
    // {
    //   title: (
    //     <>
    //       student past <br /> due assignments
    //     </>
    //   ),
    //   number: '04',
    // },
    {
      title: (
        <>
          student assignments <br /> pending review
        </>
      ),
      number: fileList.length,
      onClick: () => navigate('/trainer/assessments'),
    },
  ];

  const trainerPages = [
    {
      title: 'Evidence Record',
      slug: 'evidence-record',
      onClick: () => navigate('/trainer/evidence'),
      id: '1',
    },
    {
      title: 'TRAINING MATERIALS',
      slug: 'training-materials',
      onClick: () => navigate('/trainer/materials'),
      id: '2',
    },
    {
      title: 'pending assignments',
      slug: 'pending-assignments',
      onClick: () => navigate('/trainer/assessments'),
      id: '3',
    },
    {
      title: 'assigned groups',
      slug: 'assigned-groups',
      onClick: () => navigate('/trainer/groups'),
      id: '4',
    },
    {
      title: 'student search',
      slug: 'student-search',
      onClick: () => navigate('/trainer/search'),
      id: '5',
    },
    {
      title: 'resources academy',
      slug: 'resources-academy',
      onClick: () => navigate('/trainer/resources'),
      id: '6',
    },
  ];
  return (
    <div>
      <MainHeadingText>
        <div className="font-extralight uppercase">Welcome</div>
        <div style={{ lineHeight: '85%', margin: '-5px auto' }}>
          {currentUser?.name || `Trainer`}
        </div>
      </MainHeadingText>
      <div className="my-[32px]">
        <SubHeadingText>
          {currentUser?.academyName || 'Livingstone Academy Bournemouth'}
        </SubHeadingText>
      </div>
      <MainHeadingSeparator />
      <DashboardContentHeading>CURRENT NOTIFICATIONS</DashboardContentHeading>
      <HighlightCard>
        {data.map((item, index) => (
          <EvidenceHighlightsItem
            title={item.title}
            equivalent={item.equivalent}
            description={item.description}
            text={item.number}
            onClick={item.onClick}
            key={index}
          />
        ))}
      </HighlightCard>
      <DashboardContentText>
        <strong>Welcome to the Aspirations Employability Diploma Online Dossier.</strong>
      </DashboardContentText>
      <DashboardContentText>
        Here you will be able to navigate the online dossier, assign, review and organize as well as
        view detail about evidence records and notifications.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {trainerPages.map((item, index) => {
          const { title, onClick, ...otherProps } = item;
          return (
            <div onClick={() => onClick()} key={index}>
              <LandscapeCard
                title={title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
                {...otherProps}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
      <Outlet />
    </div>
  );
}

export default Trainer;
