import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from 'services/http';

export const doUpload = createAsyncThunk('fileUpload/upload', async (data) => {
  const response = await (await http.post(`/api/project-files`, data)).data;

  return {
    response,
  };
});

const initialState = {
  isLoading: false,
  errState: false,
  errMessage: 'null',
  toast: {
    status: false,
    message: '',
  },
};

const uploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {},
  extraReducers: {
    [doUpload.pending]: (state, action) => {
      state.isLoading = true;
      state.errMessage = 'null';
    },
    [doUpload.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errState = false;
      state.errMessage = 'null';
    },
    [doUpload.rejected]: (state, action) => {
      state.errState = true;
      state.errMessage = action.error.message;
      state.isLoading = false;
    },
  },
});

export const { uploadFile, logout } = uploadSlice.actions;

export default uploadSlice.reducer;
