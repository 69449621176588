import { useState, useRef } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleOutlineIconButton from '../atoms/CircleOutlineIconButton';

function DocumentCardContainer({ children, className, disableSticky = false, ...props }) {
  const [isOverflowing] = useState(false);
  const scrollDistance = 180;
  const overflowContainer = useRef();

  const handleLeftButtonClick = () => {
    //
    /**
     * @type {HTMLDivElement}
     */
    const con = overflowContainer.current;
    con.scrollTo({
      left: con.scrollLeft - scrollDistance,
      behavior: 'smooth',
    });
  };
  const handleRightButtonClick = () => {
    //
    /**
     * @type {HTMLDivElement}
     */
    const con = overflowContainer.current;
    con.scrollTo({
      left: con.scrollLeft + scrollDistance,
      behavior: 'smooth',
    });
  };

  const _isOverflowing = (Array.isArray(children) && children.length > 1) || isOverflowing;

  return children.length > 1 ? (
    <div
      className={
        'flex items-center h-[198px] flex-row gap-[8px] max-w-[100%] overflow-x-hidden' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
    >
      {_isOverflowing && (
        <div>
          <CircleOutlineIconButton
            onClick={handleLeftButtonClick}
            icon={<ArrowBackIosNewIcon style={{ fontSize: 16 }} />}
            className="pr-[2px]"
          />
        </div>
      )}
      <div
        className="relative flex items-center min-w-[172px] gap-[40px] max-w-[100%] h-[100%] overflow-y-hidden overflow-x-auto scrollbar-hidden"
        ref={overflowContainer}
      >
        {!disableSticky && (
          <div className="sticky left-0 top-0 bottom-0 h-[100%] w-[24px] min-w-[24px] bg-black mr-[-32px] z-10 doc-card-fader-l"></div>
        )}
        {children}
        {!disableSticky && (
          <div className="sticky right-0 top-0 bottom-0 h-[100%] w-[24px] min-w-[24px] bg-black  ml-[-32px] z-10 doc-card-fader-r"></div>
        )}
      </div>
      {_isOverflowing && (
        <div>
          <CircleOutlineIconButton
            onClick={handleRightButtonClick}
            icon={<ArrowForwardIosIcon style={{ fontSize: 16 }} />}
            className="pl-[2px]"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="pl-[8px] pt-[2px]">{children}</div>
  );
}

export default DocumentCardContainer;
