export default function PaperIcon({ size, active = false }) {
  const cssProps = {
    stroke: active ? '#00A7E1' : '#232432',
    fill: 'none',
    strokeMiterlimit: '10',
    strokeWidth: 1,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 18.985 24.688"
    >
      <g id="Group_582" data-name="Group 582" transform="translate(-2983.828 -4089.175)">
        <path
          id="Path_236"
          data-name="Path 236"
          d="M3049.933,4094.214H3046.1v-3.832Z"
          transform="translate(-48.326 0)"
          {...cssProps}
        />
        <g id="Group_581" data-name="Group 581" transform="translate(2984.328 4090.382)">
          <path
            id="Path_237"
            data-name="Path 237"
            d="M3001.606,4094.214v17.466a1.683,1.683,0,0,1-1.683,1.683h-13.913a1.684,1.684,0,0,1-1.683-1.683v-19.615a1.683,1.683,0,0,1,1.683-1.683h11.764"
            transform="translate(-2984.328 -4090.382)"
            {...cssProps}
          />
        </g>
      </g>
    </svg>
  );
}
