import InfoIcon from '@mui/icons-material/Info';

const evidenceScaleData = [
  {
    name: (
      <>
        GOOD <br /> STANDING [2/3]
      </>
    ),
    color: '#00a7e1',
  },
  {
    name: (
      <>
        NEEDS <br /> ATTENTION [1/0]
      </>
    ),
    color: '#bc2f2c',
  },
  {
    name: (
      <>
        EVIDENCE <br /> IN REVIEW
      </>
    ),
    color: '#394b87',
  },
  {
    name: (
      <>
        NO CURRENT <br /> EVIDENCE
      </>
    ),
    color: '#fff',
  },
];

/**
 * @param {import("react").HTMLAttributes} param0
 * @returns
 */
export default function CompetenceScale({ data }) {
  return (
    <div className="flex flex-row items-center text-black">
      <div className="text-[10px] flex flex-row items-center gap-[8px] font-bold">
        COMPETENCE SCALE <InfoIcon style={{ fontSize: 16, color: '#aaa' }} />
      </div>
      <div className="flex flex-row fort ml-[16px]">
        {evidenceScaleData.map((item, index) => (
          <div
            key={index}
            className="flex flex-row items-center text-right pl-[16px] pr-[16px] border-r-[1px] border-black/20 text-[10px] last:border-0 max-w-[180px]"
          >
            <div>{item.name}</div>
            <div
              className="w-[32px] h-[32px] min-w-[32px] ml-[8px]"
              style={{ backgroundColor: item.color }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
