import useUser from 'hooks/auth/useUser';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../atoms/CustomIcons/ArrowLeft';
import ArrowRightIcon from '../atoms/CustomIcons/ArrowRight';

/**
 * @type {import('react').CSSProperties}
 */
// const usePathname = () => {
//   const location = useLocation();
//   return location.pathname;
// };
const mainStyle = {
  backgroundColor: 'transparent',
  color: '#777',
  fontSize: '38px',
  border: '1px solid #777',
  borderRadius: '50%',
  padding: '7px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 36,
  width: 36,
};

/**
 * @typedef {Object} BreadcrumbParams
 * @prop {string | React.Component} textHeader
 */

/**
 *
 * @param {import('react').HTMLAttributes & BreadcrumbParams} param0
 * @returns
 */
export default function Breadcrumb({
  textHeader = 'Header',
  items = null,
  prefix = false,
  ...props
}) {
  // Idk how to get forward option if there's something to forward.
  // At least i got it from here https://developer.mozilla.org/en-US/docs/Web/API/History
  const { length, state } = window.history;
  const navigate = useNavigate();
  const { userType } = useUser();
  // usePathname();
  const pathPrefix = userType === 'student' ? '/student' : `/${userType}/materials`;
  return (
    <div className="flex mt-16 gap-[24px] flex-row items-center pb-[24px]" {...props}>
      <button
        onClick={() => navigate(-1)}
        style={{
          ...mainStyle,
        }}
      >
        <ArrowLeftIcon size={16} />
      </button>
      <button
        onClick={() => navigate(1)}
        style={{
          ...mainStyle,
          opacity: state?.idx + 1 === length ? 0.35 : 1,
        }}
      >
        <ArrowRightIcon size={16} />
      </button>
      <div className="ml-[0px]">
        <div className="workSans-extraBold text-[20px] tracking-[4px] uppercase text-[#0492cd]">
          {!items && textHeader}
          {items &&
            items.map(({ text, path }, index) => (
              <NavLink
                to={!prefix ? path : pathPrefix + path}
                key={index}
                className="relative bc-item pl-[16px] pr-[16px] pt-[0px] hover:text-[#24617a]"
              >
                {text}
              </NavLink>
            ))}
        </div>
      </div>
    </div>
  );
}
