import logo from '../../assets/images/common/logo.svg';
import bgImage from '../../assets/images/common/login-bg.jpg';
import { Typography } from '@mui/material';
import TransparentButton from 'components/UI/atoms/TransparentButton';
import { useNavigate } from 'react-router-dom';
import Header from 'components/UI/organisms/Header';

export default function LandingPage() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/login');
  };
  return (
    <>
      <div
        className="fixed top-0 left-0 bottom-0 right-0 opacity-30"
        style={{
          backgroundImage: `url('${bgImage}')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      ></div>
      <div
        className={`fixed top-0 left-0 bottom-0 right-0 bg-gradient-to-t from-sky-500/80 to-sky-900/80`}
      ></div>
      <div>
        <div className={`relative px-[20%] pb-10 flex  min-h-screen justify-center items-center`}>
          <div className="absolute w-full top-0">
            <Header />
          </div>

          <div className="flex items-center  flex-col mt-12">
            <img src={logo} alt="logo" />
            <Typography
              variant="p"
              className="text-transparent fort text-[70px] 
                        tracking-widest pt-16 pb-14  text-center leading-[90px]
                        text-white/30 stroke-text-white"
            >
              welcome to the aspirations employability diploma portal
            </Typography>
            <div className="mb-[20px] mt-[30px]">
              <TransparentButton onClick={onClick} />
            </div>

            <p className="fort tracking-[3px] text-[20px] text-white uppercase">
              let's get started
            </p>
            <p className="text-black mt-[15px] uppercase text-[12px] font-extralight tracking-[2px]">
              head to &nbsp;
              <a href="/aspirationsacademies.org" className="underline" target={'_blank'}>
                aspirationsacademies.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
