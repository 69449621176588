import React, { useEffect } from 'react';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import { useSearchParams, useNavigate } from 'react-router-dom';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import useDirectories from 'hooks/student/useDirectories';

function Engagement() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { list: folderList, getByPath } = useDirectories();
  const list = folderList.filter(
    (item) => Number(item.parentId) === Number(searchParams.get('folderId'))
  );
  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
  ];

  const buttons = list.map((item) => {
    return {
      id: item.id,
      uuid: item.uuid,
      title: item.name,
      onClick: () => {
        navigate(`${item.path}?folderId=${encodeURIComponent(item.id)}`);
      },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <MainHeadingText>engagement</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText>
        Engagement means we are emotionally, intellectually, and behaviourally engaged in our
        learning and work. When we are emotionally engaged, we experience Fun & Excitement by
        becoming so involved that we lose track of time. At the end of a lesson or task, we wonder,
        “Where did that time go?” When we are intellectually engaged, we ask “Why?” or “Why not?”
        about the world around us. Curiosity & Creativity are alive in how we learn, fostering
        inquisitiveness and a desire for new discoveries. When we are behaviourally engaged, we have
        a Spirit of Adventure. We are not afraid to try new things, or to take on healthy
        challenges, whether we might succeed or fail. With Engagement, learning becomes important in
        and of itself.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {buttons.map((item, index) => {
          return (
            <div key={index} onClick={item.onClick}>
              <LandscapeCard
                title={item.title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default Engagement;
