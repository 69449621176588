import { createTheme } from '@mui/material';

const Theme = createTheme({
  palette: {
    primary: {
      // main: '#1976d2',
      main: '#14A1DB',
    },
    success: {
      main: '#4caf50',
    },
    white: {
      main: '#fff',
    },
    background: {
      paper: '-webkit-linear-gradient(to bottom, #222222 40%, #666666 100%)',
      default: '-webkit-linear-gradient(to bottom, #222222 40%, #666666 100%)',
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.2rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

export default Theme;
