import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {import('../../../utils/createFileInput').UploadFile} UploadFile
 */

/**
 * @typedef {Object} FinishedCVFinalSnapshotState
 * @prop {UploadFile} yourCV
 * @prop {UploadFile} yourPresentationSkills
 */

/**
 * @type {FinishedCVFinalSnapshotState}
 */
const initialState = {
  yourCV: null,
  yourPresentationSkills: null,
};

const finishedCVFinalSnapshotSlice = createSlice({
  name: 'finishedCVFinalSnapshotState',
  initialState,
  reducers: {
    setYourCV: (state, action) => {
      state.yourCV = action.payload;
      // return state;
    },
    setCVPresentationSkills: (state, action) => {
      state.yourPresentationSkills = action.payload;
      // return state;
    },
  },
});

export const { setYourCV, setCVPresentationSkills } = finishedCVFinalSnapshotSlice.actions;

export default finishedCVFinalSnapshotSlice.reducer;
