import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newFile } from 'redux/reducers/uploadedFile';
// import { sampleStudents } from 'constants/sampleData';
import http from 'services/http';
export default function useMaterialQuery() {
  const dispatch = useDispatch();
  const { file } = useSelector(({ uploadedFile }) => uploadedFile);
  const [materials, setMaterials] = useState([]);
  const [optionState, setOptions] = useState({});
  const [searchFile, setFileName] = useState('');
  const [loadingMaterials, setLoading] = useState(false);
  const checkMaterial = (material) =>
    setMaterials(
      materials.map((s) => ({ ...s, active: material.id !== s.id ? s.active : !material.active }))
    );

  const unCheckMaterials = () => {
    setMaterials(
      materials.map((m) => {
        if ('active' in m) {
          delete m.active;
        }
        return m;
      })
    );
  };

  const loadMaterials = async (selectedTags = {}, searchFile = null, featured = false) => {
    setLoading(true);
    try {
      const query = {};
      const keys = Object.keys(selectedTags);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (selectedTags[key].length > 0) {
          // get active tags here
          query[key] = [...selectedTags[key]];
        }
      }
      if (searchFile) {
        query.fileName = searchFile;
      }
      if (featured) query.featured = featured;

      const { data } = await http.post('/api/materials/search', { ...query });
      const { list, ...options } = data;
      setMaterials(list);
      setOptions(options);
    } catch (e) {}
    setLoading(false);
  };

  const onSearch = useCallback(loadMaterials, []);
  const selected = useMemo(() => materials.filter((i) => i.active), [materials]);

  useEffect(() => {
    if (file) {
      setMaterials([{ ...file, active: true }, ...materials]);
      dispatch(newFile(null));
    }
  }, [file]);

  useEffect(() => {
    loadMaterials();
  }, []);

  return {
    searchFile,
    setFileName,
    materials,
    selected,
    setMaterials,
    optionState, // Probably not usable at the moment
    checkMaterial,
    unCheckMaterials,
    onSearch,
    loadingMaterials,
  };
}
