// import ArrowRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import SearchStudent from 'components/UI/organisms/SearchStudent';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
// import useStudentSearch from 'hooks/trainer/EvidenceRecord/useStudentSearch';
// import { NavLink } from 'react-router-dom';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import useUser from 'hooks/auth/useUser';

export default function StudentPageSearchView() {
  const { user } = useUser();
  // const { list } = useStudentSearch();
  const breadcrumbsItems = [
    {
      text: 'TRAINER DASHBOARD',
      path: '/trainer',
    },
    {
      text: 'Evidence Record',
      path: '/trainer/evidence',
    },
  ];
  return (
    <div>
      <MainHeadingText>
        Evidence <br /> Record
      </MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentHeading>STUDENT SEARCH</DashboardContentHeading>
      <DashboardContentText className="mb-8">
        {user.userTypeId === 1 && (
          <strong>
            This is the full Admin version of the Student Evidence Record in grid format.
          </strong>
        )}
        {user.userTypeId === 2 && (
          <strong>
            Here you can search for an individual student and see a full, grid format version of
            student evidence records. You can review items submitted by students and provide a grade
            and comments on their work.
          </strong>
        )}
        {user.userTypeId >= 3 && <strong></strong>}
      </DashboardContentText>
      <DashboardContentText className="mb-8">
        {user.userTypeId === 1 && (
          <>
            In the student version of the Dossier, students navigate this by selecting a skill to
            cross-reference with a competency. Students then submit work for those two sections.
            Students can also view an overview of their progress with a visual colour coded chart of
            evidence which is graded and that which is incomplete. Trainers can then review items
            submitted by students and can provide a grade and comments on work.
          </>
        )}
        {user.userTypeId === 2 && (
          <>
            Students navigate this through their version of the dossier by cross-referencing a
            competency, they then submit work for those two selections. Through the student access
            dossier, students can also view the overview of their progress with a visual colour
            coded chart of graded and incomplete submissions.
          </>
        )}
        {user.userTypeId >= 3 && <></>}
      </DashboardContentText>
      <SearchStudent allowSelectStudent={true} />
    </div>
  );
}

// <div>
//   <SearchStudent />
//   <div>Student Search Page In Progress</div>
//   <div>Use the student below to test Evidence Record Grading:</div>
//   <div className='max-w-[400px] mt-[32px]'>
//     {list.map((item) => (
//       <NavLink to={item.uuid} key={item.uuid}>
//         <div className='pl-[32px] pr-[16px] py-[16px] bg-white text-black
// hover:bg-[#eee] flex flex-row items-center border-b-[#eee] border-b-[1px]'>
//           <div className='flex-1'>{`${item.firstName} ${item.lastName}`}</div>
//           <div>
//             <ArrowRightIcon size={16} />
//           </div>
//         </div>
//       </NavLink>
//     ))}
//   </div>
// </div>
