import React, { useEffect } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EvidenceHighlightsItem from 'components/UI/molecules/EvidenceHighlightsItem';
import EvidenceScoreItem from 'components/UI/molecules/EvidenceScoreItem';
// import EvidenceScale from 'components/UI/molecules/EvidenceScale';
// import { evidenceHighlightsItems } from './dummyData';
import InfoIcon from '@mui/icons-material/Info';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import StrokeText from 'components/UI/atoms/StrokeText';
import HighlightCard from 'components/UI/molecules/HighlightCard';
import EvidenceOverviewView from './EvidenceRecordParts/EvidenceOverviewView';
import ovalIconButtonStyles from 'styles/UI/molecules/ovalIconButton.module.css';
import EvidenceRecordView from './EvidenceRecordParts/EvidenceRecordView';
import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { SkillViewProvider } from 'hooks/student/useEvidenceSkillUpload';
import useEvidenceRecordGrades from 'hooks/student/useEvidenceRecordGrades';
import stringPadding from 'utils/stringPadding';
import useDirectories from 'hooks/student/useDirectories';
import Tooltip from 'components/UI/molecules/AedTooltip';

function EvidenceRecord() {
  const loc = useLocation();
  const { overallAssessment, evidenceSkillsScore, evidenceCompetenciesScore } =
    useEvidenceRecordGrades();
  const { getByPath } = useDirectories();
  const breadcrumbsItems = [
    {
      text: 'PURPOSE',
      path: '/purpose?folderId=' + getByPath('purpose')?.id,
    },
    {
      text: 'Evidence Record',
      path: '/purpose/evidence-record?folderId=' + getByPath('evidence-record')?.id,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SkillViewProvider>
      <div>
        <MainHeadingText>
          Evidence <br /> Record
        </MainHeadingText>
        <Breadcrumb prefix={true} items={breadcrumbsItems} />
        <MainHeadingSeparator />
        <DashboardContentHeading className="mt-[32px]">
          YOUR EVIDENCE HIGHLIGHTS
        </DashboardContentHeading>
        <HighlightCard>
          <EvidenceHighlightsItem
            title={
              <>
                OVERALL <br /> ASSESSMENT
              </>
            }
            equivalent={`OVERALL ASSESSMENT`}
            description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
            text={stringPadding(overallAssessment, 2) + '%'}
            onClick={() => {}}
            // key={index}
          />
          <EvidenceHighlightsItem
            title={
              <>
                EVIDENCE <br /> Skills
              </>
            }
            equivalent={`EVIDENCE SKILLS`}
            description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
            text={stringPadding(evidenceSkillsScore, 2)}
            onClick={() => {}}
            // key={index}
          />
          <EvidenceHighlightsItem
            title={
              <>
                EVIDENCE <br /> COMPETENCIES
              </>
            }
            equivalent={`EVIDENCE COMPETENCIES`}
            description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
            text={stringPadding(evidenceCompetenciesScore, 2)}
            onClick={() => {}}
            // key={index}
          />
        </HighlightCard>
        <div className="flex flex-row items-center mt-[72px]">
          <OvalIconButton
            title="STUDENT DASHBOARD"
            icon={
              <ChevronRightIcon
                style={{
                  fontSize: '44px',
                  fontWeight: 100,
                }}
              />
            }
          />
        </div>
        <div className="pl-[16px] pr-[16px]">
          <DashboardContentText>View assessment information here</DashboardContentText>
          <div style={{ height: 72 }} />
          <DashboardContentHeading>STUDENT EVIDENCE:</DashboardContentHeading>
          <StrokeText style={{ fontSize: 40 }}>
            FUTURE SKILLS / <br />
            PROFESSIONAL COMPETENCIES
          </StrokeText>
          <div className="flex flex-row min-w-[800px] mt-[56px] place-items-start e-f-collapse">
            <HighlightCard>
              <EvidenceScoreItem
                title={
                  <>
                    OVERALL <br /> ASSESSMENT
                  </>
                }
                equivalent={`OVERALL ASSESSMENT`}
                description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
                text={stringPadding(overallAssessment, 2) + '%'}
              />

              <EvidenceScoreItem
                title={
                  <>
                    EVIDENCE <br />
                    SKILLS
                  </>
                }
                equivalent={`EVIDENCE SKILLS`}
                description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
                text={stringPadding(evidenceSkillsScore, 2)}
              />

              <EvidenceScoreItem
                title={
                  <>
                    EVIDENCE <br />
                    COMPETENCIES
                  </>
                }
                equivalent={`EVIDENCE COMPETENCIES`}
                description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
                text={stringPadding(evidenceCompetenciesScore, 2)}
              />
            </HighlightCard>
            <div className="flex-1 min-w-[16px]"></div>
            <HighlightCard className={'py-[16px] px-[24px] gap-[32px] items-center'}>
              <div className="fort text-[14px]">
                OVERALL <br />
                ASSESSMENT{' '}
                <Tooltip
                  title={`OVERALL ASSESSMENT`}
                  description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.`}
                >
                  <InfoIcon style={{ fontSize: 16, color: '#aaa' }} />
                </Tooltip>
              </div>
              <StrokeText style={{ fontSize: 46 }}>
                {stringPadding(overallAssessment, 2) + '%'}
              </StrokeText>
            </HighlightCard>
            {/* <EvidenceScoreItem
            title={
              <>
                OVERALL <br />
                ASSESSMENT
              </>
            }
            style={{
              textAlign: 'right',
              paddingRight: 0,
              marginRight: 0,
            }}
            text='00%'
          /> */}
          </div>
        </div>
        <div className="border-t-[.5px] w-full border-white/20"></div>
        <div className="flex flex-row items-center mt-[16px] mb-[16px] p-[16px] e-f-collapse">
          <div className="flex flex-row items-center gap-[16px]">
            <NavLink to={`record${loc.search}`}>
              {({ isActive }) => (
                <OvalIconButton
                  className={isActive ? ovalIconButtonStyles.selected : null}
                  title="EVIDENCE RECORD"
                  icon={
                    <ChevronRightIcon
                      style={{
                        fontSize: '44px',
                        fontWeight: 100,
                      }}
                    />
                  }
                />
              )}
            </NavLink>

            <NavLink to={`overview${loc.search}`}>
              {({ isActive }) => (
                <OvalIconButton
                  className={isActive ? ovalIconButtonStyles.selected : null}
                  title="EVIDENCE OVERVIEW"
                  icon={
                    <ChevronRightIcon
                      style={{
                        fontSize: '44px',
                        fontWeight: 100,
                      }}
                    />
                  }
                />
              )}
            </NavLink>
          </div>
          <div className="flex-1"></div>
          {/* <EvidenceScale /> */}
        </div>
        <MainHeadingSeparator />
        <Routes>
          <Route path="record/*" element={<EvidenceRecordView />} />
          <Route path="overview" element={<EvidenceOverviewView />} />
          <Route path="*" element={<Navigate to={`record${loc.search}`} replace />} />
        </Routes>
        {/* {evidenceViewIndex === 0 ? <EvidenceRecordView /> : <EvidenceOverviewView />} */}
      </div>
    </SkillViewProvider>
  );
}

export default EvidenceRecord;
