import React, { useEffect } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import useDirectories from 'hooks/student/useDirectories';

function Purpose() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { list: folderList, getByPath } = useDirectories();
  const list = folderList.filter(
    (item) => Number(item.parentId) === Number(searchParams.get('folderId'))
  );
  const breadcrumbsItems = [
    {
      text: 'Purpose',
      path: '/purpose?folderId=' + getByPath('purpose')?.id,
    },
  ];
  const buttons = list.map((item) => ({
    id: item.id,
    uuid: item.uuid,
    title: item.name,
    onClick: () => navigate(`${item.path}?folderId=${encodeURIComponent(item.id)}`),
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainHeadingText>Purpose</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText>
        Challenge yourself to think about your Purpose—who do you want to become as well as what do
        you want to be. To reach this goal, we need to explore what it means to have, and create, a
        successful and rewarding life and to pursue our work with intention and commitment. Purpose
        is about being responsible, accountable, and confident. To develop Purpose, look for and
        take opportunities to assume Leadership & Responsibility. Look for opportunities to make
        decisions and understand the consequences of our choices. When we have Purpose, we have the
        Confidence to Take Action toward a meaningful, productive, and rewarding future. We believe
        in ourselves and are motivated to reach our dreams.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {buttons.map((item, index) => {
          return (
            <div key={index} onClick={item.onClick} data-uuid={item.uuid}>
              <LandscapeCard
                title={item.title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default Purpose;
