import useEvidenceCompetencyState from 'hooks/student/useEvidenceCompetencyState';
import useEvidenceSkillsState from 'hooks/student/useEvidenceSkillsState';
import { createContext, useContext, useEffect, useState } from 'react';
import http from 'services/http';
// import stringPadding from 'utils/stringPadding';

export const EvidenceToReviewContext = createContext();
export function EvidenceToReviewProvider({ children }) {
  const [list, setList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [target, setTarget] = useState({
    fsId: null,
    pcId: null,
  });
  const { list: skillList, getList: getEvidenceSkills } = useEvidenceSkillsState();
  const { list: competencyList, getList: getEvidenceCompetency } = useEvidenceCompetencyState();
  useEffect(() => {
    getPendingAssessments();
    getPendingAssessmentsByFileGroup();
    getEvidenceSkills();
    getEvidenceCompetency();
  }, [setList]);

  const getPendingAssessments = () => {
    http
      .post('/api/pending-assessment')
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getPendingAssessmentsByFileGroup = () => {
    http
      .post('/api/pending-assessment/files')
      .then((response) => {
        setFileList(response.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const setTargetEvidence = (params) => {
    setTarget(params);
  };

  const getUploads = async ({ fsId, pcId, studentId }) => {
    try {
      const response = await http.post('/api/evidence-record-upload/search', {
        fsId,
        pcId,
        userId: studentId,
      });

      return response.data;
    } catch (err) {
      alert(err.message);
      return [];
    }
  };

  const submitEvidenceGrade = async (params) => {
    try {
      const response = await http.post('/api/evidence-record-grades', params);
      getPendingAssessments();
      getPendingAssessmentsByFileGroup();
      window.dispatchEvent(new Event('grade.submitted'));
      return response.data;
    } catch (err) {
      alert(err.message);
      return [];
    }
  };

  return (
    <EvidenceToReviewContext.Provider
      value={{
        list,
        fileList,
        skillList,
        competencyList,
        setTargetEvidence,
        submitEvidenceGrade,
        getUploads,
        getPendingAssessmentsByFileGroup,
        ...target,
      }}
    >
      {children}
    </EvidenceToReviewContext.Provider>
  );
}

export default function useEvidenceToReview() {
  return useContext(EvidenceToReviewContext);
}
