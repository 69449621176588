import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import StrokeText from 'components/UI/atoms/StrokeText';
import useCVDevelopmentState from 'hooks/student/useCVDevelopmentState';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import useDirectories from 'hooks/student/useDirectories';
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import useUser from 'hooks/auth/useUser';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function KnowYourProfileChild() {
  const [searchParams] = useSearchParams();
  const { userType } = useUser();
  const { slot1, slot1Input, removeSlot1, targetSlot, handleLinkUpload } = useCVDevelopmentState();
  const { histories } = useFileUploadPanel();
  const { list: folderList, getByPath } = useDirectories();
  const currentFolder =
    folderList.find((item) => item.id === Number(searchParams.get('folderId'))) || {};
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'KNOW YOUR PROFILE',
      path: '/engagement/know-your-profile?folderId=' + getByPath('know-your-profile')?.id,
    },
    {
      text: currentFolder.name?.toUpperCase(),
      path: `/engagement/know-your-profile/${currentFolder.path}?folderId=${currentFolder.id}`,
    },
  ];

  const { list: assignmentDocuments } = useAssignmentDocuments();
  // dummy data
  // const assignmentDocuments = [];
  // for (let i = 1; i <= 5; i += 1) {
  //   assignmentDocuments.push({
  //     title: `Assignment Document ${i}`,
  //     onClick: () => {
  //       alert(`Document ${i} Name`);
  //     },
  //   });
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onUploadFileRemoveClick = (id) => {
    removeSlot1(id);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
    }
  };

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
    }
  };
  const dispSubRouteDesc = (c) => {
    let desc;
    switch (c) {
      case 'cv-development':
        desc =
          'A well-crafted CV tailored to specific roles is critical to ensuring that future employers appreciate your unique skills and experiences in addition to your core knowledge. ';
        break;
      case 'your-values':
        desc =
          'In this section you will consider your own beliefs and values before considering what you value in a future employer.';
        break;
      case 'describe-yourself':
        desc =
          'Who are you? Considering this simple question from an employer’s perspective will ready you for being able to clearly explain the unique value you will bring to an organization and its future success.';
        break;
      case 'employer-values':
        desc =
          'A fulfilling work life is arguably one where you share similar values to those of your employer, this is a good way to have a real sense of purpose.';
        break;
      case 'my-natural-talents':
        desc =
          'Developing and growing your talents will help you identify the most fulfilling and rewarding work for you. Here you will use tools such as the Gardner model to Review Analyse and Reflect on your potential.';
        break;
      case 'skill-map':
        desc =
          'Here you will chart your actual (where you are now) skills and attitudes before planning how to develop your aspirational skills and attributes. ';
        break;
      case 'attitudes-skills-balanced-scorecard':
        desc =
          'Having developed your current CV and skills, here you will consider how to further enhance your CV by planning opportunities with employers which will evidence the Aspirational Skills and Attitudes you have identified.';
        break;
      case 'my-working-world':
        desc =
          'What does the working world of today look like, and what will it look like when you become part of it?  Here you will evidence your skills in order to position yourself well in it and achieve a strong competitive profile.';
        break;
      case 'professionalism-module':
        desc =
          'Here you will evidence your understanding of how professional expectations have altered over time and are different in different settings. ';
        break;
      case 'communications-module':
        desc =
          'Communication is arguably the number one power skill everyone needs. Here you will Review, Analyse and Reflect on effective communication and also enjoy the challenge of evidencing your skills in a variety of real employment settings.';
        break;
      case 'virtual-working-module':
        desc =
          'The world of employment has become increasingly blended between face-to-face and virtual working. Here you will develop expertise in both formats. Your first meeting with a prospective employer is likely now to be virtual, evidence here that you are ready to excel.';
        break;
      case 'professional-skills-and-attributes':
        desc =
          'Evidence your understanding of key attributes and professional skills that make a successful professional.';
        break;
      case '21st-century-skills':
        desc =
          ' Evidence here that you understand how the world is changing in global terms and consider how best you can ready yourself for success.';
        break;
      case 'diploma-viva-criteria':
        desc =
          'The diploma award challenges you to evidence your professional development. This is a measure of professional awareness and is given to students who have understood what ‘professional behaviour, performance and delivery’ mean. It is awarded in three levels: Gold, Silver and Bronze. You will have the opportunity to present your work to established professionals and to discuss lessons learned including your professional skills gained.';
        break;
      default:
        desc = '';
        break;
    }
    return desc;
  };
  return (
    <div>
      <MainHeadingText>{currentFolder.name}</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => <>YOUR ASSIGNMENT UPLOADS</>}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={onUploadFileRemoveClick}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      <DashboardContentText>{dispSubRouteDesc(currentFolder.path)}</DashboardContentText>
      <div style={{ height: 36 }} />
      {userType === 'student' ? (
        <>
          <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
          {assignmentDocuments.length === 0 ? (
            <div className="py-[56px]">
              <StrokeText>
                No Assignment <br />
                Documents
              </StrokeText>
            </div>
          ) : (
            <DocumentCardContainer>
              {assignmentDocuments.map((item, index) => (
                <DocumentCard data={item} key={index} />
              ))}
            </DocumentCardContainer>
          )}
          <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
            <BottomLandscapeCard
              title="VISIT THE EVIDENCE RECORD"
              buttonTitle="START"
              onClick={() => {
                navigate('/student/purpose/evidence-record');
              }}
            />
          </div>
        </>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default KnowYourProfileChild;
