import localstorage from 'services/localstorage';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/reducers/auth';
import { useEffect, useState } from 'react';
const SESSION_TIMEOUT = 1000 * 60 * 30; // Thirty minutes
const PROMPT_TIME = 1000 * 60 * 5; // Five minutes
let IS_WARNING_PROMPT_SHOWED = false;

function useSessionTimeout() {
  const [showSessionWarningModal, setShowSessionWarningModal] = useState(false);
  const [adminSessionTimeout, setAdminSessionTimeout] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminSessionTimeout) return () => {};
    let sessionTimeout = localstorage.get('timeout');
    let intervalId;
    if (!sessionTimeout.success || !sessionTimeout?.value) {
      const timeout = +new Date() + SESSION_TIMEOUT;
      intervalId = setInterval(() => {
        if (timeout - PROMPT_TIME - new Date() < 0 && !IS_WARNING_PROMPT_SHOWED) {
          setShowSessionWarningModal(true);
          IS_WARNING_PROMPT_SHOWED = true;
        }
        if (timeout - new Date() < 0) {
          dispatch(logout());
          navigate('/');
        }
      }, 1000);
      localStorage.timeout = JSON.stringify({ timeout });
    } else {
      sessionTimeout = JSON.parse(sessionTimeout.value);
      if (sessionTimeout.timeout - new Date() < 0) {
        dispatch(logout());
        navigate('/');
      }
      intervalId = setInterval(() => {
        if (sessionTimeout.timeout - PROMPT_TIME - new Date() < 0 && !IS_WARNING_PROMPT_SHOWED) {
          setShowSessionWarningModal(true);
          IS_WARNING_PROMPT_SHOWED = true;
        }
        if (sessionTimeout.timeout - new Date() < 0) {
          dispatch(logout());
          navigate('/');
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [adminSessionTimeout]);

  const setSessionTimeout = () => {
    IS_WARNING_PROMPT_SHOWED = false;
    setAdminSessionTimeout(true);
  };

  return {
    setSessionTimeout,
    showSessionWarningModal,
    setShowSessionWarningModal,
  };
}

export default useSessionTimeout;
