import React from 'react';
import ExpandCircleDownSharpIcon from '@mui/icons-material/ExpandCircleDownSharp';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
function ArrowButton({ size, filled, bgColor, borderColor, borderWidth, direction, onClick }) {
  return (
    <div>
      {filled ? (
        <ExpandCircleDownSharpIcon
          onClick={onClick}
          sx={{
            color: bgColor || 'white',
            cursor: 'pointer',
            width: size,
            transform: direction === 'left' ? 'rotate(90deg)' : 'rotate(270deg)',
          }}
          fontSize="large"
        />
      ) : (
        <ExpandCircleDownOutlinedIcon
          onClick={onClick}
          sx={{
            color: bgColor || 'white',
            cursor: 'pointer',
            width: size || '30px',
            height: size || '30px',
            transform: direction === 'left' ? 'rotate(90deg)' : 'rotate(270deg)',
          }}
          fontSize="large"
        />
      )}
    </div>
  );
}

export default ArrowButton;
