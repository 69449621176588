import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const sidebarStatusSlice = createSlice({
  name: 'sidebarStatus',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setOpen } = sidebarStatusSlice.actions;

export default sidebarStatusSlice.reducer;
