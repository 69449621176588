import React from 'react';
import style from '../../../styles/UI/atoms/input.module.css';
function Input({
  type = 'text',
  value,
  className,
  onChange,
  placeholder,
  containerProps,
  errStatus,
  errMessage,
  name,
  onKeyDown,
  inputProps = {},
}) {
  return (
    <div {...containerProps}>
      <input
        className={`
        font-bold 
        focus:outline-none 
        bg-white 
        fort
        font-extralight
        autofill:font-bold 
        text-black 
        placeholder:text-[#777] ${style.input} autofill-color ${
          className || 'w-full px-3 py-2 h-10 text-center text-sm'
        }`}
        type={type}
        placeholder={placeholder.toUpperCase()}
        onChange={onChange}
        value={value}
        name={name}
        onKeyDown={onKeyDown}
        {...inputProps}
      />
    </div>
  );
}

export default Input;
