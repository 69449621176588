import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {import('../../../utils/createFileInput').UploadFile} UploadFile
 */

/**
 * @typedef {Object} EngageProfessionallyState
 * @prop {UploadFile} cvDevelopment
 * @prop {UploadFile} cvPresentation
 * @prop {UploadFile} interviewPractice
 */

/**
 * @type {EngageProfessionallyState}
 */
const initialState = {
  cvDevelopment: null,
  cvPresentation: null,
  interviewPractice: null,
};

const engageProfessionallySlice = createSlice({
  name: 'engageProfessionallyState',
  initialState,
  reducers: {
    setCVDevelopment: (state, action) => {
      state.cvDevelopment = action.payload;
      // return state;
    },
    setCVPresentation: (state, action) => {
      state.cvPresentation = action.payload;
      // return state;
    },
    setInterviewPractice: (state, action) => {
      state.interviewPractice = action.payload;
      // return state;
    },
  },
});

export const { setCVDevelopment, setCVPresentation, setInterviewPractice } =
  engageProfessionallySlice.actions;

export default engageProfessionallySlice.reducer;
