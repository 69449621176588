export default function DashboardContentHeading({ children, className, ...props }) {
  return (
    <div
      className={
        'workSans-bold mt-[24px] text-[20px] mb-[16px] text-black' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {children}
    </div>
  );
}
