import React, { useState } from 'react';
import ArrowButton from '../atoms/ArrowButton';
import Input from '../atoms/Input';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
function ResetPasswordForm({
  onPasswordChange,
  onConfirmPasswordChange,
  errStatus,
  errMessage,
  values,
  onSubmit,
  loading,
}) {
  const [showPass, setShowPass] = useState(false);
  return (
    <div className="w-11/12 relative flex flex-col items-center">
      <div className=" w-3/4">
        <div className="my-1 ">
          <Input
            placeholder={'Password'}
            errStatus={errStatus === 'password'}
            errMessage={errMessage}
            value={values.password}
            onChange={onPasswordChange}
            type={'password'}
          />
        </div>
      </div>
      <div className=" w-3/4 relative ">
        <div className="my-1  ">
          <Input
            placeholder={'Confirm Password'}
            value={values.confirmPassword}
            errStatus={errStatus === 'confirmPassword'}
            errMessage={errMessage}
            onChange={onConfirmPasswordChange}
            type={'password'}
          />
          <p
            className={`${
              !errStatus ? 'opacity-0' : 'opacity-100'
            } absolute right-1 text-sm text-red-500 ease-in-out duration-200`}
          >
            {errMessage}
          </p>
        </div>
        <div className="mt-2">
          <div className="flex items-center">
            <div
              onClick={() => setShowPass(!showPass)}
              className="w-5 mr-2 flex items-center cursor-pointer justify-center h-5 bg-transparent"
            >
              {showPass && (
                <CloseOutlinedIcon
                  style={{
                    color: 'black',
                    width: '18px',
                    height: '18px',
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={` absolute right-1 ${
              errStatus === 'password' || errStatus === 'email' ? 'top-14' : 'top-12'
            } ease-in-out duration-200`}
          ></div>
        </div>
        <div className="mt-2 flex justify-center">
          {loading ? (
            <div className="loader"></div>
          ) : (
            <ArrowButton onClick={onSubmit} filled={true} direction="right" />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
