import { Typography } from '@mui/material';
import React from 'react';
import useLogin from 'hooks/auth/useLogin';
import Toast from '../atoms/Toast';
import LoginForm from '../molecules/LoginForm';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import TransparentButton from '../atoms/TransparentButton';

function LoginComponent() {
  const {
    values,
    onEmailChange,
    onPasswordChange,
    onForgotPassword,
    onSubmit,
    error,
    loading,
    toast,
    setToast,
    onEnrollUser,
  } = useLogin();
  return (
    <div>
      <Toast
        onClose={() =>
          setToast({
            status: false,
            message: '',
          })
        }
        toast={toast}
      />
      <div
        className="pb-5 w-200 flex flex-col items-center pt-5 px-9  shadow 
      bg-transparent border-2 border-white rounded-[16px] bg-white/20"
      >
        <AccountCircleOutlinedIcon
          style={{
            color: 'white',
          }}
        />
        <Typography variant="p" className="text-white text-[20px] font-bold pb-1 pt-3  uppercase ">
          Enter your information
        </Typography>
        <LoginForm
          onEmailChange={onEmailChange}
          onPasswordChange={onPasswordChange}
          onForgotPassword={onForgotPassword}
          onEnrollUser={onEnrollUser}
          errMessage={error.errMessage}
          values={values}
          onSubmit={onSubmit}
          errStatus={error.errStatus}
          loading={loading}
        />
      </div>
      {/* Commenting out because this functionality is not necessary for now */}
      {/* <div className='flex flex-col mt-10 items-center justify-center'>
        <div>
          <TransparentButton onClick={onClick} style={{ transform: 'rotateZ(180deg)' }} />
        </div>
        <Typography variant='p' className='text-lg font-extrabold uppercase pt-3 text-white pr-1 '>
          Start over
        </Typography>
      </div> */}
    </div>
  );
}

export default LoginComponent;
