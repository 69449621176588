import styles from 'styles/UI/molecules/ovalIconButton.module.css';
//
/**
 * @typedef {import("react").CSSProperties} CSSProperties
 * @typedef {import("react").ButtonHTMLAttributes} ButtonHTMLAttributes
 * @param { ButtonHTMLAttributes & {
 *  labelProps: CSSProperties,
 *  textClassName: string
 * }} param0
 * @returns
 */
function OvalIconButton({
  title,
  icon,
  className,
  textLight = false,
  size = 'normal',
  labelProps,
  textClassName,
  ...props
}) {
  const sizes = {
    large: 40,
    normal: 34,
    small: 26,
  };

  const sizeClasses = {
    small: styles.small,
    normal: styles.normal,
    large: styles.large,
  };

  const iconPadding = {
    large: 24,
    normal: 16,
    small: 8,
  };

  const fontSizes = {
    large: 16,
    normal: 12,
    small: 10,
  };

  const ovalMinHeight = sizes[size] || 34;
  return (
    <button
      className={
        `${sizeClasses[size] || ''} text-left shrink select-none cursor-pointer overflow-hidden ` +
        'border-[1px] border-black/50 text-black hover:text-sky-500 hover:border-sky-500 ' +
        `flex items-center min-w-[180px] min-h-[${ovalMinHeight}px] transition-all ` +
        'relative rounded-full hover:bg-transparent disabled:opacity-50 ' +
        `disabled:hover:bg-transparent` +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
      style={{
        paddingRight: icon ? 0 : iconPadding[size],
        ...props.style,
      }}
    >
      {title && (
        <div
          className={`flex-1 font-bold text-md left-4 tracking-[2px] text-[${
            fontSizes[size]
          }px] font-semi whitespace-nowrap ${styles.pl} 
          ${typeof textClassName === 'string' ? ` ${textClassName}` : ''}`}
          style={labelProps}
        >
          {textLight ? (
            <span className="fort font-extralight">{title}</span>
          ) : (
            <strong>{title}</strong>
          )}
        </div>
      )}
      {icon && (
        <div
          className={`w-[32px] h-[24px] rounded-full bg-transparent items-center flex justify-center scale-[0.5] 
          ${typeof textClassName === 'string' ? ` ${textClassName}` : ''}`}
        >
          {icon}
        </div>
      )}
    </button>
  );
}

export default OvalIconButton;
