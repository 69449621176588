import { Typography } from '@mui/material';
import React, { useState } from 'react';
import Toast from '../atoms/Toast';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowButton from '../atoms/ArrowButton';
import Input from '../atoms/Input';
import LinkText from '../atoms/LinkText';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import http from 'services/http';

function ForgotPasswordComponent() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [toast, setToast] = useState({
    status: false,
    message: '',
    severity: null,
  });

  const onEmailChange = (event) => {
    setError('');
    setEmail(event.target.value);
  };

  const backToLogin = () => {
    navigate('/login');
  };

  const schema = yup.string().email('Email is not valid').required('Email is required!');

  const onSubmit = async () => {
    const isValid = await schema.isValid(email);
    schema.validate(email).catch((err) => {
      setError(err.message);
    });

    if (!isValid || loading) return;

    try {
      setLoading(true);
      await (
        await http.post(`/forgot-password`, { email })
      ).data;
      setLoading(false);
      setToast({
        status: true,
        message: 'Email sent successfully!',
        severity: 'success',
      });
      setEmail('');
    } catch (err) {
      setToast({
        status: true,
        message: err?.response?.data,
        severity: 'error',
      });
      setLoading(false);
    }
  };
  return (
    <div>
      <Toast
        onClose={() =>
          setToast({
            status: false,
            message: '',
            severity: null,
          })
        }
        toast={toast}
      />
      <div
        className="pb-5 w-200 flex flex-col items-center pt-5 px-9  shadow 
      bg-transparent border-2 border-white rounded-[16px] bg-white/20"
      >
        <AccountCircleOutlinedIcon
          style={{
            color: 'white',
          }}
        />
        <Typography variant="p" className="text-white text-[20px] font-bold pb-1 pt-3  uppercase ">
          Enter your email
        </Typography>
        <div className="w-11/12 relative flex flex-col items-center">
          <div className=" w-3/4 relative ">
            <div className="my-1  ">
              <Input
                placeholder={'Email'}
                value={email}
                errStatus={error}
                errMessage={error}
                onChange={onEmailChange}
                type={'email'}
              />
              <p
                className={`${
                  !error ? 'opacity-0' : 'opacity-100'
                } absolute right-1 text-sm text-red-500 ease-in-out duration-200`}
              >
                {error}
              </p>
            </div>
            <div className="mt-2 flex justify-center">
              {loading ? (
                <div className="loader"></div>
              ) : (
                <ArrowButton onClick={onSubmit} filled={true} direction="right" />
              )}
            </div>
            <div className="mt-1">
              <LinkText onClick={backToLogin} text={'Back to Login'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordComponent;
