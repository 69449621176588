import GradeModalFileItem from 'components/UI/molecules/GradeModalFileItem';
import OvalIconButtonWhite from 'components/UI/molecules/OvalIconButtonWhite';
import RadioScoreButton from 'components/UI/molecules/Trainer/RadioScoreButton';
// import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import useUser from 'hooks/auth/useUser';
import { useEffect, useRef } from 'react';
// import logger from 'utils/logger';

/**
 * @typedef {{
 *  fsId: number,
 *  pcId: number,
 *  skillScore: number,
 *  competencyScore: number,
 *  comments: string,
 * }} EvidenceGradingModalResponse
 */

/**
 * @typedef {{
 *  open: boolean,
 *  uploads: Array,
 *  skillScore: number,
 *  competencyScore: number,
 *  fsCode: string,
 *  fsTitle: string,
 *  fsSubtitle: string,
 *  fsDescription: string,
 *  pcCode: string,
 *  pcTitle: string,
 *  pcSubtitle: string,
 *  pcDescription: string,
 *  comments: string,
 *  studentName: string,
 *  onClose: () => void,
 *  onSkillScoreChange: (e: InputEvent) => void,
 *  onCompetencyScoreChange: (e: InputEvent) => void,
 *  onScoreChange: (e: InputEvent) => void,
 *  onCommentChange: (e: InputEvent) => void,
 *  openEvidenceOverview: () => void,
 *  onSubmit: (response: EvidenceGradingModalResponse) => void,
 *  readOnly?: boolean,
 *  props: import('react').HTMLAttributes
 * }} EvidenceGradingModalBaseParams
 * @param {EvidenceGradingModalBaseParams} param0
 * @returns
 */
export default function EvidenceGradingModalBase({
  open = false,
  uploads = [],
  skillScore = 0,
  competencyScore = 0,
  fsCode,
  fsTitle,
  fsSubtitle,
  fsDescription,
  pcCode,
  pcTitle,
  pcSubtitle,
  pcDescription,
  comments,
  studentName,
  openEvidenceOverview,
  onClose,
  onSkillScoreChange,
  onCompetencyScoreChange,
  onScoreChange,
  onCommentChange,
  onSubmit,
  readOnly = false,
  ...props
}) {
  const { token } = useUser();
  const parentRef = useRef(null);

  useEffect(() => {
    parentRef.current?.focus();
  }, []);

  const onDownloadClick = () => {
    if (!Array.isArray(uploads)) return;
    for (const item of uploads) {
      const url = new URL(item.filePath);
      url.searchParams.append('token', token);
      const toOpen = url.toString();
      window.open(toOpen, item.id);
    }
  };

  const latestUpload = uploads.sort((a, b) => {
    return -(a.updatedAt > b.updatedAt ? 1 : b.updatedAt > a.updatedAt ? -1 : 0);
  })[0];

  return ReactDOM.createPortal(
    open ? (
      <div
        className="fixed top-0 left-0 bottom-0 right-0 overflow-x-hidden overflow-y-auto flex flex-row items-center z-10 p-[16px] text-white"
        {...props}
        ref={parentRef}
      >
        <div
          className="bg-black/80 fixed top-0 left-0 bottom-0 right-0 sb2-txt-fade"
          onClick={onClose}
        ></div>
        <div className="fort font-extralight relative modal-bg w-full min-h-[400px] max-w-[640px] m-auto rounded-[8px] border-white border-[2px] p-[56px] sb2-txt-fade">
          <button
            onClick={onClose}
            className="rounded-full bg-white h-[32px] w-[32px] flex text-[#aaa]
                        items-center justify-center box-border p-[0px] absolute top-[16px] 
                        right-[16px]"
          >
            <CloseIcon style={{ fontSize: 16 }} />
          </button>
          {studentName && <div className="fort uppercase text-[32px] mb-[32px]">{studentName}</div>}
          <div className="mb-[16px] flex flex-col gap-[8px]">
            {/* {uploads.map((item) => (
              <GradeModalFileItem data={item} key={item.id} />
            ))} */}
            {latestUpload && <GradeModalFileItem data={latestUpload} />}
          </div>
          {Array.isArray(uploads) && uploads.length > 0 && (
            <OvalIconButtonWhite text="DOWNLOAD FILES" onClick={onDownloadClick} />
          )}
          <div className="text-[24px] font-normal mt-[32px]">
            {fsCode}: {fsTitle}
          </div>
          <div className="text-[22px]">{fsSubtitle}</div>
          <div className="mt-[16px] uppercase">{fsDescription || 'Description text'}</div>

          <div className="text-[18px] workSans-bold mt-[32px] mb-[16px]">
            {/* {currentFS.code} EVIDENCE SCORE */}
            SKILL EVIDENCE SCORE
          </div>
          <div className="flex flex-row items-center gap-[16px]">
            <RadioScoreButton
              name="skillScore"
              id="fs0"
              value={0}
              onChange={onSkillScoreChange || onScoreChange}
              checked={skillScore === 0}
              text="00"
            />

            <RadioScoreButton
              name="skillScore"
              id="fs1"
              value={1}
              onChange={onSkillScoreChange || onScoreChange}
              checked={skillScore === 1}
              text="01"
            />

            <RadioScoreButton
              name="skillScore"
              id="fs2"
              value={2}
              onChange={onSkillScoreChange || onScoreChange}
              checked={skillScore === 2}
              text="02"
            />

            <RadioScoreButton
              name="skillScore"
              id="fs3"
              value={3}
              onChange={onSkillScoreChange || onScoreChange}
              checked={skillScore === 3}
              text="03"
            />
          </div>

          <div className="text-[24px] mt-[32px] uppercase">
            <span className="font-normal">{pcCode}: </span>
            <span>{pcTitle}</span>
          </div>
          <div className="text-[18px] workSans-bold mt-[32px] mb-[16px]">
            {/* {currentPC.code} EVIDENCE SCORE */}
            COMPETENCY EVIDENCE SCORE
          </div>
          <div className="flex flex-row items-center gap-[16px]">
            <RadioScoreButton
              name="competencyScore"
              id="pc0"
              value={0}
              onChange={onCompetencyScoreChange || onScoreChange}
              checked={competencyScore === 0}
              text="00"
            />

            <RadioScoreButton
              name="competencyScore"
              id="pc1"
              value={1}
              onChange={onCompetencyScoreChange || onScoreChange}
              checked={competencyScore === 1}
              text="01"
            />

            <RadioScoreButton
              name="competencyScore"
              id="pc2"
              value={2}
              onChange={onCompetencyScoreChange || onScoreChange}
              checked={competencyScore === 2}
              text="02"
            />

            <RadioScoreButton
              name="competencyScore"
              id="pc3"
              value={3}
              onChange={onCompetencyScoreChange || onScoreChange}
              checked={competencyScore === 3}
              text="03"
            />
          </div>

          <div className="bg-white rounded-[16px] p-[16px] mt-[56px]">
            <div className="text-black font-normal tracking-[2px] pb-[8px] border-b-[1px] border-black px-[8px]">
              COMMENTS
            </div>
            <textarea
              value={comments}
              onChange={onCommentChange}
              placeholder="Enter comments here..."
              className="w-full h-[180px] list-scroll overflow-y-scroll p-[8px] text-black outline-none resize-none mt-[16px] font-extralight"
            ></textarea>
          </div>
          {/* <div className='mt-[16px]'>
            <OvalIconButtonWhite text='SUBMIT' onClick={onSubmit} />
          </div> */}
          <div className="mt-[16px] flex flex-row">
            {typeof openEvidenceOverview === 'function' && (
              <div className="flex-1">
                <OvalIconButtonWhite
                  text="STUDENT EVIDENCE OVERVIEW"
                  onClick={openEvidenceOverview}
                />
              </div>
            )}
            <OvalIconButtonWhite text="SUBMIT" onClick={onSubmit} />
          </div>
        </div>
      </div>
    ) : null,
    document.body
  );
}
