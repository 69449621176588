import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
// Backend controls
import AdminRoutes from 'pages/admin/AdminRoutes';
// Fallback Pages
import Missing from 'pages/fallbacks/404';
import OnlyMobile from 'pages/fallbacks/only-mobile';

import Login from 'pages/login/Login';
import LandingPage from 'pages/landing/LandingPage';
// Route listings
import StudentsRoutes from 'pages/student/StudentsRoutes';
import TrainerRoutes from 'pages/trainer/TrainerRoutes';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import Otp from 'pages/Otp/OTP';
import EnrollUser from 'pages/enrollUser/EnrollUser';
import FileSizeErrorModal from 'components/UI/organisms/FileSizeErrorModal';

const BrowserContent = () => {
  return (
    <Router>
      <Routes>
        {/* Renders admin pages per route request */}
        <Route path="*" element={<Missing />} />
        <Route path={'admin/*'} element={<AdminRoutes />}>
          <Route path="" element={<Missing />} />
        </Route>
        <Route path={'trainer/*'} element={<TrainerRoutes />} />
        <Route path={'student/*'} element={<StudentsRoutes />} />
        <Route index path={'/'} element={<LandingPage />} />
        <Route path={'login'} element={<Login />} />
        <Route path={'forgot-password'} element={<ForgotPassword />} />
        <Route path={'password-reset'} element={<ResetPassword />} />
        <Route path="two-factor-auth" element={<Otp />} />
        <Route path={'enroll'} element={<EnrollUser />} />
        <Route path={'enroll-user'} element={<ResetPassword />} />
      </Routes>
    </Router>
  );
};

// Mobile view only works for actual mobile phones only.
// Does not work on browsers under devtool config
export default function AppRouter() {
  return (
    <>
      <BrowserView>
        <BrowserContent />
      </BrowserView>
      <MobileView>
        <OnlyMobile />
      </MobileView>
      <FileSizeErrorModal />
    </>
  );
}
