import paperSvg from 'assets/images/svg/paper-white.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useRef } from 'react';
import UploadStatus from 'utils/uploadStatus';
import styles from 'styles/UI/molecules/UploadDocumentCard.module.css';
import { CircularProgress } from '@mui/material';

const { UPLOADING, SUCCESS, ERROR, REMOVING, REMOVED } = UploadStatus;

function UploadDocumentCardWhiteSmall({
  title,
  onClick,
  className,
  fileExtension,
  onRemoveClick,
  timestamp,
  status = UPLOADING,
  itemIndex,
  ...props
}) {
  const styleSource = {
    [UPLOADING]: styles.uploading,
    [SUCCESS]: styles.success,
    [ERROR]: styles.error,
    [REMOVING]: styles.removing,
    [REMOVED]: styles.removed,
  };
  const _className = typeof className === 'string' ? ` ${className}` : '';
  const _uploadStatusClassName = styleSource[status];
  const _date = new Date(timestamp);
  //
  /**
   * @type {{ current: HTMLDivElement }}
   */
  const rootEl = useRef();

  const onRemoveClickHandler = () => {
    const busyState = [UPLOADING, REMOVING];
    // when not busy, allow click trigger.
    if (busyState.indexOf(status) === -1) {
      if (typeof onRemoveClick === 'function') onRemoveClick();
    }
  };

  return (
    <div
      title={title}
      onClick={onClick}
      className={
        'relative group w-[100px] min-w-[100px] min-h-[140px] p-[8px] pt-[22px] pb-[0px] flex ' +
        'flex-col first-letter hover:border-black  transition-all relative ' +
        `ease-in-out duration-[.2s] cursor-pointer text-white
        ${_uploadStatusClassName} ${_className} ${itemIndex && itemIndex > 0 ? styles.older : ''}`
      }
      {...props}
      ref={rootEl}
    >
      <img src={paperSvg} alt="paper-image" className="w-[100px] absolute top-0 left-0" />
      <div
        className="max-h-[56px] text-white fort text-[10px] leading-[11px]  
                      uppercase tracking-[1px] flex-1 overflow-hidden text-ellipsis line-clamp-5 
                      pt-[4px] z-[2]"
      >
        {title || 'Document ## Name'}
      </div>
      <div
        className="fort font-extralight absolute bottom-[24px] left-[-8px] bg-black text-white rounded-[4px] py-[3px] px-[4px] tracking-[2px] text-[10px]
                    bg-gradient-to-r from-red-500 to-red-900 uppercase"
      >
        {fileExtension || '.PDF'}
      </div>
      <div className="absolute bottom-[9px] left-[5px] text-[9px] fort font-extralight">
        {/* {`${_date.getMonth() + 1}/${_date.getFullYear()}`} */}
        {_date.toLocaleDateString()}
      </div>
      <div className="absolute bottom-[9px] right-[5px] text-[9px] fort font-extralight">
        {_date.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: '2-digit' })}
      </div>
      {typeof onRemoveClick === 'function' && (
        <button
          className="transition-all transform-gpu ease-in-out duration-200 
        absolute top-[-8px] right-[-8px] 
                    text-[#fff] text-[24px] p-none flex items-center justify-center 
                    rounded-full scale-0 hover:text-red-500 group-hover:flex group-hover:scale-100"
          title="Remove File"
          onClick={onRemoveClickHandler}
        >
          <HighlightOffIcon style={{ fontSize: 20 }} />
        </button>
      )}

      {[UPLOADING, REMOVING].indexOf(status) > -1 && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-row items-center justify-center">
          <div className="text-center">
            <CircularProgress />
            <div className="fort text-[10px] font-extralight">
              {status === UPLOADING && <>UPLOADING</>}
              {status === REMOVING && <>REMOVING</>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadDocumentCardWhiteSmall;
