const fileObject = {
  fileName: 'name-1.txt',
  fileExt: '.google',
  description: '',
  filePath: '/file/name-1.txt',
  // Generate this part to the component uploading
  // topicId: 1,
  // fileTypeId: 1,
  // academyId: 1,
  // yearId: 1,
};

export default function linkFileParser(url = '') {
  try {
    const link = new URL(url);
    const wUrl = window.location?.pathname;

    if (link.hostname === 'drive.google.com') {
      return {
        ...fileObject,
        fileName: 'Google Link',
        filePath: url,
      };
    }
    const fileClientName = link.pathname.split('/').pop();
    const fileUploadName = wUrl.split('/').pop();
    const ext = fileClientName.split('.').pop();

    return {
      ...fileObject,
      fileName: fileUploadName,
      // TODO: If no file extension added then just assume it is
      // .link but we need another checker for this
      fileExt: ext === fileClientName ? '.link' : '.' + ext,
      filePath: url,
    };
  } catch (_) {
    return null;
  }
}
