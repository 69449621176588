import React, { useState } from 'react';
import style from '../../../styles/UI/atoms/input.module.css';
import EditIcon from '@mui/icons-material/Edit';
function EditableInput({ type, value, onChange, placeholder, name, errStatus, errMessage }) {
  const [readOnly, setReadOnly] = useState(true);
  const onEnter = (e) => {
    if (e.key === 'Enter') {
      setReadOnly(true);
    }
  };
  return (
    <div className="relative">
      <input
        name={name}
        onKeyDown={onEnter}
        onBlur={() => setReadOnly(true)}
        className={`
    w-full px-3 py-2 h-10  text-sm 
    font-bold 
    focus:outline-none 
    bg-black 
    autofill:font-bold
    text-white 
    placeholder:text-white ${style.input}`}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
      {readOnly && (
        <div onClick={() => setReadOnly(false)} className="absolute top-1 right-1 cursor-pointer">
          <EditIcon
            style={{
              color: 'white',
              fontSize: '18px',
            }}
          />
        </div>
      )}
    </div>
  );
}

export default EditableInput;
