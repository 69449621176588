import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/reducers/auth';
//
/**
 * @typedef {{
 *  title: string,
 *  message: string | null,
 * }} AuthFailedModalParams
 * @param {import('react').HTMLAttributes & AuthFailedModalParams} param0
 * @returns
 */
export default function AuthFailedModal({ title, message, className, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(logout());
    navigate('/login');
  };

  return ReactDOM.createPortal(
    <div
      className={
        'fixed top-0 right-0 left-0 bottom-0 z-[100] p-[16px] flex flex-row items-center' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-black/80 sb2-txt-fade"></div>
      <div className="relative modal-bg w-full min-h-[100px] border-white border-[1px] rounded-[16px] max-w-[480px] m-auto sb2-txt-fade delay-300">
        <div className="fort text-[24px] text-white px-[16px] py-[32px] text-center">{title}</div>
        {typeof message === 'string' && (
          <div className="fort text-[16px] text-white px-[16px] py-[8px] text-center">
            {message}
          </div>
        )}
        <div className="p-[16px] mt-[16px]">
          <button
            onClick={onClick}
            className="block fort border-white border-[1px] h-[40px] rounded-[24px] w-full max-w-[200px] text-white m-auto hover:bg-white/20"
          >
            LOGIN
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}
