import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';

/**
 * @typedef {{
 *  title: string,
 *  message: string,
 *  status: 'success' | 'error' | 'in-progress',
 *  position: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right',
 * }} NotifierParams
 */

/**
 * @param {NotifierParams} params
 */
export function notify(params) {
  window.dispatchEvent(new CustomEvent('notify', { detail: params }));
}

window.erlNotify = notify;

/**
 * @type {Object<string, import('@emotion/serialize').CSSProperties>}
 */
export const NotifierStyles = {
  success: {
    backgroundColor: '#69F0AE',
    color: 'black',
  },
  inProgress: {
    backgroundColor: '#0277BD',
    color: 'white',
  },
  error: {
    backgroundColor: '#D32F2F',
    color: 'white',
  },
};

let timer = null;
let closeTimer = null;

function Notifier() {
  //
  /**
   * @type {NotifierParams & { show: boolean }}
   */
  const initState = {
    title: null,
    message: 'Message sample',
    status: 'success',
    show: false,
    position: 'bottom',
  };
  const [state, setState] = useState({ ...initState });
  const { title, message, status, show, position } = state;

  const setNewState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    window.addEventListener('notify', callNotifier);
    return () => {
      window.removeEventListener('notify', callNotifier);
    };
  }, []);

  /**
   * @param {CustomEvent} e
   */
  const callNotifier = (e) => {
    if (timer) clearTimeout(timer);
    setNewState({ show: false });
    timer = setTimeout(() => {
      setNewState({ ...e.detail, show: true });
      startClose();
    }, 100);
  };

  const startClose = () => {
    if (closeTimer) clearTimeout(closeTimer);
    closeTimer = setTimeout(() => {
      setNewState({ show: false });
    }, 2000);
  };

  const onCloseClick = () => {
    clearTimeout(closeTimer);
    clearTimeout(timer);
    setNewState({ ...initState });
  };

  return createPortal(
    <>
      {show && (
        <div
          className="anim-tfb fort font-extralight fixed max-w-[320px] min-h-[56px] 
                    w-full block shadow-lg rounded-[8px] p-[16px] flex flex-row items-center"
          style={{
            ...(status === 'success' && NotifierStyles.success),
            ...(status === 'error' && NotifierStyles.error),
            ...(status === 'in-progress' && NotifierStyles.inProgress),
            ...(position === 'top' && { top: 16, margin: 'auto', left: 0, right: 0 }),
            ...(position === 'bottom' && { bottom: 16, margin: 'auto', left: 0, right: 0 }),
            zIndex: 1001,
          }}
        >
          <div className="flex-1">
            {title && <div className="fort font-normal">{title}</div>}
            {message && <div className="">{message}</div>}
          </div>
          <button
            onClick={onCloseClick}
            className="text-[18px] h-[32px] w-[32px] rounded-full hover:bg-black/10 flex items-center justify-center"
          >
            <CloseIcon style={{ fontSize: '1em' }} />
          </button>
        </div>
      )}
    </>,
    document.body
  );
}

export default memo(Notifier);
