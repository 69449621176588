export default function HighlightCard({ children, className, ...props }) {
  return (
    <div
      {...props}
      className={
        'inline-flex flex-row bg-white rounded-[12px] p-[32px] shadow-md' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
    >
      {children}
    </div>
  );
}
