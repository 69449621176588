export default function ArrowRightIcon({ size, ...props }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.84,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6.68 12.17"
      width={size || '1em'}
      height={size || '1em'}
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Nav">
          <polyline {...cssProps} points="0.3 11.87 6.08 6.08 0.3 0.3" />
        </g>
      </g>
    </svg>
  );
}
