import { useState } from 'react';
import http from 'services/http';

export default function useAuthChecker() {
  const [error, setError] = useState({
    isError: false,
    errorTitle: '',
    errorMessage: '',
  });

  const check = () => {
    http
      .post('/api/users/current')
      .then((result) => {
        if (result.status === 200) {
          setError({
            ...error,
            isError: false,
            errorTitle: '',
            errorMessage: '',
          });
        } else {
          setError({
            ...error,
            isError: true,
            errorTitle: 'Authentication Failed/Expired, Please login again.',
            errorMessage: '',
          });
        }
      })
      .catch((err) => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 401)) {
          setError({
            ...error,
            isError: true,
            errorTitle: 'Authentication Failed/Expired, Please login again.',
            errorMessage: err?.response?.data || err.message,
          });
          return;
        }

        alert(err.message);
      });
  };

  return {
    error,
    check,
  };
}
