export default function LandscapeCardListContainer({ children, className, ...props }) {
  return (
    <div
      className={
        'flex flex-wrap  w-full justify-start gap-[64px] mt-[40px]' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {children}
    </div>
  );
}
