import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} AssignmentDirectory
 * @prop {number} id
 * @prop {string} uuid
 * @prop {string} name
 * @prop {string} path
 * @prop {string} dirPath
 * @prop {number} orderId
 * @prop {number} parentId
 * @prop {boolean} isParent
 * @prop {string} createdAt
 * @prop {string} updatedAt
 */

/**
 * @typedef {{
 * isLoading: boolean,
 * list: AssignmentDirectory[],
 * page: number,
 * pageCount: number,
 * limit: number | null,
 * totalCount: number
 * }} AssignmentDirectory
 */

/**
 * @type {AssignmentDirectory}
 */
const initialState = {
  isLoading: true,
  list: [],
  treeList: [],
  selected: [],
  page: 1,
  pageCount: 1,
  limit: 25,
  totalCount: 0,
};

const assignmentDirectorySlice = createSlice({
  name: 'assignmentDirectory',
  initialState,
  reducers: {
    setAssignmentDirectory: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setAssignmentDirectory } = assignmentDirectorySlice.actions;

export default assignmentDirectorySlice.reducer;
