import { useState, useEffect, useMemo, useDeferredValue } from 'react';
import http from 'services/http';
export default function useStudentGroupModal(student = null, setOpen) {
  const [groupName, setGroupName] = useState(''); // for search
  const [groupType, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupListLoading, setListLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [page, setPage] = useState(1);
  const [listGroups, setGroups] = useState([]);
  const defGroupName = useDeferredValue(groupName);
  const limit = 2;

  const canNext = useMemo(() => {
    const active = listGroups.filter((i) => i.active);
    switch (page) {
      case 1:
        if (active.length === 0) setAlert('No groups selected!');
        return active.length > 0;
      default:
        break;
    }
    return page < limit;
  }, [page, listGroups]);
  const canPrevious = page > 1;

  const handleNextPage = () => {
    if (canNext) setPage(page + 1);
  };

  const resetModal = () => {
    if (setOpen) setOpen(null);
    setPage(1);
    setGroups([]);
    setLoading(false);
  };

  const triggerProcess = async () => {
    setLoading(true);
    try {
      await http.post('/api/assigned-users', {
        groupId: listGroups.map((i) => i.id),
        userId: student?.id,
      });
    } catch (e) {}
    resetModal();
  };

  const handlePreviousPage = () => (canPrevious ? setPage(page - 1) : null);

  const runQuery = async () => {
    setListLoading(true);
    const query = {};
    if (groupType !== null) query.groupTypeId = groupType?.id;
    if (defGroupName !== '') query.groupName = defGroupName;
    const { data } = await http.post('/api/groups/search', { ...query });
    setGroups(data?.list);
    setListLoading(false);
  };

  useEffect(() => {
    if (page === limit) triggerProcess();
  }, [page]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    runQuery();
  }, [groupType, defGroupName]);

  const toAddGroup = (group = null) => {
    if (!group) return;
    const active = group.active !== null ? !group.active : false;
    setGroups(listGroups.map((i) => (i.id === group.id ? { ...i, active } : i)));
  };

  return {
    page,
    loading,
    groupListLoading,
    groupName,
    setGroupName,
    canPrevious,
    canNext,
    activeGroup: groupType,
    listGroups,
    // modifiers
    selectGroup: setGroup,
    handlePreviousPage,
    handleNextPage,
    setLoading,
    toAddGroup,
    resetModal,
  };
}
