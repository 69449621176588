export default function PencilSMIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 10}
      height={size || 10}
      viewBox="0 0 10.356 10.355"
    >
      <g id="Group_71" data-name="Group 71" transform="translate(-5663.917 -3558.791)">
        <g id="Group_70" data-name="Group 70">
          <g id="Group_69" data-name="Group 69">
            <path
              id="Path_48"
              data-name="Path 48"
              d="M5664.351,3568.9a.183.183,0,0,1-.179-.219l.481-2.38a.191.191,0,0,1,.05-.093l6.711-6.71a1.52,1.52,0,0,1,1.388-.415h0a1.525,1.525,0,0,1,.769,2.571l-6.711,6.711a.184.184,0,0,1-.093.05l-2.38.482Z"
              fill="none"
              stroke="#3d3d3f"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
          </g>
        </g>
        <line
          id="Line_16"
          data-name="Line 16"
          x2="2.064"
          y2="2.207"
          transform="translate(5664.703 3566.204)"
          fill="none"
          stroke="#3d3d3f"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
}
