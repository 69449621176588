import { useSelector, useDispatch } from 'react-redux';
import { setEvidenceCompetencyList } from 'redux/reducers/student/evidenceCompetencyState';
import http from 'services/http';

export default function useEvidenceCompetencyState() {
  // skill list
  /**
   * @type {import('../../redux/reducers/student/evidenceCompetencyState').EvidenceCompetencyList}
   */
  const evidenceCompetency = useSelector((state) => state.evidenceCompetency);
  const list = evidenceCompetency.list;
  const dispatch = useDispatch();

  const getList = async (params) => {
    setEvidenceCompetencyList({ ...evidenceCompetency, isLoading: true });
    const result = await http.post('/api/evidence-competency/search', params);
    dispatch(setEvidenceCompetencyList({ ...result.data, isLoading: false }));
    return result;
  };

  const setList = (data) => {
    dispatch(setEvidenceCompetencyList(data));
  };

  return {
    getList,
    list,
    setList,
    evidenceCompetencyState: evidenceCompetency,
    isLoading: evidenceCompetency.isLoading,
  };
}
