export default function PlusBoxHeading({ children, className, ...props }) {
  return (
    <div
      className={
        'min-h-[34px] text-[12px] font-bold my-[32px]' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      <strong>{children}</strong>
    </div>
  );
}
