export default function TwoHandsIcon({ size }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.03 22.03"
      width={size || 32}
      height={size || 32}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Nav">
          <path {...cssProps} d="M16.76,12.63h0" />
          <path
            {...cssProps}
            d="M12.07,10.88,11,9.82l-1,1.06a4.88,4.88,0,0,0-1,3,1.79,1.79,0,0,0,.52,1.23L11,16.62l1.56-1.55a1.79,1.79,0,0,0,.52-1.23A4.88,4.88,0,0,0,12.07,10.88ZM11,.25A10.77,10.77,0,1,0,21.78,11,10.76,10.76,0,0,0,11,.25Zm5.76,12.38a1,1,0,0,1,1.39,0l.39.4-3.23,3.22a4.84,4.84,0,0,1-3,1,1.8,1.8,0,0,1-1.15-.52L11,16.62l-.12.13a1.8,1.8,0,0,1-1.16.52,4.86,4.86,0,0,1-3-1L3.48,13l.39-.4a1,1,0,0,1,1.38,0L2.47,9.84A.76.76,0,0,1,3.54,8.77l-1-1a.72.72,0,0,1,0-1,.73.73,0,0,1,1,0l.84.84A.76.76,0,0,1,5.53,6.48l.8.8a.74.74,0,0,1,0-1,.73.73,0,0,1,1,0L8.85,7.66,11,9.82l.42-.42,1.75-1.74,1.44-1.45A.76.76,0,0,1,15.7,7.28l.8-.8a.76.76,0,0,1,1.07,1.07l.84-.84a.76.76,0,0,1,1.07,1.07l-1,1a.76.76,0,0,1,1.07,1.07Zm-3.68,1.21a4.88,4.88,0,0,0-1-3L11,9.82l-1,1.06a4.88,4.88,0,0,0-1,3,1.79,1.79,0,0,0,.52,1.23L11,16.62l1.56-1.55A1.79,1.79,0,0,0,13.09,13.84ZM5.26,12.63h0"
          />
        </g>
      </g>
    </svg>
  );
}
