import React, { useEffect, useRef } from 'react';
import PlusBox from 'components/UI/atoms/PlusBox';
import PlusBoxHolder from 'components/UI/atoms/PlusBoxHolder';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';

import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';

import StrokeText from 'components/UI/atoms/StrokeText';
import HoverOptionCard from 'components/UI/molecules/HoverOptionCard';
import CheckList from 'components/UI/molecules/CheckList';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';

// Accordion Content
import useGroupInstance from 'hooks/trainer/useGroupInstance';
import useGroupQuery from 'hooks/trainer/useGroupQuery';

import Input from 'components/UI/atoms/Input';
import CheckItem from 'components/UI/molecules/CheckItem';
import GroupCard from 'components/UI/molecules/GroupCard';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';

import headframe from 'assets/images/svg/headframe.svg';
import HeadIcon from 'components/UI/atoms/CustomIcons/HeadIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PencilSMIcon from 'components/UI/atoms/CustomIcons/PencilSMIcon';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';

import SearchStudent from 'components/UI/organisms/SearchStudent';
import useUser from 'hooks/auth/useUser';

const accStyle = {
  border: 0,
  borderBottom: '1px solid black',
  margin: '0 !important',
  boxShadow: 'none',
  '&:before': {
    backgroundColor: 'unset',
  },
};

const accSummaryStyle = {
  padding: '0',
};
const accDetailStyle = {
  padding: '15px 0',
};

function AssignGroup() {
  const { loading, groups, loadGroup, createGroup, removeGroup, group } = useGroupQuery();
  const studAccordionRef = useRef();
  const { user } = useUser();
  const {
    alert,
    loading: pLoading,
    modified,
    modifying,
    groupTypes,
    setGroupType,
    toggleAccordion,
    studentAccordion,
    batchAddStudent,
    selectedStudents,
    groupType,
    groupName,
    setGroupName,
    deleteStudents,
    togStudent,
    openForm,
  } = useGroupInstance(group);
  const school = user?.academyName || 'Livingstone Academy Bournemouth';

  const breadcrumbsItems = [
    {
      text: 'Assigned Groups',
      path: '/trainer/groups',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeGroupName = ({ target }) => setGroupName(target.value);
  const processGroup = () => {
    const output = {
      name: groupName,
      groupTypeId: groupType || 1,
      academyId: 1, // Temporary
    };
    if (group?.uuid) output.uuid = group?.uuid;
    createGroup({ ...output });
  };

  const onDeleteGroup = (group) => {
    // TODO: do sweet alert here
    removeGroup(group);
  };

  const handleStudentAdd = (students) => batchAddStudent(students, group);
  const handleStudentAddButton = () => {
    toggleAccordion('studentAccordion');
    if (studAccordionRef.current) {
      setTimeout(() => {
        studAccordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  };

  const initGroup = (id) => {
    openForm(true);
    setGroupType(id);
  };
  const onLoadGroup = (group) => {
    loadGroup(group);
    openForm(false);
  };
  return (
    <div>
      <MainHeadingText>
        Assigned <br /> Groups
      </MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />

      <DashboardContentHeading className="mt-[32px]">
        RECENTLY CREATED GROUPS
      </DashboardContentHeading>
      <div className="flex w-full justify-start relative">
        {groups.length > 0 ? (
          <DocumentCardContainer disableSticky={true}>
            {groups.map((i) => (
              <GroupCard
                key={`group-${i.id}`}
                title={i.title || i.name || i.groupName}
                groupType={i.groupTypeName}
                onClickEdit={() => onLoadGroup(i)}
                onClickDelete={() => onDeleteGroup(i)}
              />
            ))}
          </DocumentCardContainer>
        ) : (
          <>
            <div className="flex justify-start">
              <PlusBoxHolder>
                <PlusBox />
              </PlusBoxHolder>
            </div>
            <StrokeText className="text-[36px] ml-[32px] max-w-[720px]">
              No Groups <br /> Currently <br /> Created
            </StrokeText>
          </>
        )}
      </div>
      <DashboardContentText>
        {user.userTypeId === 1 && <strong>Create groups for admin and trainers.</strong>}
        {user.userTypeId === 2 && <strong>Create groups or classes.</strong>}
      </DashboardContentText>
      <DashboardContentText>
        {/* {user.userTypeId === 1 && <>Create groups for admin and trainers.</>} */}
        {user.userTypeId === 2 && (
          <>
            This enables you to upload resources to an individual identified group rather than the
            entire AED year group. You can create a new group, edit existing groups and add
            materials to a group in this location.
          </>
        )}
      </DashboardContentText>
      {/* TODO: put this to an organism? but i feel like it's not to be placed there */}
      <Accordion expanded={true} sx={accStyle}>
        <AccordionSummary sx={accSummaryStyle} aria-controls="panel1a-content" id="panel1a-header">
          <DashboardContentHeading>SELECT A GROUP TYPE:</DashboardContentHeading>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0', paddingBottom: '20px' }}>
          <div className="flex mb-4">
            {/* TODO: Insert academy name to the description.   */}
            {groupType == null ? (
              groupTypes.map((i, index) => (
                <div className="mr-4" onClick={() => initGroup(i.id)} key={index}>
                  <HoverOptionCard
                    title={i.name}
                    content={`${i.description} ${school}`}
                    textColor={'white'}
                    titleTextStyle={{
                      fontSize: 28,
                    }}
                    buttonTitle="START"
                  />
                </div>
              ))
            ) : (
              <div className="p-[20px] bg-white rounded-lg w-full">
                <div className="flex items-center pb-4">
                  <img src={headframe} alt="bg-image" className="h-[60px] w-[60px]" />
                  <div className="ml-4">
                    <h4 className="fort mb-1">{school}</h4>
                    {!modifying ? (
                      <div className="fort flex mb-1 items-center">
                        <h4 className="mr-4">{groupName}</h4>
                        <div className={`cursor-pointer`} onClick={() => openForm(true)}>
                          <PencilSMIcon size={14} />
                        </div>
                      </div>
                    ) : (
                      <div className="flex ">
                        <Input
                          placeholder="name your new group"
                          className="text-left w-[400px] bg-slate-300 rounded-lg p-2 shadow"
                          value={groupName}
                          onChange={onChangeGroupName}
                        />
                        {loading && <CircularProgress />}
                      </div>
                    )}
                  </div>
                </div>
                {alert && <p className="text-red">{alert}</p>}
                <CheckList
                  title="students"
                  listClassName="rounded-lg bg-slate-300 h-[300px] p-2"
                  count={selectedStudents.length}
                  onAdd={handleStudentAddButton}
                  onDelete={deleteStudents}
                >
                  {pLoading?.studentPanel ? (
                    <CircularProgress />
                  ) : (
                    selectedStudents.map((i, k) => (
                      <CheckItem
                        key={k}
                        title={i.firstName + ' ' + i.lastName}
                        v2={true}
                        active={i.active}
                        // subtitle={i.academy?.name}
                        onClick={() => togStudent(i)}
                        svgicon={<HeadIcon size={20} active={i.active} />}
                      />
                    ))
                  )}
                </CheckList>
              </div>
            )}
          </div>
          <div className="flex">
            {groupType && (
              <div
                className={`border-[#777] bg-transparent mx-2 border-[1px] cursor-pointer rounded-full items-center flex right-0 justify-center`}
                onClick={() => setGroupType(null)}
              >
                <ChevronLeftIcon
                  style={{
                    fontSize: '32px',
                    color: '#777',
                  }}
                />
              </div>
            )}
            {(group?.id === null || modified) && (
              <OvalIconButton
                onClick={modified ? processGroup : null}
                title="Finalize Group"
                className={`self-end cursor-pointer ${modified ? 'opacity-100' : 'opacity-30'}`}
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {/* TODO: Expanded temporarily */}
      {/* Student */}
      <Accordion
        ref={studAccordionRef}
        expanded={studentAccordion}
        sx={accStyle}
        onChange={(e, b) => toggleAccordion('studentAccordion', b)}
      >
        <AccordionSummary sx={accSummaryStyle} aria-controls="panel2-content" id="panel2-header">
          <DashboardContentHeading>SELECT STUDENTS TO GROUP:</DashboardContentHeading>
        </AccordionSummary>
        <AccordionDetails sx={accDetailStyle}>
          <SearchStudent loading={pLoading?.studentPanel || false} addToGroup={handleStudentAdd} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AssignGroup;
