export default function CircleDownloadIcon({ size }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.84,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.88 26.88"
      width={size || 32}
      height={size || 32}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Container_Text" data-name="Container+Text">
          <line {...cssProps} x1="13.44" y1="7.96" x2="13.44" y2="16.71" />
          <polyline {...cssProps} points="17.45 12.7 13.44 16.71 9.43 12.7" />
          <polyline {...cssProps} points="20.03 14.96 20.03 18.91 6.84 18.91 6.84 14.96" />
          <circle {...cssProps} cx="13.44" cy="13.44" r="13.02" />
        </g>
      </g>
    </svg>
  );
}
