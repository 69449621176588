/**
 *
 * @param {number} stringLength - String length
 * @returns {string}
 */
export function stringGenerator(stringLength, strPattern) {
  const len = stringLength || 12;
  const chars = strPattern;
  let str = '';
  while (str.length < len) {
    const ranIndex = Math.floor(Math.random() * chars.length);
    str += chars[ranIndex];
  }
  return str;
}

/**
 *
 * @param {number} stringLength - String length
 * @returns {string}
 */
export function randomBase64(stringLength) {
  const len = stringLength || 11;
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_';
  return stringGenerator(len, chars);
}

/**
 *
 * @param {number} stringLength - String length
 * @returns {string}
 */
export function randomBase36(stringLength) {
  const len = stringLength || 11;
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return stringGenerator(len, chars);
}

/**
 *
 * @param {number} stringLength - String length
 * @returns {string}
 */
export function randomHex(stringLength) {
  const len = stringLength || 11;
  const chars = '0123456789ABCDEF';
  return stringGenerator(len, chars);
}
