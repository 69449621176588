const UploadStatus = {
  EMPTY: 0,
  UPLOADING: 1,
  SUCCESS: 2,
  ERROR: 3,
  REMOVING: 4,
  REMOVED: 5,
  OLDER: 6,
};
export default UploadStatus;
