export default function ArrowLeftIcon({ size, ...props }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.618,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5.572 10.27"
      width={size || 32}
      height={size || 32}
      {...props}
    >
      <path
        id="Path_170"
        data-name="Path 170"
        d="M1575.845,322.354l-4.916,4.916,4.916,4.916"
        transform="translate(-1570.491 -322.135)"
        {...cssProps}
      />
    </svg>
  );
}
