// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/material/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiChip-label': {
    fontSize: 12,
    fontFamily: 'fort',
    fontWeight: 300,
  },
  '& .MuiOutlinedInput-input.MuiInputBase-input': {
    fontSize: 14,
    fontFamily: 'fort',
    fontWeight: 300,
    textTransform: 'uppercase',
  },
}));

export default function FilterAutoComplete({
  mainTag = {},
  tags = [],
  onClick = () => {},
  onClear,
  fromModal = false,
  multiple = true,
}) {
  if (tags.length === 0) return '';
  const onTagChange = (evt, allSelected, action, current) => {
    if (action === 'clear') {
      onClear(mainTag?.asSearch);
      return;
    }
    onClick(current?.option?.id, mainTag?.asSearch);
  };
  return (
    <div
      className={`flex items-center justify-between mb-4 ${
        fromModal ? 'text-white bg-white rounded-lg' : ''
      }`}
    >
      {/* <div className={`mr-4 fort font-thin flex items-center`}>
        <span>{mainTag.title || 'Tag'}</span>
        <ChevronRightIcon
          style={{
            fontSize: '18px',
          }}
        />
      </div> */}
      <CustomAutocomplete
        onChange={onTagChange}
        multiple={multiple}
        limitTags={2}
        disableCloseOnSelect
        id="multiple-limit-tags"
        options={tags}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            className="bg-white cursor-pointer fort font-extralight uppercase text-[14px] flex flex-row items-center py-[4px]"
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 4 }}
              checked={selected}
            />
            {option.title || option.name}
          </li>
        )}
        style={{ width: '100%' }}
        getOptionLabel={(opt) => opt.title || opt.name || `Tag ${opt}`}
        renderInput={(params) => (
          <TextField
            {...params}
            className="p-0 sort-tags fort font-extralight uppercase"
            placeholder={`${mainTag.title}`}
          ></TextField>
        )}
        PaperComponent={({ children }) => (
          <ul className="fort font-extralight uppercase text-[14px] bg-white rounded-lg shadow-lg">
            {children}
          </ul>
        )}
      />
    </div>
  );
}
