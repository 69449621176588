export default function BellIcon({ size }) {
  const cssProps = {
    fill: 'currentColor',
  };
  const cssProps2 = {
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.75,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.92 13.56"
      width={size || 32}
      height={size || 32}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Nav">
          <path
            {...cssProps}
            d="M6,.75A3.79,3.79,0,0,1,9.75,4.54V6.43a3.63,3.63,0,0,0,1.42,3.32v.95H.75V9.75C1.89,8.92,2.17,7.38,2.17,6V4.54A3.79,3.79,0,0,1,6,.75M6,0A4.55,4.55,0,0,0,1.42,4.54V6c0,1.57-.37,2.64-1.11,3.18L0,9.37v2.08H11.92V9.37l-.31-.23A2.88,2.88,0,0,1,10.5,6.43V4.54A4.55,4.55,0,0,0,6,0Z"
          />
          <path
            {...cssProps}
            {...cssProps2}
            d="M7.38,12.24c0,.52-.64.94-1.42.94s-1.42-.42-1.42-.94"
          />
        </g>
      </g>
    </svg>
  );
}
