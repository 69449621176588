function CircleOutlineIconButton({
  icon,
  onClick,
  className,
  style,
  disabled,
  mode = 'black',
  ...moreProps
}) {
  return (
    <button
      className={
        'cursor-pointer h-[40px] w-[40px] box-border border-[1px] ' +
        'text-[16px] flex items-center justify-center rounded-[50%] ' +
        (mode === 'black'
          ? 'text-[#555] border-[#555] hover:bg-white hover:text-black '
          : 'text-white border-[#fff] hover:bg-white/20 hover:text-white ' +
            'transition-all disabled:opacity-50 ') +
        'disabled:text-black disabled:bg-transparent' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      onClick={onClick}
      style={style}
      disabled={disabled}
      {...moreProps}
    >
      {icon}
    </button>
  );
}

export default CircleOutlineIconButton;
