import { useState } from 'react';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/UI/molecules/AedAccordion';
import UploadDocumentCardSmall from 'components/UI/molecules/UploadDocumentCardSmall';
import DocumentCardContainerSmall from 'components/UI/molecules/DocumentCardContainerSmall';
import useUser from 'hooks/auth/useUser';

/**
 * @typedef {{
 *    fileName: string,
 *    timestamp: string | number,
 *    uuid: string,
 *    fileExt: string,
 *    status: number,
 *    itemIndex: number
 *  }} Upload
 */

/**
 * @typedef {{
 *    code: string,
 *    title: string,
 *    subtitle: string,
 *  }} NextTarget
 */

/**
 * @typedef {{
 *  [studentName]: string,
 *  code: string,
 *  title: string,
 *  subtitle: string,
 *  description: string,
 *  uploads: Array<Upload>,
 *  score: string | number,
 *  mode: string,
 *  pairList: import('react').ReactElement,
 *  nextTarget: NextTarget,
 *  nextItemClick: () => void
 *  backToList: () => void
 * }} SkillViewProps
 */

/**
 * @param {SkillViewProps} param0
 * @returns
 */
export default function SkillViewBase({
  studentName,
  code,
  title,
  subtitle,
  description,
  uploads,
  score,
  mode,
  pairList,
  nextTarget,
  nextItemClick,
  backToList,
}) {
  const [expanded, setExpanded] = useState('panel1');
  const { token } = useUser();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleFileClick = (file) => {
    if (!file?.filePath) return;
    const url = new URL(file?.filePath);
    url.searchParams.append('token', token);
    window.open(url.toString(), '_blank');
  };

  return (
    <div className="pt-[32px] px-[16px]" id="skillView">
      <div className="rounded-[16px] bg-white w-full min-h-[100px] shadow-md p-[24px] flex flex-row gap-[32px]">
        <div className="max-w-[320px]">
          <div className="fort text-black text-[18px]">
            {code}: {title}
          </div>
          <div className="fort text-[16px] font-extralight">{mode === 'skill' ? subtitle : ''}</div>
          {description && (
            <div className="list-scroll fort font-extralight text-[12px] uppercase my-[16px] py-[8px] max-h-[156px] overflow-y-auto overflow-x-hidden">
              <pre className="whitespace-pre-line fort font-extralight pr-[8px]">{description}</pre>
            </div>
          )}
          <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary className="font-bold text-[14px] uppercase">
                {typeof studentName === 'string' ? studentName : 'YOUR'} UPLOADS
              </AccordionSummary>
              <AccordionDetails>
                {uploads.length > 0 ? (
                  <DocumentCardContainerSmall>
                    {uploads.map((item, index) => (
                      <UploadDocumentCardSmall
                        title={item.fileName}
                        timestamp={item.timestamp}
                        key={item.uuid}
                        fileExtension={item.fileExt}
                        status={item.status}
                        itemIndex={index}
                        onClick={() => handleFileClick(item)}
                        // onRemoveClick={() => onUploadFileRemoveClick(item.file.uuid)}
                        // onClick={uploadModal}
                      />
                    ))}
                  </DocumentCardContainerSmall>
                ) : (
                  <div className="flex flex-row gap-[16px] cursor-pointer">
                    <div className="font-bold text-[32px] min-w-[82px] w-[82px] h-[120px] border-[1px] border-black flex flex-row items-center justify-center">
                      +
                    </div>
                    <div className="sub-heading-white-stroke2 flex-1 text-[25px] min-h-[120px]">
                      NO MATERIALS CURRENTLY UPLOADED
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary className="font-bold text-[14px] uppercase">
                {typeof studentName === 'string' ? studentName : 'YOUR'} UPLOAD LOG
              </AccordionSummary>
              <AccordionDetails>
                <div className="list-scroll h-[120px] overflow-y-scroll overflow-x-hidden">
                  <div className="h-[300px]"></div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* <div className='border-t-[1px] border-[#ddd] py-[12px] mt-[16px]'>
            <div className='text-[14px] font-bold'>YOUR UPLOAD LOG</div>
          </div> */}
          <div className="border-t-[1px] border-[#ddd] pt-[12px]">
            <div className="flex flex-row items-center gap-[12px]">
              <div className="sub-heading-white-stroke2 text-[96px]">{score}</div>
              <div className="fort font-extralight text-[16px]">
                TOTAL {mode === 'skill' ? 'SKILL' : 'COMPETENCY'} EVIDENCE SCORE
              </div>
            </div>
            {/* <div className='flex flex-row items-center gap-[24px] mt-[32px] h-[40px]'></div> */}
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="flex flex-row items-center gap-[16px]">
            <div className="font-bold text-[12px]">
              SELECT A {mode === 'skill' ? 'COMPENTECY' : 'SKILL'} TO PAIR:
            </div>
            <div className="flex-1"></div>
            <div className="fort text-[10px] font-extralight tracking-[2px]">SCORE KEY:</div>
          </div>
          <div className="flex flex-row items-center mt-[8px]">
            <div className="flex flex-rwo items-center">
              <div className="flex flex-row items-center gap-[8px]">
                <OvalIconButton
                  size="small"
                  title="SORT"
                  textLight={true}
                  style={{
                    border: 'none',
                    minWidth: 0,
                    minHeight: 26,
                  }}
                  labelProps={{
                    paddingLeft: 0,
                  }}
                  icon={
                    <ChevronRightIcon
                      style={{
                        fontSize: '44px',
                        fontWeight: 100,
                      }}
                    />
                  }
                />
                <OvalIconButton
                  size="small"
                  title="GRADE"
                  textLight={true}
                  style={{
                    minWidth: 0,
                    minHeight: 26,
                  }}
                  icon={
                    <KeyboardArrowUpIcon
                      style={{
                        fontSize: '44px',
                        fontWeight: 100,
                      }}
                    />
                  }
                />
                <OvalIconButton
                  title="INCOMPLETE"
                  size="small"
                  textLight={true}
                  style={{
                    minWidth: 0,
                    minHeight: 26,
                  }}
                />
                <OvalIconButton
                  title="COMPLETE"
                  size="small"
                  textLight={true}
                  style={{
                    minWidth: 0,
                    minHeight: 26,
                  }}
                />
              </div>
            </div>
            <div className="flex-1"></div>
            <div className="sub-heading-white-stroke2 text-[25px]">FS/PC</div>
          </div>
          <div className="relative flex-1 overflow-hidden mt-[16px]">
            <div className="absolute top-0 left-0 bottom-0 right-0 overflow-y-auto list-scroll flex flex-row">
              <div className="flex flex-row flex-wrap  gap-[8px] p-[2px] justify-center m-auto">
                {pairList}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-[32px]">
        <div
          className="fort rounded-[16px] bg-white min-w-[240px] min-h-[100px] shadow-md text-black flex flex-col p-[16px] cursor-pointer"
          onClick={backToList}
        >
          <div className="flex-1 text-[14px]">BACK TO SKILLS LIST</div>
          <div className="flex flex-row items-center min-h-[38px]">
            <div className="rounded-full w-[28px] h-[28px] flex flex-row items-center justify-center border-currentColor border-[1px] text-[#555]">
              <ChevronLeftIcon />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
        {nextTarget && (
          <div
            className="fort rounded-[16px] bg-white min-w-[240px] min-h-[100px] shadow-md text-black flex flex-col p-[16px] cursor-pointer gap-[16px]"
            onClick={nextItemClick}
          >
            <div className="flex-1 text-[14px] leading-[16px]">
              <span>
                {nextTarget?.code}: {nextTarget?.title}
              </span>
              <br />
              <span className="text-[12px] font-extralight">{nextTarget?.subtitle}</span>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center flex-1 gap-[8px] text-black"></div>
              <div className="rounded-full w-[28px] h-[28px] flex flex-row items-center justify-center border-currentColor border-[1px] text-[#555]">
                <ChevronRightIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
