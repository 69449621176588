import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDirectories from 'hooks/student/useDirectories';

function ProfessionalDevelopmentRecordList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { list: folderList, getByPath } = useDirectories();
  const list = folderList.filter((item) => item.parentId === Number(searchParams.get('folderId')));

  const buttons = list.map((item) => ({
    id: item.id,
    uuid: item.uuid,
    title: item.name,
    onClick: () => navigate(`${item.path}?folderId=${encodeURIComponent(item.id)}`),
  }));
  const breadcrumbsItems = [
    {
      text: 'SELF WORTH',
      path: '/self-worth?folderId=' + getByPath('self-worth')?.id,
    },
  ];

  return (
    <div>
      <MainHeadingText>
        Professional
        <br />
        Development Record
      </MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText>{/* TODO need copy */}</DashboardContentText>
      <LandscapeCardListContainer>
        {buttons.map((item, index) => {
          return (
            <div key={index}>
              <LandscapeCard
                onClick={item.onClick}
                title={item.title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default ProfessionalDevelopmentRecordList;
