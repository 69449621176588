import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';

/**
 *
 * @param {import("react").HTMLAttributes & {
 *  title: string,
 *  message: string,
 *  buttons: Array<{
 *    text: string,
 *    onClick: () => void
 *  }>,
 *  onClose: () => void
 * }} param0
 * @returns
 */
export default function ConfirmModal({ title, message, buttons, onClose, ...props }) {
  return ReactDOM.createPortal(
    <div
      {...props}
      className="fort font-extralight text-white uppercase fixed top-0 left-0 bottom-0 right-0 z-[100] flex flex-row p-[16px]"
    >
      <div
        className="fixed top-0 left-0 bottom-0 right-0 z-100 bg-black/75 sb2-txt-fade"
        onClick={onClose}
      ></div>
      <div
        className="relative modal-bg min-h-[200px] w-full max-w-[540px] 
                      m-auto rounded-[16px] border-[2px] border-white flex 
                      flex-col sb2-txt-fade shadow"
      >
        <div className="min-h-[56px] flex flex-row items-center gap-[16px] px-[16px]">
          <div className="flex-1 font-normal text-[18px]">{title}</div>
          <button
            className="h-[24px] w-[24px] bg-white text-[#aaa] rounded-full 
                      flex items-center justify-center"
            onClick={onClose}
          >
            <CloseIcon style={{ fontSize: '1em' }} />
          </button>
        </div>
        <div className="flex-1 items-center p-[16px] text-center">{message}</div>
        <div className="flex flex-row items-center justify-center gap-[16px] px-[16px] mb-[16px]">
          {Array.isArray(buttons) &&
            buttons.map((item, index) => (
              <button
                key={index}
                onClick={item.onClick}
                className="flex-1 min-h-[40px] max-w-[60%] border-white border-[2px] flex flex-row items-center 
                          justify-center px-[16px] text-white rounded-[24px] min-w-[80px] fort hover:bg-white/25"
              >
                {item.text}
              </button>
            ))}
        </div>
      </div>
    </div>,
    document.body
  );
}
