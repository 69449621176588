import React from 'react';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import selfWorthBgImage from 'assets/images/svg/self-worth-bg.svg';
import engagementBgImage from 'assets/images/svg/engagement-bg.svg';
import purposeBgImage from 'assets/images/svg/purpose-bg.svg';
import useDirectories from 'hooks/student/useDirectories';

function TrainingMaterials() {
  const navigate = useNavigate();
  const { list: dirList } = useDirectories();
  const breadcrumbsItems = [
    {
      text: 'TRAINING MATERIALS',
      path: '/trainer/materials',
    },
  ];
  const buttons = [
    {
      title: 'Self worth',
      onClick: () => {
        const _path = dirList.find((item) => item.path === 'self-worth' && item.parentId === null);
        navigate('self-worth?folderId=' + _path.id);
      },
      bgComponent: (
        <div className="bg-gradient-to-r from-[#34467e] to-[#2d3f71] absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
          <img
            src={selfWorthBgImage}
            alt="bg-image"
            className="absolute bottom-0 right-[-26px] opacity-20"
          />
        </div>
      ),
      boxBorderWidth: 0,
      textColor: 'white',
      titleTextStyle: {
        fontSize: 28,
      },
    },
    {
      title: 'engagement',
      onClick: () => {
        const _path = dirList.find((item) => item.path === 'engagement' && item.parentId === null);
        navigate('engagement?folderId=' + _path.id);
      },
      bgComponent: (
        <div className="bg-gradient-to-r from-[#af3635] to-[#8f3132] absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
          <img
            src={engagementBgImage}
            alt="bg-image"
            className="absolute bottom-0 right-[-56px] opacity-50"
          />
        </div>
      ),
      textColor: 'white',
      boxBorderWidth: 0,
      titleTextStyle: {
        fontSize: 28,
      },
    },
    {
      title: 'purpose',
      onClick: () => {
        const _path = dirList.find((item) => item.path === 'purpose' && item.parentId === null);
        navigate('purpose?folderId=' + _path.id);
      },
      bgComponent: (
        <div className="bg-gradient-to-r from-[#14a1db] to-[#166a94] absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
          <img
            src={purposeBgImage}
            alt="bg-image"
            className="absolute bottom-0 right-[-0px] opacity-60"
          />
        </div>
      ),
      textColor: 'white',
      boxBorderWidth: 0,
      titleTextStyle: {
        fontSize: 28,
      },
    },
  ];
  return (
    <div className="w-full">
      <MainHeadingText>Training Materials</MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText>
        Area for uploading materials to particular groups or for individual students.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {buttons.map((item, index) => {
          const { title, onClick, ...otherProps } = item;
          return (
            <div key={index} onClick={item.onClick}>
              <LandscapeCard
                title={item.title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
                {...otherProps}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default TrainingMaterials;
