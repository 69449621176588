import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import KnowYourProfileChild from './KnowYourProfileChild';
// import SubRouteList from './SubRouteList';

export default function KnowYourProfileRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <Routes>
      <Route path=":path" element={<KnowYourProfileChild />} />
      {/* {SubRouteList.map((item, index) => {
        return <Route path={item.path} element={item.component} key={index} />;
      })} */}
    </Routes>
  );
}
