/**
 *
 * @param {import("react").HtmlHTMLAttributes &
 * {
 *  inputProps: import("react").InputHTMLAttributes,
 *  className: string
 * }} param0
 * @returns
 */
export default function OutlineInput({ className, inputProps = {}, ...props }) {
  const { className: inputClassName, ...otherInputProps } = inputProps;
  return (
    <div
      {...props}
      className={`font-extralight flex flex-row h-[44px] w-full rounded-[8px] border-[2px] border-white overflow-hidden ${
        typeof className === 'string' ? className : ''
      }`}
    >
      <input
        {...otherInputProps}
        className={`font-extralight bg-black/30 outline-none h-[40px] w-full px-[16px] box-border focus:bg-black/50 ${
          typeof inputClassName === 'string' ? inputClassName : ''
        }`}
      />
    </div>
  );
}
