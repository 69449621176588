import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import http from 'services/http';

export default function useAssignmentDocuments() {
  const [list, setList] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    getList(searchParams.get('folderId'));
  }, []);

  const getList = (dirId) => {
    // TODO fix typo of assignment on the backend api service
    http
      .post('/api/current-student-assigments', { dirId: Number(dirId) })
      .then((result) => {
        const _list = [...result.data.individual, ...result.data.group];
        // remove redundant
        const objList = {};
        for (const item of _list) {
          objList[item.materialId] = item;
        }

        // obj to array
        const toResponse = Object.keys(objList).map((key) => {
          return objList[key];
        });

        toResponse.sort((a, b) => {
          return a.fileName > b.fileName ? -1 : b.fileName > a.fileName ? 1 : 0;
        });

        toResponse.sort((a, b) => {
          return a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0;
        });
        setList(toResponse);
      })
      .catch((err) => alert(`${err.message}. Please inform website admin if this error persists`));
  };

  return { list, getList };
}
