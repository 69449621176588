import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import StrokeText from 'components/UI/atoms/StrokeText';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import useEvidenceToReview, {
  EvidenceToReviewProvider,
} from 'hooks/trainer/EvidenceRecord/useEvidenceToReview';
import EvidenceToReviewItem from 'components/UI/molecules/Trainer/PendingAssessment/EvidenceToReviewItem';
import PendingAssessmentDocumentCard from 'components/UI/molecules/PendingAssessmentDocumentCard';
import useRecentAssessmentReviewed from 'hooks/trainer/useRecentAssessmentReviewed';

function PendingAssessments() {
  return (
    <EvidenceToReviewProvider>
      <PendingAssignments_ />
    </EvidenceToReviewProvider>
  );
}

function PendingAssignments_() {
  const { list, fileList } = useEvidenceToReview();
  const { list: recentList } = useRecentAssessmentReviewed();

  const breadcrumbsItems = [
    {
      text: 'PENDING ASSESSMENTS',
      path: '/trainer/assessments',
    },
  ];
  // const assignmentDocuments = [];
  // for (let i = 1; i <= 5; i += 1) {
  //   assignmentDocuments.push({
  //     title: `Assignment Document ${i}`,
  //     onClick: () => {
  //       alert(`Document ${i} Name`);
  //     },
  //   });
  // }

  return (
    <div>
      <MainHeadingText>
        PENDING
        <br />
        ASSESSMENTS
      </MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentHeading>RECENTLY VIEWED ASSESSMENTS</DashboardContentHeading>
      {recentList.length > 0 ? (
        <DocumentCardContainer>
          {recentList.map((item, index) => (
            <PendingAssessmentDocumentCard
              data={item}
              key={index}
              onClick={() => {}}
              downloadHandler={() => {}}
            />
          ))}
        </DocumentCardContainer>
      ) : (
        <div className="flex flex-row mt-[28px]">
          <StrokeText className="text-[36px] max-w-[720px]">
            No <br /> Assessments <br /> Recently Viewed
          </StrokeText>
        </div>
      )}
      <div className="h-[56px]" />
      <DashboardContentHeading className="mt-[72px]">EVIDENCE TO REVIEW</DashboardContentHeading>
      <div className={'bg-white rounded-[12px] shadow-md py-[16px] max-w-[500px]'}>
        <div className="max-h-[160px] list-scroll overflow-y-auto">
          {list.length > 0 ? (
            list.map((item) => (
              <EvidenceToReviewItem data={item} key={item.id} />
              // <div key={item.id}>{item.studentName}</div>
            ))
          ) : (
            <div className="p-[16px]">
              <StrokeText className="max-w-[720px]" style={{ fontSize: 24 }}>
                No Evidence To Review
              </StrokeText>
            </div>
          )}
        </div>
      </div>
      <DashboardContentText>
        <strong>View and grade all projects and assignments which are pending review.</strong>
      </DashboardContentText>
      {/* <DashboardContentText>
        Submissions can be sorted for example by class or group.
      </DashboardContentText> */}
      <div className="h-[56px]" />
      <DashboardContentHeading>
        CURRENT PENDING ASSESSMENTS: {fileList.length}
      </DashboardContentHeading>
      <DocumentCardContainer>
        {fileList.length > 0 ? (
          fileList.map((item, index) => (
            <PendingAssessmentDocumentCard
              data={item}
              key={index}
              onClick={item.onClick}
              downloadHandler={() => {}}
            />
          ))
        ) : (
          <div className="flex flex-row">
            <StrokeText className="text-[36px] max-w-[720px]">
              No Pending <br /> Assessments
            </StrokeText>
          </div>
        )}
      </DocumentCardContainer>
    </div>
  );
}

export default PendingAssessments;
