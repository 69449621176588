import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from 'components/UI/organisms/DashboardLayout';
import Trainer from '.';

import TrainingMaterials from 'pages/common/TrainingMaterials';
import Profile from './Profile';
// Common pages.
import ResourceAcademyRoute from 'pages/common/ResourceAcademy';
import AssignGroup from 'pages/common/AssignGroup';
import EvidenceRecordRoutes from 'pages/common/EvidenceRecord/EvidenceRecordRoutes';
import SearchStudent from 'pages/common/SearchStudent';
import MaterialsSubRoutes from 'pages/common/TrainingMaterialsSubRoutes';
// Exclusive pages
import PendingAssessments from 'pages/common/PendingAssessments';

function TrainerRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <DashboardLayout>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <div className="w-full">
                <Trainer />
              </div>
            }
          />
          <Route path="/groups" element={<AssignGroup />} />
          <Route path="/profile" element={<Profile />} />
          {/* COMMENT: This is not actually how to do it. but as 
          TODO: Restructure this as what REACT intented so we can reuse the code */}
          <Route path="/materials" element={<TrainingMaterials />} />
          {/* Additional TODO: This is student routes. Extend these pages for trainer use */}
          <Route path="/materials/*" element={<MaterialsSubRoutes />} />
          {/* Comment: This is somehow correct way to do it */}
          <Route path={'/evidence/*'} element={<EvidenceRecordRoutes />} />
          <Route path="/assessments/*" element={<PendingAssessments />} />
          <Route path="/search" element={<SearchStudent />} />
          <Route path="/resources" element={<ResourceAcademyRoute />} />
        </Routes>
      </div>
    </DashboardLayout>
  );
}

export default TrainerRoutes;
