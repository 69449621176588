import InfoIcon from '@mui/icons-material/Info';
import StrokeText from '../atoms/StrokeText';
import Tooltip from './AedTooltip';

/**
 * @param {import("react").HTMLAttributes} param0
 * @returns
 */
export default function EvidenceScoreItem({
  title,
  text,
  onClick,
  equivalent,
  description,
  ...props
}) {
  return (
    <div
      className="eh-item text-white min-w-[180px] border-r-[1px] border-black/20 mr-[32px] pr-[32px] fort"
      {...props}
    >
      <div className="relative eh-item-title text-black text-[12px] tracking-[1px]">
        {title}{' '}
        <Tooltip title={equivalent} description={description}>
          <InfoIcon
            style={{
              fontSize: '16px',
              fontWeight: 100,
              color: '#aaa',
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </div>
      <StrokeText className="text-[72px] mt-[16px]">{text}</StrokeText>
    </div>
  );
}
