import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { doLogin } from 'redux/reducers/auth';
import useSessionTimeout from 'hooks/admin/useSessionTimeout';
import * as yup from 'yup';
import { getRemember, getRememberCheckStatus, setRemember } from 'config/remember-me';

function useLogin() {
  const { setSessionTimeout } = useSessionTimeout();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.isLoading);
  const errMessage = useSelector((state) => state.auth.errMessage);

  useEffect(() => {
    if (errMessage !== 'null') {
      setToast({
        status: true,
        message: errMessage,
      });
    }
  }, [errMessage]);

  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: getRemember()?.email || '',
    password: getRemember()?.password || '',
  });

  const [error, setError] = useState({
    errStatus: '',
    errMessage: '',
  });
  const [toast, setToast] = useState({
    status: false,
    message: '',
  });
  const onEmailChange = (event) => {
    setError('');
    setValues({ ...values, email: event.target.value });
  };

  const onPasswordChange = (event) => {
    setError('');
    setValues({ ...values, password: event.target.value });
  };
  const onForgotPassword = () => {
    navigate('/forgot-password');
  };
  const onEnrollUser = () => {
    navigate('/enroll');
  };

  const schema = yup.object({
    email: yup.string().email('Email is not valid!').required('Email is required!'),
    password: yup.string().required('Password is required!'),
  });

  const onSubmit = async () => {
    const isValid = await schema.isValid(values);
    schema.validate(values).catch(function (err) {
      if (err.message.includes('Email')) {
        setError({
          errStatus: 'email',
          errMessage: err.message,
        });
      }
      if (err.message.includes('Password')) {
        setError({
          errStatus: 'password',
          errMessage: err.message,
        });
      }
    });
    if (isValid) {
      const result = await dispatch(doLogin({ values })).unwrap();

      if (result.response.token) {
        if (result?.response?.twoFARequired) {
          setSessionTimeout();
          navigate('/two-factor-auth');
        } else {
          navigate('/student');
          setToast({
            status: true,
            message: 'Login Successful',
          });
        }

        if (getRememberCheckStatus() === true) setRemember(values);

        if (result.response.data && result.response.data.usertype) {
          switch (result.response.data.usertype) {
            case 'admin':
              navigate('/admin');

              setSessionTimeout();
              break;
            case 'student':
              navigate('/student');
              break;
            case 'teacher':
              navigate('/trainer');
              break;
            default:
              navigate('/');
          }
          setToast({
            status: true,
            message: 'Login Successful',
          });
          setValues({
            email: '',
            password: '',
          });
          setError({
            errStatus: '',
            errMessage: '',
          });
        }
      } else {
        setToast({
          status: true,
          message: errMessage,
        });
      }
    }
  };
  return {
    values,
    onEmailChange,
    onPasswordChange,
    onForgotPassword,
    onEnrollUser,
    onSubmit,
    error,
    toast,
    setToast,
    loading,
  };
}

export default useLogin;
