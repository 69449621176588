import React from 'react';
import { Typography } from '@mui/material';
import Toast from '../atoms/Toast';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ResetPasswordForm from '../molecules/ResetPasswordForm';
import useResetPassword from 'hooks/auth/useResetPassword';

function ResetPasswordComponent() {
  const {
    values,
    onPasswordChange,
    onConfirmPasswordChange,
    onSubmit,
    error,
    loading,
    toast,
    setToast,
  } = useResetPassword();
  return (
    <div>
      <Toast
        onClose={() =>
          setToast({
            status: false,
            message: '',
          })
        }
        toast={toast}
      />
      <div
        className="pb-5 w-200 flex flex-col items-center pt-5 px-9  shadow 
      bg-transparent border-2 border-white rounded-[16px] bg-white/20"
      >
        <AccountCircleOutlinedIcon
          style={{
            color: 'white',
          }}
        />
        <Typography variant="p" className="text-white text-[20px] font-bold pb-1 pt-3 uppercase">
          Enter new password
        </Typography>
        <div className="text-center">
          <p className="text-white font-bold text-[16px] pb-1 pt-3  uppercase ">
            PASSWORD MUST CONTAIN:
          </p>
          <p
            className={`${
              error?.errMessage?.includes('small') ? 'text-[#f00]' : 'text-white'
            } text-[11px] font-bold pb-1 pt-3  uppercase`}
          >
            A LOWER CASE LETTER
          </p>
          <p
            className={`${
              error?.errMessage?.includes('capital') ? 'text-[#f00]' : 'text-white'
            } font-bold text-[11px] pb-1 pt-3  uppercase`}
          >
            AN UPPERCASE LETTER
          </p>
          <p
            className={`${
              error?.errMessage?.includes('number') || error?.errMessage?.includes('special')
                ? 'text-[#f00]'
                : 'text-white'
            } font-bold text-[11px] pb-1 pt-3  uppercase`}
          >
            A NUMBER AND SPECIAL CHARACTER
          </p>
          <p
            className={`${
              error?.errMessage?.includes('short') ? 'text-[#f00]' : 'text-white'
            } font-bold text-[11px] pb-1 pt-3  uppercase`}
          >
            MINIMUM OF 8 CHARACTERS
          </p>
        </div>
        <ResetPasswordForm
          onPasswordChange={onPasswordChange}
          onConfirmPasswordChange={onConfirmPasswordChange}
          errMessage={error.errMessage}
          values={values}
          onSubmit={onSubmit}
          errStatus={error.errStatus}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ResetPasswordComponent;
