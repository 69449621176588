const RadioScoreButton = ({ name, id, value, onChange, checked, text }) => {
  return (
    <div
      className={
        'cursor-pointer fort font-normal w-[86px] h-[86px] flex flex-row items-center justify-center text-[40px] ' +
        (checked ? 'bg-white text-sky-500' : 'bg-white/50 sub-heading-white-stroke2')
      }
    >
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        className="hidden cursor-pointer"
      />
      <label
        htmlFor={id}
        className="cursor-pointer w-[86px] h-[86px] flex flex-row items-center justify-center block"
      >
        {text}
      </label>
    </div>
  );
};

export default RadioScoreButton;
