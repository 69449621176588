// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
export default function TagPicker({
  mainTag = {},
  tags = [],
  selected = [],
  onClick = () => {},
  fromModal = false,
}) {
  if (tags.length === 0) return '';
  return (
    <div className={`flex flex-wrap items-center gap-2 mb-4 ${fromModal ? 'text-white' : ''}`}>
      {/* <div className={`mr-4 fort font-thin flex items-center`}>
        <span>{mainTag.title || 'Tag'}</span>
        <ChevronRightIcon
          color={fromModal ? 'white' : 'inherit'}
          style={{
            fontSize: '18px',
          }}
        />
      </div> */}
      {/* TODO: Do the filters later */}
      {tags
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((t, k) => (
          <span
            key={k}
            className={`fort font-extralight flex flex-row items-center justify-center uppercase rounded-full px-2 py-1 h-[26px] text-[10pt] whitespace-nowrap ${
              selected.indexOf(t.id) >= 0
                ? 'text-[#00A7E1] border-2 border-[#00A7E1]'
                : `border ${fromModal ? 'border-white' : 'border-[#777]'}`
            } cursor-pointer`}
            onClick={() => onClick(t.id, mainTag?.asSearch)}
          >
            {t.title || t.name || `Tag ${k}`}
          </span>
        ))}
    </div>
  );
}
