import localstorage from 'services/localstorage';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from 'services/http';

export const doLogin = createAsyncThunk('auth/login', async (data) => {
  const response = await (await http.post(`/login`, data.values)).data;

  return {
    response,
  };
});
export const do2fa = createAsyncThunk('auth/2fa', async (data, { rejectWithValue }) => {
  try {
    const response = await http.post(`/2fa`, data.values);
    return { response: response.data };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const doResetPassword = createAsyncThunk('auth/resetPassword', async (data) => {
  const response = await await http.post(
    `/password-reset?token=${data?.values?.token}`,
    data.values
  );
  return {
    response,
  };
});

const initialState = {
  user: localstorage.get('user'),
  isUserLogin: true,
  temp: '',
  isLoading: false,
  errState: false,
  errMessage: 'null',
  toast: {
    status: false,
    message: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.user = null;
      localstorage.remove('token');
      localstorage.remove('user');

      // Handle session timeout
      let sessionTimeout = localstorage.get('timeout');
      if (sessionTimeout.success) {
        try {
          sessionTimeout = JSON.parse(sessionTimeout.value);
        } catch (err) {}
      }
      localstorage.remove('timeout');
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [doLogin.pending]: (state, action) => {
      state.isLoading = true;
      state.errMessage = 'null';
    },
    [doLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errState = false;
      state.errMessage = 'null';
      if (action?.payload?.response?.twoFARequired) {
        state.temp = action.payload.response;
      } else {
        state.user = action.payload.response.data;
        localstorage.set('user', JSON.stringify(action.payload.response.data));
        localstorage.set('token', action.payload.response.token);
      }
    },
    [doLogin.rejected]: (state, action) => {
      state.user = {};
      state.errState = true;
      state.errMessage = action.error.message;
      state.isLoading = false;
    },
    [do2fa.pending]: (state, action) => {
      state.isLoading = true;
      state.errMessage = 'null';
    },
    [do2fa.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errState = false;
      state.errMessage = 'null';
      state.user = action?.payload?.response?.data;
      localstorage.set('user', JSON.stringify(action.payload.response.data));
      localstorage.set('token', action.payload.response.token);
    },
    [do2fa.rejected]: (state, action) => {
      state.user = {};
      state.errMessage = action.payload.message;
      state.errState = true;
      state.isLoading = false;
    },

    [doResetPassword.pending]: (state, action) => {
      state.isLoading = true;
      state.errMessage = 'null';
    },
    [doResetPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errState = false;
      state.errMessage = 'null';
    },
    [doResetPassword.rejected]: (state, action) => {
      state.errState = true;
      state.errMessage = action.error.message;
      state.isLoading = false;
    },
  },
});

export const { login, logout, setUser } = authSlice.actions;

export default authSlice.reducer;
