import { Typography } from '@mui/material';
import bgImage from '../../assets/images/common/login-bg.jpg';
import React from 'react';
import logo from '../../assets/images/common/logo.svg';
import OTPComponent from 'components/UI/organisms/OTPComponent';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Otp() {
  const { temp: is2faRequired } = useSelector(({ auth }) => auth);

  if (!is2faRequired?.token) return <Navigate to={'/login'} replace />;

  return (
    <>
      <div
        className="fixed top-0 left-0 bottom-0 right-0 opacity-30"
        style={{
          backgroundImage: `url('${bgImage}')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      ></div>
      <div
        className={`fixed top-0 left-0 bottom-0 right-0 bg-gradient-to-t from-sky-500/80 to-sky-900/80`}
      ></div>
      <div className={`relative pb-10 flex min-h-screen justify-center items-center`}>
        <div className="flex items-center  flex-col mt-12">
          <img src={logo} alt="logo" />
          <Typography
            variant="p"
            className="text-transparent fort text-[72px] text-center 
            tracking-widest pt-5 pb-14 text-white/30 stroke-text-white"
          >
            AED TWO-FACTOR AUTHENTICATION
          </Typography>
          <OTPComponent token={is2faRequired?.token} />
        </div>
      </div>
    </>
  );
}

export default Otp;
