import React from 'react';

function DashboardFooter() {
  const year = new Date().getFullYear();

  return (
    <div className="flex flex-row items-center justify-between mt-[100px] px-[32px] bg-white min-h-[56px] box-border">
      <p className="workSans-bold text-[14px] text-black uppercase">copyright {year} aspirations</p>
      <p className="workSans-bold text-[14px] text-black uppercase">privacy | terms of service</p>
    </div>
  );
}

export default DashboardFooter;
