import React, { useEffect } from 'react';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import { AssignResources } from 'components/UI/organisms/AssignResources';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';
import useUser from 'hooks/auth/useUser';

function ResourceAcademyRoute() {
  const { user } = useUser();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const breadcrumbsItems = [
    {
      text: 'RESOURCES ACADEMY',
      path: '/trainer/resources-academy',
    },
  ];

  return (
    <div>
      <MainHeadingText>Resources Academy</MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText className="mt-[40px]">
        <strong>Library of searchable Aspirations documents.</strong>
      </DashboardContentText>
      <DashboardContentText className="mt-[40px]">
        {user.userTypeId === 1 && (
          <>
            Only staff identified as Admin can upload resources, teachers and trainers are able to
            assign to students. Please ‘tag’ content and subject to support others when searching in
            this area.
          </>
        )}
        {/* {user.userTypeId === 2 && (
          <>
            Students navigate this through their version of the dossier by cross-referencing a
            competency, they then submit work for those two selections. Through the student access
            dossier, students can also view the overview of their progress with a visual colour
            coded chart of graded and incomplete submissions.
          </>
        )} */}
      </DashboardContentText>
      <div className="w-full my-[24px]">
        <DashboardContentHeading>RESOURCE SEARCH:</DashboardContentHeading>
        <AssignResources />
      </div>
      <UploadModalAsPanel />
    </div>
  );
}

export default ResourceAcademyRoute;
