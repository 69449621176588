export default function TrainingMaterialsIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 20.948 20.948"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1577"
            data-name="Rectangle 1577"
            width="20.948"
            height="20.948"
            fill="none"
            stroke="#989aa4"
            strokeWidth="0.5"
          />
        </clipPath>
      </defs>
      <g id="Group_2169" data-name="Group 2169" transform="translate(0 0)">
        <g
          id="Group_2169-2"
          data-name="Group 2169"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <circle
            id="Ellipse_776"
            data-name="Ellipse 776"
            cx="10.214"
            cy="10.214"
            r="10.214"
            transform="translate(0.26 0.26)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1016"
            data-name="Path 1016"
            d="M23.327,19.189H16.191V9.77h5.994l1.142,1.142Z"
            transform="translate(-9.091 -5.486)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1017"
            data-name="Path 1017"
            d="M29.191,9.77v1.42h1.347"
            transform="translate(-16.391 -5.486)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1018"
            data-name="Path 1018"
            d="M13.255,22.158l-2.444.282L9.732,13.083l2.049-.236"
            transform="translate(-5.465 -7.214)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1019"
            data-name="Path 1019"
            d="M19.815,23.045l-.09.938,7.172.657.859-9.38-1.591-.146"
            transform="translate(-11.076 -8.487)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_391"
            data-name="Line 391"
            x2="3.711"
            transform="translate(8.812 7.995)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_392"
            data-name="Line 392"
            x2="3.711"
            transform="translate(8.812 9.136)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_393"
            data-name="Line 393"
            x2="2.569"
            transform="translate(8.812 10.278)"
            fill="none"
            stroke="#989aa4"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
