import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HandHeartIcon from '../atoms/CustomIcons/HandHeartIcon';
import TwoHandsIcon from '../atoms/CustomIcons/TwoHandsIcon';
import PurposeIcon from '../atoms/CustomIcons/PurposeIcon';
import TrainingMaterialsIcon from '../atoms/CustomIcons/TrainingMaterialsIcon';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationPopper from './NavigationPopper';
import { Button as ButtonUnstyled } from '@mui/base';

export const ListGrandChildren = ({ levelTwo, path }) => {
  const [openLevelTwo, setOpenLevelTwo] = useState(false);
  const navigate = useNavigate();

  const onExpand = (event) => {
    event.stopPropagation();
    setOpenLevelTwo(!openLevelTwo);
  };

  return (
    <List component="div" disablePadding sx={{ color: '#8e9193' }}>
      <ListItemButton
        className="hover:text-white active:text-white workSans-medium mx-auto
                    tracking-[1.5px] text-[12px] uppercase"
        // sx={{ borderBottom: '1px solid #ffffff33', paddingLeft: '50px' }}
        sx={{
          borderBottom: '1px solid #ffffff33',
          padding: '16px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
        onClick={() => navigate(`${path}/${levelTwo.path}?folderId=${levelTwo.id}`)}
      >
        <ListItemText
          className={openLevelTwo ? 'text-white' : ''}
          primary={levelTwo.name}
          disableTypography={true}
        />
        {levelTwo.children?.length > 0 &&
          (openLevelTwo ? <ExpandLess onClick={onExpand} /> : <ExpandMore onClick={onExpand} />)}
      </ListItemButton>
      {levelTwo.children?.length > 0 && (
        <Collapse
          // sx={{ backgroundColor: '#ffffff11' }}
          in={openLevelTwo}
          timeout="auto"
          unmountOnExit
        >
          {levelTwo.children?.map((levelThree) => {
            return (
              <List key={levelThree.id} component="div" disablePadding>
                <ListItemButton
                  className="hover:text-white active:text-white workSans-medium mx-auto
                  tracking-[1.5px] text-[12px] uppercase"
                  sx={{
                    borderBottom: '1px solid #ffffff33',
                    padding: '16px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  }}
                  onClick={() =>
                    navigate(
                      `${path}/${levelTwo.path}/${levelThree.path}?folderId=${levelThree.id}`
                    )
                  }
                >
                  <ListItemText primary={levelThree.name} disableTypography={true} />
                </ListItemButton>
              </List>
            );
          })}
        </Collapse>
      )}
    </List>
  );
};

export const ListChildren = ({ levelOne, path, withBorder = false }) => {
  const [openLevelOne, setOpenLevelOne] = useState(false);
  const navigate = useNavigate();

  const onExpand = (event) => {
    event.stopPropagation();
    setOpenLevelOne(!openLevelOne);
  };

  return (
    <List component="div" disablePadding sx={{ color: '#8e9193' }}>
      <ListItemButton
        className="hover:text-white active:text-white block mx-auto
          workSans-medium tracking-[1.5px] text-[12px] uppercase
          w-full"
        // sx={{ borderBottom: '1px solid #ffffff33', paddingLeft: '50px' }}
        sx={{
          ...(withBorder && {
            borderTop: '1px solid #ffffff33',
          }),
          borderBottom: '1px solid #ffffff33',
          padding: '16px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
        onClick={() => navigate(`${path}/${levelOne.path}?folderId=${levelOne.id}`)}
      >
        <ListItemText
          className={`flex-1 ${openLevelOne ? 'text-white' : ''}`}
          disableTypography={true}
          primary={levelOne.name}
        />
        {levelOne.children?.length > 0 &&
          (openLevelOne ? <ExpandLess onClick={onExpand} /> : <ExpandMore onClick={onExpand} />)}
      </ListItemButton>
      {levelOne.children?.length > 0 && (
        <Collapse
          // sx={{ backgroundColor: '#ffffff11' }}
          in={openLevelOne}
          timeout="auto"
          unmountOnExit
        >
          {levelOne.children?.map((levelTwo) => {
            return (
              <ListGrandChildren
                key={levelTwo.id}
                levelTwo={levelTwo}
                path={path + `/${levelOne.path}`}
              />
            );
          })}
        </Collapse>
      )}
    </List>
  );
};

export default function NavigationNestedList({ navItem, isSidebarOpen }) {
  const [open, setOpen] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const navigate = useNavigate();
  const elemRef = useRef();

  const onExpand = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const onMouseHover = () => {
    if (!isSidebarOpen) {
      setIsPopover(true);
    }
  };

  const _className = `transition-all duration-200${
    isPopover && ' bg-white/10'
  } text-[#9a9ca5] hover:text-white w-full flex flex-row items-center justify-center hover:bg-white/10 min-h-[56px] m-auto cursor-pointer rounded-[12px]`;

  return (
    <List sx={{ padding: '0px' }} component="nav" aria-labelledby="nested-list-subheader">
      <ButtonUnstyled
        ref={elemRef}
        sx={{
          paddingLeft: '0px',
        }}
        className={_className}
        onClick={() => navigate(`${navItem.path}?folderId=${navItem.id}`)}
        onMouseEnter={onMouseHover}
        onMouseLeave={() => setIsPopover(false)}
      >
        {navItem.name === 'Training Materials' ? (
          <ListItemIcon
            style={{
              width: isSidebarOpen && '72px',
              color: isPopover ? '#ffffff' : '#9a9ca5',
              justifyContent: 'center',
            }}
          >
            <TrainingMaterialsIcon size={28} />
          </ListItemIcon>
        ) : navItem.name === 'Self Worth' ? (
          <ListItemIcon
            style={{ color: isPopover ? '#ffffff' : '#9a9ca5', justifyContent: 'center' }}
          >
            <HandHeartIcon size={28} />
          </ListItemIcon>
        ) : navItem.name === 'Engagement' ? (
          <ListItemIcon
            style={{ color: isPopover ? '#ffffff' : '#9a9ca5', justifyContent: 'center' }}
          >
            <TwoHandsIcon size={28} />
          </ListItemIcon>
        ) : navItem.name === 'Purpose' ? (
          <ListItemIcon
            style={{ color: isPopover ? '#ffffff' : '#9a9ca5', justifyContent: 'center' }}
          >
            <PurposeIcon size={28} />
          </ListItemIcon>
        ) : (
          ''
        )}
        {isSidebarOpen && (
          <div
            style={{ textAlign: 'start' }}
            className={`sb2btn-txt uppercase sb2-txt-fade flex-1 workSans-medium overflow-hidden tracking-[1.5px] text-[14px] ${
              open && 'text-white'
            } hover:text-sky-500 hover:border-sky-500`}
          >
            {navItem.name}
          </div>
        )}
        {navItem.children?.length > 0 &&
          isSidebarOpen &&
          (open ? <ExpandLess onClick={onExpand} /> : <ExpandMore onClick={onExpand} />)}
      </ButtonUnstyled>
      {navItem.children?.length > 0 && isSidebarOpen ? (
        <Collapse
          sx={{
            backgroundColor: '#ffffff22',
            maxHeight: '290px',
            overflowY: 'auto',
            padding: '24px',
            marginTop: '16px',
          }}
          className="list-scroll-w"
          in={open}
          timeout="auto"
          unmountOnExit
        >
          {navItem.children?.map((levelOne, index) => (
            <ListChildren
              key={levelOne.id}
              path={navItem.path}
              levelOne={levelOne}
              isSidebarOpen={isSidebarOpen}
              itemIndex={index}
              withBorder={index === 0}
            />
          ))}
        </Collapse>
      ) : (
        <NavigationPopper isPopover={isPopover} setIsPopover={setIsPopover} elem={elemRef?.current}>
          {navItem.children?.map((levelOne, index) => (
            <ListChildren
              key={levelOne.id}
              path={navItem.path}
              levelOne={levelOne}
              isSidebarOpen={isSidebarOpen}
              withBorder={index === 0}
            />
          ))}
        </NavigationPopper>
      )}
    </List>
  );
}
