import React, { useEffect } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import useSkillList from 'hooks/student/useSkillList';
import useDirectories from 'hooks/student/useDirectories';

function SelfWorth() {
  const navigate = useNavigate();
  // const loc = useLocation();
  const [searchParams] = useSearchParams();
  const { list: folderList, getByPath } = useDirectories();
  const list = folderList.filter(
    (item) => Number(item.parentId) === Number(searchParams.get('folderId'))
  );

  const buttons = list.map((item) => ({
    id: item.id,
    uuid: item.uuid,
    title: item.name,
    onClick: () => navigate(`${item.path}?folderId=${item.id}`),
  }));
  const breadcrumbsItems = [
    {
      text: 'SELF WORTH',
      path: '/self-worth?folderId=' + getByPath('self-worth')?.id,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainHeadingText>self worth</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText>
        Self-Worth begins when we experience a sense of Belonging : We feel like we are part of the
        school community while being recognised and appreciated for our uniqueness. We also
        experience Self-Worth when someone in our lives believes in us. We need Heroes: people we
        can look up to, respect, and learn from. To develop Self-Worth, we also need a Sense of
        Accomplishment. We must be recognised as much for our effort, perseverance, and citizenship
        as we are for high grades and good test scores. As we build Self-Worth, we are more likely
        to persevere through difficult tasks and be inspired to take the steps needed to reach our
        goals.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {buttons.map((item, index) => {
          return (
            <div key={index}>
              <LandscapeCard
                onClick={item.onClick}
                title={item.title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default SelfWorth;
