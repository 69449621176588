import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  list: [],
  keyValList: {},
  page: 1,
  pageCount: 1,
  limit: 25,
  totalCount: 0,
};

const studentProjectsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setState: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
    setList: (state, action) => {
      // this will replace all existing list
      state.list = action.payload;
      const keyValList = {};
      for (const item of action.payload) {
        keyValList[item.uuid] = item;
      }
      state.keyValList = keyValList;
    },
    appendList: (state, action) => {
      // this will NOT replace all existing list, it appends new items
      const keyValList = {};
      for (const item of action.payload) {
        keyValList[item.uuid] = item;
      }
      state.keyValList = { ...keyValList };

      /*
       * to avoid duplicates, list will be replaced
       * and generate new list based on the keyValList object
       */
      const newList = [];
      for (const key in state.keyValList) {
        newList.push(state.keyValList[key]);
      }
      state.list = newList;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setState, setList, setLoading } = studentProjectsSlice.actions;

export default studentProjectsSlice.reducer;
