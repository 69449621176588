import { createContext, useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import http from 'services/http';
import CircularProgress from '@mui/material/CircularProgress';
import useUser from 'hooks/auth/useUser';
import logger from 'utils/logger';

/**
 * @typedef {Object} EvidenceGrade
 * @prop {string} comments
 * @prop {number} competencyScore
 * @prop {string} createdAt
 * @prop {number} ecsId
 * @prop {string} ecsTitle
 * @prop {string} fsDescription
 * @prop {number} fsId
 * @prop {string} fsTitle
 * @prop {number} id
 * @prop {string} pcDescription
 * @prop {number} pcId
 * @prop {string} pcTitle
 * @prop {number} skillScore
 * @prop {number} studentId
 * @prop {string} studentName
 * @prop {number} teacherId
 * @prop {string} teacherName
 * @prop {string} updatedAt
 * @prop {string} uuid
 */

/**
 * @typedef {Object} StudentUpload
 * @prop {string} createdAt
 * @prop {string} fileExt
 * @prop {string} fileName
 * @prop {string} filePath
 * @prop {string} fsDescription
 * @prop {number} fsId
 * @prop {string} fsTitle
 * @prop {number} id
 * @prop {number} materialId
 * @prop {string} materialUUID
 * @prop {string} pcDescription
 * @prop {number} pcId
 * @prop {string} pcTitle
 * @prop {string} updatedAt
 * @prop {string} userFullName
 * @prop {number} userId
 * @prop {number} userTypeId
 * @prop {string} userTypeName
 * @prop {string} uuid
 * @prop {boolean} isGraded
 */

export const EvidenceRecordContext = createContext();

export const Actions = {
  SET_STUDENT: 'set-student',
  SET_LOADING: 'set-loading',
  SET_ERROR: 'set-error',
  SET_ERROR_MESSAGE: 'set-error-message',
  SET_FS_ID: 'set-fsId',
  SET_PC_ID: 'set-pcId',
  SET_TEACHER_ID: 'set-teacherId',
  SET_SKILL_LIST: 'set-skillList',
  SET_COMPETENCY_LIST: 'set-competencyList',
  SET_EVIDENCE_GRADES: 'set-evidenceGrades',
  SET_STUDENT_UPLOADS: 'set-studentUploads',
  SET_STUDENT_SELECTED_UPLOADS: 'set-studentSelectedUploads',
  SET_OVERALL_ASSESSMENT: 'set-overallAssessment',
  SET_EVIDENCE_SKILL_SCORE: 'set-evidenceSkillsScore',
  SET_EVIDENCE_COMPETENCY_SCORE: 'set-evidenceCompetenciesScore',
  SET_ALL: 'set-all',
};

export const initialState = {
  student: null,
  isLoading: true,
  isError: false,
  error: '',
  fsId: null,
  pcId: null,
  teacherId: null,
  skillList: [],
  competencyList: [],
  evidenceGrades: [],
  studentUploads: [],
  selectedUploadsOnly: [],
  evidenceSkillsScore: 0,
  evidenceCompetenciesScore: 0,
  overallAssessment: 0,
  evidenceToReview: [],
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case Actions.SET_ALL:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export function EvidenceRecordProvider({ children }) {
  const params = useParams();
  const { user } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * @param {EvidenceGrade[]} grades
   */
  const getFSPoints = (grades) => {
    const fsSet = new Set();
    for (const item of grades) {
      const _score = item.skillScore + item.competencyScore;
      if (_score > 0) {
        fsSet.add(item.fsId);
      }
    }
    return fsSet.size;
  };

  /**
   * @param {EvidenceGrade[]} grades
   */
  const getPCPoints = (grades) => {
    const pcSet = new Set();
    for (const item of grades) {
      const _score = item.skillScore + item.competencyScore;
      if (_score > 0) {
        pcSet.add(item.pcId);
      }
    }
    return pcSet.size;
  };

  const getHighlightScores = (grades) => {
    if (!Array.isArray(grades))
      return {
        overallAssessment: 0,
        evidenceSkillsScore: 0,
        evidenceCompetenciesScore: 0,
      };
    let _overall = 0;
    for (const gItem of grades) {
      _overall += gItem.competencyScore + gItem.skillScore;
    }

    return {
      overallAssessment: Math.round((_overall / 114) * 100),
      evidenceSkillsScore: getFSPoints(grades),
      evidenceCompetenciesScore: getPCPoints(grades),
    };
  };

  useEffect(() => {
    dispatch({
      type: Actions.SET_ALL,
      payload: { teacherId: user.userId },
    });
    (async () => {
      try {
        const userResults = await http.post('/api/users/search', { uuid: params.uuid });
        if (userResults.data.list.length === 0) throw new Error('Student not found');
        const evidenceSkillsResults = await http.post('/api/evidence-skills/search');
        const evidenceCompetencyResults = await http.post('/api/evidence-competency/search');
        const evidenceGradesResult = await getEvidenceGrades(
          userResults.data.list[0].userId,
          false
        );
        const studentUploadsResult = await getStudentSkillUploads({
          userId: userResults.data.list[0].userId,
        });
        const _toReview = getEvidenceToReview(
          studentUploadsResult?.data?.list,
          evidenceGradesResult.data.list
        );
        const { overallAssessment, evidenceSkillsScore, evidenceCompetenciesScore } =
          getHighlightScores(evidenceGradesResult.data.list);
        dispatch({
          type: Actions.SET_ALL,
          payload: {
            student: userResults.data.list[0],
            skillList: evidenceSkillsResults.data.list,
            competencyList: evidenceCompetencyResults.data.list,
            evidenceGrades: evidenceGradesResult.data.list,
            studentUploads: studentUploadsResult?.data?.list,
            overallAssessment,
            evidenceSkillsScore,
            evidenceCompetenciesScore,
            evidenceToReview: _toReview,
            isLoading: false,
            isError: false,
          },
        });
      } catch (err) {
        dispatch({
          type: Actions.SET_ALL,
          payload: {
            student: null,
            isLoading: false,
            isError: true,
            error: err.message,
          },
        });
      }
    })();
  }, []);

  const getEvidenceGrades = async (studentId, allowSetState = true) => {
    const evidenceGrades = await http.post('/api/evidence-record-grades/search', {
      studentId: studentId || state.student.userId,
      teacherId: state.teacherId,
    });
    const { overallAssessment, evidenceSkillsScore, evidenceCompetenciesScore } =
      getHighlightScores(evidenceGrades.data.list);
    if (allowSetState) {
      const _toReview = getEvidenceToReview(state.studentUploads, evidenceGrades.data.list);
      dispatch({
        type: Actions.SET_ALL,
        payload: {
          evidenceGrades: evidenceGrades.data.list,
          overallAssessment,
          evidenceSkillsScore,
          evidenceCompetenciesScore,
          evidenceToReview: _toReview,
        },
      });
    }
    return evidenceGrades;
  };

  const submitEvidenceGrade = async (params) => {
    try {
      const result = await http.post('/api/evidence-record-grades/', params);
      logger(result);
      await getEvidenceGrades();
    } catch (err) {
      alert(err.message);
    }
  };

  const getStudentSkillUploads = async (params, allowSetState = true) => {
    try {
      const result = await http.post('/api/evidence-record-upload/search', {
        ...params,
        order: 'desc',
        orderBy: 'id',
      });

      if (allowSetState)
        dispatch({
          type: Actions.SET_ALL,
          payload: { studentUploads: result.data.list },
        });

      return result;
    } catch (err) {
      alert(err.message);
      return {};
    }
  };

  const setTargetEvidence = ({ fsId, pcId }) => {
    // getStudentSkillUploads({ fsId, pcId });
    const selectedUploads = state.studentUploads.filter((item) => {
      return item.fsId === fsId && pcId === item.pcId;
    });
    // alert(JSON.stringify(selectedUploads, null, 2));
    dispatch({
      type: Actions.SET_ALL,
      payload: {
        fsId,
        pcId,
        selectedUploadsOnly: selectedUploads || [],
      },
    });
  };

  /**
   *
   * @param {StudentUpload[]} studentUploads
   * @param {EvidenceGrade[]} gradedItems
   */
  const getEvidenceToReview = (studentUploads, gradedItems) => {
    // get all to review uploads and get distinct item
    const list = [];
    for (const item of studentUploads) {
      const existing = list.find(
        (listItem) => listItem.fsId === item.fsId && listItem.pcId === item.pcId
      );
      if (!existing)
        list.push({
          ...item,
          isGraded: false,
        });
    }
    // get all not graded items
    for (let i = 0; i < list.length; i += 1) {
      for (const gradeItem of gradedItems) {
        if (list[i].fsId === gradeItem.fsId && list[i].pcId === gradeItem.pcId) {
          const _score = gradeItem.skillScore + gradeItem.competencyScore;
          if (_score > 0) {
            list[i].isGraded = true;
          }
        }
      }
    }
    // return [];
    return list.filter((item) => item.isGraded === false);
  };

  return (
    <EvidenceRecordContext.Provider
      value={{
        ...state,
        setTargetEvidence,
        getEvidenceGrades,
        submitEvidenceGrade,
        getStudentSkillUploads,
        getEvidenceToReview,
      }}
    >
      {state.isError ? (
        <div className="block fort text-[24px] text-center">{state.error}</div>
      ) : (
        <>
          {state.isLoading ? (
            <div className="flex flex-row min-h-[300px] w-full items-center justify-center">
              <CircularProgress size={100} />
            </div>
          ) : (
            children
          )}
        </>
      )}
    </EvidenceRecordContext.Provider>
  );
}

const useEvidenceRecord = () => useContext(EvidenceRecordContext);

export default useEvidenceRecord;
