import { createSlice } from '@reduxjs/toolkit';

/**
 * @type {CVDevelopmentState}
 */
const initialState = {
  list: [],
  isLoading: true,
};

const directoriesSlice = createSlice({
  name: 'directories',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAll: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setList, setLoading, setAll } = directoriesSlice.actions;

export default directoriesSlice.reducer;
