export default function StudentSearchIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 20.348 20.348"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1580"
            data-name="Rectangle 1580"
            width="20.348"
            height="20.348"
            fill="none"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
        </clipPath>
      </defs>
      <g id="Group_2173" data-name="Group 2173" transform="translate(0 0)">
        <g
          id="Group_2173-2"
          data-name="Group 2173"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_1036"
            data-name="Path 1036"
            d="M27.4,25.482a.934.934,0,1,1-.934-.934.934.934,0,0,1,.934.934"
            transform="translate(-14.657 -14.092)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1037"
            data-name="Path 1037"
            d="M27.136,31.777v-.26a.853.853,0,0,0-.853-.853H24.606a.853.853,0,0,0-.853.853v.36"
            transform="translate(-13.636 -17.603)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1038"
            data-name="Path 1038"
            d="M12.973,25.482a.934.934,0,1,1-.934-.934.934.934,0,0,1,.934.934"
            transform="translate(-6.375 -14.092)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1039"
            data-name="Path 1039"
            d="M12.71,31.813v-.3a.853.853,0,0,0-.853-.853H10.18a.853.853,0,0,0-.853.853v.3"
            transform="translate(-5.354 -17.603)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1040"
            data-name="Path 1040"
            d="M19.75,13.615a.934.934,0,1,1-.934-.934.934.934,0,0,1,.934.934"
            transform="translate(-10.266 -7.28)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1041"
            data-name="Path 1041"
            d="M19.487,19.945v-.3a.853.853,0,0,0-.853-.853H16.957a.853.853,0,0,0-.853.853v.3"
            transform="translate(-9.245 -10.79)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1042"
            data-name="Path 1042"
            d="M34.15,13.615a.934.934,0,1,1-.934-.934.934.934,0,0,1,.934.934"
            transform="translate(-18.532 -7.28)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1043"
            data-name="Path 1043"
            d="M33.887,19.945v-.3a.853.853,0,0,0-.853-.853H31.357a.853.853,0,0,0-.853.853v.3"
            transform="translate(-17.511 -10.79)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1044"
            data-name="Path 1044"
            d="M26.69,23.529A2.973,2.973,0,1,0,23.717,26.5,2.973,2.973,0,0,0,26.69,23.529Z"
            transform="translate(-11.908 -11.801)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_402"
            data-name="Line 402"
            x2="1.465"
            y2="1.465"
            transform="translate(14.207 13.482)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <circle
            id="Ellipse_781"
            data-name="Ellipse 781"
            cx="9.921"
            cy="9.921"
            r="9.921"
            transform="translate(0.253 0.253)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
