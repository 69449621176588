import { NavLink } from 'react-router-dom';
import ArrowRightIcon from '../atoms/CustomIcons/ArrowRight';
// import InfoIcon from '@mui/icons-material/Info';

export default function EvidenceRecordCard({ children, className, title, content, to, ...props }) {
  const onNavLinkClick = () => {
    setTimeout(() => {
      const targetElement = document.querySelector('#skillView');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const template = (
    <div
      className={
        'group transition-all duration-200 ease-in-out fort bg-white p-[24px] w-[240px] min-h-[190px] rounded-[16px] drop-shadow-md ' +
        'flex flex-col cursor-pointer gap-[16px] overflow-hidden es-ec-shadow' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {/* <div className='transition-all duration-200 ease-in-out opacity-0 group-hover:opacity-100 
                          absolute top-0 left-0 bottom-0 right-0 
                          bg-gradient-to-r from-[#af3635] to-[#8f3132]'></div> */}
      <div className="relative flex-1">
        {Array.isArray(children) && children}
        {!Array.isArray(children) && (
          <>
            {title && (
              <div className="text-[16px] transition-all duration-200 ease-in-out">{title}</div>
            )}
            {content && (
              <div className="text-[14px] font-extralight transition-all duration-200 ease-in-out leading-[17px]">
                {content}
              </div>
            )}
          </>
        )}
      </div>
      <div className="relative flex flex-row items-center justify-end text-[#777]">
        <button className="flex flex-row transition-all duration-200 ease-in-out rounded-full w-[32px] h-[32px] group-hover:w-[100px] flex flex-row items-center justify-center border-black border-[1px] border-currentColor group-hover:border-[#14A1DB]">
          <div className="transition-all duration-200 group-hover:pl-[8px] flex-1 text-[9pt] workSans-medium tracking-[1.5px] overflow-hidden w-[0px] group-hover:w-[80px] opacity-0 group-hover:opacity-100 group-hover:text-[#14A1DB]">
            START
          </div>
          <div className="w-[32px] h-[32px] flex flex-row items-center justify-center group-hover:text-[#14A1DB]">
            <ArrowRightIcon size={12} />
          </div>
        </button>
      </div>
    </div>
  );

  return typeof to === 'string' ? (
    <NavLink to={to} onClick={onNavLinkClick}>
      {template}
    </NavLink>
  ) : (
    template
  );
}
