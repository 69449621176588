import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} UploadFile
 * @prop {string|number} id
 * @prop {string} fileName
 * @prop {string} fileExt
 * @prop {File | null} file
 * @prop {number} timestamp
 */

/**
 * @typedef {Object} ReviewYourProfileDevelopmentState
 * @prop {UploadFile} cvDraft1
 * @prop {UploadFile} cvDraft2
 * @prop {UploadFile} cvDraft3
 */

/**
 * @type {ReviewYourProfileDevelopmentState}
 */
const initialState = {
  cvDraft1: null,
  cvDraft2: null,
  cvDraft3: null,
};

const reviewYourProfileDevelopmentSlice = createSlice({
  name: 'reviewYourProfileDevelopmentState',
  initialState,
  reducers: {
    setCVDraft1: (state, action) => {
      state.cvDraft1 = action.payload;
      // return state;
    },
    setCVDraft2: (state, action) => {
      state.cvDraft2 = action.payload;
      // return state;
    },
    setCVDraft3: (state, action) => {
      state.cvDraft3 = action.payload;
      // return state;
    },
  },
});

export const { setCVDraft1, setCVDraft2, setCVDraft3 } = reviewYourProfileDevelopmentSlice.actions;

export default reviewYourProfileDevelopmentSlice.reducer;
