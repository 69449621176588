import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import http from 'services/http';
import { clearInstance } from 'redux/reducers/trainer/manageGroupInstance';

export default function useGroupQuery() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const [optionState, setOptions] = useState({});
  const dispatch = useDispatch();
  const { selectedStudents } = useSelector(({ manageGroupInstance }) => manageGroupInstance);

  const checkGroup = (group) => {
    setGroups(groups.map((g) => ({ ...g, active: group.id !== g.id ? g.active : !group.active })));
  };
  const unCheckGroups = () => {
    setGroups(
      groups.map((g) => {
        if ('active' in g) {
          delete g.active;
        }
        return g;
      })
    );
  };

  const createGroup = async (group = {}) => {
    if (Object.keys(group).length === 0) {
      alert('New Group instance is not set properly');
      return;
    }
    if (group.name === '') {
      alert('Group name was not declared!');
      return;
    }

    setLoading(true);
    try {
      let groupId = null;
      if (!group.uuid) {
        const { data } = await http.post(`/api/groups`, { ...group });
        data.isCreated = true;
        groupId = data.id;
        setGroups([data, ...groups]); // add to recent list
      } else {
        const { data } = await http.put(`/api/groups/${group.uuid}`, { ...group });
        data.isUpdated = true;
        groupId = data.id;
        setGroups(groups.map((i) => (i.id === data.id ? data : i))); // update recent list
      }
      if (selectedStudents.length > 0 && groupId !== null) {
        await http.post('/api/assigned-users', {
          groupId,
          userId: selectedStudents.map((i) => i.userId),
        });
      }
      setGroup(null);
      dispatch(clearInstance());
    } catch (e) {}
    setLoading(false);
  };

  const loadGroup = async (group = {}) => {
    setGroup(group);
    // No use at the moment.
    /*
    const { id } = group;
    try {
      const { data } = await http.post('/api/groups/search', { groupId: id });
    } catch (e) {}
    */
  };

  const removeGroup = async (group = {}) => {
    setLoading(true);
    try {
      await http.delete(`/api/groups/${group?.uuid}`);
      setGroups(groups.filter((g) => g.id !== group.id));
      // const { list, ...options } = data;
      // setGroups(list);
      // setOptions(options);
    } catch (e) {}
    setLoading(false);
  };

  const loadGroups = async (filter = {}) => {
    setLoading(true);
    try {
      const { data } = await http.post('/api/groups/search', filter);
      const { list, ...options } = data;
      setGroups(list);
      setOptions(options);
    } catch (e) {}
    setLoading(false);
  };

  const selected = useMemo(() => groups.filter((i) => i?.active), [groups]);

  useEffect(() => {
    loadGroups();
  }, []);

  return {
    loading,
    group,
    groups,
    createGroup,
    checkGroup,
    unCheckGroups,
    optionState, // Probably not usable at the moment
    loadGroup, // Load a group
    loadGroups,
    removeGroup,
    selected,
  };
}
