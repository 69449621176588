import ChevronRightIcon from '../atoms/CustomIcons/ArrowRight';

// Extended version of EvidenceRecordCard but has customizable button title like LandscapeCard
export default function HoverOptionCard({
  children,
  className,
  buttonTitle,
  buttonIcon,
  title,
  content,
  textColor,
  titleTextStyle,
  ...props
}) {
  return (
    <div
      className={
        'group transition-all duration-300 ease-in-out fort bg-white p-[24px] w-[320px] min-h-[240px] rounded-[16px] drop-shadow-md ' +
        'flex flex-col cursor-pointer gap-[16px] overflow-hidden' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      <div className="transition-all duration-200 ease-in-out opacity-0 group-hover:opacity-100 absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-r from-[#af3635] to-[#8f3132]"></div>
      <div className="relative flex-1 group-hover:text-white">
        {Array.isArray(children) && children}
        {!Array.isArray(children) && (
          <>
            {title && (
              <div className="text-[20px] transition-all duration-200 ease-in-out">{title}</div>
            )}
            {content && (
              <div className="text-[14px] font-extralight transition-all duration-200 ease-in-out">
                {content}
              </div>
            )}
          </>
        )}
      </div>
      <div className="group absolute left-7 bottom-5">
        <div
          className="relative expanden-oval-btn w-[40px] overflow-hidden flex items-center group-hover:w-56 h-[40px] 
                      transition-all relative ease-in-out duration-[.3s] rounded-full bg-transparent border-[#777] 
                      border-[1px] group-hover:border-white/0 group-hover:bg-white/0"
          // style={{ borderColor: textColor }}
        >
          <div className="logo-text rounded-full hidden group-hover:block absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-r from-[rgba(0,0,0,0)] to-[rgba(255,255,255,0.5)]" />
          <p
            className="btn-title workSans-bold tracking-[2px] text-[14px] absolute left-4 opacity-0 group-hover:opacity-100 transition-all delay-100"
            style={{ color: textColor }}
          >
            {buttonTitle}
          </p>
          <div
            // style={{ color: textColor }}
            className="group-hover:text-white absolute h-[40px] w-[40px] rounded-full bg-transparent items-center flex right-0 justify-center pointer-events-none"
          >
            {buttonIcon || <ChevronRightIcon size={20} className="pl-[2px]" />}
          </div>
        </div>
      </div>
    </div>
  );
}
