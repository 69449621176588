import { useState, useRef, useEffect } from 'react';
import http from 'services/http';
import createFileInput from 'utils/createFileInput';
import { useSearchParams } from 'react-router-dom';
import UploadStatus from 'utils/uploadStatus';
import { FILE_UPLOAD_MAX_SIZE } from 'config/misc';
export default function usePlatinumAwardState() {
  const [searchParams] = useSearchParams();
  //
  /**
   * @typedef {import('../../utils/createFileInput').UploadFile &
   *  { uuid: string, createAt: string }} UploadFile
   * @typedef {{ file: UploadFile, status: number }} InitialState
   * @typedef {React.Dispatch<React.SetStateAction<Array<InitialState>>>} setState
   * @type {[
   *  UploadFile[],
   *  setState,
   * ]}
   */
  const [slot1, setSlot1] = useState([]);
  const [slot2, setSlot2] = useState([]);
  const [slot3, setSlot3] = useState([]);
  const [slot4, setSlot4] = useState([]);

  // for link upload helper
  const setStates = [setSlot1, setSlot2, setSlot3, setSlot4];
  const targetSlot = useRef(null);

  useEffect(() => {
    getCurrentUploads();
  }, []);

  const getFolderId = () => {
    return Number(searchParams.get('folderId'));
  };

  const getCurrentUploads = () => {
    http
      .post('/api/student-view-upload/search', {
        dirId: getFolderId(),
        order: 'desc',
        orderBy: 'date',
      })
      .then((result) => {
        //
        /**
         * @type {UploadFile[]}
         */
        const list = result.data.list;
        const uploadedItemsSlot1 = [];
        const uploadedItemsSlot2 = [];
        const uploadedItemsSlot3 = [];
        const uploadedItemsSlot4 = [];
        for (const item of list) {
          item.slotId === 1 &&
            uploadedItemsSlot1.push({
              file: { ...item, timestamp: new Date(item.createdAt) },
              status: UploadStatus.SUCCESS,
            });
          item.slotId === 2 &&
            uploadedItemsSlot2.push({
              file: { ...item, timestamp: new Date(item.createdAt) },
              status: UploadStatus.SUCCESS,
            });
          item.slotId === 3 &&
            uploadedItemsSlot3.push({
              file: { ...item, timestamp: new Date(item.createdAt) },
              status: UploadStatus.SUCCESS,
            });
          item.slotId === 4 &&
            uploadedItemsSlot4.push({
              file: { ...item, timestamp: new Date(item.createdAt) },
              status: UploadStatus.SUCCESS,
            });
        }
        setSlot1(() => [...uploadedItemsSlot1]);
        setSlot2(() => [...uploadedItemsSlot2]);
        setSlot3(() => [...uploadedItemsSlot3]);
        setSlot4(() => [...uploadedItemsSlot4]);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  /**
   *
   * @param {{
   *  items: UploadFile[],
   *  setState: React.Dispatch<React.SetStateAction<Array<InitialState>>>,
   *  slotId: number
   * }} param0
   */
  const inputOnChange = ({ items, setState, slotId }) => {
    const overLimitFile = items.find((item) => item?.file?.size > FILE_UPLOAD_MAX_SIZE);
    if (overLimitFile) {
      alert(
        `The file you're attaching ${overLimitFile?.file?.name} is bigger than the server allows, Try putting the file in a shared location and sending a link instead`
      );
      return false;
    }
    const frmData = new FormData();
    const _newItems = [];
    for (const item of items) {
      item.file && frmData.append('files', item.file);
      item.fileName && frmData.append('fileName', item.fileName);
      item.fileExt && frmData.append('fileExt', item.fileExt);
      getFolderId() && frmData.append('dirId', getFolderId());
      frmData.append('isExternal', false);
      frmData.append('fileURL', null);
      frmData.append('slotId', slotId);
      _newItems.push({
        file: {
          ...item,
          uuid: item.id,
        },
        status: UploadStatus.UPLOADING,
      });
      // uploading item
    }
    window.dispatchEvent(new Event('form-submitted'));
    setState((prevState) => [..._newItems, ...prevState]);

    http
      .post('/api/student-view-upload', frmData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        getCurrentUploads();
      })
      .catch((err) => {
        alert(err.message);
        setState((prevState) => ({
          ...prevState,
          status: UploadStatus.ERROR,
        }));
      });

    return true;
  };

  const handleLinkUpload = (data) => {
    const frmData = {
      links: data?.map((item) => ({ ...item, slotId: targetSlot.current })),
      dirId: getFolderId(),
    };

    // alert(JSON.stringify(frmData, null, 2));
    http
      .post('/api/student-view-upload/links', frmData)
      .then(() => {
        getCurrentUploads();
      })
      .catch((err) => {
        alert(err.message);
        setStates[targetSlot.current - 1]((prevState) => ({
          ...prevState,
          status: UploadStatus.ERROR,
        }));
      });
  };

  // removing items
  const removeItem = async (uuid, setState, slot) => {
    const _slot1 = slot;
    const targetIndex = slot.findIndex((item) => {
      return item.file.uuid === uuid;
    });
    try {
      _slot1[targetIndex].status = UploadStatus.REMOVING;
      setState([..._slot1]);

      const result = await http.delete(`/api/student-view-upload/${uuid}`);

      const filterItems = slot.filter((item) => {
        return item.file.uuid !== uuid;
      });

      setState([...filterItems]);
      window.dispatchEvent(new CustomEvent('upload.delete', { detail: result.data }));
    } catch (err) {
      _slot1[targetIndex].status = UploadStatus.ERROR;
      setState([..._slot1]);
      window.dispatchEvent(new CustomEvent('app-error', { detail: err.message || err }));
    }
  };

  const slot1Input = useRef(
    createFileInput(
      ({ items }) => {
        inputOnChange({ items, setState: setSlot1, slotId: 1 });
      },
      { multiple: true }
    )
  );

  const slot2Input = useRef(
    createFileInput(
      ({ items }) => {
        inputOnChange({ items, setState: setSlot2, slotId: 2 });
      },
      { multiple: true }
    )
  );

  const slot3Input = useRef(
    createFileInput(
      ({ items }) => {
        inputOnChange({ items, setState: setSlot3, slotId: 3 });
      },
      { multiple: true }
    )
  );

  const slot4Input = useRef(
    createFileInput(
      ({ items }) => {
        inputOnChange({ items, setState: setSlot4, slotId: 4 });
      },
      { multiple: true }
    )
  );

  const removeSlot1 = (uuid) => {
    removeItem(uuid, setSlot1, slot1);
  };

  const removeSlot2 = (uuid) => {
    removeItem(uuid, setSlot2, slot2);
  };

  const removeSlot3 = (uuid) => {
    removeItem(uuid, setSlot3, slot3);
  };

  const removeSlot4 = (uuid) => {
    removeItem(uuid, setSlot4, slot4);
  };

  return {
    slot1,
    slot2,
    slot3,
    slot4,
    slot1Input: slot1Input.current,
    slot2Input: slot2Input.current,
    slot3Input: slot3Input.current,
    slot4Input: slot4Input.current,
    removeSlot1,
    removeSlot2,
    removeSlot3,
    removeSlot4,
    getCurrentUploads,
    handleLinkUpload,
    targetSlot,
  };
}
