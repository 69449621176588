import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ArrowButton from '../atoms/ArrowButton';
import Input from '../atoms/Input';
import LinkText from '../atoms/LinkText';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { setRememberCheckStatus, getRememberCheckStatus, removeRemember } from 'config/remember-me';
function LoginForm({
  onEmailChange,
  onPasswordChange,
  errStatus,
  errMessage,
  values,
  onSubmit,
  loading,
  onForgotPassword,
  onEnrollUser,
}) {
  const [showPass, setShowPass] = useState(getRememberCheckStatus());
  const emailRef = useRef(null);
  const passRef = useRef(null);

  useEffect(() => {
    setRememberCheckStatus(showPass);
    if (showPass === false) removeRemember();
  }, [showPass]);

  const onEnterKeyEmailInput = (e) => {
    if (e.key === 'Enter') {
      passRef.current?.focus();
    }
  };

  const onEnterKeyPassInput = (e) => {
    if (e.key === 'Enter') {
      typeof onSubmit === 'function' && onSubmit();
    }
  };

  return (
    <div className="w-11/12 relative flex flex-col items-center">
      <div className=" w-3/4">
        <div className="my-1 ">
          <Input
            placeholder={'Email'}
            errStatus={errStatus === 'email'}
            errMessage={errMessage}
            onChange={onEmailChange}
            value={values.email}
            type={'email'}
            onKeyDown={onEnterKeyEmailInput}
            inputProps={{
              ref: emailRef,
            }}
          />
        </div>
      </div>
      <div className=" w-3/4 relative ">
        <div className="my-1  ">
          <Input
            placeholder={'Password'}
            value={values.password}
            errStatus={errStatus === 'password'}
            errMessage={errMessage}
            onChange={onPasswordChange}
            type={'password'}
            inputProps={{
              ref: passRef,
            }}
            onKeyDown={onEnterKeyPassInput}
          />
          <p
            className={`${
              !errStatus ? 'opacity-0' : 'opacity-100'
            } absolute right-1 text-sm text-red-500 ease-in-out duration-200`}
          >
            {errMessage}
          </p>
        </div>
        <div className="mt-2">
          <div className="flex items-center">
            <div
              onClick={() => setShowPass(!showPass)}
              className="w-5 mr-2 flex items-center cursor-pointer justify-center h-5 bg-transparent"
            >
              {showPass && (
                <CloseOutlinedIcon
                  style={{
                    color: 'black',
                    width: '18px',
                    height: '18px',
                  }}
                />
              )}
            </div>
            <Typography variant="p" className="text-xs text-black uppercase fort font-extralight">
              Remember me
            </Typography>
          </div>
          <div
            className={` absolute right-1 ${
              errStatus === 'password' || errStatus === 'email' ? 'top-14' : 'top-12'
            } ease-in-out duration-200`}
          >
            <LinkText
              extraText="new user"
              onClick={onEnrollUser}
              text={'enroll now'}
              className="fort font-extralight"
            />
          </div>
        </div>
        <div className="mt-2 flex justify-center">
          {loading ? (
            <div className="loader"></div>
          ) : (
            <ArrowButton onClick={onSubmit} filled={true} direction="right" />
          )}
        </div>
        <div className="mt-1">
          <LinkText onClick={onForgotPassword} text={'Forgot Password!'} />
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
