import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  list: [],
  page: 1,
  pageCount: 1,
  limit: 25,
  totalCount: 0,
};

const userListSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserList: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setUserList, setPage, setLimit, setList } = userListSlice.actions;

export default userListSlice.reducer;
