import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} UploadFile
 * @prop {string} fileName
 * @prop {string} fileExt
 * @prop {File | null} file
 * @prop {number} timestamp
 */

/**
 * @typedef {Object} CVDevelopmentState
 * @prop {UploadFile[]} uploadFiles
 */

/**
 * @type {CVDevelopmentState}
 */
const initialState = {
  uploadFiles: [],
};

const studentCVDevelopmentSlice = createSlice({
  name: 'studentCVDevelopment',
  initialState,
  reducers: {
    setUploadFiles: (state, action) => {
      state.uploadFiles = action.payload;
      // return state;
    },

    appendUploadFiles: (state, action) => {
      state.uploadFiles = [...state.uploadFiles, ...action.payload];
      // return state;
    },
  },
});

export const { setUploadFiles, appendUploadFiles } = studentCVDevelopmentSlice.actions;

export default studentCVDevelopmentSlice.reducer;
