import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HeadIcon from '../../atoms/CustomIcons/HeadIcon';
import OvalIconButton from '../OvalIconButton';
import useStudentInfo from 'hooks/student/useStudentInfo';
import { useRef, useLayoutEffect, useState } from 'react';
import ArrowLeftIcon from 'components/UI/atoms/CustomIcons/ArrowLeft';
import ArrowRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';

export default function StudentInfo({
  open = false,
  student = {},
  allowAddToGroup = true,
  handleAddToGroup,
  handleShowInfo,
  handleClose,
}) {
  const { groups } = useStudentInfo(student, open);
  return (
    <div className="h-[200px] border-b-2 pb-4 flex m-4 relative">
      <div
        className="absolute top-0 right-0 hover:text-[#af3635] cursor-pointer "
        onClick={handleClose}
      >
        <CloseIcon
          style={{
            fontSize: '30px',
          }}
        />
      </div>
      <div className="h-[135px] w-[105px] flex items-center justify-center relative border border-gray-500">
        <HeadIcon size={50} active={false} />
        {student?.yearName && (
          <span className="absolute bottom-0 right-0 border-t-[1px] border-l-[1px] border-gray-500 px-2 py-1 text-sm">
            {student.yearName}
          </span>
        )}
      </div>
      <div className="grow ml-4 pt-3 w-full overflow-x-hidden">
        <h4 className="fort uppercase text-xl">{student.fullName}</h4>
        <h6 className="workSans-bold uppercase">{student.groupAcademyName}</h6>
        <GroupPanelContainer>
          {groups.map((i) => (
            <GroupPanel key={`group-${i.id}`} item={i} />
          ))}
        </GroupPanelContainer>
        <div className="flex">
          {allowAddToGroup && (
            <OvalIconButton
              onClick={handleAddToGroup}
              title="ADD TO GROUP"
              size="small"
              className="self-end mr-4"
              icon={
                <ChevronRightIcon
                  style={{
                    fontSize: '22px',
                    fontWeight: 100,
                  }}
                />
              }
            />
          )}
          <OvalIconButton
            onClick={handleShowInfo}
            title="VIEW EVIDENCE RECORD"
            size="small"
            className="self-end mr-4"
            icon={
              <ChevronRightIcon
                style={{
                  fontSize: '22px',
                  fontWeight: 100,
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

function GroupPanel({ item = {} }) {
  return (
    <div className="flex bg-[#00A7E1] py-1 px-3 items-center mr-2 mb-2 rounded-md cursor-pointer">
      <div className="grow mr-2 min-w-[100px]">
        <p className="fort m-0 p-0 text-xs text-white">{item.name || item.groupName}</p>
        <p className="fort m-0 p-0 text-xs text-white font-extralight">#{item.id}</p>
      </div>
      <div className="rounded-full border border-white flex items-center justify-center">
        <ChevronRightIcon
          style={{
            color: 'white',
            fontSize: '15px',
            fontWeight: 100,
          }}
        />
      </div>
    </div>
  );
}

function GroupPanelContainer({ children }) {
  const containerRef = useRef();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useLayoutEffect(() => {
    if (containerRef.current.scrollLeft > 0) {
      if (showLeftArrow === false) {
        setShowLeftArrow(true);
      }
    } else {
      if (showLeftArrow === true) {
        setShowLeftArrow(false);
      }
    }

    if (
      containerRef.current.scrollLeft + containerRef.current.offsetWidth >=
      containerRef.current.scrollWidth
    ) {
      if (showRightArrow === true) {
        setShowRightArrow(false);
      }
    } else {
      if (showRightArrow === false) {
        setShowRightArrow(true);
      }
    }
  }, []);

  /**
   * @param {Event} e
   */
  const onScroll = (e) => {
    if (e.target.scrollLeft > 0) {
      if (showLeftArrow === false) {
        setShowLeftArrow(true);
      }
    } else {
      if (showLeftArrow === true) {
        setShowLeftArrow(false);
      }
    }

    if (e.target.scrollLeft + e.target.offsetWidth >= e.target.scrollWidth) {
      if (showRightArrow === true) {
        setShowRightArrow(false);
      }
    } else {
      if (showRightArrow === false) {
        setShowRightArrow(true);
      }
    }
  };

  const onClickLeft = () => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft - 150,
      behavior: 'smooth',
    });
  };

  const onClickRight = () => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + 150,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className="overflow-x-auto flex my-4 scrollbar-hidden relative"
      ref={containerRef}
      onScroll={onScroll}
    >
      {showLeftArrow && (
        <button
          onClick={onClickLeft}
          className="sticky left-0 bottom-0 top-0 z-[10] flex flex-row items-center 
                        justify-center min-w-[40px] cursor-pointer mr-[-40px] 
                        bg-gradient-to-r from-white to-tranparent"
        >
          <ArrowLeftIcon size={16} color="#555" />
        </button>
      )}
      <div className="flex flex-row">{children}</div>
      {showRightArrow && (
        <button
          onClick={onClickRight}
          className="sticky right-0 bottom-0 top-0 z-[10] flex flex-row items-center 
                      justify-center min-w-[40px] cursor-pointer ml-[-40px] 
                      bg-gradient-to-l from-white to-tranparent"
        >
          <ArrowRightIcon size={16} color="#555" />
        </button>
      )}
    </div>
  );
}
