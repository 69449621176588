import { createContext, useContext, useEffect, useState } from 'react';
import http from 'services/http';

/**
 * @typedef {Object} EvidenceGrade
 * @prop {string} comments
 * @prop {number} competencyScore
 * @prop {string} createdAt
 * @prop {number} ecsId
 * @prop {string} ecsTitle
 * @prop {string} fsDescription
 * @prop {number} fsId
 * @prop {string} fsTitle
 * @prop {number} id
 * @prop {string} pcDescription
 * @prop {number} pcId
 * @prop {string} pcTitle
 * @prop {number} skillScore
 * @prop {number} studentId
 * @prop {string} studentName
 * @prop {number} teacherId
 * @prop {string} teacherName
 * @prop {string} updatedAt
 * @prop {string} uuid
 */

/**
 *
 * @type {Array<{
 *  fsId: number,
 *  pcId: number,
 *  skillScore: number,
 *  competencyScore: number,
 *  comments: string,
 *  studentId: number,
 *  teacherId: number,
 *  teacherId: number,
 * }>}
 */
const grateItemsInitialState = [];

export default function useEvidenceRecordGrades() {
  return useEvidenceRecordGradesContext();
}

export const EvidenceRecordGradesContext = createContext();
export const useEvidenceRecordGradesContext = () => useContext(EvidenceRecordGradesContext);
export const EvidenceRecordGradesProvider = ({ children }) => {
  const [gradedItems, setGradedItems] = useState(grateItemsInitialState);
  const [scores, setScores] = useState({
    overallAssessment: 0,
    evidenceSkillsScore: 0,
    evidenceCompetenciesScore: 0,
  });

  /**
   * @param {EvidenceGrade[]} grades
   */
  const getFSPoints = (grades) => {
    const fsSet = new Set();
    for (const item of grades) {
      const _score = item.skillScore + item.competencyScore;
      if (_score > 0) {
        fsSet.add(item.fsId);
      }
    }
    return fsSet.size;
  };

  /**
   * @param {EvidenceGrade[]} grades
   */
  const getPCPoints = (grades) => {
    const pcSet = new Set();
    for (const item of grades) {
      const _score = item.skillScore + item.competencyScore;
      if (_score > 0) {
        pcSet.add(item.pcId);
      }
    }
    return pcSet.size;
  };

  const getCurrentUserGrades = () => {
    // to do post
    http
      .post('/api/evidence-record-grades/currentStudent/search', {})
      .then((result) => {
        const list = result.data.list;
        setGradedItems(list);
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  useEffect(() => {
    getCurrentUserGrades();
    const getGradeAfterUpdate = () => {
      getCurrentUserGrades();
    };
    window.addEventListener('evidence.form-submitted', getGradeAfterUpdate);
    return () => {
      window.removeEventListener('evidence.form-submitted', getGradeAfterUpdate);
    };
  }, []);

  useEffect(() => {
    let _overall = 0;
    for (const gItem of gradedItems) {
      _overall += gItem.competencyScore + gItem.skillScore;
    }
    setScores({
      overallAssessment: Math.round((_overall / 114) * 100),
      evidenceSkillsScore: getFSPoints(gradedItems),
      evidenceCompetenciesScore: getPCPoints(gradedItems),
    });
  }, [gradedItems]);

  return (
    <EvidenceRecordGradesContext.Provider
      value={{ gradedItems, setGradedItems, getCurrentUserGrades, ...scores }}
    >
      {children}
    </EvidenceRecordGradesContext.Provider>
  );
};
