import React, { useEffect } from 'react';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import { NavLink, useSearchParams } from 'react-router-dom';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import useDirectories from 'hooks/student/useDirectories';

function KnowYourProfile() {
  const [searchParams] = useSearchParams();
  const { list: folderList, getByPath } = useDirectories();
  const list = folderList.filter(
    (item) => Number(item.parentId) === Number(searchParams.get('folderId'))
  );
  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'KNOW YOUR PROFILE',
      path: '/engagement/know-your-profile?folderId=' + getByPath('know-your-profile')?.id,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainHeadingText>know your profile</MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      <DashboardContentText>
        Here you will capture your skills and experience and will chart your development of 21st
        century skills and their links to business and industry.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {list.map((item) => {
          return (
            <NavLink key={item.id} to={item.path + `?folderId=${encodeURIComponent(item.id)}`}>
              <LandscapeCard
                title={item.name}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              />
            </NavLink>
          );
        })}
      </LandscapeCardListContainer>
    </div>
  );
}

export default KnowYourProfile;
