import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import StrokeText from 'components/UI/atoms/StrokeText';
import useFinishedCVFinalSnapshotState from 'hooks/student/useFinishedCVFinalSnapshotState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import useDirectories from 'hooks/student/useDirectories';
// File history
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import useUser from 'hooks/auth/useUser';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function FinishedCVFinalSnapshot() {
  const { userType } = useUser();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { list: folderList, getByPath } = useDirectories();
  const { histories } = useFileUploadPanel();
  const currentFolder =
    folderList.find((item) => item.id === Number(searchParams.get('folderId'))) || {};
  const {
    slot1,
    slot2,
    slot1Input,
    slot2Input,
    removeSlot1,
    removeSlot2,
    targetSlot,
    handleLinkUpload,
  } = useFinishedCVFinalSnapshotState();

  const { list: assignmentDocuments } = useAssignmentDocuments();

  const breadcrumbsItems = [
    {
      text: 'PURPOSE',
      path: '/purpose?folderId=' + getByPath('purpose')?.id,
    },
    {
      text: 'FINISHED C.V. / FINAL SNAPSHOT',
      path: '/purpose/finished-cv-final-snapshot?folderId=' + currentFolder.id,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
      case 'slot2':
        targetSlot.current = 2;
        break;
    }
  };
  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
      case 'slot2':
        slot2Input.click();
        break;
    }
  };
  return (
    <div>
      <MainHeadingText>
        FINISHED C.V. / <br /> FINAL SNAPSHOT
      </MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => (
              <>
                YOUR <br /> FINAL C.V.
              </>
            )}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={removeSlot1}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => (
              <>
                YOUR SELF WORTH <br /> DOCUMENT
              </>
            )}
            activeFile={slot2}
            histories={histories}
            onUpload={() => uploadModal('slot2')}
            onRemoveHistory={removeSlot2}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      <DashboardContentText>View your final CV here</DashboardContentText>
      <div style={{ height: 36 }} />
      {userType === 'student' ? (
        <>
          <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
          {assignmentDocuments.length === 0 ? (
            <div className="py-[56px]">
              <StrokeText>
                No Assignment <br />
                Documents
              </StrokeText>
            </div>
          ) : (
            <DocumentCardContainer>
              {assignmentDocuments.map((item, index) => (
                <DocumentCard data={item} key={index} />
              ))}
            </DocumentCardContainer>
          )}
          <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
            <BottomLandscapeCard
              title="VISIT THE EVIDENCE RECORD"
              buttonTitle="START"
              onClick={() => {
                navigate('/student/purpose/evidence-record');
              }}
            />
          </div>
        </>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default FinishedCVFinalSnapshot;
