import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Engagement from 'pages/student/Engagement';
import Purpose from 'pages/student/Purpose';
import SelfWorth from 'pages/student/SelfWorth';

import EngagementRoutes from 'pages/student/EngagementSubRoutes/EngagementRoutes';
import PurposeRoutes from 'pages/student/PurposeSubRoutes/PurposeRoutes';
import SelfWorthRoutes from 'pages/student/SelfWorthSubRoutes/SelfWorthRoutes';

export default function TrainingMaterialsSubRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <Routes>
      <Route path="/self-worth" element={<SelfWorth />} />
      <Route path="/self-worth/*" element={<SelfWorthRoutes />} />
      <Route path="/engagement" element={<Engagement />} />
      <Route path="/engagement/*" element={<EngagementRoutes />} />
      <Route path="/purpose" element={<Purpose />} />
      <Route path="/purpose/*" element={<PurposeRoutes />} />
    </Routes>
  );
}
