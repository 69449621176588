import { Typography } from '@mui/material';
import React from 'react';

function LinkText({ text, onClick, extraText, className, ...props }) {
  return (
    <div
      className={
        'mt-2 text-white text-xs uppercase' + (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      <span className="">{extraText}</span>{' '}
      <Typography
        onClick={onClick}
        variant="p"
        className="
         select-none ease-in-out duration-200 
          underline underline-offset-0 cursor-pointer"
      >
        {text}
      </Typography>
    </div>
  );
}

export default LinkText;
