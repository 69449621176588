import React, { useState, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { AssignResources } from 'components/UI/organisms/AssignResources';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { KeyboardArrowDown } from '@mui/icons-material';

const accStyle = {
  border: 0,
  // 'borderBottom': '1px solid black',
  margin: '0 !important',
  boxShadow: 'none',
  '&:before': {
    backgroundColor: 'unset',
  },
};

const accSummaryStyle = {
  padding: '0',
};
const accDetailStyle = {
  padding: '15px 0',
};

function AssignResourceAccordion({ title = 'RESOURCE SEARCH:' }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const matAccordionRef = useRef();

  return (
    <>
      <Accordion
        ref={matAccordionRef}
        expanded={accordionExpanded}
        sx={accStyle}
        onChange={(e, b) => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary sx={accSummaryStyle} aria-controls="panel3-content" id="panel3-header">
          <DashboardContentHeading>
            {title} {accordionExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDown />}
          </DashboardContentHeading>
        </AccordionSummary>
        <AccordionDetails sx={accDetailStyle}>
          <AssignResources />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default AssignResourceAccordion;
