export default function HandHeartIcon({ size }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.5,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.85 20.85"
      width={size || 32}
      height={size || 32}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Nav">
          <path
            {...cssProps}
            d="M10.42.25A10.18,10.18,0,1,0,20.6,10.42,10.18,10.18,0,0,0,10.42.25ZM7.88,16.35H5.45v-.09a1.53,1.53,0,0,0-.37-1l-1.72-2a1.19,1.19,0,0,1-.3-.8L3.14,8a.41.41,0,0,1,.42-.41h.2A.41.41,0,0,1,4.18,8v3.19l.42.72a.78.78,0,0,1,.56-.42l2.23,2.1a1.59,1.59,0,0,1,.49,1.14ZM5.54,7.06A2.55,2.55,0,0,1,10.42,6a2.56,2.56,0,0,1,4.89,1.06c0,3-4.89,6.29-4.89,6.29S5.54,10.09,5.54,7.06Zm12,6.21-1.72,2a1.54,1.54,0,0,0-.38,1v.09H13V14.69a1.59,1.59,0,0,1,.49-1.14l2.23-2.1a.78.78,0,0,1,.56.42l.42-.72V8a.41.41,0,0,1,.42-.41h.2a.41.41,0,0,1,.42.41l.07,4.51A1.18,1.18,0,0,1,17.49,13.27Z"
          />
        </g>
      </g>
    </svg>
  );
}
