import Checkbox from '@mui/material/Checkbox';
// import { useState } from 'react';
// import HeadIcon from 'components/UI/atoms/CustomIcons/HeadIcon';

export default function CheckItem({
  title,
  active = false,
  subtitle,
  svgicon = null,
  v2 = false,
  className = '',
  onClick = () => {},
}) {
  // const [check, setCheck] = useState(false);
  return (
    <div
      className={`border-solid rounded flex items-center mb-2 ${
        active ? 'border-2 border-[#14A1DB]' : 'border border-slate-300'
      } ${className}`}
      onClick={onClick}
    >
      <div
        className={`w-[40px] ${
          active ? 'border-r-2 border-[#14A1DB]' : 'border-r border-slate-300'
        } flex items-center justify-center checkbox-col`}
      >
        <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} checked={active} />
      </div>
      {v2 && svgicon && (
        <div className="w-[40px] h-[40px] flex items-center justify-center">{svgicon}</div>
      )}
      <div
        className={`grow ${
          active ? 'border-r-2 border-[#14A1DB]' : 'border-r border-slate-300'
        } px-2 h-full flex items-center list-name-col`}
      >
        <div className="h-[40px] flex flex-col justify-center">
          <p className="fort font-extralight uppercase m-0 p-0 block text-[12px]" title={title}>
            {title}
          </p>
          {subtitle && (
            <p
              className="fort font-extralight uppercase m-0 p-0 block w-[200px] text-[12px] truncate"
              title={subtitle}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>
      {!v2 && svgicon && (
        <div className="w-[40px] h-[40px] flex items-center justify-center avatar-col">
          {svgicon}
        </div>
      )}
    </div>
  );
}
