import { Typography } from '@mui/material';
import useUser from 'hooks/auth/useUser';
// import paperSvg from 'assets/images/svg/paper.svg';
import CircleDownloadIcon from '../atoms/CustomIcons/CircleDownloadIcon';
import styles from 'styles/UI/molecules/UploadDocumentCard.module.css';
import PaperIcon from '../atoms/CustomIcons/PaperThinIcon';

function DocumentCard({
  title,
  data,
  onClick,
  active = false,
  className,
  fileExtension,
  downloadHandler,
  showDLButton = true,
}) {
  const _className = typeof className === 'string' ? ` ${className}` : '';
  const { token } = useUser();
  const onDownloadClick = () => {
    const url = new URL(data?.filePath);
    url.searchParams.append('token', token);
    window.open(url.toString(), '_blank');
  };

  return (
    <div
      title={data?.fileName}
      onClick={onClick}
      className={
        `${styles.docCard} m-auto w-[138px] min-w-[138px] min-h-[182px] p-[12px] pt-[40px] flex flex-col first-letter hover:border-black  transition-all relative ease-in-out duration-[.2s] cursor-pointer ` +
        _className
      }
    >
      <div className={`absolute top-0 left-0 flex justify-center`}>
        <PaperIcon size={175} active={active} />
      </div>
      <Typography
        variant="p"
        className={`file-title-webkit fort text-[14px] ${
          active ? 'font-medium text-[#00A7E1]' : 'font-extralight text-black'
        } uppercase tracking-[2px] flex-1`}
      >
        {title || data?.fileName || 'Document ## Name'}
      </Typography>
      <div className="uppercase fort font-extralight absolute bottom-[18px] left-[-8px] bg-black text-white rounded-[8px] py-[4px] px-[8px] tracking-[2px] text-[14px]">
        {(typeof data?.count === 'number' && data?.count > 1 && 'MULTI') ||
          data?.fileExt ||
          fileExtension ||
          '.PDF'}
      </div>

      {showDLButton && (
        <div
          className="w-[32px] h-[32px] rounded-full border-none absolute right-[12px] bottom-[12px] bg-transparent hover:bg-black/10"
          onClick={downloadHandler || onDownloadClick}
        >
          <CircleDownloadIcon size={32} />
        </div>
      )}
    </div>
  );
}

export default DocumentCard;
