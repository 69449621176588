import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  TextField,
  FormControl,
  MenuItem,
  MenuList,
  CircularProgress,
  ListSubheader,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import useUploadPanel from 'hooks/organisms/useUploadPanel';
import InputLinkBox from '../atoms/InputLinkBox';
import DashboardContentHeading from '../atoms/DashboardContentHeading';
import TrashSMIcon from '../atoms/CustomIcons/TrashSMIcon';
import HoverOptionCard from '../molecules/HoverOptionCard';
import OvalIconButton from '../molecules/OvalIconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useURLInput from 'hooks/organisms/useURLInput';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import Autocomplete from '@mui/material/Autocomplete';
import useTagQuery from 'hooks/misc/useTagQuery';
import createFileInput from 'utils/createFileInput';
import useDirectories from 'hooks/student/useDirectories';
import FilterAutoComplete from '../molecules/FilterAutoComplete';

const accStyle = {
  border: 0,
  borderBottom: '1px solid black',
  margin: '0 !important',
  boxShadow: 'none',
  '&:before': {
    backgroundColor: 'unset',
  },
};

const defaultOptions = {
  forceOpen: false,
};

export default function UploadModalAsPanel({ options = defaultOptions }) {
  const {
    file,
    alert,
    setFile,
    onFileNameChange,
    type,
    hasSelected,
    setType,
    open,
    setOpen,
    setSchool,
    year,
    setYear,
    removeFile,
    upload,
    back,
    setAlerts,
    loading,
    assignment,
    setAssignment,
    featured,
    setFeatured,
  } = useUploadPanel();
  const { links, blank, setFocus, preCheck, onNewLinkChange } = useURLInput(setAlerts);
  const uploadRef = useRef(
    createFileInput(
      ({ item }) => {
        if (item) {
          setType('upload');
          setFile(item);
        }
      },
      { multiple: false }
    )
  );

  const onClickLinkForm = () => {
    if (!preCheck) {
      setAlerts('Invalid Link!');
      return;
    }
    setType(null);
    if (links.length > 0 && !file) setFile(links[0]);
  };

  // useEffect(() => {
  //   if (links.length > 0 && !file) setFile(links[0]);
  // }, [links]);

  const triggerFileUploader = () => (uploadRef.current ? uploadRef.current.click() : null);

  return (
    <Accordion expanded={options.forceOpen || open} sx={accStyle} onChange={(e, b) => setOpen(b)}>
      <AccordionSummary
        sx={{
          padding: '0',
        }}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <DashboardContentHeading>
          SELECT A FILE TO UPLOAD {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </DashboardContentHeading>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '15px 0',
        }}
      >
        {type === null && !hasSelected && (
          <Box className="flex justify-start mb-4">
            <HoverOptionCard
              className={`mr-8`}
              onClick={triggerFileUploader}
              title="UPLOAD FILE"
              textColor={'white'}
              titleTextStyle={{
                fontSize: 28,
              }}
              buttonTitle="ADD FILE"
            />
            <HoverOptionCard
              className={`mr-8`}
              onClick={() => setType('url')}
              title="ADD GOOGLE DOC URL"
              buttonTitle="START"
              textColor={'white'}
              titleTextStyle={{
                fontSize: 28,
              }}
            />
          </Box>
        )}
        {type === 'url' && hasSelected && (
          <LinkForm
            alert={alert}
            blank={blank}
            preCheck={preCheck}
            onNewLinkChange={onNewLinkChange}
            onAddNext={onClickLinkForm}
            setFocus={setFocus}
          />
        )}
        {file && (
          <FileForm
            file={file}
            alert={alert}
            onSelectSchool={setSchool}
            onChange={onFileNameChange}
            assignment={assignment}
            setAssignment={setAssignment}
            year={year}
            setYear={setYear}
            onClickDelete={removeFile}
            featured={featured}
            setFeatured={setFeatured}
          />
        )}
        {hasSelected && (
          <div className="flex mb-4">
            <OvalIconButton
              onClick={back}
              className="self-end cursor-pointer min-w-[30px] mr-4"
              icon={
                <ChevronLeftIcon
                  style={{
                    fontSize: '36px',
                    fontWeight: 100,
                  }}
                />
              }
            />
            {loading && <CircularProgress />}
            {file && !loading && (
              <OvalIconButton
                onClick={upload}
                className="self-end cursor-pointer min-w-[80px]"
                title="FINALIZE RESOURCE"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '36px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
            )}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

function LinkForm({
  alert,
  setLinks = () => {},
  links,
  blank,
  preCheck,
  onNewLinkChange,
  onAddNext,
  setFocus,
}) {
  useEffect(() => {
    // To throw this data to the parent
    setLinks(links);
  }, [links]);

  return (
    <div className="bg-white rounded-lg p-8 mb-4">
      <DashboardContentHeading className="text-[14px] mt-0">ENTER URL:</DashboardContentHeading>
      <InputLinkBox
        className="bg-slate-300 mb-2"
        item={blank}
        editable={true}
        onChange={onNewLinkChange}
        preCheck={preCheck}
        setFocus={setFocus}
        autoFocus
      />
      {alert && <p className="fond text-white p-1 px-4 bg-[red] rounded-full mb-4">{alert}</p>}
      <OvalIconButton
        size={'small'}
        onClick={onAddNext}
        title="NEXT"
        className="self-end cursor-pointer min-w-[80px]"
        icon={
          <ChevronRightIcon
            style={{
              fontSize: '24px',
              fontWeight: 100,
            }}
          />
        }
      />
    </div>
  );
}

function SubOption({ item, step = 0, ...props }) {
  if (item?.isParent && item?.children?.length > 0)
    return (
      <MenuList>
        <ListSubheader className={`fort bg-white ml-${step}`}>{item?.name}</ListSubheader>
        {item?.children?.map((i, k) => (
          <SubOption key={`step${step}-${k}`} item={i} step={step + 1} {...props} />
        ))}
      </MenuList>
    );
  return (
    <MenuItem className={`fort bg-white ml-${step}`} value={item?.id} {...props}>
      {item?.name}
    </MenuItem>
  );
}

function FileForm({
  file,
  alert,
  onChange,
  onSelectSchool,
  preCheck,
  onClickDelete = () => {},
  assignment,
  setAssignment,
  year,
  setYear,
  featured,
  setFeatured,
}) {
  const { tags } = useTagQuery();
  const { showSelected, onlyChildren } = useDirectories();
  const selectedAssignment = showSelected(assignment);
  const { school, yearTag } = useMemo(() => {
    return {
      school: tags.find((i) => i.title === 'Academy'),
      yearTag: tags.find((i) => i.title === 'Year'),
    };
  }, [tags]);

  const onChangeSchool = (evt, allSelected, action, current) => {
    onSelectSchool(action === 'clear' ? null : current?.option?.id);
  };

  const onSelectAssignment = (assignment) => {
    if (assignment) {
      setAssignment(assignment);
    }
  };

  return (
    <div className="bg-white rounded-lg p-8 px-10 mb-4 flex">
      <DocumentCard data={file} />
      <div className="grow ml-4 mr-2">
        <InputLinkBox
          className="bg-slate-300 mb-0"
          item={file}
          editable={true}
          onChange={onChange}
          preCheck={preCheck}
        />
        <div className="flex justify-between items-center px-1">
          <DashboardContentHeading className="text-[14px] mt-0 mb-0">
            SELECT TAGS:
          </DashboardContentHeading>
          <DashboardContentHeading className="text-[14px] mt-0 mb-0">
            FEATURED RESOURCE
            <Checkbox
              size="small"
              checked={featured}
              onChange={({ target }) => setFeatured(target?.checked)}
            />
          </DashboardContentHeading>
        </div>
        <Autocomplete
          onChange={onChangeSchool}
          className="px-1 w-full mb-4"
          limitTags={2}
          disableCloseOnSelect
          id="multiple-limit-tags"
          options={school?.subtags || []}
          renderOption={(props, option, { selected }) => (
            <li {...props} className="fort text-xs bg-white cursor-pointer">
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.title || option.name}
            </li>
          )}
          getOptionLabel={(opt) => opt.title || opt.name || `Tag ${opt}`}
          renderInput={(params) => (
            <TextField
              {...params}
              className="p-0 sort-tags w-full"
              placeholder={`${school?.title}`}
            ></TextField>
          )}
        />
        <div className="flex p-1 mb-4">
          <FormControl className="fort w-[80%]" sx={{ paddingRight: '1rem' }} size="small">
            <FilterAutoComplete
              mainTag={{
                title: 'Assignment',
                asSearch: 'folderId',
              }}
              multiple={false}
              tags={onlyChildren}
              selected={selectedAssignment}
              onClear={() => setAssignment(null)} // For Autocomplete
              onClick={onSelectAssignment}
            />
          </FormControl>
          <FormControl className="fort w-[20%]" size="small">
            <FilterAutoComplete
              mainTag={yearTag}
              multiple={false}
              tags={yearTag?.subtags}
              selected={year}
              onClear={() => setYear(null)} // For Autocomplete
              onClick={setYear}
            />
          </FormControl>
        </div>
        {alert && <p className="fond text-white p-1 px-4 bg-[red] rounded-full mb-4">{alert}</p>}
      </div>
      <div>
        <div className="cursor-pointer" onClick={onClickDelete}>
          <TrashSMIcon size={16} />
        </div>
      </div>
    </div>
  );
}
