export default function MainHeadingText({ children, className, ...props }) {
  return (
    <div
      {...props}
      className={
        'main-heading text-[92px] leading-[120px] mt-[100px]' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
    >
      {children}
    </div>
  );
}
