import { Typography } from '@mui/material';
import EditableInput from 'components/UI/atoms/EditableInput';
import useProfile from 'hooks/student/useProfile';
import React from 'react';
function Profile() {
  const { name, formik } = useProfile();
  return (
    <div>
      <Typography
        variant="p"
        className="text-transparent main-heading-stroke text-[100px] 
        font-bold tracking-widest main-heading-stroke uppercase leading-[120px]"
      >
        Profile<sub className="text-[20px] tracking-wide main-heading-stroke-small">Student</sub>{' '}
        <br />
        {name}
      </Typography>
      <div className="w-full flex justify-between flex-wrap mt-[40px]">
        <div className="w-[49%] mt-[30px]">
          <p className="text-white workSans-bold text-[18px] mb-[10px]">Name</p>
          <EditableInput name={'name'} onChange={formik.handleChange} value={formik.values.name} />
        </div>
        <div className="w-[49%] mt-[30px]">
          <p className="text-white workSans-bold text-[18px] mb-[10px]">Email</p>
          <EditableInput
            name={'email'}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </div>
        <div className="w-[49%] mt-[30px]">
          <p className="text-white workSans-bold text-[18px] mb-[10px]">Username</p>
          <EditableInput
            name={'userName'}
            onChange={formik.handleChange}
            value={formik.values.userName}
          />
        </div>
      </div>
    </div>
  );
}

export default Profile;
