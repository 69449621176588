import { useState, useEffect } from 'react';
export default function useFileUploadPanel() {
  const [histories, setHistories] = useState([]);
  const [activeFile, setActiveFile] = useState(null);

  const handleActiveFile = (file) => setActiveFile(file);

  useEffect(() => {
    const assignmentDocuments = [];
    for (let i = 1; i <= 5; i += 1) {
      assignmentDocuments.push({
        title: `Assignment Document ${i}`,
        onClick: () => {
          alert(`Document ${i} Name`);
        },
      });
    }
    setHistories(assignmentDocuments);
    setActiveFile(assignmentDocuments[0]);
  }, []);

  return {
    histories,
    activeFile,
    handleActiveFile,
  };
}
