import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import SubHeadingText from 'components/UI/atoms/SubHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';

import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Admin({ cardsData }) {
  const navigate = useNavigate();
  const { user } = useSelector(({ auth }) => auth);
  const currentUser = user?.value ? JSON.parse(user?.value) : user;
  // TODO: receive data from server

  const adminPages = [
    {
      title: 'Evidence Record',
      onClick: () => navigate('/admin/evidence'),
      id: '1',
    },
    {
      title: 'TRAINING MATERIALS',
      onClick: () => navigate('/admin/materials'),
      id: '2',
    },
    // {
    //   title: 'pending assignments',
    //   onClick: () => navigate('/admin/assignments'),
    //   id: '3',
    // },
    {
      title: 'assigned groups',
      onClick: () => navigate('/admin/groups'),
      id: '4',
    },
    {
      title: 'student search',
      onClick: () => navigate('/admin/search'),
      id: '5',
    },
    {
      title: 'resources academy',
      slug: 'resources-academy',
      onClick: () => navigate('/admin/resources'),
      id: '6',
    },
  ];
  return (
    <div>
      <MainHeadingText>
        <div className="font-extralight uppercase">Welcome</div>
        <div style={{ lineHeight: '85%', margin: '-5px auto' }}>{currentUser?.name || `Admin`}</div>
      </MainHeadingText>
      <div className="my-[32px]">
        <SubHeadingText>
          {currentUser?.academyName || 'Livingstone Academy Bournemouth'}
        </SubHeadingText>
      </div>
      <MainHeadingSeparator />
      <DashboardContentHeading>PORTAL PERMISSIONS</DashboardContentHeading>
      <div onClick={() => navigate('/admin/roles')} className="mb-[64px]">
        <LandscapeCard
          title="Assign roles"
          buttonTitle="START"
          buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
        />
      </div>
      <MainHeadingSeparator />
      <DashboardContentText>
        <strong>
          Welcome to the administration area for the Aspirations Employability Diploma Dossier.
        </strong>
      </DashboardContentText>
      <DashboardContentText>
        The online Dossier Admin area enables uploads of master CSV files for groups of users and
        also for updating or uploading of single users in addition to detail about evidence records
        and notifications.
      </DashboardContentText>
      <LandscapeCardListContainer>
        {adminPages.map((item, index) => {
          const { title, onClick, ...otherProps } = item;
          return (
            <div onClick={() => onClick()} key={index}>
              <LandscapeCard
                title={title}
                buttonTitle="START"
                buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
                {...otherProps}
              />
            </div>
          );
        })}
      </LandscapeCardListContainer>
      <Outlet />
    </div>
  );
}

export default Admin;
