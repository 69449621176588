import { useEffect, useReducer } from 'react';
import http from 'services/http';

const initialData = {
  userList: [],
  userListLoading: false,
  limit: 50,
  page: 1,
  pageCount: 1,
  totalCount: 1,
  firstName: '',
  lastName: '',
  role: -1,
  year: -1,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

let inputTimer = null;

/**
 * @type {AbortController || null}
 */
let abortCtrl = null;
const showYearToTheseIdsOnly = [-1, 3];

export default function useSearchUser() {
  const [state, dispatch] = useReducer(reducer, initialData);

  useEffect(() => {
    getUserList();

    window.addEventListener('assigned-roles.list.reload', getUserList);
    return () => {
      window.removeEventListener('assigned-roles.list.reload', getUserList);
    };
  }, []);

  useEffect(() => {
    if (inputTimer) clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      getUserList();
    }, 300);
  }, [state.limit, state.page, state.lastName, state.firstName, state.role, state.year]);

  const getUserList = async (params = { append: true }) => {
    const { lastName, firstName, append } = params;
    try {
      if (abortCtrl) {
        //
        abortCtrl.abort();
      }
      abortCtrl = new AbortController();

      setState({
        ...(state.userListLoading === false && { userListLoading: true }),
      });

      const result = await http.post(
        '/api/users/search',
        {
          limit: state.limit,
          page: append ? state.page : 1,
          order: 'asc',
          orderBy: 'name',
          lastName: lastName ?? state.lastName,
          firstName: firstName ?? state.firstName,
          ...(state.role !== -1 && { userTypeId: state.role }),
          ...(showYearToTheseIdsOnly.indexOf(state.role) > -1 &&
            state.year !== -1 && { yearId: state.year }),
        },
        {
          signal: abortCtrl.signal,
        }
      );
      setState({
        userListLoading: false,
        userList: result.data.list,
        pageCount: result.data.pageCount,
        totalCount: result.data.totalCount,
      });

      window.dispatchEvent(new Event('userlist.scrollToTop'));
      window.dispatchEvent(new CustomEvent('request.success', { detail: result }));
    } catch (err) {
      console.log(err);
      setState({
        ...(state.userListLoading === true && { userListLoading: false }),
      });
      window.dispatchEvent(new CustomEvent('request.error', { detail: err }));
    }
  };

  const setState = (newState) => {
    dispatch(newState);
  };

  return {
    ...state,
    setState,
    getUserList,
  };
}
