import React from 'react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

/**
 *
 * @param {import('react').HTMLAttributes} param0
 * @returns
 */
function TransparentButton({ size, onClick, style }) {
  return (
    <div>
      <ArrowForwardIosOutlinedIcon
        onClick={onClick}
        style={{
          color: '#fff',
          fontSize: size || '45px',
          backgroundColor: 'transparent',
          border: '2px solid #fff',
          borderRadius: '50%',
          padding: '10px',
          cursor: 'pointer',
          ...style,
        }}
      />
    </div>
  );
}

export default TransparentButton;
