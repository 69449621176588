export default function PlusIcon({ size, ...props }) {
  const cssProps = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: 0.838,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 13.576 13.576"
      {...props}
    >
      <g id="Group_234" data-name="Group 234" transform="translate(-4461.31 -1397.914)">
        <line
          id="Line_45"
          data-name="Line 45"
          x2="13.576"
          transform="translate(4461.31 1404.702)"
          {...cssProps}
        />
        <line
          id="Line_46"
          data-name="Line 46"
          y2="13.576"
          transform="translate(4468.098 1397.914)"
          {...cssProps}
        />
      </g>
    </svg>
  );
}
