import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doResetPassword } from 'redux/reducers/auth';
import * as yup from 'yup';
function useResetPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.isLoading);
  const errMessage = useSelector((state) => state.auth.errMessage);

  useEffect(() => {
    if (errMessage !== 'null') {
      setToast({
        status: true,
        message: errMessage,
      });
    }
  }, [errMessage]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    token: searchParams.get('token'),
  });

  const [error, setError] = useState({
    errStatus: '',
    errMessage: '',
  });

  const [toast, setToast] = useState({
    status: false,
    message: '',
  });

  const onPasswordChange = (event) => {
    setError('');
    setValues({ ...values, password: event.target.value });
  };

  const onConfirmPasswordChange = (event) => {
    setError('');
    setValues({ ...values, confirmPassword: event.target.value });
  };

  const schema = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password too short')
      .matches(/[A-Z]/, 'Password must contain capital letter')
      .matches(/[a-z]/, 'Password must contain small letter')
      .matches(/[0-9]/, 'Password must contain number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must special character'),

    confirmPassword: yup.string().test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
  });

  const onSubmit = async () => {
    if (loading) return;
    try {
      await schema.validate(values);
      await dispatch(doResetPassword({ values })).unwrap();
      setToast({
        status: true,
        message: 'Password reset Successfully!',
      });
      setValues({
        email: '',
        password: '',
      });
      setError({
        errStatus: '',
        errMessage: '',
      });

      setTimeout(() => {
        navigate('/login');
      }, 1000);
    } catch (e) {
      setError({ errStatus: true, errMessage: e.message });
    }
  };
  return {
    values,
    onPasswordChange,
    onConfirmPasswordChange,
    onSubmit,
    error,
    toast,
    setToast,
    loading,
  };
}

export default useResetPassword;
