import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import PlusBox from 'components/UI/atoms/PlusBox';
// import PlusBoxHolder from 'components/UI/atoms/PlusBoxHolder';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';

import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import useUser from 'hooks/auth/useUser';

// import StrokeText from 'components/UI/atoms/StrokeText';

import SearchStudent from 'components/UI/organisms/SearchStudent';
// import useStudentQuery from 'hooks/student/useStudentQuery';

function SearchStudentPage() {
  const { user } = useUser();
  const breadcrumbsItems = [
    {
      text: 'Student Search',
      path: '/trainer/search',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainHeadingText>
        Student <br /> Search
      </MainHeadingText>
      <Breadcrumb items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {/* 
      <DashboardContentHeading className='mt-[32px]'>
        RECENTLY VIEWED STUDENTS
      </DashboardContentHeading>
      <div className='flex w-full justify-start relative'>
        <div className='flex justify-start'>
          <PlusBoxHolder>
            <PlusBox onClick={() => console.log('create below')} />
          </PlusBoxHolder>
        </div>
        <StrokeText className='text-[36px] ml-[32px] max-w-[720px]'>
          No students <br /> Search <br /> history
        </StrokeText>
      </div> */}
      <DashboardContentHeading>STUDENT SEARCH</DashboardContentHeading>
      <DashboardContentText className="mb-8">
        <strong>Navigate to individual student records.</strong>
      </DashboardContentText>
      <DashboardContentText className="mb-8">
        {/* {user.userTypeId === 1 && (
          <>
            This is the full Admin version of the Student Evidence Record in grid format.
          </>
        )} */}
        {user.userTypeId === 2 && (
          <>
            Your main navigation point for finding a particular student. You can search for a group
            name or the student’s name directly. You can then upload materials directly to that
            student or you can view their evidence record and any pending evidence for grading.
          </>
        )}
      </DashboardContentText>
      {/* TODO: put this to an organism? but i feel like it's not to be placed there */}
      <SearchStudent allowSelectStudent={true} allowAddToGroup={true} />
    </div>
  );
}

export default SearchStudentPage;
