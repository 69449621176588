import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreateExperience from './CreateExperience';
import KnowYourProfile from './KnowYourProfile';
import KnowYourProfileRoutes from './KnowYourProfileSubRoutes/KnowYourProfileRoutes';
import DevelopYourStory from './DevelopYourStory';
import ReviewYourProfileDevelopment from './ReviewYourProfileDevelopment';
import EngageProfessionally from './EngageProfessionally';
import PlatinumAward from './PlatinumAward';
import CreateExperienceRoutes from './CreateExperienceSubRoutes/CreateExperienceRoutes';

export default function EngagementRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <Routes>
      <Route path="/know-your-profile" element={<KnowYourProfile />} />
      <Route path="/know-your-profile/*" element={<KnowYourProfileRoutes />} />

      <Route path="/develop-your-story" element={<DevelopYourStory />} />

      <Route path="/create-experience" element={<CreateExperience />} />
      <Route path="/create-experience/*" element={<CreateExperienceRoutes />} />

      <Route path="/review-your-profile-development" element={<ReviewYourProfileDevelopment />} />
      <Route path="/engage-professionally" element={<EngageProfessionally />} />
      <Route path="/platinum-award" element={<PlatinumAward />} />
    </Routes>
  );
}
