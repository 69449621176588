import useDirectories from 'hooks/student/useDirectories';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlusIcon from '../atoms/CustomIcons/PlusIcon';
import LandscapeCard from './LandscapeCard';

function BottomLandscapeCard({ title, buttonTitle }) {
  const { list } = useDirectories();
  const evidenceRecord = list.find((item) => item.path === 'evidence-record');
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/student/purpose/evidence-record?folderId=${evidenceRecord.id}`);
  };
  return (
    <LandscapeCard
      title={title}
      buttonTitle={buttonTitle}
      buttonIcon={<PlusIcon size={18} className="pl-[2px]" />}
      onClick={onClick}
    />
  );
}

export default BottomLandscapeCard;
