import useUser from 'hooks/auth/useUser';
import * as Yup from 'yup';
import { useFormik } from 'formik';

function useProfile() {
  const { user } = useUser();
  const name = user.name;
  const formik = useFormik({
    initialValues: {
      name: user.name,
      userName: user.username,
      email: user.email,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      userName: Yup.string().required('Required'),
      email: Yup.string().email('Email is not valid').required('Required'),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return { name, formik };
}

export default useProfile;
