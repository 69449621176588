import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {import('../../../utils/createFileInput').UploadFile} UploadFile
 */

/**
 * @typedef {Object} ProfessionalDevelopmentRecordState
 * @prop {UploadFile[]} uploadFiles
 * @prop {UploadFile} yourCV
 * @prop {UploadFile} yourPresentationSkills
 */

/**
 * @type {Object<string, ProfessionalDevelopmentRecordState>}
 */
const initialState = {
  collections: {},
};

const professionalDevelopmentRecordSlice = createSlice({
  name: 'professionalDevelopmentRecordState',
  initialState,
  reducers: {
    setUploadFiles: (state, action) => {
      // state.uploadFiles = action.payload;
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections[action.payload.id].uploadFiles =
        state.collections[action.payload.id].uploadFiles || [];
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          uploadFiles: action.payload.data,
        },
      };
      return state;
    },

    appendUploadFiles: (state, action) => {
      // state.uploadFiles = [...state.uploadFiles, ...action.payload];
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections[action.payload.id].uploadFiles =
        state.collections[action.payload.id].uploadFiles || [];
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          uploadFiles: [
            ...state.collections[action.payload.id].uploadFiles,
            ...action.payload.data,
          ],
        },
      };
      return state;
    },

    setYourCV: (state, action) => {
      // state.projectPresentation = action.payload;
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          yourCV: action.payload.data,
        },
      };
      return state;
    },

    setYourPresentationSkills: (state, action) => {
      // state.projectProgressSnapshot = action.payload;
      state.collections[action.payload.id] = state.collections[action.payload.id] || {};
      state.collections = {
        ...state.collections,
        [action.payload.id]: {
          ...state.collections[action.payload.id],
          yourPresentationSkills: action.payload.data,
        },
      };
      return state;
    },
  },
});

export const { setUploadFiles, appendUploadFiles, setYourCV, setYourPresentationSkills } =
  professionalDevelopmentRecordSlice.actions;

export default professionalDevelopmentRecordSlice.reducer;

// import { createSlice } from '@reduxjs/toolkit';

// /**
//  * @typedef {import('../../../utils/createFileInput').UploadFile} UploadFile
//  */

// /**
//  * @typedef {Object} ProfessionalDevelopmentRecordState
//  * @prop {UploadFile} yourCV
//  * @prop {UploadFile} yourPresentationSkills
//  */

// /**
//  * @type {ProfessionalDevelopmentRecordState}
//  */
// const initialState = {
//   yourCV: null,
//   yourPresentationSkills: null,
// };

// const professionalDevelopmentRecordSlice = createSlice({
//   name: 'professionalDevelopmentRecordState',
//   initialState,
//   reducers: {
//     setYourCV: (state, action) => {
//       state.yourCV = action.payload;
//       // return state;
//     },
//     setCVPresentationSkills: (state, action) => {
//       state.yourPresentationSkills = action.payload;
//       // return state;
//     },
//   },
// });

// export const { setYourCV, setCVPresentationSkills } = professionalDevelopmentRecordSlice.actions;

// export default professionalDevelopmentRecordSlice.reducer;
