/**
 *
 * @param {import("react").HtmlHTMLAttributes &
 * {
 *  inputProps: import("react").InputHTMLAttributes,
 *  className: string
 * }} param0
 * @returns
 */
export default function OutlineSelect({ children, className, inputProps = {}, ...props }) {
  const { className: inputClassName, ...otherInputProps } = inputProps;
  return (
    <div
      {...props}
      className={`relative font-extralight flex flex-row h-[44px] w-full rounded-[8px] border-[2px] border-white overflow-hidden ${
        typeof className === 'string' ? className : ''
      }`}
    >
      <select
        {...otherInputProps}
        className={`font-extralight bg-black/30 outline-none h-[40px] w-full px-[16px] py-[4px] box-border appearance-none focus:bg-black/50 ${
          typeof inputClassName === 'string' ? inputClassName : ''
        }`}
      >
        {children}
      </select>
      <div className="text-[24px] text-[#FFF] absolute right-[6px] pointer-events-none flex items-center h-[40px]">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 48 48">
          <path d="M24 29 13.8 18.8l.8-.8 9.4 9.4 9.4-9.4.8.8Z" stroke="currentColor" />
        </svg>
      </div>
    </div>
  );
}
