export default function PurposeIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 22.028 22.028"
    >
      <g id="Group_2789" data-name="Group 2789" transform="translate(-5.061 -1236.757)">
        <g id="Group_2790" data-name="Group 2790" transform="translate(5.03 1236.952)">
          <g id="Group_2791" data-name="Group 2791">
            <rect
              id="Rectangle_2329"
              data-name="Rectangle 2329"
              width="0.473"
              height="0.473"
              transform="translate(6.273 8.164)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2330"
              data-name="Rectangle 2330"
              width="0.473"
              height="0.473"
              transform="translate(5.091 7.455)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2331"
              data-name="Rectangle 2331"
              width="0.473"
              height="0.473"
              transform="translate(3.909 6.746)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2332"
              data-name="Rectangle 2332"
              width="0.473"
              height="0.473"
              transform="translate(8.164 6.273)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2333"
              data-name="Rectangle 2333"
              width="0.473"
              height="0.473"
              transform="translate(7.455 5.091)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2334"
              data-name="Rectangle 2334"
              width="0.473"
              height="0.473"
              transform="translate(6.746 3.909)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2335"
              data-name="Rectangle 2335"
              width="0.473"
              height="0.473"
              transform="translate(10.528 4.382)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2336"
              data-name="Rectangle 2336"
              width="0.473"
              height="0.473"
              transform="translate(10.528 5.564)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2337"
              data-name="Rectangle 2337"
              width="0.473"
              height="0.473"
              transform="translate(10.528 3.2)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2338"
              data-name="Rectangle 2338"
              width="0.473"
              height="0.473"
              transform="translate(14.782 8.164)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2339"
              data-name="Rectangle 2339"
              width="0.473"
              height="0.473"
              transform="translate(15.964 7.455)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2340"
              data-name="Rectangle 2340"
              width="0.473"
              height="0.473"
              transform="translate(17.146 6.746)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2341"
              data-name="Rectangle 2341"
              width="0.473"
              height="0.473"
              transform="translate(12.891 6.273)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2342"
              data-name="Rectangle 2342"
              width="0.473"
              height="0.473"
              transform="translate(13.601 5.091)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2343"
              data-name="Rectangle 2343"
              width="0.473"
              height="0.473"
              transform="translate(14.31 3.909)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2344"
              data-name="Rectangle 2344"
              width="0.473"
              height="0.473"
              transform="translate(4.382 10.528)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2345"
              data-name="Rectangle 2345"
              width="0.473"
              height="0.473"
              transform="translate(5.564 10.528)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2346"
              data-name="Rectangle 2346"
              width="0.473"
              height="0.473"
              transform="translate(3.2 10.528)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2347"
              data-name="Rectangle 2347"
              width="0.473"
              height="0.473"
              transform="translate(6.273 12.892)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2348"
              data-name="Rectangle 2348"
              width="0.473"
              height="0.473"
              transform="translate(5.091 13.601)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2349"
              data-name="Rectangle 2349"
              width="0.473"
              height="0.473"
              transform="translate(3.909 14.31)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2350"
              data-name="Rectangle 2350"
              width="0.473"
              height="0.473"
              transform="translate(16.673 10.528)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2351"
              data-name="Rectangle 2351"
              width="0.473"
              height="0.473"
              transform="translate(15.492 10.528)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2352"
              data-name="Rectangle 2352"
              width="0.473"
              height="0.473"
              transform="translate(17.855 10.528)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2353"
              data-name="Rectangle 2353"
              width="0.473"
              height="0.473"
              transform="translate(14.782 12.892)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2354"
              data-name="Rectangle 2354"
              width="0.473"
              height="0.473"
              transform="translate(15.964 13.601)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <rect
              id="Rectangle_2355"
              data-name="Rectangle 2355"
              width="0.473"
              height="0.473"
              transform="translate(17.146 14.31)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <circle
              id="Ellipse_1161"
              data-name="Ellipse 1161"
              cx="1.475"
              cy="1.475"
              r="1.475"
              transform="translate(9.289 7.868)"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              id="Path_1316"
              data-name="Path 1316"
              d="M63.554,91.155v4.6H62.431V89.826a1.093,1.093,0,0,1,1.093-1.093h3.43a1.093,1.093,0,0,1,1.093,1.093v5.927H66.924v-4.6"
              transform="translate(-54.475 -77.425)"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <rect
              id="Rectangle_2356"
              data-name="Rectangle 2356"
              width="0.562"
              height="0.562"
              transform="translate(10.483 12.431)"
              fill="currentColor"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            />
            <line
              id="Line_561"
              data-name="Line 561"
              x2="3.369"
              transform="translate(9.079 17.807)"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <path
              id="Path_1318"
              data-name="Path 1318"
              d="M40.373,1240.481a10.764,10.764,0,0,0-10.764,10.764,10.888,10.888,0,0,0,4.316,8.62,10.184,10.184,0,0,0,3.208,1.658,10.535,10.535,0,0,0,3.24.486,10.764,10.764,0,0,0,0-21.528Z"
              transform="translate(-29.328 -1240.427)"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
