import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featuredUploads: [],
  searchedBy: {},
  isLoading: true,
  selectedResources: {},
  resources: [],
  resourceTopics: [],
  resourceFileTypes: [],
  resourceSubjects: [],
  resourceYears: [],
  resourceAcademies: [],
};

const manageResourceAcademy = createSlice({
  name: 'manageResourceAcademy',
  initialState,
  reducers: {
    toggleResource: (state, { payload }) => {
      if (!state.selectedResources[payload.id]) {
        state.selectedResources[payload.id] = payload;
      } else {
        delete state.selectedResources[payload.id];
      }
    },
    setResources: (state, { payload }) => {
      state.resources = payload;
    },
    setTopics: (state, { payload }) => {
      state.resourceTopics = payload;
    },
    setFileTypes: (state, { payload }) => {
      state.resourceFileTypes = payload;
    },
    setSubjects: (state, { payload }) => {
      state.resourceSubjects = payload;
    },
    setYears: (state, { payload }) => {
      state.resourceYears = payload;
    },
    setAcademies: (state, { payload }) => {
      state.resourceAcademies = payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilter: (state, { payload }) => {
      state.searchedBy = payload;
    },
  },
});

export const {
  toggleResource,
  setLoading,
  setResources,
  setTopics,
  setFileTypes,
  setSubjects,
  setYears,
  setAcademies,
  setFilter,
} = manageResourceAcademy.actions;

export default manageResourceAcademy.reducer;
