import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Input from 'components/UI/atoms/Input';
import BaseList from 'components/UI/atoms/BaseList';
import PaperIcon from 'components/UI/atoms/CustomIcons/PaperIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import CheckItem from 'components/UI/molecules/CheckItem';
import CircularProgress from '@mui/material/CircularProgress';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useTagQuery from 'hooks/misc/useTagQuery';
import TagAutocompleteFilter from './TagAutoCompleteFilter';
import useDebounce from 'hooks/useDebounce';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
// import FilterAutoComplete from '../molecules/FilterAutoComplete';

export default function SearchMaterialExtended({
  loading = false,
  addToGroup = () => {},
  showAssignBtn = true,
  assignTitle = 'ADD TO GROUP',
  showSelectedMaterialsCount = true,
  showEvidenceNavigateBtn = false,
  evidenceNavigatePath = '/',
  onNext = () => {},
  showNextBtn = false,
  onPrev = () => {},
  showPrevBtn = false,
  materials,
  selected,
  checkMaterial,
  onSearch,
  loadingMaterials,
  optionState,
}) {
  const navigate = useNavigate();
  const { tags, selectedUuids, addSelected, clearSelected, featured, setFeatured } = useTagQuery();
  const allowedTags = ['File Type', 'Assignment', 'Year', 'Academy'];
  const [searchFile, setSearchFileFileName] = useState('');
  const deferredText = useDebounce(searchFile, 400);

  const handleAddToGroup = () => addToGroup(selected);

  useEffect(() => {
    onSearch(selectedUuids, searchFile, featured);
  }, [selectedUuids, deferredText, featured]);

  return (
    <div className="mb-4 grid grid-cols-1 lg:grid-cols-2">
      <div className="pr-6 w-[90%]">
        <div className="flex justify-between">
          <h5 className="fort uppercase mb-2">Search by popular tags</h5>
          <FormControlLabel
            sx={{ marginRight: '0' }}
            control={<Checkbox />}
            onChange={({ target }) => setFeatured(target?.checked)}
            label={<label className={'fort text-sm'}>Featured</label>}
          />
        </div>
        <div className="mb-4">
          {tags
            .filter((tag) => allowedTags.indexOf(tag.title) !== -1)
            .sort((a, b) => (a.title !== 'File Type' && a.title !== 'Year' ? -1 : 0))
            .map((i, k) => (
              <TagAutocompleteFilter
                key={k}
                mainTag={i}
                tags={i.subtags}
                selected={selectedUuids[i.asSearch] || []}
                onClear={clearSelected} // For Autocomplete
                onClick={addSelected}
              />
            ))}
        </div>

        <h5 className="fort uppercase mb-2">Search by Name</h5>
        <Input
          placeholder="Document Name"
          className="text-left w-[100%] mb-4 rounded-lg p-2 shadow"
          value={searchFile}
          onChange={({ target }) => setSearchFileFileName(target.value)}
        />
      </div>
      {loadingMaterials ? (
        <div className="grow flex items-center justify-center">
          <CircularProgress size={72} />
        </div>
      ) : (
        <div>
          {showSelectedMaterialsCount && (
            <DashboardContentHeading className="text-[14px]">
              RESOURCES: {selected.length} / {optionState.totalCount}
            </DashboardContentHeading>
          )}
          <div className="grow">
            <BaseList listClassName="p-4 bg-white mb-4 rounded-lg h-[280px]">
              {materials.map((i, k) => (
                <CheckItem
                  key={k}
                  onClick={() => checkMaterial(i)}
                  title={i.fileName}
                  subtitle={i.academyName}
                  active={i.active || false}
                  svgicon={<PaperIcon size={20} active={i.active || false} />}
                />
              ))}
            </BaseList>
            <div className={`flex ${showEvidenceNavigateBtn ? 'justify-between' : 'justify-end'}`}>
              <div className="flex">
                {showPrevBtn && (
                  <div
                    className="bg-transparent mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center"
                    onClick={() => onPrev(selected)}
                  >
                    <ChevronLeftIcon
                      style={{
                        fontSize: '32px',
                      }}
                    />
                  </div>
                )}

                {showNextBtn && (
                  <div
                    className="bg-transparent mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center"
                    onClick={() => onNext(selected)}
                  >
                    <ChevronRightIcon
                      style={{
                        fontSize: '32px',
                      }}
                    />
                  </div>
                )}
                {loading ? (
                  <CircularProgress />
                ) : (
                  showAssignBtn && (
                    <OvalIconButton
                      onClick={handleAddToGroup}
                      title={assignTitle}
                      className={`self-end ${
                        !selected.length && 'hover:border-black/50 hover:text-black/100'
                      }`}
                      disabled={!selected.length}
                      icon={
                        <ChevronRightIcon
                          style={{
                            fontSize: '44px',
                            fontWeight: 100,
                          }}
                        />
                      }
                    />
                  )
                )}
              </div>

              {showEvidenceNavigateBtn && (
                <OvalIconButton
                  onClick={() => navigate(evidenceNavigatePath)}
                  title="VIEW EVIDENCE RECORD"
                  className="self-end"
                  icon={
                    <ChevronRightIcon
                      style={{
                        fontSize: '44px',
                        fontWeight: 100,
                      }}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
