import GradeModalFileItem from 'components/UI/molecules/GradeModalFileItem';
import OvalIconButtonWhite from 'components/UI/molecules/OvalIconButtonWhite';
import RadioScoreButton from 'components/UI/molecules/Trainer/RadioScoreButton';
import useUser from 'hooks/auth/useUser';

// import useEvidenceRecordGrades from 'hooks/trainer/EvidenceRecord/useEvidenceRecordGrades';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import useEvidenceRecordGrades from 'hooks/student/useEvidenceRecordGrades';
import useEvidenceSkillUpload, { useSkillViewContext } from 'hooks/student/useEvidenceSkillUpload';
import useEvidenceCompetencyState from 'hooks/student/useEvidenceCompetencyState';
import useEvidenceSkillsState from 'hooks/student/useEvidenceSkillsState';
// import logger from 'utils/logger';

export default function EvidenceRecordGradeViewModal({ open = false, onClose, ...props }) {
  const { gradedItems: evidenceGrades } = useEvidenceRecordGrades();
  const { target, slot1 } = useEvidenceSkillUpload();
  const { selectedItem } = useSkillViewContext();
  const { list: competencyList } = useEvidenceCompetencyState();
  const { list: skillList } = useEvidenceSkillsState();
  const { token } = useUser();
  const { fsId, pcId } = target;
  const selectedUploadsOnly = slot1.filter((item) => {
    const _fsId = item.file.fsId;
    const _pcId = item.file.pcId;
    return selectedItem.fsId === _fsId && selectedItem.pcId === _pcId;
  });

  const currentGradeData = evidenceGrades.find((item) => {
    return item.fsId === fsId && item.pcId === pcId;
  });

  const [skillScore] = useState(currentGradeData?.skillScore || 0);
  const [competencyScore] = useState(currentGradeData?.competencyScore || 0);
  const [comments] = useState(currentGradeData?.comments || '');
  const onScoreChange = (e) => {
    // if (e.target.name === 'skillScore') {
    //   setSkillScore(Number(e.target.value));
    // } else {
    //   setCompetencyScore(Number(e.target.value));
    // }
  };

  const currentFS = skillList.find((item) => item.id === fsId);
  const currentPC = competencyList.find((item) => item.id === pcId);

  const closeModal = () => {
    typeof onClose === 'function' && onClose();
  };

  const onDownloadClick = () => {
    if (!Array.isArray(selectedUploadsOnly)) return;
    for (const item of selectedUploadsOnly) {
      const url = new URL(item.filePath);
      url.searchParams.append('token', token);
      const toOpen = url.toString();
      window.open(toOpen, item.id);
    }
  };

  const latestUpload = selectedUploadsOnly.sort((a, b) => {
    return -(a.updatedAt > b.updatedAt ? 1 : b.updatedAt > a.updatedAt ? -1 : 0);
  })[0];

  return ReactDOM.createPortal(
    open ? (
      <div
        className="fixed top-0 left-0 bottom-0 right-0 overflow-x-hidden overflow-y-auto flex flex-row items-center z-10 p-[16px] text-white"
        {...props}
      >
        <div
          className="bg-black/80 fixed top-0 left-0 bottom-0 right-0 sb2-txt-fade"
          onClick={closeModal}
        ></div>
        <div className="sb2-txt-fade fort font-extralight relative modal-bg w-full min-h-[400px] max-w-[640px] m-auto rounded-[8px] border-white border-[2px] p-[56px]">
          <button
            onClick={closeModal}
            className="rounded-full bg-white h-[32px] w-[32px] flex text-[#aaa]
                        items-center justify-center box-border p-[0px] absolute top-[16px] 
                        right-[16px]"
          >
            <CloseIcon style={{ fontSize: 16 }} />
          </button>
          <div className="mb-[16px] flex flex-col gap-[8px]">
            {/* {selectedUploadsOnly.map((item) => (
              <GradeModalFileItem data={item.file} key={item.file.id} />
            ))} */}
            {latestUpload && <GradeModalFileItem data={latestUpload.file} />}
          </div>
          {Array.isArray(selectedUploadsOnly) && selectedUploadsOnly.length > 0 && (
            <OvalIconButtonWhite text="DOWNLOAD FILES" onClick={onDownloadClick} />
          )}
          <div className="text-[24px] font-normal mt-[32px]">
            {currentFS.code}: {currentFS.title}
          </div>
          <div className="text-[22px]">{currentFS.subtitle}</div>
          <div className="mt-[16px] uppercase">{currentFS.description || 'Description text'}</div>

          <div className="text-[18px] workSans-bold mt-[32px] mb-[16px]">
            {/* {currentFS.code} EVIDENCE SCORE */}
            SKILL EVIDENCE SCORE
          </div>
          <div className="flex flex-row items-center gap-[16px]">
            <RadioScoreButton
              name="skillScore"
              id="fs0"
              value={0}
              onChange={onScoreChange}
              checked={skillScore === 0}
              text="00"
              disabled={true}
            />

            <RadioScoreButton
              name="skillScore"
              id="fs1"
              value={1}
              onChange={onScoreChange}
              checked={skillScore === 1}
              text="01"
              disabled={true}
            />

            <RadioScoreButton
              name="skillScore"
              id="fs2"
              value={2}
              onChange={onScoreChange}
              checked={skillScore === 2}
              text="02"
              disabled={true}
            />

            <RadioScoreButton
              name="skillScore"
              id="fs3"
              value={3}
              onChange={onScoreChange}
              checked={skillScore === 3}
              text="03"
              disabled={true}
            />
          </div>

          <div className="text-[24px] mt-[32px] uppercase">
            <span className="font-normal">{currentPC.code}: </span>
            <span>{currentPC.title}</span>
          </div>
          <div className="text-[18px] workSans-bold mt-[32px] mb-[16px]">
            COMPETENCY EVIDENCE SCORE
            {/* {currentPC.code} EVIDENCE SCORE */}
          </div>
          <div className="flex flex-row items-center gap-[16px]">
            <RadioScoreButton
              name="competencyScore"
              id="pc0"
              value={0}
              onChange={onScoreChange}
              checked={competencyScore === 0}
              text="00"
              disabled={true}
            />

            <RadioScoreButton
              name="competencyScore"
              id="pc1"
              value={1}
              onChange={onScoreChange}
              checked={competencyScore === 1}
              text="01"
              disabled={true}
            />

            <RadioScoreButton
              name="competencyScore"
              id="pc2"
              value={2}
              onChange={onScoreChange}
              checked={competencyScore === 2}
              text="02"
              disabled={true}
            />

            <RadioScoreButton
              name="competencyScore"
              id="pc3"
              value={3}
              onChange={onScoreChange}
              checked={competencyScore === 3}
              text="03"
              disabled={true}
            />
          </div>

          <div className="bg-white rounded-[16px] p-[16px] mt-[56px]">
            <div className="text-black font-normal tracking-[2px] pb-[8px] border-b-[1px] border-black px-[8px]">
              COMMENTS
            </div>
            <div className="w-full h-[180px] list-scroll overflow-y-auto p-[8px] text-black outline-none resize-none mt-[16px] font-extralight">
              {comments}
            </div>
          </div>
        </div>
      </div>
    ) : null,
    document.body
  );
}
