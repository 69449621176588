import React from 'react';

function LandscapeCard({
  onClick,
  title,
  buttonTitle,
  buttonIcon,
  bgComponent,
  titleTextStyle,
  textColor = null,
  boxBorderWidth = 0,
}) {
  return (
    <div
      onClick={onClick}
      className={
        `group w-[300px] min-h-[232px] border-white/0 first-letter ` +
        `transition-all relative ease-in-out duration-[.2s] ` +
        'cursor-pointer shadow-md shadow-black/20 bg-white rounded-[12px] overflow-hidden box-content'
      }
      style={{
        borderWidth: boxBorderWidth,
      }}
    >
      <div className="logo-text hidden group-hover:block absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-r from-[#af3635] to-[#8f3132]"></div>
      {bgComponent && <div className="absolute top-0 left-0 bottom-0 right-0">{bgComponent}</div>}
      <p
        className="relative uppercase text-black group-hover:text-white fort p-5 px-7 leading-7 text-[20px] tracking-[3px] pt-[24px]"
        style={{ color: textColor, ...titleTextStyle }}
      >
        {title}
      </p>
      <div className="expanden-oval-btn group lc-btn absolute left-7 bottom-5 group-hover:text-white">
        <div
          className="relative w-[40px] overflow-hidden flex items-center group-hover:w-56 h-[40px] transition-all relative ease-in-out duration-[.3s] 
                      rounded-full bg-transparent border-[#777] border-[1px] group-hover:border-white/0 group-hover:bg-white/0"
          style={{ borderColor: textColor }}
        >
          <div className="hidden group-hover:block rounded-full absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-r from-[rgba(0,0,0,0)] to-[rgba(255,255,255,0.5)]" />
          <p
            className="workSans-bold tracking-[2px] text-[14px] absolute left-4 opacity-0 group-hover:opacity-100 group-hover:text-white transition-all 
                        duration-[0.3s] btn-title"
            style={{ color: textColor }}
          >
            {buttonTitle}
          </p>
          <div
            style={{ color: textColor }}
            className="absolute h-[40px] w-[40px] rounded-full bg-transparent items-center flex right-0 justify-center pointer-events-none"
          >
            {buttonIcon}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandscapeCard;
