import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import useAssignmentDirectory from 'hooks/student/useAssignmentDirectory';
import { CircularProgress } from '@mui/material';

export default function FileSystemNavigator({ setSelectedSkill }) {
  const { getList, treeList, isLoading } = useAssignmentDirectory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (treeList.length === 0) {
      getList(true, {}, true);
    }
  }, []);
  const onNodeSelected = (node) => {
    if (
      !node.children.length &&
      node.name !== 'Evidence Record' &&
      node.path !== 'evidence-record'
    ) {
      setSelectedSkill(node.id);
    } else {
      setSelectedSkill();
    }
  };

  const renderTree = (nodes) => {
    // This is for the root case
    if (!nodes.id && nodes.length) {
      return nodes.map((node) => renderTree(node));
    }
    return (
      <TreeItem
        key={+nodes.id}
        nodeId={+nodes.id}
        label={nodes.name}
        onClick={() => {
          onNodeSelected(nodes);
        }}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };

  return (
    <div className={`flex ${isLoading ? 'loader-container' : ''}`}>
      {!treeList.length && isLoading ? (
        <CircularProgress />
      ) : (
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            width: '600px',
            height: '40vh',
            flexGrow: 1,
            maxWidth: '600px',
            overflowY: 'auto',
            paddingTop: '30px',
            marginBottom: '20px',
          }}
        >
          {renderTree(treeList)}
        </TreeView>
      )}
    </div>
  );
}
