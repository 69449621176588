import { Route, Routes } from 'react-router-dom';
import EvidenceRecord from 'pages/trainer/trainerSubRoutes/EvidenceRecord/EvidenceRecord';
import StudentPageSearchView from './EvidenceRecordParts/StudentPageSearchView';

export default function EvidenceRecordRoutes() {
  return (
    <Routes>
      <Route path={''} element={<StudentPageSearchView />} />
      <Route path={':uuid/*'} element={<EvidenceRecord />} />
    </Routes>
  );
}
