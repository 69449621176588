import { configureStore } from '@reduxjs/toolkit';

import auth from './reducers/auth';
import fileUpload from './reducers/fileUpload';
import uploadedFile from './reducers/uploadedFile';
import userlist from './reducers/userlist';
import studentProjects from './reducers/studentProjects';
import sidebarStatus from './reducers/sidebarStatus';
import skillList from './reducers/skillList';
import cvDevelopment from './reducers/student/cvDevelopment';
import developYourStory from './reducers/student/developYourStoryState';
import projectSingleView from './reducers/student/projectSingleViewState';
import reviewYourProfileDevelopment from './reducers/student/reviewYourProfileDevelopmentState';
import engageProfessionally from './reducers/student/engageProfessionallyState';
import platinumAward from './reducers/student/platinumAwardState';
import finishedCVFinalSnapshot from './reducers/student/finishedCVFinalSnapshotState';
import evidenceSkills from './reducers/student/evidenceSkillsState';
import evidenceCompetency from './reducers/student/evidenceCompetencyState';
import evidenceSkillsCompetency from './reducers/student/evidenceSkillsCompetencyState';
import directories from './reducers/student/directories';
import assignmentDirectory from './reducers/assignmentDirectories';
// import professional development record from reducer
import professionalDevelopmentRecord from './reducers/student/professionalDevelopmentRecordState';
// trainer reducers
import manageGroupInstance from './reducers/trainer/manageGroupInstance';
import manageResourceAcademy from './reducers/trainer/resources';

const store = configureStore({
  reducer: {
    auth,
    userlist,
    studentProjects,
    fileUpload,
    sidebarStatus,
    skillList,
    cvDevelopment,
    developYourStory,
    projectSingleView,
    reviewYourProfileDevelopment,
    engageProfessionally,
    platinumAward,
    finishedCVFinalSnapshot,
    professionalDevelopmentRecord,
    assignmentDirectory,
    // trainer
    manageGroupInstance,
    evidenceSkills,
    evidenceCompetency,
    evidenceSkillsCompetency,
    manageResourceAcademy,
    directories,
    // trainer or admin
    uploadedFile,
  },
});

export default store;
