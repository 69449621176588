import { useState, useEffect } from 'react';
import linkFileParser from 'utils/linkFileParser';

export default function useURLInput(setAlerts = null) {
  const [focused, setFocus] = useState(false);
  const [links, setLinks] = useState([]);
  const [blank, setBlank] = useState(null);
  const [text, setText] = useState('');
  const [preCheck, setCheck] = useState(false);

  const onNewLinkChange = ({ target }) => {
    setLinks([]);
    setBlank({ ...blank, fileName: target.value });
    setText(target.value);
  };

  const onNameLinkChange = ({ target }) =>
    setLinks(links.map((i) => ({ ...i, fileName: target.value })));

  useEffect(() => {
    if (!focused) return;
    if (text === '') {
      if (setAlerts) setAlerts('Please add a valid google drive link!');
      setCheck(false);
      return;
    }
    const newLink = linkFileParser(text);
    if (!newLink) {
      setAlerts('Please update to a valid google drive link!');
      setCheck(false);
      return;
    }
    setLinks([newLink]);
    // setBlank(newLink);
    setCheck(true);
  }, [text]);

  return {
    text,
    links,
    blank,
    preCheck,
    setFocus,
    // Modifiers
    onNewLinkChange,
    onNameLinkChange,
  };
}
