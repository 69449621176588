import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAll } from 'redux/reducers/student/directories';
import http from 'services/http';
import { generateDataTree } from 'utils/flatToTree';

export default function useDirectories() {
  const { list, isLoading } = useSelector((state) => state.directories);
  const dispath = useDispatch();
  const getDirectories = async () => {
    const result = await http.post('/api/directories/search');
    dispath(
      setAll({
        list: result.data.list,
        isLoading: false,
      })
    );
  };

  useEffect(() => {
    if (list.length === 0) {
      getDirectories();
    }
  }, []);

  const getByPath = (path) => {
    return list.find((item) => item.path === path);
  };

  const treeJson = useMemo(() => generateDataTree(list), [list]);

  const onlyChildren = useMemo(() => list.filter((i) => !i.isParent), [list]);

  const showSelected = useCallback((id) => list.find((i) => i.id === id), [list]);

  return { list, onlyChildren, treeJson, isLoading, getDirectories, getByPath, showSelected };
}
