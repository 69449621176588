import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from 'redux/reducers/auth';
import localstorage from 'services/localstorage';

function useUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rawUser = localstorage?.get('user');
  if (rawUser.value !== 'undefined' && rawUser.value !== undefined && rawUser.value !== null) {
    const userType =
      JSON?.parse(rawUser?.value)?.usertype === 'teacher'
        ? 'trainer'
        : JSON?.parse(rawUser?.value)?.usertype;
    const name = JSON?.parse(rawUser?.value)?.name;
    const token = localstorage?.get('token')?.value;
    const isLoggedIn = token && token !== 'undefined';
    const user = JSON.parse(rawUser?.value);
    return { userType, token, isLoggedIn, name, user };
  } else {
    dispatch(logout());
    navigate('/login');
  }

  return { userType: null, token: null, isLoggedIn: false, name: null, user: null };
}

export default useUser;
