import ReactDOM from 'react-dom';

export default function AedProfilePopup({ children, onClose, ...props }) {
  return ReactDOM.createPortal(
    <div className="absolute top-0 left-0 bottom-0 right-0 z-[200] min-h-[100%]" {...props}>
      <div className="fixed top-0 left-0 bottom-0 right-0" onClick={onClose}></div>
      <div className="top-right-expand popup-arrow absolute top-[64px] right-[17px] w-[100%] max-w-[400px] bg-white rounded-[16px] drop-shadow-xl">
        {children}
      </div>
    </div>,
    document.body
  );
}
