import useEvidenceRecord from 'hooks/trainer/EvidenceRecord/useEvidenceRecord';
import { useState, useEffect } from 'react';

const CompetencyScale = {
  NO_CURRENT_EVIDENCE: 0,
  EVIDENCE_IN_REVIEW: 1,
  NEED_ATTENTION: 2,
  GOOD_STANDING: 3,
};

export default function EvidenceOverviewTableData({ status, value, fsId, pcId }) {
  const { evidenceGrades: gradedItems, student, teacherId, studentUploads } = useEvidenceRecord();
  const [state, setState] = useState({
    status,
    value,
  });

  useEffect(() => {
    let _scale = 0;
    const currnetStudentUploads = studentUploads.filter((item) => {
      return item.fsId === fsId && pcId === item.pcId;
    });

    if (currnetStudentUploads.length > 0) {
      _scale = CompetencyScale.EVIDENCE_IN_REVIEW;
      setState((prevState) => ({
        ...prevState,
        status: _scale,
      }));
    }
    const currentItem = gradedItems.find((item) => {
      // logger('to compare: ', `${fsId}=${item.fsId} | ${pcId}=${item.pcId}`);
      return (
        fsId === item.fsId &&
        pcId === item.pcId &&
        item.studentId === student.userId &&
        item.teacherId === teacherId
      );
    });

    if (currentItem) {
      const squareTotal = currentItem.skillScore + currentItem.competencyScore;
      if (squareTotal >= 1 && squareTotal <= 4) {
        _scale = CompetencyScale.NEED_ATTENTION;
      } else if (squareTotal >= 5) {
        _scale = CompetencyScale.GOOD_STANDING;
      }
      setState((prevState) => ({
        ...prevState,
        status: _scale,
        value: currentItem.skillScore + '/' + currentItem.competencyScore,
      }));
    }
  }, [gradedItems]);

  return <td className={'fort' + ` competence-scale-${state.status}`}>{state.value}</td>;
}
