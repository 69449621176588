import { randomBase64 } from 'utils/randomString';
import { FILE_UPLOAD_MAX_SIZE } from 'config/misc';

/**
 * @typedef {Object} UploadFile
 * @prop {string|number} id
 * @prop {string} fileName
 * @prop {string} fileExt
 * @prop {File | null} file
 * @prop {number} timestamp
 */

/**
 *
 * @callback InputCallback
 * @param {{
 *  item?: UploadFile,
 *  items?: UploadFile[].
 *  file?: File,
 *  files?: File[]
 * }}
 */

/**
 * @param {InputCallback} fn
 * @param {{
 *  multiple: boolean
 * }} [opt]
 * @returns
 */
const createFileInput = (fn, opt = { multiple: false }) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = opt.multiple;
  if (typeof opt.accept === 'string') input.accept = opt.accept;
  input.onclick = (e) => {
    e.target.value = null;
  };
  input.onchange = (e) => {
    if (e.target.files.length === 0) return;
    let overLimitErrorCount = 0;
    //
    /**
     * @type {UploadFile}
     */
    const items = [];
    const uploads = {};
    for (let i = 0; i < e.target.files.length; i += 1) {
      //
      /**
       * @type {File}
       */
      const currentFile = e.target.files[i];
      if (currentFile.size > FILE_UPLOAD_MAX_SIZE) {
        overLimitErrorCount += 1;
      }
      let assignedId = randomBase64(5);
      const keyChecker = () => {
        if (uploads[assignedId]) {
          assignedId = randomBase64(5);
          keyChecker();
        }
      };
      keyChecker();
      uploads[assignedId] = currentFile;
      const fileName = currentFile.name.replace(/\.\w+$/gi, '');
      const ext = currentFile.name.match(/\.\w+$/gi);
      const fileExt = Array.isArray(ext) ? ext[0] : '';
      items.push({
        id: assignedId,
        fileName,
        fileExt,
        timestamp: Date.now(),
        file: currentFile,
      });
    }

    if (overLimitErrorCount > 0) {
      // const overLimitFile = [...e.target.files].filter((item) => {
      //   return item.size > FILE_UPLOAD_MAX_SIZE;
      // });

      const msg = (
        <>
          <span className="normal-case">
            Uploaded file size must be less than <strong>32MB</strong>. Please upload larger files
            to
            <strong> Google Drive</strong> and attach a link instead.
          </span>
        </>
      );

      window.dispatchEvent(
        new CustomEvent('fileSize.error', { detail: { title: 'File Size Error', message: msg } })
      );
    }

    const validFiles = items.filter((item) => {
      return item?.file?.size <= FILE_UPLOAD_MAX_SIZE;
    });

    if (validFiles.length > 0) {
      if (typeof fn === 'function') {
        !opt.multiple && fn({ item: validFiles[0], file: e.target.files[0] });
        opt.multiple && fn({ items: validFiles, files: uploads });
      }
    }
  };
  return input;
};

export default createFileInput;
