export default function AssignedGroupIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 20.348 20.348"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1578"
            data-name="Rectangle 1578"
            width="20.348"
            height="20.348"
            fill="none"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
        </clipPath>
      </defs>
      <g id="Group_2171" data-name="Group 2171" transform="translate(0 0)">
        <g
          id="Group_2170"
          data-name="Group 2170"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <circle
            id="Ellipse_777"
            data-name="Ellipse 777"
            cx="9.921"
            cy="9.921"
            r="9.921"
            transform="translate(0.253 0.253)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1020"
            data-name="Path 1020"
            d="M20.2,28.825l.733-.463a1.783,1.783,0,0,0,.833-1.509v-3.1a1.683,1.683,0,0,0-1.682-1.683H11.263a1.682,1.682,0,0,0-1.682,1.683v3.1a1.783,1.783,0,0,0,.833,1.509l.734.463"
            transform="translate(-5.5 -12.669)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <circle
            id="Ellipse_778"
            data-name="Ellipse 778"
            cx="1.842"
            cy="1.842"
            r="1.842"
            transform="translate(5.575 4.873)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_394"
            data-name="Line 394"
            y1="7.684"
            transform="translate(5.647 11.319)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_395"
            data-name="Line 395"
            y2="3.268"
            transform="translate(7.417 16.44)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1021"
            data-name="Path 1021"
            d="M29.718,13.284a1.842,1.842,0,1,1-1.842-1.842,1.842,1.842,0,0,1,1.842,1.842"
            transform="translate(-14.945 -6.569)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_396"
            data-name="Line 396"
            y2="7.684"
            transform="translate(14.701 11.319)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1022"
            data-name="Path 1022"
            d="M23.542,35.032V26.019H21.568v9"
            transform="translate(-12.382 -14.937)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <line
            id="Line_397"
            data-name="Line 397"
            y2="3.265"
            transform="translate(12.931 16.44)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1023"
            data-name="Path 1023"
            d="M22.253,22.068a1.682,1.682,0,0,1,1.683,1.683"
            transform="translate(-12.775 -12.669)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
