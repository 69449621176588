import logo from '../../assets/images/common/logo.svg';
import bgImage from '../../assets/images/common/login-bg.jpg';
import { Typography } from '@mui/material';
import TransparentButton from 'components/UI/atoms/TransparentButton';
import { useNavigate, Link } from 'react-router-dom';

export default function Missing() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/admin');
  };
  return (
    <>
      <div
        className="fixed top-0 left-0 bottom-0 right-0 opacity-30"
        style={{
          backgroundImage: `url('${bgImage}')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      ></div>
      <div
        className={`fixed top-0 left-0 bottom-0 right-0 bg-gradient-to-t from-sky-500/80 to-sky-900/80`}
      ></div>
      <div>
        <div className={`relative px-[20%] pb-10 flex  min-h-screen justify-center items-center`}>
          <div className="flex items-center  flex-col mt-12">
            <img src={logo} alt="logo" />
            <Typography
              variant="p"
              className="text-transparent fort text-[40px] 
                        tracking-widest pt-8 text-center leading-[90px]
                        text-white stroke-text-white"
            >
              <p> Page not found.</p>
              <p>
                <Link to="/admin">Return to Home </Link>
              </p>
            </Typography>
            <div className="mb-[20px] mt-[30px]">
              <TransparentButton onClick={onClick} style={{ transform: 'rotateZ(180deg)' }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
