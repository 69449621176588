export default function HeadIcon({ size, active = false }) {
  const cssProps = {
    stroke: active ? '#00A7E1' : '#232432',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '1',
  };
  return (
    <svg width={size || 32} height={size || 32} viewBox="0 0 47.006 47.006">
      <g id="Group_1789" data-name="Group 1789" transform="translate(-2922.422 -850.335)">
        <circle
          id="Ellipse_154"
          data-name="Ellipse 154"
          cx="23.003"
          cy="23.003"
          r="23.003"
          transform="translate(2922.922 850.835)"
          {...cssProps}
        />
        <path
          id="Path_694"
          data-name="Path 694"
          d="M2928.673,888.31c4.7-5.452,10.709-8.721,17.253-8.721s12.549,3.269,17.253,8.721"
          fill="none"
          {...cssProps}
        />
        <circle
          id="Ellipse_155"
          data-name="Ellipse 155"
          cx="8.626"
          cy="8.626"
          r="8.626"
          transform="translate(2937.299 862.336)"
          {...cssProps}
        />
      </g>
    </svg>
  );
}
