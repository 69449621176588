import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} EvidenceSkillCompetency
 * @prop {number} id
 * @prop {string} uuid
 * @prop {number} evidenceSkillId
 * @prop {string} evidenceSkillUUID
 * @prop {string} evidenceSkillTitle
 * @prop {string} evidenceSkillDescription
 * @prop {string} evidenceSkillInfo
 * @prop {number} evidenceCompetencyId
 * @prop {string} evidenceCompetencyUUID
 * @prop {string} evidenceCompetencyTitle
 * @prop {string} evidenceCompetencyDescription
 * @prop {string} evidenceCompetencyInfo
 * @prop {string} createdAt
 * @prop {string} updatedAt
 */

/**
 * @typedef {{
 * isLoading: boolean,
 * list: EvidenceSkillCompetency[],
 * page: number,
 * pageCount: number,
 * limit: number | null,
 * totalCount: number
 * }} EvidenceSkillCompetencyList
 */

/**
 * @type {EvidenceSkillCompetencyList}
 */
const initialState = {
  isLoading: true,
  list: [],
  page: 1,
  pageCount: 1,
  limit: 25,
  totalCount: 0,
};

const evidenceSkillCompetencySlice = createSlice({
  name: 'EvidenceSkillCompetencyList',
  initialState,
  reducers: {
    setEvidenceSkillCompetencyList: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setEvidenceSkillCompetencyList } = evidenceSkillCompetencySlice.actions;

export default evidenceSkillCompetencySlice.reducer;
