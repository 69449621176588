// import { useState } from 'react';
import { Box, Modal, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  CloseOutlined as CloseOutlinedIcon,
} from '@mui/icons-material';
import Input from 'components/UI/atoms/Input';
import HighlightCard from 'components/UI/molecules/HighlightCard';
import BaseList from 'components/UI/atoms/BaseList';
import CheckItem from 'components/UI/molecules/CheckItem';
import Tooltip from 'components/UI/molecules/AedTooltip';
import HeadIcon from 'components/UI/atoms/CustomIcons/HeadIcon';

// import CrossBox from 'components/UI/atoms/CustomIcons/CrossBox';
// import GroupWhiteSelect from 'components/UI/atoms/GroupWhiteSelect';
import useGroupInstance from 'hooks/trainer/useGroupInstance';
import useStudentGroupModal from 'hooks/trainer/useStudentGroupModal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  bgcolor: '#ffffff53',
  border: '2px solid #fff',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
};
export default function StudentGroupModal({ open = null, student = null, setOpen = () => {} }) {
  const { groupTypes } = useGroupInstance();
  const {
    page,
    activeGroup,
    selectGroup,
    listGroups,
    loading,
    groupListLoading,
    canPrevious,
    canNext,
    handlePreviousPage,
    handleNextPage,
    toAddGroup,
    groupName,
    setGroupName,
    resetModal,
  } = useStudentGroupModal(student, setOpen);

  return (
    <Modal open={!!open} onClose={resetModal}>
      <Box sx={style}>
        <div className="relative">
          <div
            className="absolute -top-6 -right-8 inset bg-white mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center"
            onClick={resetModal}
          >
            <CloseIcon
              style={{
                fontSize: '20px',
              }}
            />
          </div>
        </div>
        {page === 1 && (
          <div className="flex flex-col items-center font-extralight">
            <p className="text-white text-[20px] fort w-fit tracking-widest font-[500]">
              GROUP SEARCH:
            </p>
            <div className="grid grid-cols-2 justify-around my-4">
              <div className="pr-6">
                <div className="flex items-center">
                  <div className="flex flex-col items-start justify-between h-[100px] mb-4">
                    {groupTypes.map((g, k) => (
                      <div key={k}>
                        <FormControlLabel
                          className="text-white text-[14px] tracking-widest uppercase"
                          control={
                            <Checkbox
                              checked={activeGroup?.id === g.id}
                              style={{ width: '30px', height: '30px' }}
                              checkedIcon={<CloseOutlinedIcon />}
                              onChange={() => selectGroup(activeGroup?.id !== g.id ? g : null)}
                              sx={{
                                color: 'transparent',
                                border: '1px solid #fff',
                                borderRadius: '0px',
                                marginRight: '10px',
                                '&.Mui-checked': {
                                  color: '#fff',
                                },
                              }}
                            />
                          }
                          label={g?.name}
                        />
                        <Tooltip title={''} description={g?.description}>
                          <InfoIcon
                            style={{
                              fontSize: '16px',
                              fontWeight: 100,
                              color: '#aaa',
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
                <Input
                  placeholder="Group Name"
                  className="text-left w-[90%] mb-8 rounded-lg p-2 shadow"
                  value={groupName}
                  onChange={({ target }) => setGroupName(target.value)}
                />
              </div>
              <div>
                <HighlightCard className={'px-4 py-5'}>
                  <BaseList listClassName="w-[400px] pr-4 max-w-[500px] bg-white rounded-lg h-[165px]">
                    {groupListLoading ? (
                      <CircularProgress size={72} />
                    ) : (
                      listGroups.map((g, k) => (
                        <CheckItem
                          key={k}
                          active={g?.active || false}
                          title={g.groupName}
                          subtitle={g.academyName}
                          onClick={() => toAddGroup(g)}
                          svgicon={<HeadIcon size={20} active={false} />}
                        />
                      ))
                    )}
                  </BaseList>
                </HighlightCard>
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <CircularProgress size={72} />
        ) : (
          <Box className="flex justify-center">
            {canPrevious && (
              <div
                className="bg-white mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center"
                onClick={handlePreviousPage}
              >
                <ChevronLeftIcon
                  style={{
                    fontSize: '32px',
                  }}
                />
              </div>
            )}
            {canNext && (
              <div
                className="bg-white mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center"
                onClick={handleNextPage}
              >
                <ChevronRightIcon
                  style={{
                    fontSize: '32px',
                  }}
                />
              </div>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
