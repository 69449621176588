import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from 'components/UI/organisms/DashboardLayout';
import Admin from '.';
import Profile from './Profile';
// exclusive page
import AssignRole from './adminSubRoutes/AssignRole';
// common page
import AssignGroup from 'pages/common/AssignGroup';
import EvidenceRecordRoutes from './adminSubRoutes/EvidenceRecord/EvidenceRecordRoutes';
import SearchStudent from 'pages/common/SearchStudent';
import ResourceAcademy from 'pages/common/ResourceAcademy';
import TrainingMaterialsSubRoutes from 'pages/common/TrainingMaterialsSubRoutes';
import TrainingMaterials from 'pages/common/TrainingMaterials';
import PendingAssessments from 'pages/common/PendingAssessments';

import SessionTimeoutModal from 'components/UI/organisms/SessionTimeoutModal';
import useSessionTimeout from 'hooks/admin/useSessionTimeout';
import useUser from 'hooks/auth/useUser';
function AdminRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  const { userType } = useUser();
  const { setSessionTimeout, showSessionWarningModal, setShowSessionWarningModal } =
    useSessionTimeout();

  useEffect(() => {
    if (userType === 'admin') {
      setSessionTimeout();
    }
  }, []);

  return (
    <DashboardLayout>
      <SessionTimeoutModal
        showSessionWarningModal={showSessionWarningModal}
        setShowSessionWarningModal={setShowSessionWarningModal}
      />
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <div className="w-full">
                <Admin />
              </div>
            }
          />
          <Route path="/roles" element={<AssignRole />} />
          <Route path="/groups" element={<AssignGroup />} />
          <Route path="/profile" element={<Profile />} />
          {/* COMMENT: This is not actually how to do it. but as 
          TODO: Restructure this as what REACT intented so we can reuse the code */}
          <Route path="/materials" element={<TrainingMaterials />} />
          {/* Additional TODO: This is student routes. Extend these pages for admin use */}
          <Route path="/materials/*" element={<TrainingMaterialsSubRoutes />} />
          {/* Comment: This is somehow correct way to do it */}
          <Route path={'/evidence/*'} element={<EvidenceRecordRoutes />} />
          <Route path="/assessments" element={<PendingAssessments />} />
          <Route path="/search" element={<SearchStudent />} />
          <Route path="/resources" element={<ResourceAcademy />} />
          {/* <Route path=':slug/*' element={<AdminRoute />} /> */}
        </Routes>
      </div>
    </DashboardLayout>
  );
}

export default AdminRoutes;
