import logo from 'assets/images/svg/logo.svg';
import logoText from 'assets/images/svg/logo-text.svg';
import Sidebar2Button from '../molecules/Sidebar2Button';
import ArrowRightIcon from '../atoms/CustomIcons/ArrowRight';
// import BellIcon from '../atoms/CustomIcons/BellIcon';
import { createPortal } from 'react-dom';
import useSidebar from 'hooks/useSidebar';
import { NavLink } from 'react-router-dom';
import NavigationNestedList from '../molecules/NavigationNestedList';

export default function Sidebar2({ routesList, userType }) {
  const { isOpen, setOpen } = useSidebar();

  const toggleSideBar = () => {
    setOpen(!isOpen);
  };

  return createPortal(
    <div
      className={
        'sb2 bg-[#232431] fixed top-0 left-0 bottom-0 overflow-x-hidden overflow-y-auto flex flex-col pt-[16px] pb-[16px] pl-[16px] pr-[16px] items-center z-10 gap-[8px]' +
        (isOpen ? ' sb2-open' : ' sb2-close')
      }
      style={{
        width: isOpen ? '300px' : '86px',
      }}
    >
      <NavLink
        to={`/${userType}`}
        className="w-full min-h-[120px] flex flex-row items-center m-auto cursor-pointer"
      >
        <div className="logo-holder flex flex-row items-center justify-center">
          <img src={logo} className="w-[40px] h-[40px]" />
        </div>
        <div>
          <img src={logoText} className="logo-text h-[26px]" />
        </div>
      </NavLink>
      <div className="flex flex-col gap-[32px] w-full uppercase">
        {routesList.map((item, index) => {
          if (item.children?.length > 0) {
            return (
              <NavigationNestedList key={item.id || index} navItem={item} isSidebarOpen={isOpen} />
            );
          } else {
            return (
              <Sidebar2Button
                icon={item.icon || item.letter || null}
                text={item.text}
                key={index}
                path={item.path}
                // title={item.text}
                sideBarIsOpen={isOpen}
              />
            );
          }
        })}
      </div>

      <div className="border-b-2 flex-1 border-white/20 w-full mb-[16px]"></div>

      <div
        onClick={toggleSideBar}
        className={
          'mt-[16px] cursor-pointer text-[#9a9ca5] border-[1px] border-[#9a9ca5] hover:text-sky-500 hover:border-sky-500 rounded-full min-h-[36px] w-[36px] flex flex-row items-center justify-center pl-[2px] transform-gpu transition-all duration-200' +
          (isOpen ? ' rotate-180' : ' rotate-0')
        }
      >
        <ArrowRightIcon size={20} />
      </div>
    </div>,
    document.body
  );
}
