import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import http from 'services/http';

export default function useUploadModal(handleUpload, setOpen, handleLinkUploadFunc) {
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]); // links to send
  const [selected, setOption] = useState(null);
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const dirId = searchParams.get('folderId');
  const [alerts, setAlerts] = useState(null);
  // const [checkInput, setCheck] = useState(false);
  // const [skippable, setSkippable] = useState(false);
  const maxPage = 4;
  const canNext = useMemo(() => {
    if (selected === 'upload') return page < 2;
    if (page === 2 && links.length === 0) return false;
    return page < maxPage;
  }, [page, selected, links]);

  const canSubmit = page === maxPage - 1 && links.length;
  const canPrevious = page > 1;

  const runReset = () => {
    setLoading(false);
    setPage(1);
    setOption(null);
    if (setOption !== null && typeof setOption === 'function') setOpen(false);
  };

  const triggerUpload = async () => {
    // This will trigger the form upload
    // mechanics where real form post request is sent.
    setLoading(true);
    await handleUpload(new Event('Trigger Upload'));
    await runReset();
  };

  const handleLinkUpload = async () => {
    setLoading(true);
    if (typeof handleLinkUploadFunc === 'function') {
      handleLinkUploadFunc([...links]);
    } else {
      await http.post(`/api/materials-link?dirId=${dirId}`, [...links]);
    }
    runReset();
  };

  useEffect(() => {
    if (selected === 'upload' && page === 2) triggerUpload();
    else if (selected === 'url' && page === 4) handleLinkUpload();
  }, [page, selected]);

  useEffect(() => {
    if (alerts) {
      setTimeout(() => {
        setAlerts(null);
      }, 3000);
    }
  }, [alerts]);

  const handleSelect = (select = null) => setOption(select);

  const handleNextPage = () => {
    if (page === 2 && selected === 'url' && links.length === 0) {
      setAlerts('Please update to a valid google drive link!');
      return;
    }
    if (canNext) setPage(page + 1);
    else triggerUpload();
  };

  const handlePreviousPage = () => (canPrevious ? setPage(page - 1) : null);

  return {
    page,
    loading,
    selected,
    setLinks,
    canNext,
    canPrevious,
    canSubmit,
    handleSelect,
    handleNextPage,
    handlePreviousPage,
    // Only use as a closing callback
    setPage,
    triggerUpload,
    alerts,
    setAlerts,
    // setSkippable,
  };
}
