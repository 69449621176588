import SkillViewCardItem from 'components/UI/molecules/Trainer/SkillViewCardItem';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useEvidenceSkillsState from 'hooks/student/useEvidenceSkillsState';
import useEvidenceCompetencyState from 'hooks/student/useEvidenceCompetencyState';
import logger from 'utils/logger';
import useEvidenceRecord from 'hooks/trainer/EvidenceRecord/useEvidenceRecord';
// import useUser from 'hooks/auth/useUser';
import stringPadding from 'utils/stringPadding';
import SkillViewBase from 'components/UI/organisms/SkillViewBase';

export default function SkillView() {
  const params = useParams();
  const navigate = useNavigate();
  const { mode, id } = params;
  const { list: evidenceSkilllist } = useEvidenceSkillsState();
  const { list: evidenceCompetencylist } = useEvidenceCompetencyState();
  const {
    student,
    selectedUploadsOnly,
    // evidenceGrades,
    // fsId,
    // pcId,
    evidenceSkillsScore,
    evidenceCompetenciesScore,
  } = useEvidenceRecord();
  // const { user } = useUser();
  const defaultValue = {
    id: null,
    uuid: null,
    title: null,
    description: null,
    info: null,
  };
  const [target, setTarget] = useState(defaultValue);
  const [nextTarget, setNextTarget] = useState(defaultValue);
  // const currentGradeData = evidenceGrades.find((item) => {
  //   return (
  //     item.fsId === fsId &&
  //     item.pcId === pcId &&
  //     item.teacherId === user.userId &&
  //     item.studentId === student.userId
  //   );
  // });

  useEffect(() => {
    logger('route-changed');
  }, [params]);

  useEffect(() => {
    const _nextTarget = getNextTarget();
    setNextTarget(_nextTarget);
  }, [target]);

  useEffect(() => {
    const target =
      params.mode === 'skill'
        ? evidenceSkilllist.find((item) => Number(id) === Number(item.id))
        : evidenceCompetencylist.find((item) => Number(id) === Number(item.id));
    setTarget(target || defaultValue);
  }, [evidenceSkilllist, evidenceCompetencylist, params, setTarget]);

  const pairItem = mode === 'skill' ? evidenceCompetencylist : evidenceSkilllist;

  const getNextTarget = () => {
    const currentItemIndex =
      params.mode === 'skill'
        ? evidenceSkilllist.findIndex((item) => Number(id) === Number(item.id))
        : evidenceCompetencylist.findIndex((item) => Number(id) === Number(item.id));

    const _nextTarget =
      params.mode === 'skill'
        ? evidenceSkilllist[currentItemIndex + 1]
        : evidenceCompetencylist[currentItemIndex + 1];
    return _nextTarget;
  };

  const backToList = () => {
    navigate(`/trainer/evidence/${params.uuid}/record`);
  };

  const nextItemClick = () => {
    const _nextTarget = getNextTarget();
    navigate(`/trainer/evidence/${params.uuid}/record/${params.mode}/${_nextTarget.id}`);
  };

  const uploads = selectedUploadsOnly.map((item) => {
    const result = {
      fileName: item.fileName,
      timestamp: item.createdAt,
      uuid: item.uuid,
      fileExt: item.fileExt,
      status: 2,
    };
    return result;
  });

  let description = target.description || '';
  description = description
    .split('\n')
    .map((item) => item.trim())
    .join('\n');

  return (
    <SkillViewBase
      studentName={`${student.firstName} ${student.lastName}`}
      mode={mode}
      code={target.code}
      title={target.title}
      subtitle={target.subtitle}
      description={description}
      pairList={pairItem.map((item) => {
        const { id: itemId, title, code, subtitle, createdAt, updatedAt, ...otherProps } = item;
        return (
          <SkillViewCardItem
            key={`${mode === 'skill' ? `${id}-${itemId}` : `${itemId}-${id}`}`}
            data={{
              id: itemId,
              title:
                mode === 'skill' ? (
                  `${code}: `
                ) : (
                  <>
                    {`${code}: ${title}`}
                    <br />
                  </>
                ),
              content: mode === 'skill' ? title : subtitle,
              ...otherProps,
            }}
            data-id={`${mode === 'skill' ? `${id}-${itemId}` : `${itemId}-${id}`}`}
          ></SkillViewCardItem>
        );
      })}
      uploads={uploads}
      score={
        mode === 'skill'
          ? stringPadding(evidenceSkillsScore, 3)
          : stringPadding(evidenceCompetenciesScore, 3)
      }
      nextTarget={nextTarget}
      nextItemClick={nextItemClick}
      backToList={backToList}
    />
  );
}
