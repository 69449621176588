// import { sampleLinks } from 'constants/sampleData';
import { useEffect } from 'react';
import useURLInput from 'hooks/organisms/useURLInput';
import InputLinkBox from '../atoms/InputLinkBox';

export default function URLInputs({ page = 2, setLinks = () => {}, setAlerts, check = () => {} }) {
  const { links, setFocus, blank, preCheck, onNewLinkChange, onNameLinkChange } =
    useURLInput(setAlerts);

  useEffect(() => {
    if (typeof check === 'function') check(preCheck);
  }, [preCheck]);

  useEffect(() => {
    // To throw this data to the parent
    setLinks(links);
  }, [links]);

  if (page < 2) return '';

  return (
    <>
      <p className="fort text-center text-white text-[18px] mb-4">
        {page === 2 && (
          <>
            Add Google Doc <br /> URL[S] below:
          </>
        )}
        {page === 3 && <>Click and Rename Documents:</>}
      </p>
      <div className="list-scroll max-h-[200px] overflow-y-auto overflow-x-hidden mb-4">
        <InputLinkBox
          item={page === 3 ? links[0] : blank}
          editable={true}
          setFocus={setFocus}
          selected={page === 3}
          preview={page === 3}
          onChange={page === 3 ? onNameLinkChange : onNewLinkChange}
          preCheck={preCheck}
        />
      </div>
    </>
  );
}
