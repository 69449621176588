import CloseIcon from '@mui/icons-material/Close';
import useUser from 'hooks/auth/useUser';

export default function GradeModalFileItem({ data }) {
  const { token } = useUser();
  const onLinkClick = () => {
    const url = new URL(data.filePath);
    url.searchParams.append('token', token);
    const toOpen = url.toString();
    window.open(toOpen, '_blank');
  };

  return (
    <div
      className="flex flex-row items-center max-w-[100%] gap-[4px] text-black fort font-extralight hover:text-sky-500"
      data-path={data.filePath}
    >
      <button
        className="cursor-pointer flex items-center justify-center h-[32px] 
                        w-[32px] bg-white rounded-[6px] border-white border-[1px] 
                        hover:border-sky-500 hover:text-sky-500"
      >
        <CloseIcon style={{ fontSize: 16 }} />
      </button>
      <div
        title={data.fileName}
        className="bg-white min-h-[32px] max-w-[100%] flex items-center pl-[16px] pr-[0px] 
                  rounded-[6px] text-[14px] cursor-pointer border-white border-[1px] 
                  hover:border-sky-500 hover:text-sky-500"
        onClick={onLinkClick}
      >
        <div className="tracking-[1.5px] pr-[32px]">
          {data?.fileName}
          {data?.fileExt}
        </div>
        <div className="w-[32px] flex flex-row items-center justify-center text-[20px]">+</div>
      </div>
    </div>
  );
}
