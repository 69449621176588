import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EvidenceRecord from './EvidenceRecord';
import FinishedCVFinalSnapshot from './FinishedCVFinalSnapshot';

export default function PurposeRoutes() {
  const { isUserLogin } = useSelector(({ auth }) => auth);

  if (!isUserLogin) return <Navigate to={'/login'} replace />;

  return (
    <Routes>
      <Route path="evidence-record/*" element={<EvidenceRecord />} />
      <Route path="finished-cv-final-snapshot" element={<FinishedCVFinalSnapshot />} />
    </Routes>
  );
}
