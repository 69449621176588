import { NavLink } from 'react-router-dom';
import SidebarBarToolTip from './SidebarBarToolTip';

const iconSize = {
  l: { w: 40, h: 40 },
  m: { w: 26, h: 26 },
  s: { w: 16, h: 16 },
};

/**
 *
 * @param {import("react").HTMLAttributes} param0
 * @returns
 */
export default function Sidebar2Button({
  icon,
  onClick,
  size,
  text,
  className,
  path,
  sideBarIsOpen = false,
  ...otherProps
}) {
  const _size = iconSize[size] || iconSize.m;
  const _className = `transition-all duration-200 text-[#9a9ca5] hover:text-white w-full flex flex-row items-center justify-center hover:bg-white/10 min-h-[56px] m-auto cursor-pointer rounded-[12px] ${
    typeof className === 'string' ? className : ''
  }`;

  /**
   * Use NavLink if path is define and Normal Div if not.
   */
  const Container =
    typeof path === 'string'
      ? NavLink
      : ({ children, ...props }) => <div {...props}>{children}</div>;

  /**
   * Use NavLink className function paramter if path is define.
   * If not, use directly _className
   */
  const navClassName =
    typeof path === 'string'
      ? ({ isActive }) => (isActive ? `${_className} sb2-btn-active` : _className)
      : _className;

  const ContentElement = () => (
    <>
      <div className="sb2btn-ico flex flex-row justify-center items-center pointer-events-none">
        {typeof icon === 'string' ? (
          <img src={icon} style={{ width: _size.w, height: _size.h }} />
        ) : (
          icon
        )}
      </div>
      <div className="sb2btn-txt sb2-txt-fade flex-1 overflow-hidden workSans-medium tracking-[1.5px] text-[14px] hover:text-sky-500 hover:border-sky-500">
        {text}
      </div>
    </>
  );

  return (
    <Container className={navClassName} to={`${path}`} onClick={onClick} {...otherProps}>
      {!sideBarIsOpen ? (
        <SidebarBarToolTip title={text}>
          <div className="flex flex-row items-center w-full">
            <ContentElement />
          </div>
        </SidebarBarToolTip>
      ) : (
        <ContentElement />
      )}
    </Container>
  );
}
