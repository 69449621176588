import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modifying: false,
  modified: false,
  groupName: '',
  groupType: null,
  selectedStudents: [],
  studentAccordion: false,
  selectedMaterials: [],
  materialAccordion: false,
};

const manageGroupInstance = createSlice({
  name: 'manageGroupInstance',
  initialState,
  reducers: {
    setName: (state, { payload }) => {
      state.groupName = payload;
      state.modified = true;
    },
    setType: (state, action) => {
      state.groupType = action.payload;
      // state.modified = true;
    },
    openAccordion: (state, { payload }) => {
      if (payload?.accordion != null && state[payload?.accordion] != null) {
        state[payload?.accordion] = payload?.value;
      }
    },
    // toggles to active or deactive check on students from selected list
    toggleStudent: (state, { payload }) => {
      state.selectedStudents = state.selectedStudents.map((i) => ({
        ...i,
        active: payload.id !== i.id ? i.active : !payload.active,
      }));
    },
    // toggles to add or remove students from selected list
    toggleMaterial: (state, { payload }) => {
      state.selectedMaterials = state.selectedMaterials.map((i) => ({
        ...i,
        active: payload.id !== i.id ? i.active : !payload.active,
      }));
    },

    removeSelectedStudents: (state) => {
      state.selectedStudents = state.selectedStudents.filter((i) => !i.active);
    },

    removeSelectedMaterials: (state) => {
      state.selectedMaterials = state.selectedMaterials.filter((i) => !i.active);
    },

    addBatchStudents: (state, { payload }) => {
      const { students, clear } = payload;
      const studs = clear ? [...students] : [...state.selectedStudents, ...students];
      state.selectedStudents = studs
        .filter((v, i, a) => a.findIndex((_v) => _v.id === v.id) === i)
        .map((i) => ({ ...i, active: false }));
      state.modified = true;
    },
    // Depreciated
    addBatchMaterials: (state, { payload }) => {
      const { materials, clear } = payload;
      const mats = clear ? [...materials] : [...state.selectedMaterials, ...materials];
      state.selectedMaterials = mats
        .filter((v, i, a) => a.findIndex((_v) => _v.id === v.id) === i)
        .map((i) => ({ ...i, active: false }));
    },

    openModify: (state, { payload }) => {
      state.modifying = payload;
    },

    saved: (state) => {
      state.modified = false;
      state.modifying = false;
    },

    clearInstance: (state) => {
      state.modified = false;
      state.groupName = '';
      state.groupType = null;
      state.selectedStudents = [];
      state.selectedMaterials = [];
    },
  },
});

export const {
  setName,
  setType,
  openAccordion,
  toggleStudent,
  toggleMaterial,
  addBatchStudents,
  removeSelectedStudents,
  removeSelectedMaterials,
  addBatchMaterials,
  openModify,
  clearInstance,
} = manageGroupInstance.actions;

export default manageGroupInstance.reducer;
