export default function EvidenceRecordIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 20.423 20.423"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1576"
            data-name="Rectangle 1576"
            width="20.423"
            height="20.423"
            fill="none"
            stroke="#999ba5"
            strokeWidth="0.5"
          />
        </clipPath>
      </defs>
      <g id="Group_2168" data-name="Group 2168" transform="translate(0 0)">
        <g
          id="Group_2168-2"
          data-name="Group 2168"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <circle
            id="Ellipse_775"
            data-name="Ellipse 775"
            cx="9.958"
            cy="9.958"
            r="9.958"
            transform="translate(0.254 0.254)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1011"
            data-name="Path 1011"
            d="M24.653,31.885v2.842L23.73,33.8l-.923.923V31.885"
            transform="translate(-13.057 -18.254)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1012"
            data-name="Path 1012"
            d="M14.056,32.7h-4a.923.923,0,0,1-.923-.923v-.615h1.846"
            transform="translate(-5.229 -17.842)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1013"
            data-name="Path 1013"
            d="M31.746,14.857v7.587a.923.923,0,0,1-.923.923H29.285"
            transform="translate(-16.766 -8.506)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1014"
            data-name="Path 1014"
            d="M11.292,21.307a.923.923,0,0,0,.923-.923v-8.2a.923.923,0,0,1,1.846,0V12.8h8.922v-.615a.922.922,0,0,0-.923-.923H13.138"
            transform="translate(-6.465 -6.446)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <path
            id="Path_1015"
            data-name="Path 1015"
            d="M24.315,25.105a1.936,1.936,0,1,0-1.936,1.936A1.936,1.936,0,0,0,24.315,25.105Z"
            transform="translate(-11.704 -13.264)"
            fill="none"
            stroke="#999ba5"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
