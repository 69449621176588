import { forwardRef } from 'react';
const BaseList = ({ listClassName, children }, ref) => {
  return (
    <div
      ref={ref}
      className={`list-scroll overflow-y-auto overflow-x-hidden h-full ${listClassName}`}
    >
      {children}
    </div>
  );
};

export default forwardRef(BaseList);
