import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#232431',
    border: 'none',
    color: 'white',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 4,
    // borderRadius: 0,
    padding: '8px 16px',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.5)',
    left: '10px',
    // overflow: 'hidden',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #232431',
      backgroundColor: '#232431',
    },
  },
}));

export default function SidebarBarToolTip({ children, title, ...props }) {
  return typeof title === 'string' ? (
    <HtmlTooltip
      {...props}
      title={
        <>
          <div className="text-center uppercase workSans-medium text-[12px] py-[8px] px-[4px] tracking-[2px] cursor-pointer">
            {title}
          </div>
        </>
      }
      arrow={true}
      placement="right"
    >
      {children}
    </HtmlTooltip>
  ) : (
    children
  );
}
