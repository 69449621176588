import PlusBox from 'components/UI/atoms/PlusBox';
import PlusBoxHolder from 'components/UI/atoms/PlusBoxHolder';
import PlusBoxHeading from 'components/UI/atoms/PlusBoxHeading';
import UploadDocumentCard from 'components/UI/molecules/UploadDocumentCard';
import StrokeText from 'components/UI/atoms/StrokeText';
// import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/auth/useUser';

export default function FileUploadPanel({
  activeFile = [],
  title = null,
  histories = [],
  onUpload = () => {},
  onRemoveHistory = () => {},
  children,
}) {
  const location = useLocation();
  const { token } = useUser();
  // TODO: Or change this to user type.
  const isStudentRoute = location?.pathname?.indexOf('/student') === 0;

  const onFileClick = (file) => {
    const url = new URL(file?.filePath);
    url.searchParams.append('token', token);
    window.open(url.toString(), '_blank');
  };

  return (
    <div className="flex w-full justify-start relative overflow-x-hidden max-w-[100%]">
      <div className="flex justify-start overflow-x-hidden max-w-[100%]">
        <PlusBoxHolder className={'min-w-[200px]'}>
          <PlusBoxHeading>{typeof title === 'function' ? title() : title}</PlusBoxHeading>
          <PlusBox onClick={onUpload} />
        </PlusBoxHolder>
        <PlusBoxHolder className={'overflow-x-hidden max-w-[100%]'}>
          <PlusBoxHeading>UPLOAD HISTORY</PlusBoxHeading>
          {activeFile.length > 0 && (
            <DocumentCardContainer>
              {activeFile.map((item, index) => (
                <UploadDocumentCard
                  className="mt-[-1px]"
                  title={item.file?.fileName}
                  timestamp={item.file?.timestamp}
                  key={item.file?.id}
                  fileExtension={item.file?.fileExt}
                  status={index === 0 ? item.status : 6}
                  onRemoveClick={!isStudentRoute ? () => onRemoveHistory(item?.file?.uuid) : null}
                  onClick={() => onFileClick(item.file)}
                />
              ))}

              {/* {histories.map((item, index) => (
              <DocumentCard
                title={item.title}
                key={index}
                onClick={item.onClick}
                className={`${index === 0 ? 'opacity-100' : 'opacity-40'}`}
              />
            ))} */}
            </DocumentCardContainer>
          )}
          {activeFile.length === 0 && (
            <StrokeText className="text-[36px] max-w-[720px]">
              No Materials <br /> Currently <br /> Uploaded
            </StrokeText>
          )}
        </PlusBoxHolder>
      </div>
      {/* display top-right corner info as sub component here */}
      {children}
    </div>
  );
}
