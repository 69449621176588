export default function SubHeadingText({ children, className, ...props }) {
  return (
    <div
      className={
        'uppercase workSans-bold text-[24px] tracking-[4px] text-[#0492cd]' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {children}
    </div>
  );
}
