import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';
import DocumentCard from 'components/UI/molecules/DocumentCard';
import DocumentCardContainer from 'components/UI/molecules/DocumentCardContainer';
import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import StrokeText from 'components/UI/atoms/StrokeText';
import useDevelopYourStoryState from 'hooks/student/useDevelopYourStoryState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BottomLandscapeCard from 'components/UI/molecules/BottomLandscapeCard';
import ModalUploader from 'components/UI/organisms/ModalUploader';
import useDirectories from 'hooks/student/useDirectories';
// File history
import FileUploadPanel from 'components/UI/organisms/FileUploadPanel';
import useFileUploadPanel from 'hooks/student/useFileUploadPanel';
import useAssignmentDocuments from 'hooks/student/useAssignmentDocuments';
import AssignResourceAccordion from 'components/UI/organisms/AssignResourceAccordion';
import useUser from 'hooks/auth/useUser';
import EvidenceRecordButton from 'components/UI/atoms/EvidenceRecordButton';
import UploadModalAsPanel from 'components/UI/organisms/UploadModalAsPanel';

function DevelopYourStory() {
  const [searchParams] = useSearchParams();
  const { userType } = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { list: folderList, getByPath } = useDirectories();
  const { histories } = useFileUploadPanel();
  const currentFolder =
    folderList.find((item) => item.id === Number(searchParams.get('folderId'))) || {};

  const { list: assignmentDocuments } = useAssignmentDocuments();

  const breadcrumbsItems = [
    {
      text: 'ENGAGEMENT',
      path: '/engagement?folderId=' + getByPath('engagement')?.id,
    },
    {
      text: 'DEVELOP YOUR STORY',
      path: '/engagement/develop-your-story?folderId=' + currentFolder.dirId,
    },
  ];

  const {
    slot1,
    slot2,
    slot1Input,
    slot2Input,
    removeSlot1,
    removeSlot2,
    targetSlot,
    handleLinkUpload,
  } = useDevelopYourStoryState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadModal = (type = null) => {
    setOpen(type);
    switch (type) {
      case 'slot1':
        targetSlot.current = 1;
        break;
      case 'slot2':
        targetSlot.current = 2;
        break;
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    switch (open) {
      case 'slot1':
        slot1Input.click();
        break;
      case 'slot2':
        slot2Input.click();
        break;
    }
  };

  return (
    <div>
      <MainHeadingText>
        DEVELOP <br /> YOUR STORY
      </MainHeadingText>
      <Breadcrumb prefix={true} items={breadcrumbsItems} />
      <MainHeadingSeparator />
      {userType === 'student' && (
        <>
          <FileUploadPanel
            title={() => <>YOUR C.V.</>}
            activeFile={slot1}
            histories={histories}
            onUpload={() => uploadModal('slot1')}
            onRemoveHistory={removeSlot1}
          ></FileUploadPanel>
          <FileUploadPanel
            title={() => (
              <>
                YOUR <br /> PRESENTATION SKILLS
              </>
            )}
            activeFile={slot2}
            histories={histories}
            onUpload={() => uploadModal('slot2')}
            onRemoveHistory={removeSlot2}
          ></FileUploadPanel>
          <EvidenceRecordButton />
        </>
      )}
      <DashboardContentText>
        Your experience and skills are key to developing your ‘story’. Employers and universities
        will look for this in your UCAS application, your CV and your letter of application when
        selecting. They are also likely to probe this at interview. This section helps you to be
        ready.
      </DashboardContentText>
      <DashboardContentText>
        Make sure that your personal, social media online footprint does not contradict this.
        Increasingly employers are googling prospective candidate.Use your social media to see your
        story as well.
      </DashboardContentText>
      <div style={{ height: 36 }} />
      {userType === 'student' ? (
        <>
          <DashboardContentHeading>ASSIGNMENT DOCUMENTS</DashboardContentHeading>
          {assignmentDocuments.length === 0 ? (
            <div className="py-[56px]">
              <StrokeText>
                No Assignment <br />
                Documents
              </StrokeText>
            </div>
          ) : (
            <DocumentCardContainer>
              {assignmentDocuments.map((item, index) => (
                <DocumentCard data={item} key={index} />
              ))}
            </DocumentCardContainer>
          )}
          <div className="flex flex-wrap  w-full justify-start gap-[72px] mt-[140px]">
            <BottomLandscapeCard
              title="VISIT THE EVIDENCE RECORD"
              buttonTitle="START"
              onClick={() => {
                navigate('/student/purpose/evidence-record');
              }}
            />
          </div>
        </>
      ) : (
        <>
          <AssignResourceAccordion />
          <UploadModalAsPanel />
        </>
      )}

      <ModalUploader
        open={!!open}
        setOpen={setOpen}
        handleUpload={handleUpload}
        handleLinkUploadFunc={handleLinkUpload}
      />
    </div>
  );
}

export default DevelopYourStory;
