/**
 * Generate dummy data
 * @param {*} count
 * @param {*} obj
 * @returns
 */
export function generateDataTree(flatData) {
  const hashTable = Object.create(null);
  flatData.forEach((aData) => (hashTable[aData.id] = { ...aData, children: [] }));
  const dataTree = [];
  flatData.forEach((aData) => {
    if (aData.parentId) hashTable[aData.parentId].children.push(hashTable[aData.id]);
    else dataTree.push(hashTable[aData.id]);
  });
  return dataTree;
}
