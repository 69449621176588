import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from '../redux/reducers/sidebarStatus';

function useSidebar() {
  const { isOpen } = useSelector((state) => state.sidebarStatus);
  const dispatch = useDispatch();
  const setOpenStatus = (value) => {
    dispatch(setOpen(value));
  };

  return { isOpen, setOpen: setOpenStatus };
}

export default useSidebar;
