import useUser from 'hooks/auth/useUser';
import { useEffect, useState } from 'react';
import useEvidenceToReview from 'hooks/trainer/EvidenceRecord/useEvidenceToReview';
import { useNavigate } from 'react-router-dom';
import EvidenceGradingModalBase from 'components/UI/organisms/EvidenceGradingModalBase';
// import GradeModalFileItem from 'components/UI/molecules/GradeModalFileItem';
// import OvalIconButtonWhite from 'components/UI/molecules/OvalIconButtonWhite';
// import RadioScoreButton from 'components/UI/molecules/Trainer/RadioScoreButton';
// import ReactDOM from 'react-dom';
// import CloseIcon from '@mui/icons-material/Close';
// import useEvidenceRecordGrades from 'hooks/trainer/EvidenceRecord/useEvidenceRecordGrades';
// import logger from 'utils/logger';

export default function EvidenceRecordGradingModal({
  open = false,
  onSubmit,
  onClose,
  defaultValue,
  ...props
}) {
  const navigate = useNavigate();
  const [uploads, setUploads] = useState([]);
  const { fsId, pcId, skillList, competencyList, getUploads } = useEvidenceToReview();
  const { user } = useUser();

  const currentGradeData = defaultValue;

  const [skillScore, setSkillScore] = useState(currentGradeData?.skillScore || 0);
  const [competencyScore, setCompetencyScore] = useState(currentGradeData?.competencyScore || 0);
  const [comments, setComments] = useState(currentGradeData?.comments || '');
  const onScoreChange = (e) => {
    if (e.target.name === 'skillScore') {
      setSkillScore(Number(e.target.value));
    } else {
      setCompetencyScore(Number(e.target.value));
    }
  };

  const currentFS = skillList.find((item) => item.id === fsId);
  const currentPC = competencyList.find((item) => item.id === pcId);

  const closeModal = () => {
    typeof onClose === 'function' && onClose();
  };

  useEffect(() => {
    getUploads({ fsId, pcId, studentId: defaultValue.studentId }).then((result) => {
      setUploads(result.list);
    });
  }, []);

  const onSubmitGrade = () => {
    typeof onSubmit === 'function' &&
      onSubmit({
        fsId,
        pcId,
        skillScore,
        competencyScore,
        teacherId: user.userId,
        studentId: defaultValue.studentId,
        comments,
      });
    closeModal();
  };

  /**
   *
   * @param {InputEvent} e
   */
  const onCommentChange = (e) => {
    e.preventDefault();
    setComments(e.target.value);
  };

  const gotoEvidenceOverview = () => {
    navigate(`/trainer/evidence/${defaultValue.studentUUID}/overview`);
  };

  return (
    <EvidenceGradingModalBase
      open={open}
      skillScore={skillScore}
      competencyScore={competencyScore}
      fsCode={currentFS.code}
      fsTitle={currentFS.title}
      fsSubtitle={currentFS.subtitle}
      fsDescription={currentFS.description}
      pcCode={currentPC.code}
      pcTitle={currentPC.title}
      pcSubtitle={currentPC.subtitle}
      pcDescription={currentPC.description}
      onScoreChange={onScoreChange}
      comments={comments}
      onCommentChange={onCommentChange}
      onSubmit={onSubmitGrade}
      onClose={onClose}
      uploads={uploads}
      tabIndex={0}
      studentName={currentGradeData?.studentName || currentGradeData?.userFullName}
      openEvidenceOverview={gotoEvidenceOverview}
    />
  );
}
