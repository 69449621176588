import useUser from './useUser';

function useAllowedRoutes() {
  const { userType } = useUser();
  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  const path = window.location.pathname;
  const url = window.location.href;
  const baseUrl = url.replace(path, '');
  const secondIndex = getPosition(path, '/', 2);
  const secPart = path.slice(0, secondIndex);
  const currentUrl = `${baseUrl}${secPart}`;
  const allowedUrl = `${baseUrl}/${userType}`;
  const isAllow = currentUrl === allowedUrl;
  return { currentUrl, allowedUrl, isAllow };
}

export default useAllowedRoutes;
