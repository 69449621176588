import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'components/UI/molecules/Breadcrumb';

import MainHeadingText from 'components/UI/atoms/MainHeadingText';
import MainHeadingSeparator from 'components/UI/atoms/MainHeadingSeparator';
import DashboardContentText from 'components/UI/atoms/DashboardContentText';
import DashboardContentHeading from 'components/UI/atoms/DashboardContentHeading';
// import HoverOptionCard from 'components/UI/molecules/HoverOptionCard';
import LandscapeCard from 'components/UI/molecules/LandscapeCard';
import LandscapeCardListContainer from 'components/UI/molecules/LandscapeCardListContainer';
import ChevronRightIcon from 'components/UI/atoms/CustomIcons/ArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// import CheckList from 'components/UI/molecules/CheckList';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import CircularProgress from '@mui/material/CircularProgress';

// Accordion Content
import useGroupInstance from 'hooks/trainer/useGroupInstance';
import useGroupQuery from 'hooks/trainer/useGroupQuery';

// import Input from 'components/UI/atoms/Input';
// import CheckItem from 'components/UI/molecules/CheckItem';
// import headframe from 'assets/images/svg/headframe.svg';
// import HeadIcon from 'components/UI/atoms/CustomIcons/HeadIcon';
// import Slider from 'react-slick';
// Tag related
// import { tagFilters } from 'constants/sampleData';
// Document slider
// import DocumentCard from 'components/UI/molecules/DocumentCard';

// import SearchUser from 'components/UI/organisms/trainer/SearchUser';
import NewUserInput from 'components/UI/organisms/trainer/NewUserInput';
import useAssignedRoles from 'hooks/admin/useAssignedRoles';
import { CircularProgress } from '@mui/material';
import UserSearch from 'components/UI/organisms/admin/UserSearch';
import NewUserForm from 'components/UI/organisms/admin/NewUserForm';
import Notifier from 'components/UI/organisms/Notifier';
import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import http from 'services/http';
import logger from 'utils/logger';
// import useStudentQuery from 'hooks/student/useStudentQuery';

const accStyle = {
  border: 0,
  borderBottom: '1px solid black',
  margin: '0 !important',
  boxShadow: 'none',
  '&:before': {
    backgroundColor: 'unset',
  },
};

const accSummaryStyle = {
  padding: '0',
};
const accDetailStyle = {
  padding: '15px 0',
};

const WrapLoading = ({ loading = false, children }) => {
  return (
    <div className="relative">
      <div
        style={{
          opacity: loading === true ? 0.3 : 1,
        }}
      >
        {children}
      </div>
      {loading === true && (
        <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
          <div className="text-center">
            <CircularProgress />
            <div className="fort text-[12px] animate-pulse">UPLOADING</div>
          </div>
        </div>
      )}
    </div>
  );
};

function AssignRole() {
  const {
    loading: pLoading,
    toggleAccordion,
    studentAccordion,
    materialAccordion,
    batchAddStudent,
  } = useGroupInstance();
  const { group } = useGroupQuery();

  const { uploadStudentInput, uploadTrainerInput, studentInputLoading, trainerInputLoading } =
    useAssignedRoles();

  const breadcrumbsItems = [
    {
      text: 'Admin Dashboard',
      path: '/admin/roles',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStudentAdd = (students) => batchAddStudent(students, group);

  const onUploadStudentClick = () => {
    uploadStudentInput.click();
  };

  const onUploadTrainerClick = () => {
    uploadTrainerInput.click();
  };

  const onGradeAdvance = async () => {
    try {
      await http.post('api/users/gradeAdvancement');
      window.dispatchEvent(
        new CustomEvent('notify', {
          detail: {
            title: null,
            message: 'Student Grades Updated',
            status: 'success',
          },
        })
      );

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      logger(err);
      alert(err?.response?.data || err.message);
    }
  };

  return (
    <>
      <div>
        <MainHeadingText>
          Assigned <br /> Roles
        </MainHeadingText>
        <Breadcrumb items={breadcrumbsItems} />
        <MainHeadingSeparator />
        <DashboardContentText>
          Please use this area to upload master CSV files or to update/upload single users.
        </DashboardContentText>
        <LandscapeCardListContainer>
          <WrapLoading loading={studentInputLoading}>
            <LandscapeCard
              title="Upload Student .CSV File"
              buttonTitle="START"
              buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              onClick={onUploadStudentClick}
            />
          </WrapLoading>

          <WrapLoading loading={trainerInputLoading}>
            <LandscapeCard
              title="Upload Trainer .CSV File"
              buttonTitle="START"
              buttonIcon={<ChevronRightIcon size={20} className="pl-[2px]" />}
              onClick={onUploadTrainerClick}
            />
          </WrapLoading>
        </LandscapeCardListContainer>
        <Accordion expanded={true} sx={accStyle}>
          <AccordionDetails sx={{ padding: '0', paddingBottom: '20px' }}></AccordionDetails>
        </Accordion>
        {/* TODO: Expanded temporarily */}
        {/* Student */}
        <Accordion
          expanded={studentAccordion}
          sx={accStyle}
          onChange={(e, b) => toggleAccordion('studentAccordion', b)}
        >
          <AccordionSummary sx={accSummaryStyle} aria-controls="panel2-content" id="panel2-header">
            <DashboardContentHeading>
              ASSIGN A NEW USER <KeyboardArrowDownIcon />
            </DashboardContentHeading>
          </AccordionSummary>
          <AccordionDetails sx={accDetailStyle}>
            <NewUserForm />
            <br />
            {false && (
              <NewUserInput
                loading={pLoading?.studentPanel || false}
                addToGroup={handleStudentAdd}
              />
            )}
          </AccordionDetails>
        </Accordion>
        {/* TODO: Expanded temporarily; will shorten this to a component later. */}
        {/* Material */}
        <Accordion
          expanded={materialAccordion}
          sx={accStyle}
          onChange={(e, b) => toggleAccordion('materialAccordion', b)}
        >
          <AccordionSummary sx={accSummaryStyle} aria-controls="panel3-content" id="panel3-header">
            <DashboardContentHeading>
              EDIT EXISTING USER
              <KeyboardArrowDownIcon />
            </DashboardContentHeading>
          </AccordionSummary>
          <AccordionDetails sx={accDetailStyle}>
            <OvalIconButton
              title="ADVANCE GRADE OF STUDENTS"
              onClick={onGradeAdvance}
              style={{ marginBottom: '20px' }}
            />
            <UserSearch />
          </AccordionDetails>
        </Accordion>
      </div>
      <Notifier />
    </>
  );
}

export default AssignRole;
