import SkillViewCardItem from 'components/UI/molecules/student/SkillViewCardItem';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useEvidenceSkillsState from 'hooks/student/useEvidenceSkillsState';
import useEvidenceCompetencyState from 'hooks/student/useEvidenceCompetencyState';
import useEvidenceSkillUpload, { useSkillViewContext } from 'hooks/student/useEvidenceSkillUpload';
import logger from 'utils/logger';
import useEvidenceRecordGrades from 'hooks/student/useEvidenceRecordGrades';
import stringPadding from 'utils/stringPadding';
import SkillViewBase from 'components/UI/organisms/SkillViewBase';

export default function SkillView() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { mode, id } = params;
  const { list: evidenceSkilllist } = useEvidenceSkillsState();
  const { list: evidenceCompetencylist } = useEvidenceCompetencyState();
  // const { slot1, removeSlot1 } = useEvidenceSkillUpload();
  const { slot1 } = useEvidenceSkillUpload();
  const { selectedItem } = useSkillViewContext();
  const { evidenceSkillsScore, evidenceCompetenciesScore } = useEvidenceRecordGrades();
  const defaultValue = {
    id: null,
    uuid: null,
    title: null,
    description: null,
    info: null,
  };
  const [target, setTarget] = useState(defaultValue);
  const [nextTarget, setNextTarget] = useState(defaultValue);

  const uploadedFiles = slot1.filter((item) => {
    const fsId = item.file.fsId;
    const pcId = item.file.pcId;
    return fsId === selectedItem?.fsId && pcId === selectedItem?.pcId;
  });

  useEffect(() => {
    logger('route-changed');
  }, [params]);

  useEffect(() => {
    const _nextTarget = getNextTarget();
    setNextTarget(_nextTarget);
  }, [target]);

  useEffect(() => {
    const target =
      params.mode === 'skill'
        ? evidenceSkilllist.find((item) => Number(id) === Number(item.id))
        : evidenceCompetencylist.find((item) => Number(id) === Number(item.id));
    setTarget(target || defaultValue);
  }, [evidenceSkilllist, evidenceCompetencylist, params, setTarget]);

  // const onUploadFileRemoveClick = (uuid) => {
  //   removeSlot1(uuid);
  // };

  const backToList = () => {
    navigate('/student/purpose/evidence-record/record?folderId=' + searchParams.get('folderId'));
  };

  const getNextTarget = () => {
    const currentItemIndex =
      params.mode === 'skill'
        ? evidenceSkilllist.findIndex((item) => Number(id) === Number(item.id))
        : evidenceCompetencylist.findIndex((item) => Number(id) === Number(item.id));

    const _nextTarget =
      params.mode === 'skill'
        ? evidenceSkilllist[currentItemIndex + 1]
        : evidenceCompetencylist[currentItemIndex + 1];
    return _nextTarget;
  };

  const nextItemClick = () => {
    const _nextTarget = getNextTarget();

    navigate(
      `/student/purpose/evidence-record/record/${params.mode}/${
        _nextTarget.id
      }?folderId=${searchParams.get('folderId')}`
    );
  };

  const pairItem = mode === 'skill' ? evidenceCompetencylist : evidenceSkilllist;
  let description = target.description || '';
  description = description
    .split('\n')
    .map((item) => item.trim())
    .join('\n');

  const uploads = uploadedFiles.map((item) => {
    //
    /**
     * @type {import('../../../../components/UI/organisms/SkillViewBase').Upload}
     */
    const result = {
      fileName: item.file.fileName,
      filePath: item.file.filePath,
      timestamp: item.file.timestamp,
      uuid: item.file.uuid,
      fileExt: item.file.fileExt,
      status: item.status,
    };
    return result;
  });

  return (
    <SkillViewBase
      mode={mode}
      code={target.code}
      title={target.title}
      subtitle={target.subtitle}
      description={description}
      pairList={pairItem.map((item) => {
        const { id: itemId, title, code, subtitle, createdAt, updatedAt, ...otherProps } = item;
        return (
          <SkillViewCardItem
            key={`${mode === 'skill' ? `${id}-${itemId}` : `${itemId}-${id}`}`}
            data={{
              id: itemId,
              title:
                mode === 'skill' ? (
                  `${code}: `
                ) : (
                  <>
                    {`${code}: ${title}`}
                    <br />
                  </>
                ),
              content: mode === 'skill' ? title : subtitle,
              ...otherProps,
            }}
            data-id={`${mode === 'skill' ? `${id}-${itemId}` : `${itemId}-${id}`}`}
          ></SkillViewCardItem>
        );
      })}
      uploads={uploads}
      score={
        mode === 'skill'
          ? stringPadding(evidenceSkillsScore, 3)
          : stringPadding(evidenceCompetenciesScore, 3)
      }
      nextTarget={nextTarget}
      nextItemClick={nextItemClick}
      backToList={backToList}
    />
  );
}
