import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'utils/logger';
import PlusIcon from '../../atoms/CustomIcons/PlusIcon';
import EvidenceRecordGradingModal from 'components/UI/organisms/trainer/EvidenceRecordGradingModal';
import useEvidenceRecord from 'hooks/trainer/EvidenceRecord/useEvidenceRecord';

const SkillViewCardItemStatus = {
  NORMAL: 0,
  ACTIVE: 1,
  UPLOADED: 2,
  GRADED: 3,
};

const CompetencyScale = {
  NO_CURRENT_EVIDENCE: 0,
  EVIDENCE_IN_REVIEW: 1,
  NEED_ATTENTION: 2,
  GOOD_STANDING: 3,
};

const ScaleColors = ['#fff', '#394b87', '#bc2f2c', '#00a7e1'];

/**
 * @typedef {{
 *  data: {
 *    id: string,
 *    title: string,
 *    content: string
 *    score: string
 *  }
 * }} SkillViewCardItemProps
 * @param {import("react").HTMLAttributes & SkillViewCardItemProps} param0
 * @returns
 */
export default function SkillViewCardItem({ data = {}, style, ...props }) {
  // const { user } = useUser();
  const [openGradingModal, setOpenGradingModal] = useState(false);
  const buttonLabelRef = useRef(null);
  const { NORMAL } = SkillViewCardItemStatus;
  const { mode, id } = useParams();

  const { setTargetEvidence, evidenceGrades, submitEvidenceGrade, studentUploads } =
    useEvidenceRecord();

  const [state, setState] = useState({
    status: NORMAL,
    isFocused: false,
    buttonLabel: 'UPLOAD EVIDENCE',
    buttonWidth: 0,
    hasUploads: false,
    isGraded: false,
    score: null,
    scale: CompetencyScale.NO_CURRENT_EVIDENCE,
  });

  const { isFocused, buttonLabel, buttonWidth, hasUploads, isGraded, scale, score } = state;

  useEffect(() => {
    let _scale = 0;
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    const currnetStudentUploads = studentUploads.filter((item) => {
      return item.fsId === fsId && pcId === item.pcId;
    });
    if (currnetStudentUploads.length > 0) {
      _scale = CompetencyScale.EVIDENCE_IN_REVIEW;
      setState((prevState) => ({
        ...prevState,
        isGraded: true,
        scale: _scale,
      }));
    }
    const currentItem = evidenceGrades.find((item) => {
      logger('to compare: ', `${fsId}=${item.fsId} | ${pcId}=${item.pcId}`);
      return fsId === item.fsId && pcId === item.pcId;
    });
    logger('currentItem: ', currentItem);

    if (currentItem) {
      const squareTotal = currentItem.skillScore + currentItem.competencyScore;
      if (squareTotal >= 1 && squareTotal <= 4) {
        _scale = CompetencyScale.NEED_ATTENTION;
      } else if (squareTotal >= 5) {
        _scale = CompetencyScale.GOOD_STANDING;
      }
      setState((prevState) => ({
        ...prevState,
        isGraded: squareTotal > 0 ? true : currnetStudentUploads.length > 0,
        score: currentItem.skillScore + '/' + currentItem.competencyScore,
        scale: _scale,
      }));
    }
  }, [evidenceGrades, setState]);

  const onClick = () => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    setTargetEvidence({
      fsId,
      pcId,
    });
    setOpenGradingModal(true);
  };

  const closeGradingModal = () => {
    setOpenGradingModal(false);
  };

  const containerFocus = () => {
    const fsId = mode === 'skill' ? Number(id) : data.id;
    const pcId = mode === 'skill' ? data.id : Number(id);
    // target.fsId = fsId;
    // target.pcId = pcId;
    setTargetEvidence({
      fsId,
      pcId,
    });
    setState((prevState) => ({
      ...prevState,
      isFocused: true,
    }));
  };

  const containerNotFocus = () => {
    setState((prevState) => ({
      ...prevState,
      isFocused: false,
    }));
  };

  const onGradeSubmit = (data) => {
    submitEvidenceGrade(data);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      buttonWidth: isFocused ? buttonLabelRef.current.scrollWidth : 0,
    }));
  }, [buttonLabel, setState, isFocused]);

  const hoverClass =
    'hover:opacity-100 hover:shadow-[0px_0px_0px_2px] hover:shadow-sky-500 hover:text-sky-500';
  const activeClass = 'opacity-100 shadow-[0px_0px_0px_2px] shadow-sky-500 text-sky-500';

  return (
    <>
      <div
        tabIndex={0}
        className={`border-[1px] group rounded-[12px] w-[240px] min-h-[180px] p-[16px] flex flex-col cursor-pointer ${
          isGraded ? 'opacity-100' : hasUploads ? 'opacity-100' : 'opacity-50'
        } ${isFocused ? activeClass : hoverClass}`}
        style={{
          ...style,
          backgroundColor: ScaleColors[scale],
          borderColor: isGraded ? ScaleColors[scale] : '#ccc',
          color: isGraded ? '#FFF' : null,
        }}
        {...props}
        onFocus={containerFocus}
        onBlur={containerNotFocus}
      >
        <div
          className="flex-1 fort text-[13px] text-black leading-[15px]"
          style={{ color: isGraded ? '#FFF' : null }}
        >
          <p>
            <span>{data?.title} </span>
            <span className="font-extralight uppercase">{data?.content}</span>
          </p>
        </div>
        <div className="flex flex-row items-center">
          <div
            className={
              'transition-all duration-200 ease-in-out flex flex-row items-center flex-1 gap-[8px] text-black' +
              (isFocused ? ' opacity-0' : ' opacity-100')
            }
            style={{ color: isGraded ? '#FFF' : null }}
          >
            <div
              className={`${
                isGraded ? 'stoke-text-white' : 'sub-heading-white-stroke2'
              } text-[32px]`}
            >
              {score || '0/0'}
            </div>
            <div className="text-[12px] leading-[14px] font-bold">
              PAIRING
              <br />
              EVIDENCE
              <br />
              SCORE
            </div>
          </div>
          <div className="relative h-[28px] min-w-[28px]">
            <button
              className="rounded-full h-[28px] flex flex-row items-center justify-center border-currentColor border-[1px] absolute right-0"
              onClick={onClick}
            >
              <div
                className="transition-all duration-200 overflow-hidden block"
                style={{ width: buttonWidth }}
              >
                <div
                  className="pl-[16px] text-[12px] fort font-extralight whitespace-nowrap block"
                  ref={buttonLabelRef}
                >
                  SET GRADE
                </div>
              </div>
              <div className="w-[28px] h-[28px] flex flex-row items-center justify-center">
                <PlusIcon size={12} />
              </div>
            </button>
          </div>
        </div>
      </div>
      {openGradingModal && (
        <EvidenceRecordGradingModal
          open={openGradingModal}
          onClose={closeGradingModal}
          onSubmit={onGradeSubmit}
        />
      )}
    </>
  );
}
