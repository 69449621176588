import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { newFile } from 'redux/reducers/uploadedFile';
import http from 'services/http';
import { useSearchParams } from 'react-router-dom';

export default function useUploadPanel() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [type, setType] = useState(null);
  const [year, setYear] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlerts] = useState(null);
  const [hasSelected, setSelected] = useState(false);
  const [selectedSchool, setSchool] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [featured, setFeatured] = useState(false);
  const [searchParams] = useSearchParams();
  const onFileNameChange = ({ target }) => setFile({ ...file, fileName: target.value });

  useEffect(() => {
    const folderId = Number(searchParams.get('folderId'));
    if (folderId !== null) setAssignment(folderId);
  }, [searchParams]);

  useEffect(() => {
    if (type !== null && !hasSelected) setSelected(true);
  }, [type]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlerts(null);
      }, 3000);
    }
  }, [alert]);

  const removeFile = () => {
    setFile(null);
    setType(null);
    setYear(null);
    setSchool(null);
    setSelected(false);
  };

  const upload = async () => {
    if (!file) {
      setAlerts('No file set');
      return;
    }
    setLoading(true);
    try {
      let params = {};
      let postLink = '/api/materials-file';

      const tags = {};
      if (selectedSchool) tags.academyId = [selectedSchool];
      if (assignment) tags.dirId = typeof assignment === 'object' ? [...assignment] : [assignment];
      if (year) tags.yearId = year;
      if (file?.fileExt === '.link') {
        params.academyId = selectedSchool;
        params.yearId = year;
        params.featured = featured;
        postLink = '/api/materials-link';
        params = { ...file, tags };
      } else {
        params = new FormData();
        params.append('files', file.file);
        params.append('fileName', file.fileName);
        params.append('fileExt', file.fileExt);
        params.append('featured', featured);
        params.append('tags', JSON.stringify(tags));
      }
      const { data } = await http.post(postLink, params);
      await dispatch(newFile(data[0]));
      removeFile();
    } catch (e) {
      setAlerts('Unable to upload file');
    }
    setLoading(false);
  };

  const back = () => {
    setFile(null);
    setType(null);
    setSelected(false);
  };

  return {
    alert,
    setAlerts,
    assignment,
    setAssignment,
    file,
    back,
    setFile,
    onFileNameChange,
    type,
    hasSelected,
    selectedSchool,
    setType,
    open,
    setOpen,
    setSchool,
    year,
    setYear,
    removeFile,
    upload,
    loading,
    featured,
    setFeatured,
  };
}
