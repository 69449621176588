export default function PlusBoxHolder({ children, className, ...props }) {
  return (
    <div
      className={
        'border-r-[1px] border-black/20 pr-[40px] mr-[40px] last:border-r-0 last:pr-[0px] ' +
        'last:mr-[0px]' +
        (typeof className === 'string' ? ` ${className}` : '')
      }
      {...props}
    >
      {children}
    </div>
  );
}
