import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OvalIconButton from '../molecules/OvalIconButton';
import CheckItem from '../molecules/CheckItem';
import BaseList from '../atoms/BaseList';
import Input from '../atoms/Input';
import ArrowRightIcon from '../atoms/CustomIcons/ArrowRight';
import DocumentCardContainerSmall from '../molecules/DocumentCardContainerSmall';
import { Checkbox, CircularProgress, FormControlLabel, Modal, Box } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import UploadDocumentCardWhiteSmall from '../molecules/UploadDocumentCardWhiteSmall';
import AedTooltip from '../molecules/AedTooltip';
import useGroupInstance from 'hooks/trainer/useGroupInstance';
import useGroupQuery from 'hooks/trainer/useGroupQuery';
import SearchStudent from './SearchStudent';
import HeadIcon from '../atoms/CustomIcons/HeadIcon';
import HighlightCard from '../molecules/HighlightCard';
import InfoIcon from '@mui/icons-material/Info';
import { useSearchParams } from 'react-router-dom';
import StudentTree from 'components/UI/organisms/SkillsTree';
import useDebounce from 'hooks/useDebounce';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '0 20px',
  bgcolor: '#717171d9',
  border: '2px solid #fff',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
};

function ModalShareDocuments({
  showModal,
  setShowModal,
  selectedMaterials,
  checkMaterial,
  unCheckMaterials,
  loadingMaterials,
}) {
  const [searchParams] = useSearchParams();
  const {
    groupType,
    groupTypes,
    setGroupType,
    batchAddMaterialGroups,
    loading: materialGroupAddLoading,
    batchAddMaterialGroupsDirectories,
  } = useGroupInstance();
  const {
    groups,
    loadGroups,
    checkGroup,
    unCheckGroups,
    selected: selectedGroups,
  } = useGroupQuery();

  // const [selectedStudents, setSelectedStudents] = useState();
  const [selectedSkill, setSelectedSkill] = useState();
  const [step, setStep] = useState(0);
  const [shareWith, setShareWith] = useState('GROUP');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [groupName, setGroupName] = useState('');
  const deferredGroupName = useDebounce(groupName, 400);

  useEffect(() => {
    loadGroups({ groupTypeId: groupType?.id, groupName });
  }, [deferredGroupName, groupType]);

  const onCheckItemChange = (item) => {
    checkMaterial(item);
  };

  const onModalClose = () => {
    setShowModal(false);
    resetModalValues();
  };
  const resetModalValues = () => {
    setStep(0);
    unCheckGroups();
    unCheckMaterials();
    setShareWith('GROUP');
    setGroupType('');
    setGroupName('');
    setSelectedStudents([]);
  };
  const onGroupTypeChange = (selectedGrpType) => {
    if (selectedGrpType === groupType) {
      setGroupType(null);
    } else {
      setGroupType(selectedGrpType);
    }
  };
  const addMaterialsToGroups = async () => {
    try {
      await batchAddMaterialGroups(
        selectedMaterials,
        selectedGroups,
        Number(searchParams.get('folderId')) || selectedSkill
      ); // selectedSkill need to be passed
      // resp.statusText === 'OK' &&
      // setToast({
      //   status: true,
      //   message: 'Material Assigned Successfully',
      // });
    } catch (err) {
      // setToast({
      //   status: true,
      //   message: err.message,
      // });
    }
    setShowModal(false);
    resetModalValues();
  };
  // TODO: Pass directories
  const addMaterialsToGroupsStudents = async (students) => {
    try {
      await batchAddMaterialGroupsDirectories(
        selectedMaterials,
        students,
        Number(searchParams.get('folderId')) || selectedSkill
      );
      // resp.statusText === 'OK' &&
      //   setToast({
      //     status: true,
      //     message: 'Material Assigned Successfully',
      //   });
    } catch (err) {
      // setToast({
      //   status: true,
      //   message: err.message,
      // });
    }
    setShowModal(false);
    resetModalValues();
  };

  const newWidth = () => {
    switch (step) {
      case 1:
        return shareWith === 'GROUP' ? 950 : 1350;
      case 2:
        return 750;
      default:
        return 500;
    }
  };

  // const handleSearch = () => onSearch(selectedUuids, searchFile);

  return (
    <Modal open={showModal || false} onClose={onModalClose}>
      <Box sx={{ ...style, width: newWidth() }}>
        <div className="relative">
          <div
            className="absolute -top-6 -right-8 inset bg-white mx-2 border-[#777] border-[1px] rounded-full items-center flex right-0 justify-center"
            onClick={onModalClose}
          >
            <CloseIcon
              style={{
                fontSize: '20px',
              }}
            />
          </div>
        </div>
        {step === 0 && (
          <div className="flex flex-col items-center font-extralight">
            <p className="text-white text-[20px] fort w-fit tracking-widest font-[500]">
              DOCUMENT(S) SELECTED TO SHARE:
            </p>
            <DocumentCardContainerSmall className={'max-w-[75%] my-3'} mode="white">
              {selectedMaterials.map((item, index) => (
                <UploadDocumentCardWhiteSmall
                  title={item.fileName}
                  timestamp={item.createdAt}
                  key={item.id}
                  // className={'bg-white'}
                  status={loadingMaterials}
                  fileExtension={item.fileExt}
                  onRemoveClick={() => onCheckItemChange(item)}
                />
              ))}
            </DocumentCardContainerSmall>
            <div className="fort">
              <span className="mr-5 text-white text-[16px] tracking-widest font-[500]">
                SHARE WITH:{' '}
              </span>
              <FormControlLabel
                className="text-white text-[14px] tracking-widest"
                control={
                  <Checkbox
                    checked={shareWith === 'GROUP'}
                    style={{ width: '30px', height: '30px' }}
                    checkedIcon={<CloseOutlinedIcon />}
                    onChange={() => setShareWith('GROUP')}
                    sx={{
                      color: 'transparent',
                      border: '1px solid #fff',
                      borderRadius: '0px',
                      marginRight: '10px',
                      '&.Mui-checked': {
                        color: '#fff',
                      },
                    }}
                  />
                }
                label="GROUPS"
              />
              <FormControlLabel
                className="text-white text-[14px] tracking-widest"
                control={
                  <Checkbox
                    checked={shareWith === 'INDIVIDUALS'}
                    style={{ width: '30px', height: '30px' }}
                    checkedIcon={<CloseOutlinedIcon />}
                    onChange={() => setShareWith('INDIVIDUALS')}
                    sx={{
                      color: 'transparent',
                      border: '1px solid #fff',
                      borderRadius: '0px',
                      marginRight: '10px',
                      '&.Mui-checked': {
                        color: '#fff',
                      },
                    }}
                  />
                }
                label="INDIVIDUALS"
              />
            </div>
            <div className="flex justify-end">
              <div
                className="mt-5 bg-white mx-2 border-[#777] p-3 text-[#555] 
                    font-md border-[1px] rounded-full items-center flex right-0 
                    justify-center cursor-pointer"
                disabled={!selectedMaterials.length || loadingMaterials}
                onClick={() => {
                  !selectedMaterials.length || loadingMaterials ? setStep(0) : setStep(1);
                }}
              >
                <ArrowRightIcon size={16} />
              </div>
            </div>
          </div>
        )}
        {step === 1 && shareWith === 'GROUP' && (
          <div className="flex flex-col items-center font-extralight">
            <p className="text-white text-[20px] fort w-fit tracking-widest font-[500]">
              GROUP SEARCH:
            </p>
            <div className="grid grid-cols-2 justify-around my-4">
              <div className="pr-6">
                <div className="flex items-center">
                  <div className="flex flex-col items-start justify-between h-[100px] mb-4">
                    {groupTypes.map((g, k) => (
                      <div key={k}>
                        <FormControlLabel
                          className="text-white text-[14px] tracking-widest uppercase"
                          control={
                            <Checkbox
                              checked={groupType?.id === g?.id}
                              style={{ width: '30px', height: '30px' }}
                              checkedIcon={<CloseOutlinedIcon />}
                              onChange={() => onGroupTypeChange(g)}
                              sx={{
                                color: 'transparent',
                                border: '1px solid #fff',
                                borderRadius: '0px',
                                marginRight: '10px',
                                '&.Mui-checked': {
                                  color: '#fff',
                                },
                              }}
                            />
                          }
                          label={g?.name}
                        />
                        <AedTooltip title={''} description={g?.description}>
                          <InfoIcon
                            style={{
                              fontSize: '16px',
                              fontWeight: 100,
                              color: '#aaa',
                              cursor: 'pointer',
                            }}
                          />
                        </AedTooltip>
                      </div>
                    ))}
                  </div>
                </div>
                <Input
                  placeholder="Group Name"
                  className="text-left w-[90%] mb-8 rounded-lg p-2 shadow"
                  value={groupName}
                  onChange={({ target }) => setGroupName(target.value)}
                />
              </div>
              <div>
                <HighlightCard className={'px-4 py-5'}>
                  <BaseList listClassName="w-[400px] pr-4 max-w-[500px] bg-white rounded-lg h-[165px]">
                    {groups.map((g, k) => (
                      <CheckItem
                        key={k}
                        active={g?.active || false}
                        title={g.groupName}
                        subtitle={g.academyName}
                        onClick={() => checkGroup(g)}
                        svgicon={<HeadIcon size={20} active={false} />}
                      />
                    ))}
                  </BaseList>
                </HighlightCard>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex">
                <div
                  className="bg-transparent mx-2 border-[#fff] text-[#fff] border-[1px] rounded-full items-center flex right-0 justify-center"
                  onClick={() => setStep(0)}
                >
                  <ChevronLeftIcon
                    style={{
                      fontSize: '32px',
                    }}
                  />
                </div>
                {searchParams.get('folderId') ? (
                  <OvalIconButton
                    disabled={!selectedGroups.length}
                    onClick={addMaterialsToGroups}
                    title="ASSIGN DOCUMENTS"
                    className="min-w-[20px] text-[14px] text-[#27b4e6] border-[#27b4e6] bg-white font-bold hover:!bg-white/100"
                    icon={
                      <ChevronRightIcon
                        style={{
                          fontSize: '44px',
                          fontWeight: 100,
                        }}
                      />
                    }
                  />
                ) : (
                  <div
                    className="border-[#fff] text-[#ffffff] bg-transparent mx-2 border-[1px] cursor-pointer rounded-full items-center flex right-0 justify-center"
                    disabled={!selectedGroups.length || materialGroupAddLoading?.resourcePanel}
                    onClick={() => {
                      !selectedGroups.length || materialGroupAddLoading?.resourcePanel
                        ? setStep(1)
                        : setStep(2);
                    }}
                  >
                    <ChevronRightIcon
                      style={{
                        fontSize: '32px',
                        color: '#fff',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {step === 1 && shareWith === 'INDIVIDUALS' && (
          <div className="flex flex-col items-center font-extralight">
            <p className="text-white text-[20px] fort w-fit tracking-widest font-[500]">
              STUDENT SEARCH:
            </p>
            <div className="my-4 w-full">
              <SearchStudent
                fromModal={true}
                allowNext={!searchParams.get('folderId')}
                onNext={(selected) => {
                  if (!selected.length) return;
                  setSelectedStudents(selected);
                  setStep(2);
                }}
                onPrev={(selected) => {
                  setStep(0);
                }}
                onAssign={(selected) => {
                  if (!selected.length) {
                    return;
                  }
                  addMaterialsToGroupsStudents(selected);
                }}
              />
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="flex flex-col items-center font-extralight">
            <p className="text-white text-[20px] fort w-fit tracking-widest font-[500]">
              CHOOSE A {shareWith === 'INDIVIDUALS' ? 'STUDENT' : 'GROUP'} ASSIGNMENT/PROJECT
            </p>
            <div className="grow mt-[30px]">
              <BaseList listClassName="w-[470px] bg-white rounded-lg px-4 min-h-[45vh] capitalize text-sky-500 flex flex-col">
                <StudentTree setSelectedSkill={setSelectedSkill} />
              </BaseList>
            </div>

            <div className="flex mt-[30px]">
              <div
                className="bg-transparent mx-2 border-[#fff] text-[#fff] border-[1px] rounded-full items-center flex right-0 justify-center"
                onClick={() => setStep(1)}
              >
                <ChevronLeftIcon
                  style={{
                    fontSize: '32px',
                  }}
                />
              </div>
              <OvalIconButton
                disabled={!selectedSkill}
                onClick={
                  shareWith === 'INDIVIDUALS'
                    ? () => addMaterialsToGroupsStudents(selectedStudents)
                    : addMaterialsToGroups
                }
                title="ASSIGN DOCUMENTS"
                className="min-w-[20px] text-[14px] text-[#27b4e6] border-[#27b4e6] bg-white font-bold hover:!bg-white/100"
                icon={
                  <ChevronRightIcon
                    style={{
                      fontSize: '44px',
                      fontWeight: 100,
                    }}
                  />
                }
              />
              {materialGroupAddLoading?.resourcePanel && (
                <CircularProgress className=" ml-[20px] text-[#ffffff]" />
              )}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}
export default ModalShareDocuments;
