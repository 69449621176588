import { useSelector, useDispatch } from 'react-redux';
import { setAssignmentDirectory } from 'redux/reducers/assignmentDirectories';
import http from 'services/http';
import { generateDataTree } from 'utils/flatToTree';

export default function useAssignmentDirectory() {
  // assignment directory list
  /**
   * @type {import('../../redux/reducers/assignmentDirectories').AssignmentDirectory}
   */
  const assignmentDirectory = useSelector((state) => state.assignmentDirectory);
  const { list, treeList } = assignmentDirectory;
  const dispatch = useDispatch();

  const getList = async (generateTree, params = {}, hideEvidence = false) => {
    setAssignmentDirectory({ ...assignmentDirectory, isLoading: true });
    const result = await http.post('/api/directories/search', params);
    if (hideEvidence) {
      result.data.list = result.data.list.filter(
        (item) => item.name !== 'Evidence Record' && item.path !== 'evidence-record'
      );
    }
    let treeList = [];
    if (generateTree) {
      treeList = generateDataTree(result.data.list);
    }
    dispatch(setAssignmentDirectory({ ...result.data, treeList, isLoading: false }));
    return result;
  };

  const setList = (data) => {
    dispatch(setAssignmentDirectory(data));
  };

  return {
    getList,
    list,
    treeList,
    setList,
    isLoading: assignmentDirectory.isLoading,
  };
}
