import OvalIconButton from 'components/UI/molecules/OvalIconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import useDirectories from 'hooks/student/useDirectories';

export default function EvidenceRecordButton() {
  const navigate = useNavigate();
  const { list } = useDirectories();
  const evidenceRecord = list.find((item) => item.path === 'evidence-record');
  return (
    <div className="flex w-full justify-start mt-[72px] gap-[40px]">
      <OvalIconButton
        title="EVIDENCE RECORD"
        onClick={() => {
          navigate(`/student/purpose/evidence-record?folderId=${evidenceRecord.id}`);
        }}
        icon={
          <ChevronRightIcon
            style={{
              fontSize: '44px',
              fontWeight: 100,
            }}
          />
        }
      />
    </div>
  );
}
