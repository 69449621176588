import { useEffect, useState } from 'react';
import http from 'services/http';

let searchTimer = null;

/**
 * @type {AbortController | null}
 */
let abortCtrl = null;
export default function useTrainerList() {
  const [state, setState] = useState({
    list: [],
    page: 1,
    limit: 30,
    isLoading: false,
    keyword: '',
    pageCount: 1,
    totalCount: 1,
  });

  useEffect(() => {
    getList();
  }, []);

  const getList = async (_payload = {}, append = true) => {
    try {
      if (abortCtrl) abortCtrl.abort();
      abortCtrl = new AbortController();
      setNewState({ isLoading: true, list: !append ? [] : state.list });
      const result = await http.post(
        '/api/users/search',
        {
          page: _payload?.page ?? state.page,
          limit: _payload?.limit ?? state.limit,
          userTypeId: 2,
          keyword: _payload?.keyword ?? state.keyword,
          order: 'asc',
          orderBy: 'name',
        },
        {
          signal: abortCtrl.signal,
        }
      );
      setState((prevState) => {
        return {
          page: _payload?.page ?? prevState.page,
          limit: _payload?.limit ?? prevState.limit,
          list: append === true ? [...prevState.list, ...result.data.list] : result.data.list ?? [],
          isLoading: false,
          keyword: _payload?.keyword ?? prevState.keyword,
          pageCount: result.data.pageCount,
          totalCount: result.data.totalCount,
        };
      });
    } catch (err) {
      if (err?.code === 'ERR_CANCELED') {
        console.log(err);
        return;
      }

      setState({
        isLoading: false,
      });
      alert(err?.response?.data ?? err.message);
    }
  };

  const loadMoreTrainers = async () => {
    await getList({
      page: state.page + 1,
    });
  };

  const onSearchTrainer = (keywordString) => {
    if (searchTimer) clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      getList(
        {
          page: 1,
          keyword: keywordString ?? '',
        },
        false
      );
    }, 400);
  };

  const setNewState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  return {
    ...state,
    getList,
    loadMoreTrainers,
    setState: setNewState,
    onSearchTrainer,
  };
}
