import { Typography } from '@mui/material';
import HeadIcon from '../atoms/CustomIcons/HeadIcon';
import PencilSMIcon from '../atoms/CustomIcons/PencilSMIcon';
import TrashSMIcon from '../atoms/CustomIcons/TrashSMIcon';

function GroupCard({
  title,
  groupType = 'class',
  active = false,
  className,
  onClickEdit = () => {},
  onClickDelete = () => {},
}) {
  const _className = typeof className === 'string' ? ` ${className}` : '';

  return (
    <div
      title={title}
      className={
        'min-w-[200px] max-w-[200px] min-h-[182px] p-[20px] pt-[28px] bg-white rounded-lg flex shadow-md shadow-black/20 ' +
        'flex-col first-letter hover:border-black transition-all relative ease-in-out duration-[.2s]' +
        _className
      }
    >
      <div className="absolute top-3 right-3">
        <div className="cursor-pointer" onClick={onClickEdit}>
          <PencilSMIcon size={16} />
        </div>
        <div className="mb-[5px]" />
        <div className="cursor-pointer" onClick={onClickDelete}>
          <TrashSMIcon size={16} />
        </div>
      </div>
      <HeadIcon size={48} active={false} />
      <div className="flex flex-col cursor-pointer mt-[16px]" onClick={onClickEdit}>
        <Typography
          variant="p"
          className={`file-title-webkit grow fort font-normal text-[16px] leading-[18px] tracking-[1px]  ${
            active ? 'font-medium text-[#00A7E1]' : 'font-extralight text-black'
          } uppercase tracking-[2px]`}
        >
          {title || 'Document ## Name'}
        </Typography>
        <Typography
          variant="p"
          className={`fort text-[15px] pt-[4px] text-[#555] tracking-[1px] ${
            active ? 'font-medium text-[#00A7E1]' : 'font-extralight text-black'
          } uppercase tracking-[2px]`}
        >
          {groupType}
        </Typography>
      </div>
    </div>
  );
}

export default GroupCard;
