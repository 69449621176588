import { useEffect, useState } from 'react';
import http from 'services/http';

export default function useUserGroup({ userId }) {
  const [userGroup, setUserGroup] = useState();
  const [isLoading, setUserGroupLoading] = useState(false);

  const getUserGroups = async () => {
    setUserGroupLoading(true);
    const response = await http.post('/api/assigned-users/search', { userId });
    setUserGroupLoading(false);
    const oneItem = response.data.list.find((item) => {
      return typeof item.teacherId === 'number';
    });
    setUserGroup(oneItem);
  };

  useEffect(() => {
    getUserGroups();
  }, []);

  return {
    userGroup,
    getUserGroups,
    isLoading,
  };
}
